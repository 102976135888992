import React from "react";

import { PresentationHeader } from "./Utils/presentationHeader";
import { renderSlideHTML, useFacilitatorDelivery } from "./hooks";
import { StartPage, FinalPage } from "./Utils/startFinalPage";
import { DynamicScreenModal } from "./Utils/dynamicScreenModal";
import { CancelPresentationModal } from "./Utils/cancelPresentationModal";
import { PresentationSlideNotes } from "../presentationsList/notes";

export const FacilitatorDelivery = () => {
  const {
    languageText,
    slideStep,
    currentSlide,
    facilitatorSlides,
    profiles,
    presentationData,
    finalPage,
    startPresentationPage,
    cancelPresentationModal,
    participantPublished,
    participantDynamicSlideModal,
    participantDynamicSlide,
    startPresentation,
    previousSlide,
    nextSlide,
    handleSlideClick,
    handleCancelClick,
    openProfileDynamicSlides,
    closeProfileDynamicSlides,
    handleCloseModal,
    handleConfirmCancelPresentation,
    publishParticipants,
    handleSelectAllProfilesForDelivery,
    handleSelectProfileForDelivery,
    handleFinalPageBackBtn,
    isConnected,
  } = useFacilitatorDelivery();

  return (
    <>
      <div>
        {startPresentationPage && (
          <StartPage
            languageText={languageText}
            startPresentation={startPresentation}
          />
        )}

        {!startPresentationPage && !finalPage && (
          <>
            <PresentationHeader
              languageText={languageText}
              slideNumber={currentSlide.slideNumber}
              facilitatorSlides={facilitatorSlides}
              profiles={profiles}
              facilitatorName={presentationData.facilitatorName}
              previousSlide={previousSlide}
              nextSlide={nextSlide}
              handleSlideClick={handleSlideClick}
              handleCancelClick={handleCancelClick}
              openProfileDynamicSlides={openProfileDynamicSlides}
              isConnected={isConnected}
            />

            {renderSlideHTML(currentSlide, slideStep)}

            <PresentationSlideNotes
              languageText={languageText}
              slide={currentSlide}
            />
          </>
        )}

        {!startPresentationPage && finalPage && (
          <FinalPage
            languageText={languageText}
            profiles={profiles}
            profilePublished={participantPublished}
            handleSelectAllProfilesForDelivery={
              handleSelectAllProfilesForDelivery
            }
            handleSelectProfileForDelivery={handleSelectProfileForDelivery}
            publishParticipants={publishParticipants}
            handleFinalPageBackBtn={handleFinalPageBackBtn}
          />
        )}

        {participantDynamicSlideModal && (
          <DynamicScreenModal
            languageText={languageText}
            slideStep={slideStep}
            participantDynamicSlide={participantDynamicSlide}
            handleCloseModal={closeProfileDynamicSlides}
          />
        )}
      </div>

      {cancelPresentationModal && (
        <CancelPresentationModal
          languageText={languageText}
          handleCloseModal={handleCloseModal}
          handleConfirmCancelPresentation={handleConfirmCancelPresentation}
        />
      )}
    </>
  );
};
