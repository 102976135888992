import { LanguageCode } from "../containers/commonEnums";
import { ILanguage } from "@app/containers/commonInterfaces";
import { ILanguageText } from "@app/containers/reducer";
import { createDefaultText } from "@app/containers/actions";
import { langKey } from "@app/consts";
import { NavigateFunction } from "react-router-dom";

export type ITranslationObject = ILanguageText;

export interface ILanguageObject {
  id: number;
  name: string;
  displayName: string;
  icon: string;
}

export type TranslateFn = (key: string) => string;

export function getLanguageValue(
  langObject: ITranslationObject,
  langKey: string,
): string {
  // sometimes this function is called with untyped & unchecked data directly
  // from the backend. that's obviously a bug somewhere else, but we don't have
  // time to track down every single invocation.
  //   -johan, 2024-06-06
  if (typeof langKey !== "string" || langKey === "") {
    return "";
  }

  const keys = [langKey, langKey.toLowerCase()];

  for (const key of keys) {
    const value = langObject[key];
    if (typeof value === "string") {
      return value;
    }
  }

  //We have to save the new keys only when the translation list is loaded. Otherwise, 1st time, for every key, this method will be called.
  if (Object.keys(langObject).length > 0) {
    //Key is not available. So inserting this key (value also same) to the backend and inject here.
    createDefaultText(langKey);
    //As we are not passing the dispatch method here, we cannot update the translations list until user reopens the page, so returning the key as value.
  }

  //We are not enlosing the value with [] as the key will be added in the backend async.
  return langKey;
}

export const reorderLanguageList = (
  languages: ReadonlyArray<ILanguage>,
): ILanguage[] => {
  // From api, languages are in alphabetical order.
  // Always English & Swedish should be 1st and 2nd, the rest alphabetically
  const order: { [key: string]: number } = {
    [LanguageCode.English]: 0,
    [LanguageCode.Swedish]: 1,
  };

  const updatedLanguages = languages.slice();

  updatedLanguages.sort((a, b) => {
    const orderA: number | undefined = order[a.name];
    const orderB: number | undefined = order[b.name];

    // Prioritize languages with defined order
    if (orderA !== undefined && orderB !== undefined) {
      return orderA - orderB;
    } else if (orderA !== undefined) {
      return -1;
    } else if (orderB !== undefined) {
      return 1;
    }

    // Maintain original order for languages not in defined order
    return 0;
  });
  return updatedLanguages;
};

export const updateURLLanguage = (
  lang: string,
  navigate: NavigateFunction,
): void => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(langKey, lang);
  navigate(
    {
      pathname: location.pathname,
      search: searchParams.toString(),
    },
    { replace: true },
  );
};
