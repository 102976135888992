import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { DigitCodeInput } from "@app/components/digitCodeInput";

interface ITwoFactorAuthProps {
  languageText: ITranslationObject;
  handleSubmit: () => unknown;
  handleChange: (value: string) => unknown;
  handleBackClick: () => unknown;

  /** Describes how many digits this input requires. */
  length: number;

  /** This value should _not_ be a translation placeholder: it should be translated before passed into this prop. */
  title: string;
  value: string;
}

export const TwoFactorAuth = (props: ITwoFactorAuthProps): JSX.Element => {
  const { languageText, handleBackClick, handleSubmit, handleChange } = props;
  const isValid =
    typeof props.value === "string" && props.value.length === props.length;

  return (
    <React.Fragment>
      <div className="px-10 login-subpages-container">
        <div className="text-center my-10">
          {/* the title should be translated outside of this component. */}
          <p className="text-dark mb-3 fs-4">{props.title}</p>
        </div>

        <DigitCodeInput
          handleChange={props.handleChange}
          handleSubmit={props.handleSubmit}
          length={props.length}
          value={props.value}
        />

        <div className="d-flex justify-content-between">
          <button
            type="button"
            onClick={handleBackClick}
            className="btn btn-light justify-content-center"
          >
            {getLanguageValue(languageText, "Back")}
          </button>
          <button
            type="submit"
            className="btn btn-success justify-content-center"
            disabled={!isValid}
          >
            {getLanguageValue(languageText, "Validate")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
