import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { IFocusError } from "../../../components/formComponents/input";
import { RootState } from "@app/store/configureStore";
import { createWord, updateWord } from "./actions";
import { getBehaveOptions } from "../interface";
import { addToast, setSpinner } from "../../actions";

export const WordInputs = {
  wordNo: "wordNo",
  leftWord: "leftWord",
  rightWord: "rightWord",
  display: "display",
  calculate: "calculate",
  isScoredLeftToRight: "isScoredLeftToRight",
  behave: "behave",
  countryCode: "countryCode",
  cultureId: "cultureId",
} as const;

export interface IAddEditWordModalProps {
  rowData: any;
  cultures: IDropdownList[];
  countriesList: IDropdownList[];
  closeWordModal: (refreshWordList: boolean) => void;
}

interface IFocusInput {
  wordNo: IFocusError;
  leftWord: IFocusError;
  rightWord: IFocusError;
  countryCode: IFocusError;
  cultureId: IFocusError;
  behave: IFocusError;
}

interface IWords {
  wordNo: string;
  leftWord: string;
  rightWord: string;
  calculate: boolean;
  display: boolean;
  behave: string;
  isScoredLeftToRight: boolean;
  countryCode: string;
  cultureId: string;
}

interface IPayloadBody {
  id?: number;
  wordNo: string;
  leftWord: string;
  rightWord: string;
  calculate: boolean;
  display: boolean;
  behave: number;
  isScoredLeftToRight: boolean;
  countryCode: number;
  idiLanguageId: number;
  englishRightWord?: string;
  englishLeftWord?: string;
}

export const useAddEditWordModal = (props: IAddEditWordModalProps) => {
  const { rowData, cultures, countriesList, closeWordModal } = props;
  const dispatch = useDispatch();
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [wordInfo, setWordInfo] = useState<IWords>({
    wordNo: "",
    leftWord: "",
    rightWord: "",
    calculate: false,
    display: false,
    behave: "",
    isScoredLeftToRight: false,
    countryCode: "",
    cultureId: "",
  });

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    wordNo: {
      touched: false,
      errorMessage: "",
    },
    leftWord: {
      touched: false,
      errorMessage: "",
    },
    rightWord: {
      touched: false,
      errorMessage: "",
    },
    countryCode: {
      touched: false,
      errorMessage: "",
    },
    cultureId: {
      touched: false,
      errorMessage: "",
    },
    behave: {
      touched: false,
      errorMessage: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let isEditClicked: boolean = false;
  if (Object.keys(rowData).length > 0) isEditClicked = true;

  const bhaveOptions = useMemo(
    () => getBehaveOptions(languageText),
    [languageText],
  );

  useEffect(() => {
    if (isEditClicked) {
      updatewordInfo(countriesList, cultures);
    }
  }, []);

  const updatewordInfo = (
    countries: IDropdownList[],
    cultures: IDropdownList[],
  ): void => {
    const updatedCountry = countries.find(
      (country) => country.value === String(rowData.countryCode),
    );
    const updatedCulture = cultures.find(
      (culture) => culture.value === String(rowData.cultureId),
    );
    const updatedBehave = bhaveOptions.find(
      (item) => item.value === String(rowData.behave),
    );

    setWordInfo({
      ...wordInfo,
      wordNo: rowData.wordNo.substring(1),
      leftWord: rowData.leftWord,
      rightWord: rowData.rightWord,
      calculate: rowData.calculate,
      display: rowData.display,
      behave: updatedBehave ? updatedBehave.value : "",
      isScoredLeftToRight: rowData.isScoredLeftToRight,
      countryCode: updatedCountry ? updatedCountry.value : "",
      cultureId: updatedCulture ? updatedCulture.value : "",
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    if (name === WordInputs.wordNo && value && isNaN(Number(value))) {
      return;
    }

    setWordInfo({
      ...wordInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setWordInfo({
      ...wordInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    if (e.target.name === WordInputs.display) {
      setWordInfo({
        ...wordInfo,
        display: e.target.checked,
      });
    } else {
      setWordInfo({
        ...wordInfo,
        [name]: e.target.checked,
      });
    }
  };

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    switch (name) {
      case WordInputs.wordNo:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Word No. is required");
        }
        break;
      case WordInputs.leftWord:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Left word is required",
          );
        }
        break;
      case WordInputs.rightWord:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Right word is required",
          );
        }
        break;
      case WordInputs.behave:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Behave value is required",
          );
        }
        break;
      case WordInputs.countryCode:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Country is required");
        }
        break;
      case WordInputs.cultureId:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Culture is required");
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !wordInfo.wordNo ||
      focusInput.wordNo.errorMessage ||
      !wordInfo.leftWord ||
      focusInput.leftWord.errorMessage ||
      !wordInfo.rightWord ||
      focusInput.rightWord.errorMessage ||
      !wordInfo.behave ||
      focusInput.behave.errorMessage ||
      !wordInfo.countryCode ||
      focusInput.countryCode.errorMessage ||
      !wordInfo.cultureId ||
      focusInput.cultureId.errorMessage
    ) {
      for (const item of Object.values(WordInputs)) {
        handleFormErrors(item, wordInfo[item]);
      }
      return false;
    }
    return true;
  };

  const handleSubmit = (e: boolean): void => {
    setIsSubmitting(true);

    const body: IPayloadBody = {
      wordNo: wordInfo.wordNo,
      leftWord: wordInfo.leftWord,
      rightWord: wordInfo.rightWord,
      calculate: wordInfo.calculate,
      display: wordInfo.display,
      behave: Number(wordInfo.behave),
      isScoredLeftToRight: wordInfo.isScoredLeftToRight,
      countryCode: Number(wordInfo.countryCode),
      idiLanguageId: Number(wordInfo.cultureId),
    };

    if (isEditClicked) {
      body.id = rowData.id ?? 0;
      body.englishRightWord = rowData.englishRightWord;
      body.englishLeftWord = rowData.englishLeftWord;
    }

    dispatch(setSpinner(true));
    if (isEditClicked) {
      updateWord(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast("Changes saved successfully") as AnyAction);
            closeAddEditWordModal(true);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    } else {
      createWord(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast("Word created successfully") as AnyAction);
            closeAddEditWordModal(true);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    }
  };

  const onSubmitClick = (): void => {
    if (!handleValidationOnSubmit()) return;
    else if (isEditClicked) {
      handleSubmit(false);
    } else {
      handleSubmit(true);
    }
  };

  const closeAddEditWordModal = (value: boolean) => {
    closeWordModal(value);
  };

  const closeModal = () => closeAddEditWordModal(false);

  return {
    isEditClicked,
    languageText,
    wordInfo,
    focusInput,
    isSubmitting,
    countriesList,
    cultures,
    bhaveOptions,
    onSubmitClick,
    handleCheckboxChange,
    handleDropdownSelect,
    closeModal,
    handleBlurEvent,
    handleInputChange,
  };
};
