import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IFetchUsersBody } from "./hooks";
import { IClients, IUsers } from "../commonInterfaces";
import { ApiResponse, ApiResult } from "@app/types";
import { AccessTokenScope, setLoggedInUserRole } from "../auth/signUp/actions";
import { RoleEnum } from "../commonEnums";
import { UserId } from "../reducer";

export const getUserList = async (
  body: IFetchUsersBody,
  dispatch: Dispatch,
): Promise<ApiResult<IUsers>> => {
  try {
    const response = await api.post(
      apiEndPoints.getUsers,
      JSON.stringify(body),
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

type GetUserByIdRole = {
  roleId: RoleEnum;
  roleName: string | null;
  clientId: number;
  isDefaultClient: boolean;
};

type GetUserByIdResult = {
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  isEmailConfirmed: boolean;
  isTwoFactorEnabled: boolean;
  isLockoutEnabled: boolean;
  roles: ReadonlyArray<GetUserByIdRole>;
  isActive: boolean;
  userClients: ReadonlyArray<IClients>;
  languageId: number;
  participantLanguageId: number | null;
  isConsultant: boolean;
  shouldChangePasswordOnNextLogin: boolean;
  fromDate?: string | null;
  toDate?: string | null;
  sourceType: string;
  sourceAddress: string;
  isManager: boolean;
};

export const getUserById = async (
  userId: UserId,
  dispatch: Dispatch,
): Promise<GetUserByIdResult> => {
  try {
    const response = await api.get<ApiResponse<GetUserByIdResult>>(
      apiEndPoints.getUserById,
      {
        params: { id: userId },
      },
    );
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAdminUserList = async (
  body: IFetchUsersBody,
  dispatch: Dispatch,
): Promise<ApiResult<IUsers>> => {
  try {
    const response = await api.post(
      apiEndPoints.getAdminUsers,
      JSON.stringify(body),
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteUser = async (
  userId: UserId,
  dispatch: Dispatch,
): Promise<unknown> => {
  const paramQuery = {
    id: userId,
  };
  try {
    const response = await api.delete<ApiResponse<unknown>>(
      apiEndPoints.deleteUser,
      {
        params: paramQuery,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

type ImpersonatedAuthenticateResultModel = {
  accessToken: AccessTokenScope;
  expireInSeconds: number;
};

export const impersonatedAuthenticate = async (
  userId: UserId,
  dispatch: Dispatch,
  roleId: RoleEnum,
): Promise<ImpersonatedAuthenticateResultModel> => {
  const body = {
    userId: userId,
    roleId: roleId,
  };

  try {
    const response = await api.post<
      ApiResponse<ImpersonatedAuthenticateResultModel>
    >(apiEndPoints.impersonatedAuthenticate, body);
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    const roleName = response.data.result.accessToken.roleName;
    dispatch(setLoggedInUserRole(roleName));
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
