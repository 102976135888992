import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addToast,
  clearNotifications,
  setNotification,
  setSpinner,
} from "../../actions";
import { RootState } from "@app/store/configureStore";
import { createEmployees, isEmployeeExists } from "../actions";
import {
  ICreateEmployeesBody,
  IEmployeeTableProps,
  ITableHeaders,
} from "../uploadEmployeeModal/hooks";
import { ValidationHelper } from "../../validationHelper";
import {
  getWindowDimensions,
  IWindowDimensions,
} from "../../../commonUtils/screenWidthHelper";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { E164Number } from "libphonenumber-js/types";
import { AnyAction } from "redux";
import { UserId } from "@app/containers/reducer";

export interface IAddEmployeeModalProps {
  closeAddEmployeeModal: (refreshEmployeeList: boolean) => void;
}

export interface ICountryCode {
  country: string;
}

export const useAddEmployee = (props: IAddEmployeeModalProps) => {
  const { closeAddEmployeeModal } = props;
  const dispatch = useDispatch();

  const defaultClient = useSelector(
    (state: RootState) => state.loginReducer.defaultClient,
  );
  const defaultLanguageId = useSelector(
    (state: RootState) => state.loginReducer.userData.selectedLanguageId,
  );

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions(),
  );
  const [employees, setEmployees] = useState<IEmployeeTableProps[]>([
    {
      id: 0 as UserId,
      name: "",
      surname: "",
      emailAddress: "",
      phoneNumber: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      roleId: 0,
      roleText: "",
      noOfRespondents: 0,
    },
  ]);

  const addRow = () => {
    setEmployees([
      ...employees,
      {
        id: 0 as UserId,
        name: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        roleId: 0,
        roleText: "",
        noOfRespondents: 0,
      },
    ]);
  };

  const tableHeaders: ITableHeaders[] = [
    {
      name: getLanguageValue(languageText, "Firstname"),
      icon: windowDimensions.width < 600 ? "" : "bi bi-person-fill",
      required: windowDimensions.width < 600 ? false : true,
    },
    {
      name: getLanguageValue(languageText, "Lastname"),
      icon: windowDimensions.width < 600 ? "" : "bi bi-person-fill",
      required: windowDimensions.width < 600 ? false : true,
    },
    {
      name: getLanguageValue(languageText, "Email"),
      icon: windowDimensions.width < 600 ? "" : "bi bi-envelope-fill",
      required: windowDimensions.width < 600 ? false : true,
    },
    {
      name: getLanguageValue(languageText, "Phonenumber"),
      icon: windowDimensions.width < 600 ? "" : "bi bi-phone-fill",
      required: false,
    },
  ];

  useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closeModal = (): void => closeAddModal(false);

  const closeAddModal = (value: boolean): void => {
    closeAddEmployeeModal(value);
  };

  const deleteRow = (index: number): void => {
    setEmployees(employees.filter((_, i) => i !== index));
  };

  const validateFirstName = (value: string | any[], index: number): void => {
    let error = "";
    if (value.length === 0) {
      error = getLanguageValue(languageText, "Firstname is required");
    }
    setEmployees((preEmployees) => {
      const updatedEmployees = [...preEmployees];
      updatedEmployees[index].firstNameError = error;
      return updatedEmployees;
    });
  };

  const validateLastName = (value: string | any[], index: number): void => {
    let error = "";
    if (value.length === 0) {
      error = getLanguageValue(languageText, "Lastname is required");
    }
    setEmployees((preEmployees) => {
      const updatedEmployees = [...preEmployees];
      updatedEmployees[index].lastNameError = error;
      return updatedEmployees;
    });
  };

  const validatePhoneNumber = (value: E164Number, index: number): void => {
    let error = "";
    const phoneNumber = parseInt(value);

    if (phoneNumber === 0) {
      error = "";
    } else if (value.length >= 1 && value.length <= 4) {
      error = getLanguageValue(languageText, "Invalid phonenumber");
    } else {
      error = "";
    }
    setEmployees((preEmployees) => {
      const updatedEmployees = [...preEmployees];
      updatedEmployees[index].phoneNumberError = error;
      return updatedEmployees;
    });
  };

  const validateEmail = async (value: string, index: number): Promise<void> => {
    const email = value;
    const clientId: number = defaultClient.defaultClientId;

    const updatedEmployees = [...employees];
    if (!value) {
      updatedEmployees[index].emailError = getLanguageValue(
        languageText,
        "Email is required",
      );
    } else if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)) {
      updatedEmployees[index].emailError = getLanguageValue(
        languageText,
        "Invalid email",
      );
    } else {
      try {
        const response = await isEmployeeExists(email, clientId, dispatch);
        if (response.result) {
          updatedEmployees[index].emailError = getLanguageValue(
            languageText,
            "Email already exists",
          );
        } else {
          updatedEmployees[index].emailError = "";
        }
      } catch (error) {
        updatedEmployees[index].emailError = getLanguageValue(
          languageText,
          "Failed checking email",
        );
        throw error;
      }
    }
    setEmployees(updatedEmployees);
  };

  const handleChange = (
    e: { target: { value: any } },
    index: number,
    field: string | number,
  ): void => {
    const updatedEmployees = [...employees];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      [field]: e.target.value,
    };
    setEmployees(updatedEmployees);
  };

  const handlePhoneInputChange = (
    value: E164Number,
    index: number,
    field: string | number,
  ): void => {
    const updatedEmployees = [...employees];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      [field]: value,
    };
    setEmployees(updatedEmployees);
  };

  const handleValidationOnSubmit = async (): Promise<boolean> => {
    const updatedEmployees = [...employees];
    let isValid = true;

    for (let i = 0; i < updatedEmployees.length; i++) {
      const employee = updatedEmployees[i];

      if (!employee.name) {
        employee.firstNameError = getLanguageValue(
          languageText,
          "Firstname is required",
        );
        isValid = false;
      }

      if (!employee.surname) {
        employee.lastNameError = getLanguageValue(
          languageText,
          "Lastname is required",
        );
        isValid = false;
      }

      if (employee.phoneNumberError) {
        isValid = false;
      }

      if (!employee.emailAddress) {
        employee.emailError = getLanguageValue(
          languageText,
          "Email is required",
        );
        isValid = false;
      } else if (!ValidationHelper.isEmailValid(employee.emailAddress)) {
        employee.emailError = getLanguageValue(languageText, "Invalid email");
        isValid = false;
      }
    }

    setEmployees(updatedEmployees);
    return isValid;
  };

  const onSubmit = (): void => {
    dispatch(clearNotifications(""));
    const emailAddresses = employees.map((row) => row.emailAddress);
    const hasDuplicates = emailAddresses.some(
      (email, index) => emailAddresses.indexOf(email) !== index,
    );
    if (hasDuplicates) {
      const notificationMessage = "Duplicate email addresses found";
      dispatch(setNotification(notificationMessage));
      return;
    }
    if (employees.length !== 0) {
      const filteredData: ICreateEmployeesBody[] = employees.map((row) => {
        const { ...rest } = row;
        return {
          ...rest,
          clientId: defaultClient.defaultClientId,
          tenetId: 1,
          languageId: defaultLanguageId || undefined,
          name: rest.name ?? "",
          surname: rest.surname ?? "",
          emailAddress: rest.emailAddress ?? "",
          phoneNumber: rest.phoneNumber ?? "",
          isDefaultClient: true,
        };
      });
      dispatch(setSpinner(true));
      createEmployees(filteredData, dispatch)
        .then((response) => {
          const numberOfEmployeesCreated: number = filteredData.length;
          setEmployees([]);
          dispatch(
            addToast(
              numberOfEmployeesCreated > 1
                ? "Users created successfully"
                : "User created successfully",
            ) as AnyAction,
          );
          closeAddEmployeeModal(true);
        })
        .finally(() => {
          dispatch(setSpinner(false));
        });
    }
  };

  const onSubmitClick = async (): Promise<void> => {
    const isValid = await handleValidationOnSubmit();
    if (!isValid) return;
    onSubmit();
  };

  return {
    languageText,
    employees,
    tableHeaders,
    onSubmitClick,
    closeModal,
    addRow,
    handleChange,
    validateEmail,
    validateFirstName,
    validateLastName,
    deleteRow,
    validatePhoneNumber,
    handlePhoneInputChange,
  };
};
