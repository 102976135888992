import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { ISavedMessage } from "./invitationMessageListModal";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";

interface ICustomActionsCellProps {
  languageText: ITranslationObject;
  selectedMessage: ISavedMessage;
  handleEditClick: (selectedMessage: ISavedMessage) => void;
  handleDeleteClick: (selectedMessage: ISavedMessage) => void;
}

export const MessageActionsCell: React.FC<ICustomActionsCellProps> = (
  props,
) => {
  const { languageText, selectedMessage, handleEditClick, handleDeleteClick } =
    props;

  const onEditClick = (): void => {
    handleEditClick(selectedMessage);
  };

  const onDeleteClick = (): void => {
    handleDeleteClick(selectedMessage);
  };

  const dropdownItems: ReadonlyArray<DropdownMenuItem> = [
    {
      name: getLanguageValue(languageText, "Edit"),
      onClick: onEditClick,
    },
    {
      name: getLanguageValue(languageText, "Delete"),
      onClick: onDeleteClick,
    },
  ];

  return <DropdownMenu items={dropdownItems} />;
};
