import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import * as React from "react";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { Input } from "@app/components/formComponents/input";
import { Checkbox } from "@app/components/formComponents/checkbox";
import { useDispatch } from "react-redux";
import { IConsent } from "../auth/signUp/reducer";
import { TextEditor } from "@app/components/textEditor/textEditor";

type EditConsentModalProps = {
  lang: ITranslationObject;
  consent: IConsent;
  onConsentChange: (consent: IConsent) => void;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  handleSave: (event: React.MouseEvent<HTMLElement>) => void;
};

export const EditConsentModal: React.FC<EditConsentModalProps> = (props) => {
  const isEditing = typeof props.consent.id !== "undefined";
  const dispatch = useDispatch();

  return (
    <ModalComponent
      width="xl"
      headerText={getLanguageValue(
        props.lang,
        isEditing ? "Edit consent" : "Create consent",
      )}
      cancelButtonText={getLanguageValue(props.lang, "Dismiss")}
      handleCancelClick={props.handleClose}
      submitButtonText={getLanguageValue(props.lang, "Save")}
      handleSubmitClick={props.handleSave}
    >
      <Input
        label={getLanguageValue(props.lang, "Title")}
        value={props.consent.title}
        placeholder={getLanguageValue(props.lang, "Title")}
        handleInputChange={(event) => {
          props.onConsentChange({
            ...props.consent,
            title: event.target.value,
          });
        }}
      />
      <TextEditor
        label={getLanguageValue(props.lang, "Body")}
        value={props.consent.body}
        placeholder={getLanguageValue(props.lang, "Body")}
        name="body"
        handleTextEditor={(value) => {
          props.onConsentChange({
            ...props.consent,
            body: value,
          });
        }}
        rows={8}
      />
      <Input
        label={getLanguageValue(props.lang, "Version")}
        value={props.consent.version}
        placeholder={getLanguageValue(props.lang, "Version")}
        handleInputChange={(event) => {
          props.onConsentChange({
            ...props.consent,
            version: event.target.value,
          });
        }}
      />
      <Checkbox
        label={getLanguageValue(props.lang, "Active")}
        value={props.consent.isActive}
        handleCheckboxChange={(box) => {
          props.onConsentChange({
            ...props.consent,
            isActive: box.target.checked,
          });
        }}
      />
    </ModalComponent>
  );
};
