import React from "react";

import { useParticipantAcademy } from "./hooks";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { limitTextAndEllipses } from "../../commonUtils/limitTextAndEllipsesHelper";
import { CourseCard } from "./coursesList/courseCard";
import { CourseProgressBar } from "./coursesList/courseCard/courseProgressBar";
import { ClassNames } from "../commonEnums";
import { CourseLanguageSelector } from "./courseLanguageSelector/courseLanguageSelector";

export const ParticipantAcademy = (): JSX.Element => {
  const { languageText, categories, allCourses, handleContinueClick } =
    useParticipantAcademy();

  return (
    <div className="p-2 pt-4 p-md-4">
      <CourseLanguageSelector />

      <h3 className="mb-4 fw-bold">
        {getLanguageValue(languageText, "Academy")}
      </h3>

      <div className="row w-100 mx-0 px-md-3 px-2 bg-primary bg-gradient bg-opacity-10 rounded">
        <div className="col-md-7 my-4">
          {`${getLanguageValue(languageText, "Welcome to IDI academy")}`}
        </div>
        <div></div>
        {categories.length > 0 ? (
          categories.map((category, i) => (
            <div key={category.id} className="col-12 col-md-4 mb-4">
              <div
                id={i === 0 ? "academyInstruction3" : ""}
                className="card bg-white border-0 h-100"
              >
                <div className="card-body p-4">
                  <h4 className="fw-bold text-primary">{category.name}</h4>
                  <div className="d-flex flex-wrap mt-3">
                    <div className="d-flex align-items-center flex-nowrap small mb-2 text-secondary">
                      <i className="bi bi-folder me-2 fs-6" />
                      {category.coursesCount}{" "}
                      {getLanguageValue(languageText, "allCourses")}
                      <i className="bi bi-book me-2 fs-6 ms-3" />
                      {category.lessonsCount}{" "}
                      {getLanguageValue(languageText, "lessons")}
                      <i className="bi bi-bookmark me-2 fs-6 ms-3" />
                      {category.topic}
                    </div>
                    <div className="py-3">
                      <span className="ellipsis-text">
                        {category.description &&
                          limitTextAndEllipses({
                            text: category.description,
                            limit: 150,
                          })}
                      </span>
                    </div>

                    <CourseProgressBar
                      id={i === 0 ? "academyInstruction4" : ""}
                      bgColor={ClassNames.White_bg}
                      progress={category.progress}
                    />
                  </div>
                </div>
                <div
                  className="card-footer p-3 border-0 bg-primary bg-opacity-25 btn-lg"
                  onClick={() => handleContinueClick(category.id)}
                  role="button"
                >
                  {getLanguageValue(languageText, "Continue")}
                  <i className="bi bi-arrow-right ps-2 text-dark" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="fs-3 fw-bold">
            {getLanguageValue(languageText, "No academy categories")}
          </div>
        )}
      </div>
      {allCourses && allCourses.length > 0 && (
        <div className="row g-4 mt-4">
          {allCourses.map((course, idx) => (
            <div className="col-md-4" key={idx}>
              <CourseCard
                index={idx}
                key={course.id}
                languageText={languageText}
                course={course}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
