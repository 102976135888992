import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { ProfileStatus } from "../../../profileEnums";
import { IEditProfileByIdAndStatus } from "../../interface";
import { Checklabel } from "@app/components/Checklabel";

interface IProfileParticipantFormProps {
  languageText: ITranslationObject;
  status: number;
  respondentMsg1: string;
  respondentMsg2: string;
  respondentsAnswered: number;
  respondentsInvited: number;

  profileParticipantData: IEditProfileByIdAndStatus;
}

export const ProfileParticipantForm = (
  props: IProfileParticipantFormProps,
): JSX.Element => {
  return (
    <div id="editParticipantFormstatus">
      <div className="fs-5 fw-bold mb-2">
        {getLanguageValue(props.languageText, "Progress")}
      </div>

      <div className="row">
        {/* Self form info */}
        <div className="col-lg-4">
          <h6 className="my-2">
            {getLanguageValue(props.languageText, "Self form")}
          </h6>
          <Checklabel
            checked={props.status !== ProfileStatus.New}
            checkedLabel={getLanguageValue(
              props.languageText,
              "Self form is filled",
            )}
            uncheckedLabel={getLanguageValue(
              props.languageText,
              "Self form is not filled",
            )}
          />
        </div>
        {/* Respondents info */}
        <div className="col-lg-8">
          <h6 className="my-md-2 mt-4">
            {getLanguageValue(props.languageText, "Respondent status")}
          </h6>
          <Checklabel
            checked={
              props.profileParticipantData.respondentsAnswered >=
              props.profileParticipantData.noOfRespondents
            }
            checkedLabel={`${props.profileParticipantData.respondentsInvited}/${props.profileParticipantData.respondentsAnswered} (${props.profileParticipantData.noOfRespondents}) - ${props.respondentMsg2}`}
            uncheckedLabel={`${props.profileParticipantData.respondentsInvited}/${props.profileParticipantData.respondentsAnswered} (${props.profileParticipantData.noOfRespondents}) - ${props.respondentMsg1}`}
          />
        </div>
      </div>
      <div className="row">
        {/* Profile downloaded */}
        <div className="col-lg-4">
          <h6 className="my-2">
            {getLanguageValue(props.languageText, "Profile download")}
          </h6>
          <Checklabel
            checked={props.profileParticipantData.pdfProfileDownloaded}
            checkedLabel={getLanguageValue(
              props.languageText,
              "Profile is downloaded",
            )}
            uncheckedLabel={getLanguageValue(
              props.languageText,
              "Profile is NOT downloaded",
            )}
          />
        </div>

        <div className="col-lg-4">
          <h6 className="my-2">
            {getLanguageValue(props.languageText, "Profile delivered")}
          </h6>
          <Checklabel
            checked={props.status === ProfileStatus.Delivery}
            checkedLabel={getLanguageValue(
              props.languageText,
              "Profile is delivered",
            )}
            uncheckedLabel={getLanguageValue(
              props.languageText,
              "Profile is NOT delivered",
            )}
          />
        </div>
      </div>
      <div className="border-bottom my-4"></div>
    </div>
  );
};
