import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getNewsById } from "../actions";
import { RootState } from "@app/store/configureStore";
import { getMediaImageFile, setSpinner } from "../../../../containers/actions";

import { routePath } from "../../../routePaths";
import { INewsList, NewsType } from "../../newsList/types";
import { UserGeneratedMediaType } from "../../../commonEnums";

export const useGetNewsById = () => {
  const dispatch = useDispatch();
  const viewId = Number(useParams().id) || 0;
  const navigate = useNavigate();

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [latestNews, setLatestNews] = useState<INewsList>({
    excerpt: "",
    id: 0,
    image: "",
    post: "",
    published: false,
    title: "",
    type: "",
    imageFile: null,
    publishedDate: new Date().toISOString(),
    tenantId: null,
  });
  const [newsType, setNewsType] = useState<number>(0);

  useEffect(() => {
    if (location.pathname.includes(routePath.latestNews)) {
      setNewsType(NewsType.News);
    } else if (location.pathname.includes(routePath.latestBlogs)) {
      setNewsType(NewsType.Blogs);
    }
  }, [location.pathname]);

  const getNews = (viewId: number): void => {
    dispatch(setSpinner(true));
    getNewsById(viewId, dispatch)
      .then(async (fetchedNews) => {
        if (fetchedNews) {
          const fetchedImage: string = await getMediaImageFile(
            fetchedNews.id,
            UserGeneratedMediaType.News,
            dispatch,
          );

          fetchedNews.image = fetchedImage;
          setLatestNews(fetchedNews);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (viewId) getNews(viewId);
  }, [viewId]);

  const handleNavigateBack = (): void => {
    navigate(-1);
  };

  return {
    languageText,
    newsType,
    latestNews,
    handleNavigateBack,
  };
};
