import { Dispatch } from "redux";
import { api, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IAuditLogGetData } from "./interface";

interface IAuditLogResult {
  totalCount: number;
  items: [];
}

export const getAuditLogList = async (
  body: IAuditLogGetData,
  dispatch: Dispatch,
): Promise<IAuditLogResult> => {
  try {
    const response = await api.get(apiEndPoints.getAuditLogs, {
      params: body,
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
