import React from "react";

import { CourseCardFooter } from "./courseCardFooter";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { ICourse, ILesson } from "../../types";
import { ConvertCourseDurationToMinutes } from "../../../../commonUtils/coursesHelper";

interface ICourseCardProps {
  index: number;
  course: ICourse | ILesson;
  languageText: ITranslationObject;
}

export const CourseCard = (props: ICourseCardProps): JSX.Element => {
  const { index, languageText, course } = props;

  return (
    <div
      id={index === 0 ? "academyInstruction7" : ""}
      className={`card h-100 rounded ${
        !(course && course.progress !== undefined && course.progress < 100)
          ? "bg-success border-0 bg-opacity-10"
          : "bg-white border-0 shadow-sm"
      }`}
    >
      <div className="card-body p-4">
        {course.markAsFinish && (
          <i className="bi bi-check-square-fill text-success float-end fs-3"></i>
        )}
        <img src={course.logo} height={45} />
        <h4 className="fw-bolder mt-4">{course.title && course.title}</h4>
        <div className="d-flex flex-wrap mt-3">
          <div className="d-flex align-items-center me-4 mb-2">
            <i className="bi bi-file-earmark me-2 fs-4"></i>
            {course.partsCount} {getLanguageValue(languageText, "parts")}
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="bi bi-stopwatch me-2 fs-4" />
            <span>
              {ConvertCourseDurationToMinutes(course.duration)}{" "}
              {getLanguageValue(languageText, "mins")}
            </span>
          </div>
        </div>
        <div className="my-2">
          {/* Parent Categories */}
          {(course as ICourse)?.parentCategories &&
            (course as ICourse).parentCategories.length > 0 &&
            [...(course as ICourse).parentCategories]
              .reverse()
              .map((info, idx) => (
                <span className="small" key={idx}>
                  {info.name} /{" "}
                </span>
              ))}

          {/* Sub Category */}
          {(course as ICourse)?.category && (
            <span className="small">{(course as ICourse).category.name}</span>
          )}
        </div>
        <div className="mb-3">
          {course.tags &&
            Array.isArray(course.tags) &&
            course.tags.map((tag, index) => (
              <span
                key={index}
                className="py-1 px-2 small rounded bg-success text-white me-1"
              >
                {tag.name}
              </span>
            ))}
        </div>

        <p className="academy-card-text-ellipsis text-gray">
          {course.description}
        </p>
      </div>
      <CourseCardFooter
        index={index}
        progress={course.progress ?? 0}
        courseId={course.id}
        languageText={languageText}
        canStartCourse={!!course.canStartCourse}
      />
    </div>
  );
};
