import { Dispatch } from "redux";
import { api, apiEndPoints } from "../../api";
import { setNotification } from "../actions";

export async function resetPassword(body: any, dispatch: Dispatch) {
  try {
    const response = await api.post(
      apiEndPoints.resetPassword,
      JSON.stringify(body),
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
}
