import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { ILanguageResponse } from "@app/components/languageNavbarComponent/hooks";
import { ILanguage } from "@app/containers/commonInterfaces";
import { reorderLanguageList } from "@app/commonUtils/languageFunctionsHelper";

export type LanguageTextKey = {
  key: string;
};

export type LanguageTextKeyPair = {
  key: string;
  allowAdminRole: boolean;
  allowFacilitatorRole: boolean;
  allowParticipantRole: boolean;
  allowRespondentRole: boolean;
  notes: string;
  values: { [lang: string]: string };
};

export const deleteLanguageText = async (
  body: LanguageTextKey,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    await api.delete(apiEndPoints.deleteLanguageText, {
      data: body,
    });
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export type LanguageNameValue = {
  languageName: string;
  value: string;
};

export type CreateOrUpdateLanguageTextBody = {
  key: string;
  oldKey: string | undefined;
  allowAdminRole: boolean;
  allowFacilitatorRole: boolean;
  allowParticipantRole: boolean;
  allowRespondentRole: boolean;
  notes: string;
  languageNameValues: LanguageNameValue[];
};

export const createOrUpdateLanguageText = async (
  body: CreateOrUpdateLanguageTextBody,
  dispatch: Dispatch,
): Promise<unknown> => {
  try {
    const response = await api.post(
      apiEndPoints.createOrUpdateLanguageText,
      JSON.stringify(body),
      {},
    );
    return response.status;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getFacilitatorLanguages = async (
  dispatch: Dispatch,
): Promise<ILanguage[]> => {
  try {
    const response = await api.get(apiEndPoints.getFacilitatorLanguages);
    const result: ILanguageResponse = response?.data?.result;
    if (result) {
      result.items = reorderLanguageList(result.items);
    }
    return result.items;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getParticipantLanguages = async (
  dispatch: Dispatch,
): Promise<ILanguage[]> => {
  try {
    const response = await api.get(apiEndPoints.getParticipantLanguages);
    const result: ILanguageResponse = response?.data?.result;
    if (result) {
      result.items = reorderLanguageList(result.items);
    }
    return result.items;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
