import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { IActivityInfo } from "./activityDescriptionStep";
import { ISendParticipantsInvitationBody } from "./activityInviteParticipantsStep";
import { IFetchParticipantsParams } from "./activityParticipantsStep";
import { ICreateUpdateMessageBody } from "./emailSMSInvitation/addEditMessage";
import { ISavedMessagesBody } from "./emailSMSInvitation/invitationMessageListModal";
import {
  ICreateProfileBody,
  IRoleStepBody,
  IUpdateActivityClientBody,
  IUpdateActivityRespondentsBody,
  IUpdateActivityTypeBody,
} from "./hooks";
import { ActivityId } from "@app/containers/reducer";
import { ApiResponse, ApiResult } from "@app/types";
import { IEmployee } from "@app/containers/employeeList/hooks";
import { IEmployeeTableProps } from "@app/containers/employeeList/uploadEmployeeModal/hooks";

export const updateActivityType = async (
  body: IUpdateActivityTypeBody,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateActivityType,
      JSON.stringify(body),
      {
        params: body,
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createActivityCulture = async (
  body: IActivityInfo,
  dispatch: Dispatch,
): Promise<ActivityId> => {
  try {
    const response = await api.post(
      apiEndPoints.createActivityCulture,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject();
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateActivityCulture = async (
  body: IActivityInfo,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateActivityCulture,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject();
    }
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateActivityRole = async (
  body: IRoleStepBody,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateActivityRole,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject();
    }
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateActivityRespondents = async (
  body: IUpdateActivityRespondentsBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.put(
      apiEndPoints.updateActivityRespondents,
      JSON.stringify(body),
      {},
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getProfileEmployeesByClientId = async (
  body: IFetchParticipantsParams,
  dispatch: Dispatch,
): Promise<ApiResult<IEmployee>> => {
  try {
    const response = await api.get<ApiResponse<ApiResult<IEmployee>>>(
      apiEndPoints.getProfileEmployeesByClientId,
      {
        params: body,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createProfileParticipants = async (
  body: ICreateProfileBody[],
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.createProfileParticipants,
      JSON.stringify(body),
      {
        params: { activityId },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createParticipantsFromEditProfile = async (
  body: ICreateProfileBody[],
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.createParticipantsFromEditProfile,
      JSON.stringify(body),
      {
        params: { activityId },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllSavedMessages = async (
  body: ISavedMessagesBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getAllSavedMessages, {
      params: body,
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createSavedMessage = async (
  body: ICreateUpdateMessageBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.createSavedMessage,
      JSON.stringify(body),
      {},
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateSavedMessage = async (
  body: ICreateUpdateMessageBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.put(
      apiEndPoints.updateSavedMessage,
      JSON.stringify(body),
      {},
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteSavedMessage = async (
  savedMessageId: number,
  dispatch: Dispatch,
): Promise<any> => {
  const paramQuery = {
    id: savedMessageId,
  };
  try {
    const response = await api.delete(apiEndPoints.deleteSavedMessage, {
      params: paramQuery,
    });
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const sendParticipantsInvitation = async (
  body: ISendParticipantsInvitationBody[],
  languageCode: string,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.sendParticipantsInvitation,
      JSON.stringify(body),
      {
        params: { languageCode },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const previewParticipantInvitation = async (
  body: ISendParticipantsInvitationBody,
  languageCode: string,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.previewParticipantInvitation,
      JSON.stringify(body),
      {
        params: { languageCode },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateActivityClient = async (
  body: IUpdateActivityClientBody,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateActivityClient,
      JSON.stringify(body),
      {
        params: body,
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
