import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";

interface ICoursesActionsCellProps {
  id: number;
  name: string;
  isEnabled: boolean;
  languageText: ITranslationObject;
  handleEditClick: (id: number) => void;
  handleDeleteClick: (id: number, name: string) => void;
  handleChangeActiveStatusClick: (id: number, isEnabled: boolean) => void;
  handleCoursePermission: (id: number, name: string) => void;
}

export const CoursesActionsCell: React.FC<ICoursesActionsCellProps> = (
  props,
) => {
  const {
    id,
    name,
    isEnabled,
    languageText,
    handleEditClick,
    handleDeleteClick,
    handleChangeActiveStatusClick,
    handleCoursePermission,
  } = props;

  const onEditClick = (): void => {
    handleEditClick(id);
  };

  const onDeleteClick = (): void => {
    handleDeleteClick(id, name);
  };

  const onChangeActiveStatus = (): void => {
    handleChangeActiveStatusClick(id, isEnabled);
  };

  const showCoursePermission = () => {
    handleCoursePermission(id, name);
  };

  const dropdownItems: Array<DropdownMenuItem> = [
    { name: getLanguageValue(languageText, "Edit"), onClick: onEditClick },
    { name: getLanguageValue(languageText, "Delete"), onClick: onDeleteClick },
    {
      name: isEnabled
        ? getLanguageValue(languageText, "Disable course")
        : getLanguageValue(languageText, "Enable course"),
      onClick: onChangeActiveStatus,
    },
    {
      name: getLanguageValue(languageText, "Permission"),
      onClick: showCoursePermission,
    },
  ];

  return <DropdownMenu items={dropdownItems} />;
};
