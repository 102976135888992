import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";

import { PresentationActionsCell } from "./presentationActionsCell";
import { BooleanCell } from "../../components/reactTable/booleanCell";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { routePath } from "../routePaths";
import { RootState } from "@app/store/configureStore";
import { deletePresentationTemplate } from "./actions";
import { setSpinner } from "../actions";
import { IPresentationSlide } from "../facilitatorDelivery/hooks";
import { useApiEndpoints } from "@app/api/end-points";

export interface IPresentationList {
  id: number;
  description: string;
  language: number;
  languageText: string;
  isActive: boolean;
  enableAccessControl: boolean;
}

export type ImportExportPresentationTemplateSlides = {
  schemaVersion: number;
  slides: ReadonlyArray<unknown>;
};

export const usePresentationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = useApiEndpoints(dispatch);
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [data, setData] = useState<IPresentationList[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openPresentationModal, setOpenPresentationModal] =
    useState<boolean>(false); // Add/Edit presentation popup
  const [presentationUpdated, setPresentationUpdated] =
    useState<boolean>(false); // This flag is used to re-reder the presentation data on requirement
  const [
    openPermissionModalForPresentation,
    setOpenPermissionModalForPresentation,
  ] = useState<IPresentationList | undefined>(undefined);

  const fetchPresentations = (skipCount: number): void => {
    dispatch(setSpinner(true));
    api
      .getAllPresentationTemplates(filter, sorting, pageLimit, skipCount)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setData(response.items.slice());
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (presentationUpdated) setPresentationUpdated(false);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchPresentations(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchPresentations(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  useEffect(() => {
    if (presentationUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchPresentations(skipCount);
    }
  }, [presentationUpdated]);

  const createTemplateClick = (): void => {
    setOpenPresentationModal(true);
  };

  const refreshPresentationList = (): void => setPresentationUpdated(true);

  const closePresentationModal = (): void => {
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenPresentationModal(false);
  };

  const handleEditClick = (item: IPresentationList): void => {
    setEditedRowId(item.id);
    setOpenPresentationModal(true);
  };

  const handleDeleteClick = (item: IPresentationList): void => {
    setDeleteRowId(item.id);
    setSelectedActionRowName(item.description);
  };

  const handleDeletePresentation = (): void => {
    deletePresentationTemplate(deleteRowId, dispatch).then((response) => {
      if (response) {
        setDeleteRowId(0);
        setSelectedActionRowName("");
        setPresentationUpdated(true);
      }
    });
  };

  const handleSlideListClick = (item: IPresentationList): void => {
    navigate(routePath.slidesList.replace(":id", item.id.toString()));
  };

  const handlePermissionsClick = (item: IPresentationList) => {
    setOpenPermissionModalForPresentation(item);
  };

  const handlePreviewClick = (item: IPresentationList) => {
    const url = routePath.previewPresentationTemplate.replace(
      ":id",
      String(item.id),
    );
    window.open(url);
  };

  const handlePreviewSelfDeliveryClick = (item: IPresentationList) => {
    const url = routePath.previewPresentationTemplateSelfDelivery.replace(
      ":id",
      String(item.id),
    );
    window.open(url);
  };

  const closeDeleteModal = (): void => {
    setDeleteRowId(0);
  };

  const closePermissionModal = () => {
    setOpenPermissionModalForPresentation(undefined);
  };

  const tableHeader = useMemo<ColumnDef<IPresentationList, unknown>[]>(
    () => [
      {
        header: getLanguageValue(languageText, "Actions"),
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <PresentationActionsCell
            languageText={languageText}
            presentation={props.row.original}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            handleSlideListClick={handleSlideListClick}
            handlePermissionsClick={handlePermissionsClick}
            handlePreviewClick={handlePreviewClick}
            handlePreviewSelfDeliveryClick={handlePreviewSelfDeliveryClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Description"),
        accessorKey: "description",
      },
      {
        header: getLanguageValue(languageText, "Language"),
        accessorKey: "languageText",
      },
      {
        header: getLanguageValue(languageText, "Active"),
        accessorKey: "isActive",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.isActive}
          />
        ),
      },
    ],
    [languageText],
  );

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openPresentationModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    createTemplateClick,
    refreshPresentationList,
    closePresentationModal,
    handleDeletePresentation,
    closeDeleteModal,
    openPermissionModalForPresentation,
    closePermissionModal,
  };
};

export type PresentationPosition = {
  slideIndex: number;
  slideStepIndex: number | null;
};

export type PresentationControls = {
  position: PresentationPosition;
  setPosition: (next: PresentationPosition) => void;
  next: () => PresentationPosition;
  previous: () => PresentationPosition;
};

/**
 * Refer to 'usePresentationControls' for the stateful React version.
 * @see usePresentationControls
 */
export function createStatelessPresentationControls(
  slides: ReadonlyArray<IPresentationSlide>,
  position: PresentationPosition,
): PresentationControls {
  function next(): PresentationPosition {
    const slide = slides[position.slideIndex];

    if (!slide) {
      return position;
    }

    const numStepsForSlide = slide.slideSteps.length;
    const wantsNextStep =
      numStepsForSlide > 0 &&
      (position.slideStepIndex === null ||
        position.slideStepIndex < numStepsForSlide - 1);
    const wantsNextSlide =
      !wantsNextStep && position.slideIndex < slides.length - 1;

    let next: PresentationPosition | undefined;

    if (wantsNextStep) {
      next = {
        slideIndex: position.slideIndex,
        slideStepIndex: (position.slideStepIndex ?? -1) + 1, // 0 is the first slide step.
      };
    } else if (wantsNextSlide) {
      next = {
        slideIndex: position.slideIndex + 1,
        slideStepIndex: null,
      };
    }
    return next || position;
  }

  function previous(): PresentationPosition {
    const slide = slides[position.slideIndex];

    if (!slide) {
      return position;
    }

    const numStepsForSlide = slide.slideSteps.length;
    const wantsPreviousStep =
      numStepsForSlide > 0 && position.slideStepIndex !== null;
    const wantsPrevousSlide = !wantsPreviousStep && position.slideIndex !== 0;

    let prev: PresentationPosition | undefined;

    if (wantsPreviousStep) {
      prev = {
        slideIndex: position.slideIndex,
        slideStepIndex:
          position.slideStepIndex === 0
            ? null
            : (position.slideStepIndex ?? 1) - 1,
      };
    } else if (wantsPrevousSlide) {
      const numStepsForPreviousSlide =
        slides[position.slideIndex - 1].slideSteps.length;
      prev = {
        slideIndex: position.slideIndex - 1,
        slideStepIndex:
          numStepsForPreviousSlide > 0 ? numStepsForPreviousSlide - 1 : null,
      };
    }
    return prev || position;
  }

  return {
    position: position,
    setPosition: () => {},
    next: next,
    previous: previous,
  };
}

export function usePresentationControls(
  slides: ReadonlyArray<IPresentationSlide>,
): PresentationControls {
  const [position, setPosition] = React.useState<PresentationPosition>({
    slideIndex: 0,
    slideStepIndex: null,
  });

  const controls = createStatelessPresentationControls(slides, position);
  const [searchParams, setSearchParams] = useSearchParams({
    slideNumber: "",
  });

  function next(): PresentationPosition {
    const next = controls.next();
    setPositionActually(next);
    return next;
  }

  function previous(): PresentationPosition {
    const prev = controls.previous();
    setPositionActually(prev);
    return prev;
  }

  function setPositionActually(next: PresentationPosition): void {
    setPosition(next);
    const slide = slides[next.slideIndex];
    setSearchParams((prev) => {
      // unpacking the previous values with '...' doesn't
      // work here, probably because it's not a plain object.
      prev.set("slideNumber", String(slide.slideNumber));
      return prev;
    });
  }

  React.useEffect(() => {
    const slideNumber = searchParams.get("slideNumber");

    if (!slideNumber) {
      return;
    }

    const index = slides.findIndex(
      (s) => s.slideNumber === parseInt(slideNumber, 10),
    );

    if (index !== -1 && index !== position.slideIndex) {
      setPosition({
        slideIndex: index,
        slideStepIndex: null,
      });
    }
  }, [slides, searchParams]);

  return {
    position: position,
    setPosition: setPositionActually,
    next: next,
    previous: previous,
  };
}
