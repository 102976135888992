import React, { useEffect, useRef } from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { useActivityFilters } from "./hooks";
import { Input } from "@app/components/formComponents/input";
import { ReactDatePicker } from "@app/components/datePicker/ReactDatePicker";
import { IFilterObject } from "./hooks";
import { Dropdown } from "@app/components/formComponents/dropdown";
import {
  ActivityStatus,
  InvoicedFilter,
} from "@app/containers/profileList/profileEnums";
import { SubscriptionType } from "@app/containers/clientList/addEditModal/clientEnums";
import { IDropdownList } from "../formComponents/dropdownSelect";

export interface IFilterProps {
  languageText: ITranslationObject;
  initialFilterObject: IFilterObject;
  clearFilterOptions: boolean;
  handleUpdatedFilterOptions?: (options: IFilterObject) => void;
}

function isEnumStringKey(key: string): boolean {
  // typescript enums are two-way mapped which means that
  // an enum has both string keys and numeric keys. the
  // numeric keys are pointing to the enum field names while
  // the string keys are pointing to the enum values.
  return !/\d+/.test(key);
}

export const ActivityFilters = (props: IFilterProps): JSX.Element => {
  const isMounted = useRef<boolean>(false);

  const {
    filterOptions,
    handleInputChange,
    handleDateSelect,
    handleDropdownChange,
  } = useActivityFilters(props.initialFilterObject, props.clearFilterOptions);

  useEffect(() => {
    if (isMounted.current && props.handleUpdatedFilterOptions) {
      props.handleUpdatedFilterOptions(filterOptions);
    }
    if (!isMounted.current) isMounted.current = true;
  }, [filterOptions]);

  const activityStatusList: Array<IDropdownList> = Object.keys(ActivityStatus)
    .filter(isEnumStringKey)
    .map((key, idx) => ({
      id: idx + 1,
      value: ActivityStatus[key as keyof typeof ActivityStatus].toString(),
      displayName: getLanguageValue(props.languageText, key),
    }));
  activityStatusList.unshift({
    id: 0,
    value: "",
    displayName: getLanguageValue(props.languageText, "All"),
  });

  const subscriptionTypeList: Array<IDropdownList> = Object.keys(
    SubscriptionType,
  )
    .filter(isEnumStringKey)
    .map((key, idx) => ({
      id: idx + 1,
      value: SubscriptionType[key as keyof typeof SubscriptionType].toString(),
      displayName: getLanguageValue(props.languageText, key),
    }));
  subscriptionTypeList.unshift({
    id: 0,
    value: "",
    displayName: getLanguageValue(props.languageText, "All"),
  });

  return (
    <>
      <div>
        <form id="multi_filter_form" className="row gy-2">
          <div className="col-md-2 col-6">
            <Dropdown
              name="status"
              defaultLabel={getLanguageValue(
                props.languageText,
                "Activity Status",
              )}
              value={filterOptions?.status?.toString() ?? ""}
              handleDropdownSelect={handleDropdownChange}
              list={activityStatusList}
            />
          </div>
          <div className="col-md-2 col-6">
            <Dropdown
              name="subscriptionType"
              defaultLabel={getLanguageValue(
                props.languageText,
                "Subscription Type",
              )}
              value={filterOptions?.subscriptionType?.toString() ?? ""}
              handleDropdownSelect={handleDropdownChange}
              list={subscriptionTypeList}
            />
          </div>
          <div className="col-md-2 col-6">
            <Dropdown
              name="invoiced"
              defaultLabel={getLanguageValue(
                props.languageText,
                "Invoiced status",
              )}
              value={filterOptions?.invoiced?.toString() ?? ""}
              handleDropdownSelect={handleDropdownChange}
              list={Object.keys(InvoicedFilter)
                .filter(isEnumStringKey)
                .map((key, idx) => ({
                  id: idx + 1,
                  value:
                    InvoicedFilter[
                      key as keyof typeof InvoicedFilter
                    ].toString(),
                  displayName: getLanguageValue(props.languageText, key),
                }))}
            />
          </div>
          <div className="col-md-2 col-6">
            <Input
              name="clientName"
              placeholder={getLanguageValue(props.languageText, "Client")}
              value={filterOptions && filterOptions.clientName}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-2 col-6">
            <Input
              name="facilitatorName"
              placeholder={getLanguageValue(props.languageText, "Facilitator")}
              value={filterOptions && filterOptions.facilitatorName}
              handleInputChange={handleInputChange}
            />
          </div>

          <div className="col-md-2 col-6">
            <Input
              name="source"
              placeholder={getLanguageValue(props.languageText, "Source")}
              value={filterOptions && filterOptions.source}
              handleInputChange={handleInputChange}
            />
          </div>

          <div className="col-md-2 col-6">
            <Input
              name="addedProfilesMin"
              placeholder={getLanguageValue(
                props.languageText,
                "Added Participants Min",
              )}
              value={filterOptions && (filterOptions.addedProfilesMin ?? "")}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-2 col-6">
            <Input
              name="addedProfilesMax"
              placeholder={getLanguageValue(
                props.languageText,
                "Added Participants Max",
              )}
              value={filterOptions && (filterOptions.addedProfilesMax ?? "")}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-2 col-6">
            <Input
              name="completedProfilesMin"
              placeholder={getLanguageValue(
                props.languageText,
                "Completed Participants Min",
              )}
              value={
                filterOptions && (filterOptions.completedProfilesMin ?? "")
              }
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-2 col-6">
            <Input
              name="completedProfilesMax"
              placeholder={getLanguageValue(
                props.languageText,
                "Completed Participants Max",
              )}
              value={
                filterOptions && (filterOptions.completedProfilesMax ?? "")
              }
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-2 col-6">
            <ReactDatePicker
              className="d-block"
              placeholder={getLanguageValue(
                props.languageText,
                "Completed From",
              )}
              name="dateFrom"
              date={filterOptions.dateFrom}
              handleDateSelect={handleDateSelect}
            />
          </div>
          <div className="col-md-2 col-6">
            <ReactDatePicker
              placeholder={getLanguageValue(props.languageText, "Completed To")}
              name="dateTo"
              date={filterOptions.dateTo}
              handleDateSelect={handleDateSelect}
            />
          </div>
        </form>
      </div>
    </>
  );
};
