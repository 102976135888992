import { Dispatch } from "redux";
import { ITableCommonParams } from "../commonInterfaces";
import { IDIInvoice } from "@app/components/invoice/invoiceInterface";
import { ApiResponse, ApiResult } from "@app/types";
import { api, apiEndPoints } from "@app/api";
import { setNotification } from "../actions";

export const getInvoices = async (
  params: ITableCommonParams,
  dispatch: Dispatch,
): Promise<ApiResult<IDIInvoice>> => {
  return api
    .get<ApiResponse<ApiResult<IDIInvoice>>>(apiEndPoints.getInvoices, {
      params,
    })
    .then((response) => {
      if (!response.data.success) {
        return Promise.reject();
      }
      return response.data.result;
    })
    .catch((err) => {
      dispatch(setNotification(err));
      throw err;
    });
};
