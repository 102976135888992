import {
  getMediaImageFile,
  setNotification,
  setSpinner,
} from "../containers/actions";
import { UserGeneratedMediaType } from "../containers/commonEnums";
import { Dispatch } from "redux";

export const getDocumentDownloadFile = async (
  id: number,
  fileName: string,
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setSpinner(true));
  try {
    const fetchedDocument = await getMediaImageFile(
      id,
      UserGeneratedMediaType.Documents,
      dispatch,
    );
    const response = await fetch(fetchedDocument);
    if (!response.ok) {
      dispatch(setNotification("Failed to download document"));
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  } finally {
    dispatch(setSpinner(false));
  }
};
