import * as React from "react";
import { DemographicPage } from "./profileParticipantPages/demographicPage.tsx/demographicPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import {
  getLanguageValue,
  TranslateFn,
} from "@app/commonUtils/languageFunctionsHelper";
import { useParticipantProfileAuth } from "./useParticipantProfileAuth";
import { IProfileDetails } from "./reducer";
import { getParticipantProfileInfo } from "@app/commonUtils/participantProfileHelper";
import { Spinner } from "@app/components/spinner/spinner";
import { ParticipantSelfFormPage } from "./profileParticipantPages/selfFormPage/participantSelfFormPage";
import { ApiEndpoints, useApiEndpoints } from "@app/api/end-points";
import { InviteRespondents } from "./profileParticipantPages/inviteRespondents/inviteRespondents";
import { PresentationStep } from "./profileParticipantPages/profilePage/presentationStep";
import { RespondentStatusStep } from "./respondentStatusStep";
import { ProfileStatus } from "../profileList/profileEnums";
import { RespondentProfileStatus } from "../profileList/editProfile/interface";
import { ProfileStep } from "./profileStep";
import { usePrevious } from "../utils";

/**
 * NOTE: These aren't indices and are not necessarily defined in the same order
 *   as the view components.
 *
 * @see STEP_DEFINITIONS
 * @see getStepIndex
 */
export enum Step {
  DemographicSurvey,
  RoleAndRespondents,
  SelfAssessment,
  RespondentStatus,
  Presentation,
  Profile,
  Academy, // naming?
}

type SetStateFn<S> = React.Dispatch<React.SetStateAction<S>>;
type StepStatus = "completed" | "current" | "upcoming";

export type StepComponentProps = {
  __t: TranslateFn;
  profile: IProfileDetails;
  api: ApiEndpoints;
  status: StepStatus;
  setStep: SetStateFn<Step>;
  languageCode: string;
};
export type StepComponent = React.FC<StepComponentProps>;

type StepDefinition = {
  step: Step;
  component: StepComponent;
};

const STEP_DEFINITIONS: ReadonlyArray<StepDefinition> = [
  {
    step: Step.DemographicSurvey,
    component: DemographicPage,
  },
  {
    step: Step.RoleAndRespondents,
    component: (props: StepComponentProps) => {
      return <InviteRespondents {...props} variant={{ kind: "step" }} />;
    },
  },
  {
    step: Step.SelfAssessment,
    component: ParticipantSelfFormPage,
  },
  {
    step: Step.RespondentStatus,
    component: RespondentStatusStep,
  },
  {
    step: Step.Presentation,
    component: PresentationStep,
  },
  {
    step: Step.Profile,
    component: ProfileStep,
  },
];

function getStepIndex(step: Step): number {
  return STEP_DEFINITIONS.findIndex((x) => x.step === step);
}

function deriveCurrentStep(profile: IProfileDetails): Step {
  const canPresentProfile =
    profile.presentationId ||
    profile.status === ProfileStatus.Completed ||
    (profile.respondents.length >= profile.noOfRespondents &&
      profile.respondents.every(
        (r) => r.status === RespondentProfileStatus.Completed,
      ));

  // contrary to its naming 'Delivery' means that the profile is
  // already delivered. :)
  if (profile.status === ProfileStatus.Delivery) {
    return Step.Profile;
  }
  if (canPresentProfile) {
    return Step.Presentation;
  }
  if (profile.isFormFilled) {
    return Step.RespondentStatus;
  }

  //We used to look if formFilledData in the profile was true
  //but that doesn't cover for the case when someone changes language
  //while filling out the self form and the formFilledData is cleared.
  if (profile.respondents.length >= profile.noOfRespondents) {
    return Step.SelfAssessment;
  }
  if (profile.isDemographicsFilled) {
    return Step.RoleAndRespondents;
  }
  return Step.DemographicSurvey;
}

export const CreateProfilePage: React.FC<unknown> = (props) => {
  const [currentStep, setCurrentStep] = React.useState<Step | undefined>(
    undefined,
  );
  const previousStep = usePrevious(currentStep);
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const __t = getLanguageValue.bind(undefined, languageText);
  const { uniqueCode } = useParticipantProfileAuth();
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const [details, setDetails] = React.useState<IProfileDetails | undefined>(
    undefined,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );

  React.useEffect(() => {
    if (
      typeof previousStep === "undefined" &&
      typeof currentStep !== "undefined"
    ) {
      // this happens on the initial render when this hook is first called. we
      // derive the step and set it, but we don't want to trigger another backend
      // call.
      return;
    }

    getParticipantProfileInfo(uniqueCode, dispatch, false).then((res) => {
      setDetails(res);

      // after the step has been set any state-change is controlled by the user.
      // let's not override their actions, because that's annoying.
      if (typeof currentStep === "undefined") {
        const step = deriveCurrentStep(res);
        setCurrentStep(step);
      }
    });
  }, [currentStep]);

  if (typeof currentStep === "undefined" || typeof details === "undefined") {
    return <Spinner />;
  }

  return (
    <div className="mt-5 mb-5 col-md-10 mx-auto">
      <h3 className="pb-1">{__t("Create new profile")}</h3>
      {STEP_DEFINITIONS.map((defn, i) => {
        const status: StepStatus =
          defn.step === currentStep
            ? "current"
            : getStepIndex(defn.step) < getStepIndex(currentStep)
              ? "completed"
              : "upcoming";
        const Component = defn.component;

        return (
          <Component
            key={i}
            profile={details}
            api={api}
            status={status}
            setStep={setCurrentStep as SetStateFn<Step>}
            __t={__t}
            languageCode={userLanguage.userLanguageCode}
          />
        );
      })}
    </div>
  );
};
