// Account
enum AccountSelectBtns {
  Select = "1",
  AddNew = "2",
}

// Step 1 (Individual / Group)
enum IndividualGroupBtnsEnum {
  group = "1",
  individual = "2",
}

// Step 2 (Description)
const DescriptionInputs = {
  description: "description",
  completionDate: "completionDate",
  culture: "culture",
  country: "country",
  notes: "notes",
} as const;

// Step 3 (Roles)
enum ProfileRoleStatus {
  RoleSetByFacilitator = "1",
  RoleSetByParticipant = "2",
}
const getRoleStatusName = (roleStatus: string) => {
  switch (roleStatus) {
    case ProfileRoleStatus.RoleSetByFacilitator:
      return "Facilitator";
    case ProfileRoleStatus.RoleSetByParticipant:
      return "Participant";
    default:
      return "";
  }
};

enum RoleBtnsEnum {
  sameRole = "1",
  individualRole = "2",
}
const getRoleSettingsName = (roleType: boolean) => {
  switch (roleType) {
    case true:
      return "Same for all";
    case false:
      return "Individual role";
    default:
      return "";
  }
};

enum TypeOfRole {
  Colleagues = 1,
  TeamMembers = 2,
  Clients = 3,
  Suppliers = 4,
  Subordinates = 5,
}

enum GetTypeOfRoleName {
  "Colleagues" = 1,
  "Team members" = 2,
  "Clients" = 3,
  "Suppliers" = 4,
  "Subordinates" = 5,
}

enum NoOfRespondents {
  "N3" = "3",
  "N4" = "4",
  "N5" = "5",
  "N6" = "6",
}

enum ParticipantChangeRole {
  canChange = "1",
  cannotChange = "2",
}

// Step 4 (Respondents)
const InviteRespondents = {
  isInviteExternal: "isInviteExternal",
  isInviteColleagues: "isInviteColleagues",
  isInviteOtherParticipant: "isInviteOtherParticipant",
} as const;

// Step 6 (Invite Participants)
enum InvitationCategory {
  same = "1",
  personalized = "2",
}

enum InvitationType {
  email = 1,
  sms = 2,
  emailSMS = 3,
}

// ProfileStatus
enum ActivityStatus {
  Unknown = 0,
  Created = 1,
  Active = 2,
  Completed = 3,
  Invoiced = 4,
  Cancelled = 5,
  ProBono = 6,
  Delivered = 7,
}
const activityStatusColors: { [key in ActivityStatus]?: string } = {
  [ActivityStatus.Created]: "bg-warning bg-opacity-50 text-secondary",
  [ActivityStatus.Active]: "bg-primary-subtle text-primary",
  [ActivityStatus.Completed]: "bg-info text-success",
  [ActivityStatus.Invoiced]: "text-bg-primary text-white",
  [ActivityStatus.Cancelled]: "text-bg-light",
  [ActivityStatus.ProBono]: "text-bg-light",
  [ActivityStatus.Delivered]: "text-bg-success",
};

// Edit profile participant Status
export enum ProfileStatus {
  Unknown = 0,
  New = 1,

  /** TODO: rename to 'Ongoing' */
  Active = 2,
  Completed = 3,
  DeliveryPlanned = 4,
  Delivery = 5,
}

enum CoursePresentationStatus {
  Unknown = 0,
  Planned = 1,
  Ongoing = 2,
  Completed = 3,
  Cancelled = 4,
}
const getCoursePresentationStatus = (
  presentationStatus: CoursePresentationStatus,
): string => {
  switch (presentationStatus) {
    case CoursePresentationStatus.Planned:
      return "Planned";
    case CoursePresentationStatus.Ongoing:
      return "Ongoing";
    case CoursePresentationStatus.Completed:
      return "Finished";
    case CoursePresentationStatus.Cancelled:
      return "Cancelled";
    default:
      return "";
  }
};

// ProfileMeasure
enum ProfileMeasure {
  Low = 1,
  Medium = 2,
  High = 3,
}

// Download option (no of pages)
export type ProfileOutputPage = "All" | "Page1" | "Page2";
export const ProfileOutputPages: { [K in ProfileOutputPage]: K } = {
  All: "All",
  Page1: "Page1",
  Page2: "Page2",
};

export type ProfileOutputFormat = "Html" | "Pdf" | "Frontend";

enum InvoicedFilter {
  All = 0,
  InvoiceCreated = 1,
  InvoiceNotCreated = 2,
}

export {
  AccountSelectBtns,
  IndividualGroupBtnsEnum,
  DescriptionInputs,
  ProfileRoleStatus,
  RoleBtnsEnum,
  TypeOfRole,
  GetTypeOfRoleName,
  ParticipantChangeRole,
  InviteRespondents,
  InvitationCategory,
  InvitationType,
  ActivityStatus,
  activityStatusColors,
  CoursePresentationStatus,
  NoOfRespondents,
  ProfileMeasure,
  InvoicedFilter,
  getCoursePresentationStatus,
  getRoleSettingsName,
  getRoleStatusName,
};
