import * as React from "react";
import { ReactTable } from "@app/components/reactTable/reactTable";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { ITableCommonParams } from "../commonInterfaces";
import { CustomActionsCell } from "@app/components/reactTable/customActionsCell";
import { setSpinner } from "../actions";
import { useNavigate } from "react-router-dom";
import { routePath } from "../routePaths";
import { Input } from "@app/components/formComponents/input";
import { getUpcomingPresentations } from "./actions";
import { ActivityId } from "../reducer";

export interface IUpcomingPresentationsParams extends ITableCommonParams {
  presentationDateStart: string;
  presentationDateEnd: string;
}

type Props = unknown;
export type UpcomingPresentation = {
  id: number;
  description: string;
  date: string;
  activityId: ActivityId;
  activityName: string;
  activityEndDate: string;
  facilitatorId: number;
  facilitatorName: string;
  clientId: number | null;
  clientName: string;
};

export const UpcomingPresentationList: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const tableHeader: Array<ColumnDef<UpcomingPresentation>> = [
    {
      header: getLanguageValue(languageText, "Actions"),
      accessorKey: "actions",
      enableSorting: false,
      cell: (ctx) => {
        return (
          <CustomActionsCell
            id={ctx.row.original.activityId}
            editOption={true}
            handleEditClick={(id) => {
              navigate(routePath.editActivity.replace(":id", String(id)));
            }}
            languageText={languageText}
          />
        );
      },
    },
    {
      header: getLanguageValue(languageText, "Activity ID"),
      accessorKey: "activityId",
    },
    {
      header: getLanguageValue(languageText, "Activity name"),
      accessorKey: "activityName",
    },
    {
      header: getLanguageValue(languageText, "Activity end date"),
      accessorKey: "activityEndDate",
    },
    {
      header: getLanguageValue(languageText, "Presentation date"),
      accessorKey: "date",
    },
    {
      header: getLanguageValue(languageText, "Description"),
      accessorKey: "description",
    },
    {
      header: getLanguageValue(languageText, "Facilitator"),
      accessorKey: "facilitatorName",
    },
    {
      header: getLanguageValue(languageText, "Client"),
      accessorKey: "clientName",
    },
    {
      header: getLanguageValue(languageText, "Presentation template"),
      accessorKey: "presentationTemplateDescription",
    },
  ];
  const [totalCount, setTotalCount] = React.useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = React.useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = React.useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = React.useState<number>(0); // page_number_selected
  const [filter, setFilter] = React.useState("");
  const [sorting, setSorting] = React.useState("activityId DESC");
  const [data, setData] = React.useState<Array<UpcomingPresentation>>([]);
  const [presentationDateStart, setPresentationDateStart] = React.useState("");
  const [presentationDateEnd, setPresentationDateEnd] = React.useState("");

  function loadUpcomingPresentationsAndUpdateState(): void {
    const params: IUpcomingPresentationsParams = {
      filter: filter,
      sorting: sorting,
      skipCount: pageSelected * pageLimit,
      maxResultCount: pageLimit,
      presentationDateStart: presentationDateStart,
      presentationDateEnd: presentationDateEnd,
    };

    dispatch(setSpinner(true));

    getUpcomingPresentations(params, dispatch)
      .then((res) => {
        if (res?.items.length > 0) {
          setData(res.items.slice());
          setTotalCount(res.totalCount);
          setPageCount(Math.ceil(res.totalCount / pageLimit));
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  }

  React.useEffect(() => {
    loadUpcomingPresentationsAndUpdateState();
  }, [
    pageLimit,
    pageSelected,
    filter,
    sorting,
    presentationDateStart,
    presentationDateEnd,
  ]);

  return (
    <React.Fragment>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Upcoming presentations")}
        searchPlaceholder={getLanguageValue(languageText, "Search")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilter={setFilter}
        sorting={sorting}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
        showClearFiltersButton={
          !!(filter || presentationDateStart || presentationDateEnd)
        }
        handleClearCustomFilters={() => {
          setFilter("");
          setPresentationDateStart("");
          setPresentationDateEnd("");
        }}
        customFilters={
          <React.Fragment>
            <div className="row">
              <div className="col-3">
                <Input
                  label={getLanguageValue(
                    languageText,
                    "Presentation date start",
                  )}
                  type="date"
                  value={presentationDateStart}
                  handleInputChange={(event) => {
                    setPresentationDateStart(event.target.value);
                  }}
                />
              </div>
              <div className="col-3">
                <Input
                  label={getLanguageValue(
                    languageText,
                    "Presentation date end",
                  )}
                  type="date"
                  value={presentationDateEnd}
                  handleInputChange={(event) => {
                    setPresentationDateEnd(event.target.value);
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};
