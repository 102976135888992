import * as React from "react";
import { IPresentationSlide } from "../facilitatorDelivery/hooks";
import { ILanguageText } from "../reducer";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";

type Props = {
  languageText: ILanguageText;
  slide: IPresentationSlide;
};

const TOGGLE_BUTTON_HEIGHT = "40px";

export const PresentationSlideNotes: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);

  function handleClick(event: React.MouseEvent): void {
    event.preventDefault();
    setOpen(!open);
  }

  // this works by initially putting the container in a fixed
  // position at bottom 0. it is then CSS-translated offscreen by
  // its entire height minus the height of the toggle button.
  //
  // when it is opened we just set the translation to 0 which brings
  // the entire thing into the open.
  //
  // maybe there's a way to do this without hard-coding the height
  // of the toggler, but this works OK for now.
  //   -johan, 2024-06-13
  const style: React.CSSProperties = {
    bottom: 0,
    transform: open
      ? "translateY(0)"
      : `translateY(calc(100% - ${TOGGLE_BUTTON_HEIGHT}))`,
    transition: "transform 0.25s",
    display: props.slide.notes ? "block" : "none",
  };

  return (
    <React.Fragment>
      <div className="position-fixed w-100" style={style}>
        <div className="container">
          <div className="bg-white rounded px-4 border-start border-top border-end">
            <div
              className="d-flex justify-content-center align-items-center fw-bold"
              role="button"
              onClick={handleClick}
              style={{ height: TOGGLE_BUTTON_HEIGHT }}
            >
              {open ? (
                <i className="bi bi-chevron-down me-2" />
              ) : (
                <i className="bi bi-chevron-up me-2" />
              )}
              {getLanguageValue(props.languageText, "Notes")} (
              {getLanguageValue(props.languageText, "only visible for you")})
            </div>
            <div
              className="pb-4"
              dangerouslySetInnerHTML={{ __html: props.slide.notes }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
