import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../commonUtils/languageFunctionsHelper";
import { getDirOrAffDeviation } from "@app/commonUtils/dimensionsHelper";

interface IAffiliationDirectivenessGraphProps {
  languageText: ITranslationObject;
  oAffPos: number;
  oDirPos: number;
  sAffPos: number;
  sDirPos: number;
  outsideStdDevA: number;
  outsideStdDevD: number;
}

export const AffiliationDirectivenessGraph = (
  props: IAffiliationDirectivenessGraphProps,
): JSX.Element => {
  const {
    languageText,
    oAffPos,
    oDirPos,
    sAffPos,
    sDirPos,
    outsideStdDevA,
    outsideStdDevD,
  } = props;

  // Directiveness deviation
  const { leftOrBottomDeviation: leftDevD, rightOrTopDeviation: rightDevD } =
    getDirOrAffDeviation(oDirPos, outsideStdDevD);
  // Affiliation deviation
  const { leftOrBottomDeviation: bottomDevA, rightOrTopDeviation: topDevA } =
    getDirOrAffDeviation(oAffPos, outsideStdDevA);

  return (
    <div className="position-relative">
      <div className="ratio ratio-1x1 border position-relative">
        <div className={`idi-position-parent y${oAffPos} x${oDirPos}`}>
          <div className="idi-position-child bg-success">
            <div className={`deviDir deviDirL${leftDevD}`}></div>
            <div className={`deviDir deviDirR${rightDevD}`}></div>
            <div className={`deviAff deviAffB${bottomDevA}`}></div>
            <div className={`deviAff deviAffT${topDevA}`}></div>
          </div>
        </div>

        <div className={`idi-position-parent y${sAffPos} x${sDirPos}`}>
          <div className="idi-position-child bg-danger rounded-circle"></div>
        </div>

        <div className="d-flex justify-content-between rotate90 ms-n4">
          <div>{getLanguageValue(languageText, "Task focus")}</div>
          <div className="fw-bold">
            {getLanguageValue(languageText, "Affiliation")}
          </div>
          <div>{getLanguageValue(languageText, "People focus")}</div>
        </div>
        <div className="d-flex flex-wrap">
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25"></div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-1">
        <div>{getLanguageValue(languageText, "Inquiry")}</div>
        <div className="fw-bold">
          {getLanguageValue(languageText, "Directiveness")}
        </div>
        <div>{getLanguageValue(languageText, "Advocacy")}</div>
      </div>

      <div className="d-flex gap-4 mt-3">
        <div className="d-flex align-items-center gap-2">
          <div className="self-circle bg-danger" />
          {getLanguageValue(languageText, "Self")}
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="other-square bg-success" />
          {getLanguageValue(languageText, "Respondents")}
        </div>
      </div>
    </div>
  );
};
