import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Select from "react-select";
import { IFocusError } from "./input";
import { classNames } from "@app/containers/utils";

export interface IMultiDropdownList {
  id: number;
  label: string | JSX.Element;
  value: string;
}

interface IDropdownSelectProps {
  label?: string;
  name: string;
  required?: boolean;
  list: IMultiDropdownList[];
  placeholder: string;
  value?: Array<string>;
  focusInput?: IFocusError;
  disabled?: boolean;
  handleMultiDropdownSelect: (name: string, value: Array<string>) => void;
  narrow?: boolean;
}

export const DropdownMultiSelect = (
  props: IDropdownSelectProps,
): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<
    IMultiDropdownList[] | null
  >(null);

  useEffect(() => {
    let defaultSelectedOptions: IMultiDropdownList[] = [];
    if (props.value!.length > 0 && props.list.length > 0) {
      defaultSelectedOptions = props.list.filter((item) =>
        props.value?.includes(item.value),
      );
    }
    setSelectedOptions(defaultSelectedOptions);
  }, [props.list, props.value]);

  const handleChange = (selectedItems: ReadonlyArray<IMultiDropdownList>) => {
    let selectedValues: string[] = selectedItems.map((item) => item.value);
    if (!selectedValues) selectedValues = [];
    props.handleMultiDropdownSelect(props.name, selectedValues);
  };

  const clazz = classNames({
    "mb-3": !props.narrow,
  });

  return (
    <div className={clazz}>
      {props.label && (
        <label className={clsx("mb-1", { required: props.required })}>
          {props.label}
        </label>
      )}

      <Select
        closeMenuOnSelect={false}
        isMulti
        options={props.list}
        value={selectedOptions}
        hideSelectedOptions={false}
        placeholder={props.placeholder}
        isDisabled={props.disabled ?? false}
        onChange={handleChange}
      />

      {props.focusInput?.errorMessage && (
        <div className="invalid-feedback d-inline-block">
          {props.focusInput.errorMessage}
        </div>
      )}
    </div>
  );
};
