import React from "react";
import { useSelector } from "react-redux";
import { E164Number } from "libphonenumber-js/types";

import { Input } from "../../../components/formComponents/input";
import { PhoneNumberInput } from "../../employeeList/addEmployeeModal/phoneNumberInput";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { useEditUser } from "../edit-user/hooks";
import { RootState } from "@app/store/configureStore";
import { CustomModal } from "../../../components/modals/customModal";

export const ProfileDetails = (): JSX.Element => {
  const userData = useSelector(
    (state: RootState) => state.loginReducer.userData,
  );
  const {
    userProfileData,
    languageText,
    focusInput,
    UserInputs,
    phoneNumberError,
    changeUserEmailModal,
    handleCancelClick,
    validatePhonenumber,
    handleBlurEvent,
    handleInputChange,
    handlePhoneInputChange,
    handleSubmit,
    handleChangeUserEmail,
  } = useEditUser();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="rounded border my-3">
          <div className="mt-3 p-3 p-md-4">
            <span className="mb-2">
              {getLanguageValue(languageText, "Username")}
            </span>
            <div className="mb-3 fw-bold form-control bg-dark bg-opacity-10">
              {userData.userName}
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 mb-3">
                <Input
                  name={UserInputs.name}
                  label={getLanguageValue(languageText, "Name")}
                  placeholder={getLanguageValue(languageText, "Name")}
                  value={userProfileData.name || ""}
                  errorMessage={focusInput.name.errorMessage}
                  handleBlurEvent={handleBlurEvent}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 col-12 mb-3">
                <Input
                  name={UserInputs.surname}
                  label={getLanguageValue(languageText, "Surname")}
                  placeholder={getLanguageValue(languageText, "Surname")}
                  value={userProfileData.surname || ""}
                  errorMessage={focusInput.surname.errorMessage}
                  handleBlurEvent={handleBlurEvent}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <Input
                name={UserInputs.emailAddress}
                label={getLanguageValue(languageText, "Email")}
                placeholder={getLanguageValue(languageText, "Email")}
                value={userProfileData.emailAddress || ""}
                errorMessage={focusInput.emailAddress.errorMessage}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
              />
            </div>
            <div>
              <PhoneNumberInput
                languageText={languageText}
                label={getLanguageValue(languageText, "Phone")}
                value={(userProfileData.phoneNumber as E164Number) || ""}
                onChange={(value) => handlePhoneInputChange(value)}
                onBlur={(e) =>
                  validatePhonenumber(e.target.value as E164Number)
                }
                phoneNumberError={phoneNumberError}
              />
            </div>
          </div>
          <div className="bg-dark bg-opacity-10 p-3 p-md-4">
            <button
              className="btn btn-success align-self-center mx-8 my-6"
              type="submit"
            >
              {getLanguageValue(languageText, "Update Details")}
            </button>
          </div>
        </div>
      </form>

      {changeUserEmailModal && (
        <CustomModal
          handleSubmitClick={handleChangeUserEmail}
          headerText={getLanguageValue(languageText, "Caution")}
          bodyText={getLanguageValue(
            languageText,
            "Changing your email require you to verify the new one and will reset your 2FA settings are you sure you want to reset your settings",
          )}
          submitButtonText={getLanguageValue(languageText, "Yes")}
          cancelButtonText={getLanguageValue(languageText, "Cancel")}
          handleCancelClick={handleCancelClick}
        />
      )}
    </>
  );
};
