import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  getProfileGraphLabel,
  handleProfileClick,
  IMyProfiles,
} from "../hooks";
import {
  GetTypeOfRoleName,
  ProfileStatus,
} from "../../../profileList/profileEnums";
import { getFormattedDateOnly } from "../../../../commonUtils/dateFunctionsHelper";
import { ProfileId } from "@app/containers/reducer";
import { classNames } from "@app/containers/utils";

interface IMyProfilesProps {
  languageText: ITranslationObject;
  profiles: ReadonlyArray<IMyProfiles>;
  selectedProfileId?: ProfileId | undefined;
  setSelectedProfileId?: (id: ProfileId | undefined) => unknown;
}

function getProfileTitle(
  profile: IMyProfiles,
  lang: ITranslationObject,
): string {
  if (profile.title) {
    return profile.title;
  }

  const isRoleFilled = profile.isRoleFilled ?? false;
  const isSelfFormFilled = profile.isSelfFormFilled ?? false;
  const isRespondentsCompleted = profile.isRespondentsInvited ?? false;
  const isPresentationCreated = profile.presentationId ?? false;

  // FIXME: this is likely _highly_ inaccurate. it's a copy-pasta from an older implementation of
  //   this file, initially superseded by PR 1448. some time after that PR was merged we also
  //   rearranged the entire profile creation flow and gave the steps new titles. it's unclear if
  //   these titles match the new steps (but probably not).
  //
  //   https://github.com/IDI-Profiling/platform/pull/1448
  let title: string = "";

  if (isPresentationCreated && profile.status === ProfileStatus.Completed) {
    title = "Presentation Planned";
  } else if (!isPresentationCreated && isRespondentsCompleted) {
    title = "Presentation Not Planned";
  } else if (!isRespondentsCompleted && isSelfFormFilled) {
    title = "Respondents Status";
  } else if (!isSelfFormFilled && isRoleFilled) {
    title = "Self Assessment";
  } else if (!isRoleFilled) {
    title = "Choose Role";
  }

  return (
    getLanguageValue(lang, "Ongoing") + " - " + getLanguageValue(lang, title)
  );
}

export const MyProfiles = (props: IMyProfilesProps): JSX.Element => {
  const navigate = useNavigate();

  /** FIXME: It seems we have ongoing profiles in this array of profiles as well
   * Let's filter them out and sort them in descending order on profiledate (profiledate)
   */

  return (
    <>
      {props.profiles.length > 0 ? (
        <div>
          {props.profiles.map((profile, index) => {
            const number = getProfileGraphLabel(profile, props.profiles);
            const data = getFormattedDateOnly(profile.profileDate);

            return (
              <div
                key={number}
                className={classNames({
                  "d-flex rounded mb-3 align-items-center": true,
                  "bg-light": props.selectedProfileId !== profile.profileId,
                  "bg-success-subtle":
                    props.selectedProfileId === profile.profileId,
                })}
                onMouseOver={(event) => {
                  props.setSelectedProfileId?.(profile.profileId);
                }}
                onMouseLeave={(event) => {
                  props.setSelectedProfileId?.(undefined);
                }}
                onClick={() => handleProfileClick(profile, navigate)}
                role="button"
              >
                <div className="py-4 px-4 border-end fs-4 text-secondary">
                  <div className="py-2 my-2">{number}</div>
                </div>
                <div className="w-100 p-3 ps-4">
                  <div className={clsx("fs-5  mb-2", "fw-bold")}>
                    {getProfileTitle(profile, props.languageText)}
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    <div className="p-1 px-2 rounded bg-dark bg-opacity-10 text-secondary opacity-75">
                      {data}
                    </div>
                    <div className="p-1 px-2 rounded bg-dark bg-opacity-10 text-secondary opacity-75">
                      {getLanguageValue(props.languageText, "ID")}{" "}
                      {profile.profileId}
                    </div>
                    <div className="p-1 px-2 rounded bg-success-subtle text-success fw-bold min-vw-50">
                      {profile.noOfRespondents}{" "}
                      {profile.roleId
                        ? getLanguageValue(
                            props.languageText,
                            GetTypeOfRoleName[profile.roleId],
                          )
                        : profile.roleText
                          ? profile.roleText
                          : getLanguageValue(props.languageText, "Respondents")}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="color-666 fs-20px fw-bold p-7">
          {getLanguageValue(props.languageText, "Profile not yet created")}!
        </div>
      )}
    </>
  );
};
