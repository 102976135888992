import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps } from "intro.js-react";
import "intro.js/themes/introjs-modern.css";
import "intro.js/introjs.css";

import "@app/css/components/instruction.scss";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";
import { markGuideAsCompleted } from "@app/containers/actions";
import { GuidePages } from "@app/containers/commonEnums";

export interface IInstructionSteps {
  element: string;
  intro: string;
}
interface IIntroJsProps {
  guidePage: GuidePages;
  instructionSteps: IInstructionSteps[];
  stepsNotToSkip?: Array<number>; // Indices of steps not to be skipped
  stepsToSkip: Array<number>; // Indices of steps to be skipped
  isRespondent: boolean; // Respondents doesn't have userId, guide api is unauthorized

  /**
   * @deprecated
   *
   * There should be no outside logic controlling when to call the backend
   * to say 'OK, I'm complete'. The guide itself needs to have knowledge on
   * what elements it operates on.
   *
   * This kind of stuff should literally be embedded in the 'IInstructionSteps'
   * It needs to have knowledge of its dependencies, its skipping behavior,
   * its exit behavior, etc.
   *
   * Let's just make the guides more declarative.
   *   -johan, 2024-09-22
   */
  skipPostGuideRequest: boolean; // If we want to handle post request in the parent component
  handleExit: (guidePage: GuidePages, isSkipped: boolean) => void;
}

export const IntroJs = (props: IIntroJsProps) => {
  const dispatch = useDispatch();
  const stepsRef = useRef<Steps>(null);
  const previousStepIndex = useRef<number>();
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const onAfterChange = (newStepIndex: number, newElement: HTMLDivElement) => {
    if (newStepIndex === -1) {
      stepsRef.current?.introJs.nextStep();
      return;
    }
    // Skip element if it's not present (floating element)
    //
    // FIXME: 'skip' is an extremely bad word in this context, because
    //   it's not the same 'skip' as the user can invoke on the guide.
    let shouldElementSkip = newElement.classList.contains(
      "introjsFloatingElement",
    );

    // Don't want to skip the element which is not present in the DOM
    //
    // 2024-10-21: 'not present in the DOM'? what? we're not checking the
    //   DOM here. what is this thing doing?
    if (props.stepsNotToSkip && props.stepsNotToSkip.includes(newStepIndex)) {
      shouldElementSkip = false;
    }

    // Skip element which are not required
    if (props.stepsToSkip && props.stepsToSkip.includes(newStepIndex)) {
      shouldElementSkip = true;
    }

    // FIXME: i can't decipher this. are we somehow moving to the next
    //   guide step automatically? as mentioned above, 'skip' doesn't
    //   really mean 'skip' as the user sees it.
    //
    //   2024-10-21: i still have no fucking idea what this means.
    //     'shouldElementSkip'? what?
    if (shouldElementSkip && previousStepIndex.current) {
      if (newStepIndex < previousStepIndex.current) {
        stepsRef.current?.introJs.previousStep();
      } else {
        stepsRef.current?.introJs.nextStep();
      }
    }
    previousStepIndex.current = newStepIndex;
  };

  const onExit = (exitedAtStepIndex: number) => {
    if (exitedAtStepIndex < 0) {
      // intro.js seems to call the exit handler with a negative index
      // when no user interaction was involved in the closure. one example
      // of this is when the component unmounts.
      return;
    }

    const lastStepIndex = props.instructionSteps.length - 1;
    const isSkipped = exitedAtStepIndex < lastStepIndex;

    props.handleExit(props.guidePage, isSkipped);

    if (props.isRespondent) {
      // 'isRespondent' is a cute way of saying that we're not logged in.
      return;
    }

    if (!props.skipPostGuideRequest) {
      markGuideAsCompleted(props.guidePage, dispatch);
    }
  };

  return (
    <>
      <Steps
        enabled={true}
        steps={props.instructionSteps}
        initialStep={0}
        ref={stepsRef}
        onExit={onExit}
        onAfterChange={onAfterChange}
        options={{
          skipLabel: getLanguageValue(languageText, "AP-ALL-CO-ButtonSkip"),
          prevLabel: getLanguageValue(languageText, "AP-ALL-CO-ButtonBack"),
          nextLabel: getLanguageValue(languageText, "AP-ALL-CO-ButtonNext"),
          doneLabel: getLanguageValue(languageText, "AP-ALL-CO-ButtonDone"),
          exitOnEsc: false,
          exitOnOverlayClick: false,
          keyboardNavigation: false,
        }}
      />
    </>
  );
};
