import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { INewsList, NewsType } from "../newsList/types";
import { ApiResponse, ApiResult } from "@app/types";

export const getLatestNews = async (
  maxResultCount: number,
  skipCount: number,
  newsType: NewsType,
  dispatch: Dispatch,
): Promise<ApiResult<INewsList>> => {
  const body = {
    maxResultCount,
    skipCount,
    newsType,
  };
  try {
    const response = await api.get<ApiResponse<ApiResult<INewsList>>>(
      apiEndPoints.getLatestNews,
      {
        params: body,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getNewsById = async (
  id: number,
  dispatch: Dispatch,
): Promise<INewsList> => {
  const body = {
    id,
  };
  try {
    const response = await api.get<ApiResponse<INewsList>>(
      apiEndPoints.getNewsById,
      {
        params: body,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
