import { ApiResult } from "@app/types";
import { IEmailTemplate } from "./types";
import { api, apiEndPoints } from "@app/api";

export function getEmailTemplates(
  query: unknown,
): Promise<ApiResult<IEmailTemplate>> {
  return api
    .get(apiEndPoints.getEmailTemplates, {
      params: query,
    })
    .then((res) => res.data.result);
}

export function updateTemplate(
  id: number,
  body: IEmailTemplate,
): Promise<IEmailTemplate> {
  return api
    .put(apiEndPoints.updateEmailTemplate(id), body)
    .then((res) => res.data.result);
}

export function createTemplate(body: IEmailTemplate): Promise<IEmailTemplate> {
  return api
    .post(apiEndPoints.createEmailTemplate, body)
    .then((res) => res.data.result);
}

export function deleteTemplate(id: number): Promise<IEmailTemplate> {
  return api
    .delete(apiEndPoints.deleteEmailTemplate(id))
    .then((res) => res.data.result);
}
