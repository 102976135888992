export const downloadFileFromBlob = async (
  contents: Blob,
  fileName: string,
): Promise<void> => {
  const downloadUrl = window.URL.createObjectURL(contents);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
};

export function blobToString(blobby: Blob): Promise<string> {
  const rdr = new FileReader();

  return new Promise((resolve, reject) => {
    rdr.onload = (event) => {
      if (typeof rdr.result !== "string") {
        reject("Could not read file as string.");
        return;
      }
      resolve(rdr.result);
    };
    rdr.onerror = (err) => {
      reject(err);
    };
    rdr.readAsText(blobby);
  });
}
