import React from "react";
import { useSelector } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";
import { IPresentationList } from "./hooks";
import { RootState } from "@app/store/configureStore";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";

interface IPresentationActionsCellProps {
  presentation: IPresentationList;
  languageText: ITranslationObject;
  handleEditClick: (item: IPresentationList) => void;
  handleDeleteClick: (item: IPresentationList) => void;
  handleSlideListClick: (item: IPresentationList) => void;
  handlePermissionsClick: (item: IPresentationList) => void;
  handlePreviewClick: (item: IPresentationList) => void;
  handlePreviewSelfDeliveryClick: (item: IPresentationList) => void;
}

export const PresentationActionsCell: React.FC<
  IPresentationActionsCellProps
> = (props) => {
  const {
    languageText,
    handleEditClick,
    handleDeleteClick,
    handleSlideListClick,
  } = props;
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const onEditClick = (): void => {
    handleEditClick(props.presentation);
  };

  const onDeleteClick = (): void => {
    handleDeleteClick(props.presentation);
  };

  const onSlideListClick = (): void => {
    handleSlideListClick(props.presentation);
  };

  const onPermissionsClick = () => {
    props.handlePermissionsClick(props.presentation);
  };

  const onPreviewClick = () => {
    props.handlePreviewClick(props.presentation);
  };

  const onPreviewSelfDeliveryClick = () => {
    props.handlePreviewSelfDeliveryClick(props.presentation);
  };

  const dropdownItems: Array<DropdownMenuItem> = [];

  if (isLoggedInRoleAdmin(loggedInUserRole)) {
    dropdownItems.push(
      { name: getLanguageValue(languageText, "Edit"), onClick: onEditClick },
      {
        name: getLanguageValue(languageText, "Delete"),
        onClick: onDeleteClick,
      },
      {
        name: getLanguageValue(languageText, "Slide list"),
        onClick: onSlideListClick,
      },
      {
        name: getLanguageValue(languageText, "Permissions"),
        onClick: onPermissionsClick,
        disabled: !props.presentation.enableAccessControl,
        title: !props.presentation.enableAccessControl
          ? "Access control is disabled."
          : undefined,
      },
    );
  }

  dropdownItems.push({
    name: getLanguageValue(languageText, "Preview"),
    onClick: onPreviewClick,
  });

  if (isLoggedInRoleAdmin(loggedInUserRole)) {
    dropdownItems.push({
      name: getLanguageValue(languageText, "Preview self delivery"),
      onClick: onPreviewSelfDeliveryClick,
    });
  }

  return <DropdownMenu items={dropdownItems} />;
};
