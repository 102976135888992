import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BooleanCell } from "../../components/reactTable/booleanCell";
import { CoursesActionsCell } from "./coursesActionsCell";
import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { deleteCourse, getAllCourses, toggleActiveStatus } from "./actions";
import { getMediaURL, setSpinner } from "../actions";
import { UserGeneratedMediaType } from "../commonEnums";
import { ICourse } from "../academy/types";

export interface IRequestCourseBody {
  filter: string;
  sorting: string;
  maxResultCount: number;
  skipCount: number;
}

export const useCoursesList = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [data, setData] = useState<ICourse[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openAddEditCoursesModal, setOpenAddEditCoursesModal] =
    useState<boolean>(false); // Add/Edit Course popup
  const [openReorderCoursesModal, setOpenReorderCoursesModal] =
    useState<boolean>(false); // Reorder Course popup
  const [courseUpdated, setCourseUpdated] = useState<boolean>(false); // This flag is used to re-render the courses data on requirement
  const [coursePermission, setCoursePermission] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<number>(0);
  const [courseTitle, setCourseTitle] = useState<string>("");

  const tableHeader: Array<ColumnDef<ICourse, any>> = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <CoursesActionsCell
          languageText={languageText}
          id={props.row.original.id}
          name={props.row.original.title}
          isEnabled={props.row.original.isEnabled}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          handleChangeActiveStatusClick={handleChangeActiveStatusClick}
          handleCoursePermission={openCoursesPermissionModal}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(languageText, "Icon"),
      accessorKey: "logo",
      enableSorting: false,
      cell: ({ ...props }) => (
        <div className="mw-100px">
          <div className="text-truncate">
            <img
              width="40px"
              height="40px"
              src={props.row.original.logo}
              className="img-thumbnail"
            />
          </div>
        </div>
      ),
    },
    {
      header: getLanguageValue(languageText, "Title"),
      accessorKey: "title",
    },
    {
      header: getLanguageValue(languageText, "Order Number"),
      accessorKey: "orderNumber",
    },
    {
      header: getLanguageValue(languageText, "Category"),
      accessorKey: "categoryName",
    },
    {
      header: getLanguageValue(languageText, "Status"),
      accessorKey: "isEnabled",
      cell: ({ ...props }) => (
        <BooleanCell
          trueValueText={getLanguageValue(languageText, "Enabled")}
          falseValueText={getLanguageValue(languageText, "Disabled")}
          languageText={languageText}
          boolValue={props.row.original.isEnabled}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, "Language"),
      accessorKey: "languageName",
    },
  ];

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchCourses(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchCourses(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  useEffect(() => {
    if (courseUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchCourses(skipCount);
    }
  }, [courseUpdated]);

  const fetchCourses = (skipCount: number): void => {
    dispatch(setSpinner(true));

    const body: IRequestCourseBody = {
      filter: filter,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: skipCount,
    };

    getAllCourses(body, dispatch)
      .then((response) => {
        if (response) {
          const courseImageList = response.items.map((courseList) => {
            const logoURL = getMediaURL(
              courseList.id,
              UserGeneratedMediaType.AcademyCourse,
            );
            return {
              ...courseList,
              logo: logoURL,
            };
          });

          Promise.all(courseImageList).then((updatedCourse) => {
            setData(updatedCourse);
          });

          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setData(response.items.slice());
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
        }
        if (courseUpdated) setCourseUpdated(false);
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  };

  const addCourseClick = (): void => {
    setOpenAddEditCoursesModal(true);
  };

  const closeCoursesModal = (refreshCourseList: boolean): void => {
    if (refreshCourseList) {
      setCourseUpdated(true);
    }
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenAddEditCoursesModal(false);
  };

  const onReorderCoursesClick = (): void => {
    setOpenReorderCoursesModal(true);
  };

  const closeReorderCoursesModal = (): void => {
    setOpenReorderCoursesModal(false);
  };

  const refetchCourseList = (): void => setCourseUpdated(true);

  const handleEditClick = (id: number): void => {
    setEditedRowId(id);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  const handleChangeActiveStatusClick = (
    id: number,
    isEnabled: boolean,
  ): void => {
    const toggledIsEnabled = !isEnabled;

    toggleActiveStatus(id, toggledIsEnabled, dispatch).then((res) => {
      if (res.success === true) {
        setCourseUpdated(true);
      }
    });
  };

  const handleDeleteCourse = (): void => {
    deleteCourse(deleteRowId, dispatch).then(() => {
      setDeleteRowId(0);
      setSelectedActionRowName("");
      setCourseUpdated(true);
    });
  };

  const closeDeleteModal = (): void => setDeleteRowId(0);

  const openCoursesPermissionModal = (id: number, name: string): void => {
    setCourseId(id);
    setCourseTitle(name);
    setCoursePermission(true);
  };

  const closePermissionModal = (): void => {
    setCoursePermission(false);
  };

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openAddEditCoursesModal,
    openReorderCoursesModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    coursePermission,
    courseTitle,
    courseId,
    closePermissionModal,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    openCoursesPermissionModal,
    addCourseClick,
    closeCoursesModal,
    onReorderCoursesClick,
    closeReorderCoursesModal,
    refetchCourseList,
    handleDeleteCourse,
    closeDeleteModal,
  };
};
