import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { E164Number } from "libphonenumber-js/types";

import {
  getInvoiceArticlesByProfileOrClientSubscriptionId,
  getActivityInvoiceAddresses,
  getProfileInvoiceDetails,
  getFortnoxCustomers,
  getFortnoxInvoicesByCustomerNumber,
  sendActivityInvoiceToFortnox,
  createOrUpdateProfileInvoice,
  updateFortnoxInvoiceStatus,
} from "./actions";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { getAllCountries } from "../../../clientList/addEditModal/action";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { ValidationHelper } from "../../../validationHelper";
import { IFocusError } from "../../../../components/formComponents/input";
import {
  IPaymentTypes,
  getPaymentTypes,
} from "../../../../commonUtils/planSuscriptionHelper";
import { PaymentType } from "../../../clientList/addEditModal/clientEnums";
import { addToast, setSpinner } from "../../../actions";
import { IProfileInvoiceBillingModalProps } from "./profileInvoiceBillingModal";
import {
  IInvoiceExistingAddress,
  IInvoiceFocusInput,
  IInvoiceFormData,
  IProductData,
  IProfileInvoiceDetails,
  InvoiceInputs,
  ProductInput,
} from "../../../../components/invoice/invoiceInterface";
import { ICountry } from "@app/containers/commonInterfaces";
import { AnyAction } from "redux";
import { ActivityId } from "@app/containers/reducer";

export const useBillingModal = (props: IProfileInvoiceBillingModalProps) => {
  const { languageText, handleCloseClick } = props;

  const dispatch = useDispatch();
  const activityId = (Number(useParams().id) || 0) as ActivityId;

  const paymentTypes: IPaymentTypes[] = useMemo(
    () => getPaymentTypes(languageText),
    [],
  );

  const initialProfileInvoiceDetailsState: IProfileInvoiceDetails = {
    name: "",
    clientId: 0,
    status: 1,
    idiInvoice: {
      idiClientId: 0,
      activityId: 0 as ActivityId,
      clientSubscriptionId: 0,
      idiLanguageId: 0,
      date: "",
      company: "",
      address: "",
      city: "",
      zip: "",
      country: 0,
      fortnoxInvoiceNumber: 0,
      fortnoxCustomerNumber: 0,
      fortnoxCustomerWithName: "",
      reference: "",
      businessIdentification: "",
      email: "",
      telephone: "",
      euvatid: "",
      subTotal: 0,
      taxAmount: 0,
      total: 0,
      currency: "",
      linkedToExistingFortnoxInvoice: true,
      fortnoxInvoiceStatus: null,
      id: 0,
      invoice: false,
      creditCard: false,
    },
    profileInvoiceRows: [],
    id: 0 as ActivityId,
  };
  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: "",
  };
  const initialProfileAddressData: IInvoiceFormData = {
    paymentId: 0,
    company: "",
    businessIdentification: "",
    invoiceReference: "",
    email: "",
    telephone: "",
    address: "",
    city: "",
    zip: "",
    countryId: "",
    euvatid: "",
    invoiceId: 0,
    fortnoxCustomerNumber: "",
    fortnoxInvoiceNumber: "",
    date: "",
    currency: "",
    profileInvoiceRows: [],
  };
  const initialProductData: IProductData = {
    idiInvoiceId: 0,
    id: 0,
    articleId: 0,
    articleNumber: "",
    description: "",
    quantity: 0,
    price: 0,
    discount: 0,
    sum: 0,
    taxPercentage: 0,
    temporaryId: 0,
  };
  const initialInvoiceList: IDropdownList = {
    id: 0,
    displayName: "New",
    value: "0",
  };
  const initialDropdownSelectedItemState: IDropdownSelectedItem = {
    id: 0,
    name: "",
    value: "",
  };

  const [countries, setCountries] = useState<IDropdownList[]>([]);
  const [existingAddresses, setExistingAddresses] = useState<
    IInvoiceExistingAddress[]
  >([]);
  const [selectAddressModal, setSelectAddressModal] = useState<boolean>(false);
  const [existingInvoiceDetails, setExistingInvoiceDetails] =
    useState<IProfileInvoiceDetails>(initialProfileInvoiceDetailsState);
  const [invoiceFormData, setInvoiceFormData] = useState<IInvoiceFormData>(
    initialProfileAddressData,
  );
  const [focusInput, setFocusInput] = useState<IInvoiceFocusInput>({
    company: initialFocusInputState,
    invoiceReference: initialFocusInputState,
    address: initialFocusInputState,
    city: initialFocusInputState,
    zip: initialFocusInputState,
    countryId: initialFocusInputState,
    businessIdentification: initialFocusInputState,
    email: initialFocusInputState,
    telephone: initialFocusInputState,
    articleId: initialFocusInputState,
    price: initialFocusInputState,
    quantity: initialFocusInputState,
    description: initialFocusInputState,
    sum: initialFocusInputState,
    fortnoxCustomerNumber: initialFocusInputState,
    fortnoxInvoiceNumber: initialFocusInputState,
    discount: initialFocusInputState,
  });
  const [fortnoxClientList, setFortnoxClientList] = useState<IDropdownList[]>(
    [],
  );
  const [fortnoxInvoiceList, setFortnoxInvoiceList] = useState<IDropdownList[]>(
    [initialInvoiceList],
  );
  const [selectedFortnoxCustomer, setSelectedFortnoxCustomer] =
    useState<IDropdownSelectedItem>(initialDropdownSelectedItemState); // To Show Fortnox Customer Default Message
  const [showFortnoxInvoiceInputs, setShowFortnoxInvoiceInputs] =
    useState(false);
  const [productFormData, setProductFormData] =
    useState<IProductData>(initialProductData);
  const [productsList, setProductsList] = useState<IDropdownList[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const getFortnoxInvoices = async (
    fortnoxCustomerNumber: number,
  ): Promise<void> => {
    getFortnoxInvoicesByCustomerNumber(fortnoxCustomerNumber, dispatch).then(
      (response) => {
        if (response) {
          const invoiceClient: IDropdownList[] = response.map((item) => ({
            id: item.documentNumber || 0,
            displayName: String(item.documentNumber),
            value: String(item.documentNumber),
          }));

          const combinedArray = [initialInvoiceList, ...invoiceClient];

          setFortnoxInvoiceList(combinedArray);
        } else {
          setFortnoxInvoiceList([initialInvoiceList]);
        }
      },
    );
  };

  const getProfileInvoiceData = (): void => {
    dispatch(setSpinner(true));
    getProfileInvoiceDetails(activityId, dispatch)
      .then((response) => {
        if (response) {
          setExistingInvoiceDetails(response);
          const { idiInvoice, profileInvoiceRows } = response;

          if (idiInvoice.fortnoxCustomerNumber) {
            getFortnoxInvoices(idiInvoice.fortnoxCustomerNumber);
          }

          if (idiInvoice.fortnoxInvoiceNumber) {
            setShowFortnoxInvoiceInputs(true);
          }

          // Added temporaryId's for product edit/delete purpose
          let updatedInvoiceRows: IProductData[] = [];
          if (profileInvoiceRows.length > 0) {
            updatedInvoiceRows = profileInvoiceRows.map((invoice, index) => ({
              ...invoice,
              temporaryId: index + 1,
            }));
          }

          setInvoiceFormData({
            company: idiInvoice.company ?? "",
            invoiceReference: idiInvoice.reference ?? "",
            address: idiInvoice.address ?? "",
            city: idiInvoice.city ?? "",
            zip: idiInvoice.zip ?? "",
            countryId: idiInvoice.country ? idiInvoice.country.toString() : "",
            businessIdentification: idiInvoice.businessIdentification ?? "",
            email: idiInvoice.email ?? "",
            telephone: idiInvoice.telephone ?? "",
            euvatid: idiInvoice.euvatid ?? "",
            paymentId: idiInvoice.creditCard
              ? PaymentType.CreditCard
              : PaymentType.Invoice,
            invoiceId: idiInvoice.id,
            fortnoxCustomerNumber: idiInvoice.fortnoxCustomerNumber
              ? idiInvoice.fortnoxCustomerNumber.toString()
              : "",
            fortnoxInvoiceNumber: idiInvoice.fortnoxInvoiceNumber
              ? idiInvoice.fortnoxInvoiceNumber.toString()
              : "0",
            date: idiInvoice.date,
            currency: idiInvoice.currency,
            profileInvoiceRows: updatedInvoiceRows,
          });
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    getProfileInvoiceData();
  }, []);

  // many many informations, yes!
  const getAllInformationsRequired = (): void => {
    // Get Invoice addresses:
    getActivityInvoiceAddresses(activityId, dispatch).then((response) => {
      if (response) {
        setExistingAddresses(response);
      }
    });

    // Get Countries
    getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }));
        setCountries(countries);
      }
    });

    //get fortnox customers
    let customers: IDropdownList[] = [];
    getFortnoxCustomers(dispatch).then((response) => {
      if (response) {
        const customerArray = Object.entries(response).map(([id, value]) => ({
          id,
          value,
        }));

        customers = customerArray.map((item) => ({
          id: Number(item.id),
          displayName: String(item.value),
          value: String(item.id),
        }));
        setFortnoxClientList(customers);
      }
    });
  };

  useEffect(() => {
    getAllInformationsRequired();
  }, []);

  useEffect(() => {
    // Get Invoice products
    if (invoiceFormData.invoiceId) {
      getInvoiceArticlesByProfileOrClientSubscriptionId(
        activityId,
        true,
        dispatch,
      ).then((response) => {
        if (response) {
          const productsArray = Object.entries(response).map(([id, value]) => ({
            id,
            value,
          }));
          const products: IDropdownList[] = productsArray.map((item) => ({
            id: Number(item.id),
            displayName: String(item.value),
            value: String(item.id),
          }));
          setProductsList(products);
        }
      });
    }
  }, [invoiceFormData.invoiceId]);

  useEffect(() => {
    // Get Invoice products
    if (existingInvoiceDetails.idiInvoice.fortnoxInvoiceNumber) {
      updateFortnoxInvoiceStatus(invoiceFormData.invoiceId, dispatch);
    }
  }, [existingInvoiceDetails.idiInvoice.fortnoxInvoiceNumber]);

  // Payment Selection
  const handleSelectPaymentMethod = (paymentId: number) => {
    setInvoiceFormData({
      ...invoiceFormData,
      paymentId:
        PaymentType.Invoice === paymentId
          ? PaymentType.Invoice
          : PaymentType.CreditCard,
    });
  };

  // Select Existing Address
  const openSelectAddressModal = (): void => {
    setSelectAddressModal(true);
  };

  const closeSelectAddressModal = (): void => {
    setSelectAddressModal(false);
  };

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = "";
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [InvoiceInputs.company]: "Company name is required",
        [InvoiceInputs.invoiceReference]: "Invoice is required",
        [InvoiceInputs.businessIdentification]:
          "Business identification is required",
        [InvoiceInputs.email]: "Email is required",
        [InvoiceInputs.address]: "Address is required",
        [InvoiceInputs.city]: "City is required",
        [InvoiceInputs.zip]: "Zip code is required",
        [InvoiceInputs.countryId]: "Country is required",
        [InvoiceInputs.fortnoxCustomerNumber]: "Fortnox client is required",
        [InvoiceInputs.fortnoxInvoiceNumber]: "Fortnox invoice is Required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }
    if (name === InvoiceInputs.email && value) {
      const isValid = ValidationHelper.isEmailValid(value);
      if (!isValid)
        errorMessage = getLanguageValue(languageText, "Invalid email");
    }
    if (name === InvoiceInputs.telephone && value) {
      if (value.length >= 1 && value.length <= 4) {
        errorMessage = getLanguageValue(languageText, "Invalid phonenumber");
      }
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleInvoiceFormValidation = (
    invoiceFormData: IInvoiceFormData,
  ): boolean => {
    if (
      !invoiceFormData.company ||
      focusInput.company.errorMessage ||
      !invoiceFormData.businessIdentification ||
      focusInput.businessIdentification.errorMessage ||
      !invoiceFormData.invoiceReference ||
      focusInput.invoiceReference.errorMessage ||
      !invoiceFormData.email ||
      focusInput.email.errorMessage ||
      focusInput.telephone.errorMessage ||
      !invoiceFormData.address ||
      focusInput.address.errorMessage ||
      !invoiceFormData.city ||
      focusInput.city.errorMessage ||
      !invoiceFormData.zip ||
      focusInput.zip.errorMessage ||
      !invoiceFormData.countryId ||
      focusInput.countryId.errorMessage ||
      !invoiceFormData.fortnoxCustomerNumber ||
      focusInput.fortnoxCustomerNumber.errorMessage ||
      !invoiceFormData.fortnoxInvoiceNumber ||
      focusInput.fortnoxInvoiceNumber.errorMessage
    ) {
      for (const item of Object.values(InvoiceInputs)) {
        if (item === InvoiceInputs.euvatid) continue;
        handleFormErrors(item, invoiceFormData[item]);
      }
      return false;
    }
    return true;
  };

  const onSelectAddressClick = (data: IInvoiceExistingAddress) => {
    const updatedCountry =
      countries.find((items) => Number(items.value) === Number(data.countryId))
        ?.value ?? "";

    const updatedFormData: IInvoiceFormData = {
      ...invoiceFormData,
      company: data.company ?? "",
      businessIdentification: data.businessIdentification ?? "",
      invoiceReference: data.invoiceReference ?? "",
      email: data.email ?? "",
      telephone: data.telephone ?? "",
      address: data.address ?? "",
      city: data.city ?? "",
      zip: data.zip ?? "",
      countryId: updatedCountry,
      euvatid: data.euvatid ?? "",
    };

    setInvoiceFormData(updatedFormData);
    handleInvoiceFormValidation(updatedFormData);
    closeSelectAddressModal();
  };

  // Company Address and Details
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name as keyof IInvoiceFocusInput;
    const value = e.target.value;

    setInvoiceFormData({
      ...invoiceFormData,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IInvoiceFocusInput;
    const value = selectedItem.value;

    setInvoiceFormData({
      ...invoiceFormData,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    handleFormErrors(name, value);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name as keyof IInvoiceFocusInput;
    const value = e.target.value;

    setInvoiceFormData({
      ...invoiceFormData,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaBlurEvent = (
    e: React.FocusEvent<HTMLTextAreaElement>,
  ): void => {
    const { name, value } = e.target;
    handleFormErrors(name, value);
  };

  const handlePhoneInputChange = (value: E164Number): void => {
    setInvoiceFormData({
      ...invoiceFormData,
      telephone: value,
    });

    if (focusInput.telephone?.touched) {
      handleFormErrors(InvoiceInputs.telephone, value);
    }
  };

  // Fortnox Customer & Invoice selection
  const handleCloseCustomerDefaultPopup = (): void => {
    setSelectedFortnoxCustomer(initialDropdownSelectedItemState);
  };

  const handleFortnoxCustomerSelect = async (
    selectedItem: IDropdownSelectedItem,
  ): Promise<void> => {
    const name = selectedItem.name as keyof IInvoiceFocusInput;
    const value = selectedItem.value;

    setInvoiceFormData({
      ...invoiceFormData,
      fortnoxCustomerNumber: value,
      fortnoxInvoiceNumber: "0",
    });

    dispatch(setSpinner(true));
    await getFortnoxInvoices(Number(value));
    dispatch(setSpinner(false));

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleSaveCustomerAsDefault = (): void => {
    handleFortnoxCustomerSelect(selectedFortnoxCustomer);
    setSelectedFortnoxCustomer(initialDropdownSelectedItemState);
  };

  const handleFortnoxClientSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    if (
      existingInvoiceDetails.idiInvoice.fortnoxCustomerNumber &&
      selectedItem.id &&
      existingInvoiceDetails.idiInvoice.fortnoxCustomerNumber !==
        selectedItem.id
    ) {
      setSelectedFortnoxCustomer(selectedItem);
    } else handleFortnoxCustomerSelect(selectedItem);
  };

  const handleInvoiceSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IInvoiceFocusInput;
    const value = selectedItem.value;

    setInvoiceFormData({
      ...invoiceFormData,
      fortnoxInvoiceNumber: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  // Add/Edit/Delete Product
  const handleProductFormErrors = (
    name: string,
    value: string | number,
  ): void => {
    let errorMessage: string = "";
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [ProductInput.articleId]: "Product is required",
        [ProductInput.description]: "Description is required",
        [ProductInput.price]: "Price is required",
        [ProductInput.quantity]: "Quantity is required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleProductSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IInvoiceFocusInput;
    const value = selectedItem.value;

    const updatedArticleNumber =
      productsList
        .find((product) => product.value === value)
        ?.displayName.toString() ?? "";

    setProductFormData({
      ...productFormData,
      [name]: value,
      articleNumber: updatedArticleNumber,
    });

    if (focusInput[name]?.touched) {
      handleProductFormErrors(name, value);
    }
  };

  const handleProductInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name as keyof IInvoiceFocusInput;
    const value = e.target.value;

    setProductFormData((prevFields) => {
      const { quantity, price, discount } = prevFields;
      let calculatedSum = 0;
      if (name === ProductInput.quantity) {
        calculatedSum = Number(value) * Number(price) - Number(discount);
      } else if (name === ProductInput.price) {
        calculatedSum = Number(quantity) * Number(value) - Number(discount);
      } else if (name === ProductInput.discount) {
        calculatedSum = Number(quantity) * Number(price) - Number(value);
      }

      return {
        ...prevFields,
        [name]: value,
        sum: calculatedSum,
      };
    });

    if (focusInput[name]?.touched) {
      handleProductFormErrors(name, value);
    }
  };

  const handleCancelProduct = (): void => {
    setProductFormData(initialProductData);
    if (isEditMode) setIsEditMode(false);
  };

  const handleValidationOnAddEditProduct = (): boolean => {
    if (
      !productFormData.articleId ||
      focusInput.articleId.errorMessage ||
      !productFormData.description ||
      focusInput.description.errorMessage ||
      !productFormData.price ||
      focusInput.price.errorMessage ||
      !productFormData.quantity ||
      focusInput.quantity.errorMessage
    ) {
      for (const item of Object.values(ProductInput)) {
        if (item === ProductInput.discount) continue;
        handleProductFormErrors(item, productFormData[item]);
      }
      return false;
    }
    return true;
  };

  const handleAddProduct = () => {
    if (!handleValidationOnAddEditProduct()) return;

    // Adding temporary Id
    let updatedProductFormData: IProductData = initialProductData;
    updatedProductFormData = {
      ...productFormData,
      temporaryId: invoiceFormData.profileInvoiceRows.length + 1,
    };

    setInvoiceFormData({
      ...invoiceFormData,
      profileInvoiceRows: [
        ...invoiceFormData.profileInvoiceRows,
        updatedProductFormData,
      ],
    });
    handleCancelProduct();
  };

  const handleSaveProduct = () => {
    if (!handleValidationOnAddEditProduct()) return;

    const existingProductIndex = invoiceFormData.profileInvoiceRows.findIndex(
      (product) => product.temporaryId === productFormData.temporaryId,
    );

    const updatedProducts = [...invoiceFormData.profileInvoiceRows];
    updatedProducts[existingProductIndex] = {
      ...updatedProducts[existingProductIndex],
      ...productFormData,
    };

    setInvoiceFormData({
      ...invoiceFormData,
      profileInvoiceRows: updatedProducts,
    });
    handleCancelProduct();
  };

  const handleEditProduct = (id: number) => {
    const editProduct =
      invoiceFormData.profileInvoiceRows.find(
        (product) => product.temporaryId === id,
      ) ?? initialProductData;

    setIsEditMode(true);
    setProductFormData(editProduct);
    setFocusInput({
      ...focusInput,
      articleId: initialFocusInputState,
      price: initialFocusInputState,
      quantity: initialFocusInputState,
      description: initialFocusInputState,
      sum: initialFocusInputState,
    });
  };

  const handleDeleteProduct = (id: number) => {
    const updatedProducts = [...invoiceFormData.profileInvoiceRows]
      .filter((product) => product.temporaryId !== id)
      .map((product, index) => ({
        ...product,
        temporaryId: index + 1,
      }));

    setInvoiceFormData({
      ...invoiceFormData,
      profileInvoiceRows: updatedProducts,
    });
  };

  //  Create / Update Invoice draft
  const createOrUpdateInvoiceDraft = (): void => {
    if (!handleInvoiceFormValidation(invoiceFormData)) return;

    dispatch(setSpinner(true));

    const { idiInvoice } = existingInvoiceDetails;
    const customerName =
      fortnoxClientList
        .find(
          (client) => client.value === invoiceFormData.fortnoxCustomerNumber,
        )
        ?.displayName.toString() ?? "";
    const subTotal = invoiceFormData.profileInvoiceRows.reduce(
      (acc, item) => acc + item.sum,
      0,
    );
    const taxAmount = parseFloat((subTotal * 0.25).toFixed(2));
    const netTotal = Number((subTotal + taxAmount).toFixed(2));

    const updatedProducts: IProductData[] = [
      ...invoiceFormData.profileInvoiceRows,
    ].map((product) => {
      const { temporaryId, ...rest } = product;
      return rest;
    });

    const body: IProfileInvoiceDetails = {
      name: existingInvoiceDetails.name,
      clientId: existingInvoiceDetails.clientId,
      status: existingInvoiceDetails.status,
      id: existingInvoiceDetails.id,
      idiInvoice: {
        idiClientId: idiInvoice.idiClientId,
        activityId: idiInvoice.activityId,
        idiLanguageId: idiInvoice.idiLanguageId,
        date: idiInvoice.date,
        company: invoiceFormData.company,
        address: invoiceFormData.address,
        city: invoiceFormData.city,
        zip: invoiceFormData.zip,
        country: Number(invoiceFormData.countryId),
        fortnoxInvoiceNumber: Number(invoiceFormData.fortnoxInvoiceNumber),
        fortnoxCustomerNumber: Number(invoiceFormData.fortnoxCustomerNumber),
        fortnoxCustomerWithName: customerName,
        reference: invoiceFormData.invoiceReference,
        businessIdentification: invoiceFormData.businessIdentification,
        email: invoiceFormData.email,
        telephone: invoiceFormData.telephone,
        euvatid: invoiceFormData.euvatid,
        subTotal: subTotal,
        taxAmount: taxAmount,
        total: netTotal,
        currency: idiInvoice.currency,
        linkedToExistingFortnoxInvoice:
          existingInvoiceDetails.idiInvoice.linkedToExistingFortnoxInvoice,
        fortnoxInvoiceStatus: idiInvoice.fortnoxInvoiceStatus,
        id: idiInvoice.id,
        invoice: invoiceFormData.paymentId === PaymentType.Invoice,
        creditCard: invoiceFormData.paymentId === PaymentType.CreditCard,
      },
      profileInvoiceRows: updatedProducts,
    };

    createOrUpdateProfileInvoice(body, dispatch)
      .then((response) => {
        if (response) {
          getProfileInvoiceData();
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  // Send to Fortnox
  const handleSendToFortnox = (): void => {
    dispatch(setSpinner(true));

    sendActivityInvoiceToFortnox(
      activityId,
      existingInvoiceDetails.clientId,
      dispatch,
    )
      .then((response) => {
        if (response) {
          dispatch(addToast("Successfully send to fortnox") as AnyAction);
          handleCloseClick();
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  };

  return {
    countries,
    paymentTypes,
    existingAddresses,
    selectAddressModal,
    existingInvoiceDetails,
    invoiceFormData,
    focusInput,
    fortnoxClientList,
    fortnoxInvoiceList,
    selectedFortnoxCustomer,
    showFortnoxInvoiceInputs,
    productFormData,
    isEditMode,
    productsList,
    handleSelectPaymentMethod,
    openSelectAddressModal,
    closeSelectAddressModal,
    onSelectAddressClick,
    handleInputChange,
    handlePhoneInputChange,
    handleTextAreaChange,
    handleCountrySelect,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleFortnoxClientSelect,
    handleInvoiceSelect,
    handleCloseCustomerDefaultPopup,
    handleSaveCustomerAsDefault,
    handleProductSelect,
    handleProductInputChange,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
    handleEditProduct,
    handleDeleteProduct,
    createOrUpdateInvoiceDraft,
    handleSendToFortnox,
  };
};
