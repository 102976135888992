import React from "react";

import { Input } from "../../../../components/formComponents/input";
import { Switchbox } from "../../../../components/formComponents/switchbox";
import { TextEditor } from "../../../../components/textEditor/textEditor";
import { TextArea } from "../../../../components/formComponents/textArea";
import { ReactDatePicker } from "../../../../components/datePicker/ReactDatePicker";
import { IAddEditNewsModalProps, useAddEditNews } from "./hooks";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { NewsInputs } from "../types";
import { FileUpload } from "../../../../components/formComponents/fileUpload";
import { DropdownSelect } from "@app/components/formComponents/dropdownSelect";
import { MaxLength } from "../../../commonEnums";
import { ModalComponent } from "../../../../components/modals/modalComponent";

export const AddEditNewsModal = (props: IAddEditNewsModalProps) => {
  const {
    focusInput,
    isEditClicked,
    imgErrorMessage,
    languageText,
    newsData,
    newsTypeList,
    closeModal,
    handleNewsSubmit,
    handleDateSelect,
    handleDropdownSelect,
    handleBlurEvent,
    handleInputChange,
    handleTextAreaChange,
    onNewsTextEditorChange,
    handleCheckboxChange,
    handleFileUpload,
    removeIcon,
  } = useAddEditNews(props);

  return (
    <>
      <ModalComponent
        width="lg"
        headerText={getLanguageValue(
          languageText,
          isEditClicked ? "Edit News" : "Add News",
        )}
        cancelButtonText={getLanguageValue(languageText, "Cancel")}
        submitButtonText={getLanguageValue(languageText, "Save")}
        handleCancelClick={closeModal}
        handleSubmitClick={handleNewsSubmit}
      >
        <form className="row">
          <div className="col-6">
            <Input
              name={NewsInputs.title}
              label={getLanguageValue(languageText, "Name")}
              errorMessage={focusInput.title.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              value={newsData.title}
              placeholder={getLanguageValue(
                languageText,
                "Latest News from IDI",
              )}
              required
            />
          </div>

          <div className="col-3" style={{ zIndex: "9" }}>
            <ReactDatePicker
              name={NewsInputs.publishedDate}
              label={getLanguageValue(languageText, "Date")}
              placeholder={getLanguageValue(languageText, "Select a date")}
              date={
                newsData.publishedDate ? new Date(newsData.publishedDate) : null
              }
              focusInput={focusInput.publishedDate}
              handleDateSelect={handleDateSelect}
              dateWithTime={false}
              required
            />
          </div>

          <div className="col-3 mt-8 float-right">
            <Switchbox
              name={NewsInputs.published}
              labelFirstText={getLanguageValue(languageText, "Published")}
              value={newsData.published}
              handleSwitchboxChange={handleCheckboxChange}
            />
          </div>

          <div className="row">
            <div className="col-6">
              <FileUpload
                label={getLanguageValue(languageText, "Select Image")}
                errorMessage={imgErrorMessage.length > 0 ? imgErrorMessage : ""}
                name={NewsInputs.image}
                handleFileUpload={handleFileUpload}
                imagePath={newsData.image}
                isEditMode={isEditClicked}
                removeIconBtnText={getLanguageValue(
                  languageText,
                  "Remove image",
                )}
                removeIcon={removeIcon}
              />
            </div>

            <div className="col-6">
              <DropdownSelect
                name={NewsInputs.type}
                label={getLanguageValue(languageText, "News Type")}
                defaultLabel={getLanguageValue(
                  languageText,
                  "Search News Type",
                )}
                list={newsTypeList}
                value={newsData.type?.toString()}
                focusInput={focusInput.type}
                handleDropdownSelect={handleDropdownSelect}
                searchOption
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TextArea
                name={NewsInputs.excerpt}
                areaClass="form-control form-control-solid min-h-100px"
                label={getLanguageValue(languageText, "Description")}
                placeholder={getLanguageValue(languageText, "Description")}
                value={newsData.excerpt}
                maxLength={MaxLength.L400}
                focusInput={focusInput.excerpt}
                onChange={handleTextAreaChange}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TextEditor
                name={NewsInputs.post}
                focusInput={focusInput.post}
                label={`${getLanguageValue(languageText, "Article")}:`}
                value={newsData.post}
                handleTextEditor={onNewsTextEditorChange}
                required
              />
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
};
