import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { IInstructionSteps } from "./instruction";

// Edit profile Instruction Steps
const editProfileInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#step0",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourWelcome"),
  },
  {
    element: "#status",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourTopSettings"),
  },
  {
    element: "#roleSettings",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourTopRoleSettings"),
  },
  {
    element: "#respondentSettings",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourTopRespSettings"),
  },
  {
    element: "#editProfileAddParticipants",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourAddParticipant"),
  },
  {
    element: "#instructionBtn",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourButton"),
  },
];

const editProfileNewStatusInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#editProfileNewStatusHeader",
    intro: getLanguageValue(
      languageText,
      "This section shows new participants who have not yet started filling their profile.",
    ),
  },
  {
    element: "#newParticipantDetails",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticDetails"),
  },
  {
    element: "#roleIcon",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticRoleSettings"),
  },
  {
    element: "#participantLink",
    intro: getLanguageValue(
      languageText,
      "Here you can see the participant link.",
    ),
  },
  {
    element: "#newInvitation",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticMailSMS"),
  },
  {
    element: "#newIndividualCheckbox",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticMark"),
  },
  {
    element: "#newMultiEmailSMS",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticMenu"),
  },
];

const editProfileOnGoingStatusInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#editProfileOnGoingStatusHeader",
    intro: getLanguageValue(
      languageText,
      "This section shows participants whose profiles are ongoing.",
    ),
  },
  {
    element: "#onGoingParticipantDetails",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticDetails"),
  },
  {
    element: "#onGoingNoOfRespondents",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourActParticResp"),
  },
  {
    element: "#onGoingInvitation",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticMailSMS"),
  },
  {
    element: "#onGoingIndividualCheckbox",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticMark"),
  },
  {
    element: "#onGoingMultiEmailSMS",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticMenu"),
  },
];

const editProfileCompletedStatusInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#editProfileCompletedStatusHeader",
    intro: getLanguageValue(
      languageText,
      "This section shows participants with completed profiles.",
    ),
  },
  {
    element: "#completedParticipantDetails",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticDetails"),
  },
  {
    element: "#completedNoOfRespondents",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourActParticResp"),
  },
  {
    element: "#completedIndividualDropdown",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourComParticProfile"),
  },
  {
    element: "#presentationIcon",
    intro: getLanguageValue(
      languageText,
      "You can create presentation from here after selecting the participants.",
    ),
  },
  {
    element: "#completedMassAction",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourComParticMenu"),
  },
];

const editProfileDeliveredStatusInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#editProfileDeliveredStatusHeader",
    intro: getLanguageValue(
      languageText,
      "This section shows delivered profile participants.",
    ),
  },
  {
    element: "#deliveredParticipantDetails",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticDetails"),
  },
  {
    element: "#deliveredNoOfRespondents",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourActParticResp"),
  },
  {
    element: "#deliveredIndividualDropdown",
    intro: getLanguageValue(
      languageText,
      "Download as PDF or preview participants IDI-profile.",
    ),
  },
  {
    element: "#deliveredMassAction",
    intro: getLanguageValue(
      languageText,
      "Perform mass actions. E.g. download as PDF, preview and un-deliver IDI profile.",
    ),
  },
];

// Below instruction steps are when all the participant profiles are delivered
const editProfileDeliveredProfileInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#editProfileDeliveredProfileHeader",
    intro: getLanguageValue(
      languageText,
      "This section shows delivered profile participants.",
    ),
  },
  {
    element: "#deliveredProfileParticipantDetails",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourNewParticDetails"),
  },
  {
    element: "#deliveredProfileIndividualDropdown",
    intro: getLanguageValue(
      languageText,
      "Download as PDF or preview participants IDI-profile.",
    ),
  },
  {
    element: "#deliveredProfileMassAction",
    intro: getLanguageValue(
      languageText,
      "Perform mass actions. E.g. download as PDF, preview and un-deliver IDI profile.",
    ),
  },
];

const editProfileParticipantInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#editParticipantName",
    intro: getLanguageValue(languageText, "AP-FA-AE-EditParticName"),
  },
  {
    element: "#editParticipantRole",
    intro: getLanguageValue(
      languageText,
      "Information about the number of respondents that the participant invited and answered. You can edit it before participants profile is delivered.",
    ),
  },
  {
    element: "#editParticipantInvite",
    intro: getLanguageValue(languageText, "AP-FA-AE-EditParticInvite"),
  },
  {
    element: "#editParticipantFormstatus",
    intro: getLanguageValue(languageText, "AP-FA-AE-EditParticFormstatus"),
  },
  {
    element: "#editParticipantProfile",
    intro: getLanguageValue(languageText, "AP-FA-AE-EditParticProfile"),
  },
  {
    element: "#editParticipantPublish",
    intro: getLanguageValue(languageText, "AP-FA-AE-EditParticPublish"),
  },
];

const participantSelfFormInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#instruction1",
    intro: getLanguageValue(languageText, "AP-DE-FI-Step3Instruction1"),
  },
  {
    element: "#instruction2",
    intro: getLanguageValue(languageText, "AP-DE-FI-Step3Instruction2"),
  },
  {
    element: "#instruction3",
    intro: getLanguageValue(languageText, "AP-DE-FI-Step3Instruction3"),
  },
  {
    element: "#instruction4",
    intro: getLanguageValue(languageText, "AP-DE-FI-Step3Instruction4"),
  },
  {
    element: "#instruction5",
    intro: getLanguageValue(languageText, "AP-DE-FI-Step3Instruction5"),
  },
  {
    element: "#instructionBtn",
    intro: getLanguageValue(languageText, "AP-FA-AE-TourButton"),
  },
];

const respondentSelfFormInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#instruction1",
    intro: getLanguageValue(languageText, "AP-RE-FI-Step2Instruction1"),
  },
  {
    element: "#instruction2",
    intro: getLanguageValue(languageText, "AP-RE-FI-Step2Instruction2"),
  },
  {
    element: "#instruction3",
    intro: getLanguageValue(languageText, "AP-RE-FI-Step2Instruction3"),
  },
  {
    element: "#instruction4",
    intro: getLanguageValue(languageText, "AP-RE-FI-Step2Instruction4"),
  },
  {
    element: "#instruction5",
    intro: getLanguageValue(languageText, "AP-RE-FI-Step2Instruction5"),
  },
  {
    element: "#instructionBtn",
    intro: getLanguageValue(languageText, "AP-RE-AE-TourButton"),
  },
];

const participantRoleSelectionInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#roleInstruction1",
    intro: getLanguageValue(
      languageText,
      "This guide will help you to select role settings for your IDI profile.",
    ),
  },
  {
    element: "#roleInstruction2",
    intro: getLanguageValue(
      languageText,
      "You can select type of role from here.",
    ),
  },
  {
    element: "#roleInstruction3",
    intro: getLanguageValue(languageText, "Select number of respondents."),
  },
  {
    element: "#roleInstruction4",
    intro: getLanguageValue(
      languageText,
      "Role settings are already selected by facilitator. You can proceed further.",
    ),
  },
  {
    element: "#instructionBtn",
    intro: getLanguageValue(languageText, "AP-RE-AE-TourButton"),
  },
];

const inviteRespondentsInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#inviteRespondentInstruction1",
    intro: getLanguageValue(
      languageText,
      "This guide will help you to invite respondents to your IDI profile.",
    ),
  },
  {
    element: "#inviteRespondentInstruction2",
    intro: getLanguageValue(
      languageText,
      "You can invite other participants and colleagues as respondents from the list below.",
    ),
  },
  {
    element: "#inviteRespondentInstruction3",
    intro: getLanguageValue(
      languageText,
      "You can invite external persons as respondents by email or SMS from here.",
    ),
  },
  {
    element: "#instructionBtn",
    intro: getLanguageValue(languageText, "AP-RE-AE-TourButton"),
  },
  /*

  FIXME: these three should go somewhere else - we don't have a place to preview
    invites anymore as they're sent automagically after the self-form. if we wanted
    to preview them we would have to save the messages beforehand, which is kinda awkward.
    -johan, 2024-10-21

  {
    element: '#inviteRespondentInstruction4',
    intro: getLanguageValue(
      languageText,
      'Here you can send the email and sms invitation to the respondents.'
    ),
  },
  {
    element: '#inviteRespondentInstruction5',
    intro: getLanguageValue(languageText, 'Here you can preview invitation.'),
  },
  {
    element: '#inviteRespondentInstruction6',
    intro: getLanguageValue(
      languageText,
      'You can keep track of the respondents status from below.'
    ),
  },
  */
];

const academyInstructionSteps = (
  languageText: ITranslationObject,
): IInstructionSteps[] => [
  {
    element: "#academyInstruction1",
    intro: getLanguageValue(
      languageText,
      "This guide will assist you with the academy.",
    ),
  },
  {
    element: "#academyInstruction2",
    intro: getLanguageValue(
      languageText,
      "You can change language of your academy here.",
    ),
  },
  {
    element: "#academyInstruction3",
    intro: getLanguageValue(
      languageText,
      "These are the academy modules, each containing sub-modules and lessons.",
    ),
  },
  {
    element: "#academyInstruction4",
    intro: getLanguageValue(
      languageText,
      "This bar displays your progress in the modules.",
    ),
  },
  {
    element: "#academyInstruction5",
    intro: getLanguageValue(
      languageText,
      "These are the academy sub-modules, each containing their lessons.",
    ),
  },
  {
    element: "#academyInstruction6",
    intro: getLanguageValue(
      languageText,
      "This bar displays your progress in the sub-modules.",
    ),
  },
  {
    element: "#academyInstruction7",
    intro: getLanguageValue(
      languageText,
      "Here are the lessons you can explore.",
    ),
  },
  {
    element: "#academyInstruction8",
    intro: getLanguageValue(
      languageText,
      "This bar shows shows your lesson progress.",
    ),
  },
  {
    element: "#instructionBtn",
    intro: getLanguageValue(languageText, "AP-RE-AE-TourButton"),
  },
];

export {
  editProfileInstructionSteps,
  editProfileNewStatusInstructionSteps,
  editProfileOnGoingStatusInstructionSteps,
  editProfileCompletedStatusInstructionSteps,
  editProfileDeliveredStatusInstructionSteps,
  editProfileDeliveredProfileInstructionSteps,
  editProfileParticipantInstructionSteps,
  participantSelfFormInstructionSteps,
  respondentSelfFormInstructionSteps,
  participantRoleSelectionInstructionSteps,
  inviteRespondentsInstructionSteps,
  academyInstructionSteps,
};
