import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@app/store/configureStore";
import { routePath } from "../routePaths";
import { TermsFooter } from "../../components/termsFooter/termsFooter";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { RespondentLanguageNavbar } from "./respondentLanguageNavbar/respondentLanguageNavbar";
import { CheckValidProfileOrRespondentLink } from "@app/types";

export const RespondentStartPage = (): JSX.Element => {
  const navigate = useNavigate();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const respondentCode: string = useSelector(
    (state: RootState) => state.respondentReducer.respondentCode,
  );
  const respondentData: CheckValidProfileOrRespondentLink = useSelector(
    (state: RootState) => state.respondentReducer.respondentData,
  );

  const onGetStartClick = () => {
    navigate(routePath.respondentSelfForm.replace(":c", respondentCode));
  };

  return (
    <div className="container my-md-5">
      <div className="row p-3">
        <div className="col-lg-10 rounded shadow-sm bg-white mx-auto p-md-5 p-4">
          <RespondentLanguageNavbar languageText={languageText} />

          <h4 className="fw-bolder my-4 pt-3">
            {getLanguageValue(languageText, "Contribute to the IDI-profile of")}{" "}
            <span className="text-success">
              {respondentData.participantName}
            </span>
            !
          </h4>
          <span className="fs-6">
            {getLanguageValue(languageText, "IDI respondent description")}.
          </span>
          <br />
          <p className="fs-6">
            {getLanguageValue(languageText, "IDI test role description")}.
          </p>
          <p className="fs-6">
            {getLanguageValue(
              languageText,
              "An IDI profile is created by your and others answers",
            )}
            .
          </p>
          <p className="fs-6">
            {getLanguageValue(
              languageText,
              "It takes only 5 miniutes to complete the form",
            )}
            .
          </p>
          <p className="fs-6">
            {getLanguageValue(
              languageText,
              "Your Data is sent in secure and encrypted connection",
            )}
            .
          </p>
          <button
            className="btn btn-success btn-lg mt-3"
            onClick={onGetStartClick}
          >
            {getLanguageValue(languageText, "Get Started")}
          </button>
        </div>
        <TermsFooter languageText={languageText} />
      </div>
    </div>
  );
};
