import React from "react";
import clsx from "clsx";
import { IResolveParams } from "reactjs-social-login";

import { SocialLogins } from "./socialLogins";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IProfile, useSocialLogins } from "./hooks";
import { CustomModal as DisconnectModal } from "../../../../components/modals/customModal";
import { setNotification } from "../../../actions";

interface IProfileSecurityProps {
  languageText: ITranslationObject;
}

export const ProfileSecurity = (props: IProfileSecurityProps): JSX.Element => {
  const { languageText } = props;
  const {
    socialLogins,
    socialLoginsList,
    socialLoginStatus,
    disconnectLoginName,
    userData,
    setProfile,
    setProvider,
    dispatch,
    handleToggleTwoFactorAuthStatus,
    handleDisconnectLogin,
    handleDisconnectCancel,
  } = useSocialLogins();

  return (
    <>
      <h4 className="mt-3">{getLanguageValue(languageText, "Security")}</h4>
      <div className="container">
        <div className="row gap-3">
          {socialLogins.map((item) => {
            const connected =
              socialLoginsList.length > 0
                ? socialLoginsList.includes(item.name)
                : socialLoginStatus.includes(item.name);

            return (
              <div
                key={item.id}
                className="border rounded col-12 col-md-3 p-0"
                role="button"
              >
                <SocialLogins
                  provider={item.name.toLowerCase()}
                  onResolve={({ provider, data }: IResolveParams) => {
                    setProvider(provider);
                    setProfile(data as IProfile);
                  }}
                  onReject={(error: any) => {
                    dispatch(setNotification(error));
                    throw error;
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <div className="d-flex align-items-center">
                      <i className={`fs-2 me-3 ${item.icon}`} />
                      {
                        // change this so it gets for each type login Google/Microsoft
                      }
                      <div className="d-flex flex-column fs-5">
                        <span className="fw-bold">{item.name}</span>
                        <span
                          className={clsx(
                            connected
                              ? "text-success fs-6"
                              : "text-danger fs-6",
                          )}
                        >
                          {connected
                            ? getLanguageValue(languageText, "Enabled")
                            : getLanguageValue(languageText, "Disabled")}
                        </span>
                      </div>
                    </div>
                    <div className=" pt-2 p-1 fs-3">
                      <i
                        className={clsx(
                          connected
                            ? "bi bi-check-circle-fill text-success "
                            : "bi bi-circle text-secondary opacity-50",
                        )}
                      />
                    </div>
                  </div>
                </SocialLogins>
              </div>
            );
          })}
          <div
            className="border rounded col-12 col-md-3 p-3"
            onClick={handleToggleTwoFactorAuthStatus}
            role="button"
          >
            <div className="d-flex float-start">
              <i className="bi bi-shield pt-1 pe-3 ps-0 fs-2"></i>

              <div className="d-flex flex-column">
                <span className="fw-bold fs-5">
                  {getLanguageValue(languageText, "2-Step Verification")}
                </span>
                <span
                  className={clsx(
                    userData.isTwoFactorEnabled
                      ? "text-success fs-6"
                      : "text-danger fs-6",
                  )}
                >
                  {getLanguageValue(
                    languageText,
                    userData.isTwoFactorEnabled ? "Enabled" : "Disabled",
                  )}
                </span>
              </div>
            </div>
            <div className="float-end pt-2 p-1 fs-3">
              <i
                className={clsx(
                  userData.isTwoFactorEnabled
                    ? "bi bi-check-circle-fill text-success"
                    : "bi bi-circle text-secondary opacity-50",
                )}
              />
            </div>
          </div>
        </div>
      </div>

      {disconnectLoginName && (
        <DisconnectModal
          headerText={`${getLanguageValue(languageText, `Disconnect`)} "${disconnectLoginName}"`}
          bodyText={`${getLanguageValue(
            languageText,
            `Do you want to disconnect login via`,
          )} "${disconnectLoginName}"`}
          cancelButtonText={getLanguageValue(languageText, "No")}
          submitButtonText={getLanguageValue(languageText, "Yes")}
          handleSubmitClick={handleDisconnectLogin}
          handleCancelClick={handleDisconnectCancel}
        />
      )}
    </>
  );
};
