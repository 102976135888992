import { RefObject } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const useDownloadReport = () => {
  const generatePdf = async (
    name: string,
    pages: RefObject<HTMLDivElement>[],
  ) => {
    // Set the document dimensions to A4 size
    const pdf = new jsPDF("p", "mm", "a4", true);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    for (let i = 0; i < pages.length; i++) {
      if (i > 0) pdf.addPage();
      const element = pages[i].current;
      if (element) {
        const canvas = await html2canvas(element);
        const scaleFactor = pageWidth / canvas.width; // Calculate the scaling factor based on the page width
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          pageWidth,
          canvas.height * scaleFactor,
          undefined,
          "FAST",
        );

        addFooterToPdfPage(pdf, pageHeight);
      }
    }
    pdf.save(`${name}.pdf`);
  };

  const addFooterToPdfPage = (pdf: jsPDF, pageHeight: number) => {
    const footerStyles = {
      fontSize: 6,
      textColor: "#444444",
    };

    // Customize the footer content as needed
    const footerText = `© ${new Date()
      .getFullYear()
      .toString()} Copyright IDI Profling AB - www.idi.se`;

    // Calculate the position of the footer
    const footerX = 20; // X position of the footer
    const footerY = pageHeight - 10; // Y position of the footer

    // Apply styles to the footer
    pdf.setFontSize(footerStyles.fontSize);
    pdf.setTextColor(footerStyles.textColor);

    // Add the footer to the PDF
    pdf.text(footerText, footerX, footerY);
  };

  return { generatePdf };
};
