import React from "react";
import { useNavigate } from "react-router-dom";

import { useAdminDashboard } from "./hooks";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { routePath } from "../routePaths";

export const AdminDashboard = (): JSX.Element => {
  const { languageText, accounts, profiles, users, presentations } =
    useAdminDashboard();
  const navigate = useNavigate();

  return (
    <>
      <div className="p-2 pt-4 p-md-4">
        <h3 className="mb-4 fw-bold">
          {getLanguageValue(languageText, "Dashboard")}
        </h3>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card h-100 shadow-sm bg-white rounded border-0">
              <div
                className="card-header border-0 p-3 fs-5 text-body-tertiary"
                onClick={() => navigate(routePath.allaccounts)}
                role="button"
              >
                {getLanguageValue(languageText, "Latest Accounts")}
              </div>
              <div className="card-body p-4">
                {accounts.map((account) => (
                  <li key={account.id}>{account.name}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 shadow-sm bg-white rounded border-0">
              <div
                className="card-header border-0 p-3 fs-5 text-body-tertiary"
                onClick={() => navigate(routePath.activities)}
                role="button"
              >
                {getLanguageValue(languageText, "Latest Activites")}
              </div>
              <div className="card-body p-4">
                {profiles.map((profile) => (
                  <li key={profile.id}>{profile.name}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 shadow-sm bg-white rounded border-0">
              <div
                className="card-header border-0 p-3 fs-5 text-body-tertiary"
                onClick={() => navigate(routePath.allparticipants)}
                role="button"
              >
                {getLanguageValue(languageText, "Latest Users")}
              </div>
              <div className="card-body p-4">
                {users.map((user) => (
                  <li key={user.id}>
                    {user.name} {user.surname}
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card h-100 shadow-sm bg-white rounded border-0">
              <div className="card-header border-0 p-3 fs-5 text-body-tertiary">
                {getLanguageValue(languageText, "Latest Invoices")}
              </div>
              <div className="card-body p-4"></div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100 shadow-sm bg-white rounded border-0">
              <div
                className="card-header border-0 p-3 fs-5 text-body-tertiary"
                onClick={() => navigate(routePath.upcomingPresentations)}
                role="button"
              >
                {getLanguageValue(languageText, "Latest Presentations")}
              </div>
              <div className="card-body p-4">
                {presentations.map((presentation) => (
                  <li key={presentation.id}>
                    {presentation.activityName} -{" "}
                    {presentation.date.slice(0, 10)}
                  </li>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100 shadow-sm bg-white rounded border-0">
              <div className="card-header border-0 p-3 fs-5 text-body-tertiary">
                {getLanguageValue(languageText, "Latest from website")}
              </div>
              <div className="card-body p-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
