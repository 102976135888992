import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { RoleEnum } from "../commonEnums";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";
import { UserId } from "../reducer";

interface IUserActionsCellProps {
  id: UserId;
  roleId: number;
  isActive: boolean;
  email: string;
  name: string;
  languageText: ITranslationObject;
  handleEditClick: (id: UserId) => void;
  handleDeleteClick: (id: UserId, name: string) => void;
  handleResetPasswordClick: (id: UserId, name: string, email: string) => void;
  handleLoginUserClick: (id: UserId, name: string, email: string) => void;
}

export const UserActionsCell: React.FC<IUserActionsCellProps> = (props) => {
  const {
    id,
    roleId,
    isActive,
    email,
    name,
    languageText,
    handleEditClick,
    handleDeleteClick,
    handleResetPasswordClick,
    handleLoginUserClick,
  } = props;

  const onEditClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    handleEditClick(id);
  };

  const onDeleteClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    handleDeleteClick(id, name);
  };

  const onResetPasswordClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    handleResetPasswordClick(id, name, email);
  };

  const onLoginUserClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    handleLoginUserClick(id, name, email);
  };

  const dropdownItems: Array<DropdownMenuItem> = [
    { name: getLanguageValue(languageText, "Edit"), onClick: onEditClick },
    { name: getLanguageValue(languageText, "Delete"), onClick: onDeleteClick },
    {
      name: getLanguageValue(languageText, "Reset Password"),
      onClick: onResetPasswordClick,
    },
  ];

  if (isActive && roleId !== RoleEnum.Admin) {
    dropdownItems.push({
      name: getLanguageValue(languageText, "Login as user"),
      onClick: onLoginUserClick,
    });
  }

  return <DropdownMenu items={dropdownItems} />;
};
