import { Dispatch } from "redux";
import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { ICreateProfileByUserBody } from "./dashboardComponents/createNewProfileModal";
import { ApiResponse } from "@app/types";
import { IDashboardDetails } from "./interface";

export const getParticipantDashboardInfo = async (
  dispatch: Dispatch,
): Promise<IDashboardDetails> => {
  try {
    const response = await api.get<ApiResponse<IDashboardDetails>>(
      apiEndPoints.getParticipantDashboardInfo,
    );

    if (!response.data.success) {
      return await Promise.reject(response.data);
    }

    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createProfileByParticipant = async (
  body: ICreateProfileByUserBody,
  dispatch: Dispatch,
): Promise<string> => {
  try {
    const response = await api.post(
      apiEndPoints.createProfileByParticipant,
      JSON.stringify(body),
      {},
    );
    const uniqueCode: string = response.data.result;
    return uniqueCode;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
