import React from "react";
import clsx from "clsx";
import { classNames } from "@app/containers/utils";
import { KeyCode } from "../formComponents/input";

interface IModalComponentProps {
  headerText: string;

  /**
   * Controls the width of the modal. Defaults to 'md'.
   *
   * sm: 300px
   * md: 500px
   * lg: 800px
   * xl: 1140px
   */
  width?: "sm" | "md" | "lg" | "xl" | "fullscreen";
  isModalBodyScrollable?: boolean;
  submitButtonText?: string;
  secondarySubmitButtonText?: string;
  cancelButtonText?: string;

  isSubmitDangerButton?: boolean;

  submitButtonDisabled?: boolean;
  secondarySubmitButtonDisabled?: boolean;
  secondarySubmitButtonColor?: string;
  cancelButtonDisabled?: boolean;

  children: React.ReactNode;

  handleSubmitClick?: React.MouseEventHandler<HTMLElement>;
  handleSecondarySubmitClick?: React.MouseEventHandler<HTMLElement>;
  handleCancelClick?: React.MouseEventHandler<HTMLElement>;
}

let MODAL_STACK_POINTER: number = 0;

export const ModalComponent = (props: IModalComponentProps) => {
  const {
    headerText,
    submitButtonText,
    secondarySubmitButtonText,
    cancelButtonText,
    isSubmitDangerButton,
    submitButtonDisabled,
    secondarySubmitButtonDisabled,
    secondarySubmitButtonColor,
    cancelButtonDisabled,
    children,
    handleSubmitClick,
    handleSecondarySubmitClick,
    handleCancelClick,
  } = props;

  const dialogClazz = classNames({
    "modal-dialog": true,
    "modal-sm": props.width === "sm",
    "modal-lg": props.width === "lg",
    "modal-xl": props.width === "xl",
    "modal-fullscreen": props.width === "fullscreen",
    "modal-dialog-scrollable": props.isModalBodyScrollable,
  });

  React.useEffect(() => {
    MODAL_STACK_POINTER += 1;
    document.body.style.overflow = "hidden";
    return () => {
      MODAL_STACK_POINTER -= 1;
      if (MODAL_STACK_POINTER === 0) {
        document.body.style.overflow = "auto";
      }
    };
  }, []);

  React.useEffect(() => {
    function onEscapeClick(event: KeyboardEvent): void {
      if (handleCancelClick && event.keyCode === KeyCode.Escape) {
        // TODO: this any-cast is not great, but we don't have any options
        //   at the moment unless we want to refactor every 'handleCancelClick'
        //   in the entire application. hopefully we don't interact with the
        //   mouse event anyways.
        handleCancelClick(event as any);
      }
    }

    window.addEventListener("keydown", onEscapeClick);

    return () => {
      window.removeEventListener("keydown", onEscapeClick);
    };
  }, [handleCancelClick]);

  return (
    <>
      <div className="modal show fade d-block" role="dialog" tabIndex={-1}>
        <div className={dialogClazz}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{headerText}</h5>
              {handleCancelClick && (
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleCancelClick}
                />
              )}
            </div>
            <div className="modal-body">{children}</div>
            {(cancelButtonText || submitButtonText) && (
              <div className="modal-footer">
                {cancelButtonText && (
                  <button
                    type="reset"
                    onClick={handleCancelClick}
                    className="btn btn-light"
                    data-kt-users-modal-action="cancel"
                    disabled={cancelButtonDisabled ?? false}
                  >
                    {cancelButtonText}
                  </button>
                )}

                {secondarySubmitButtonText && (
                  <button
                    type="submit"
                    className={clsx(
                      "btn",
                      secondarySubmitButtonColor ?? "btn-success",
                    )}
                    data-kt-users-modal-action="submit"
                    onClick={handleSecondarySubmitClick}
                    disabled={secondarySubmitButtonDisabled ?? false}
                  >
                    <span className="indicator-label">
                      {secondarySubmitButtonText}
                    </span>
                  </button>
                )}

                {submitButtonText && (
                  <button
                    type="submit"
                    className={classNames({
                      btn: true,
                      "btn-danger": isSubmitDangerButton,
                      "btn-success": !isSubmitDangerButton,
                    })}
                    data-kt-users-modal-action="submit"
                    onClick={handleSubmitClick}
                    disabled={submitButtonDisabled ?? false}
                  >
                    <span className="indicator-label">{submitButtonText}</span>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-backdrop fade show"></div>
    </>
  );
};
