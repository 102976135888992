import * as React from "react";
import { useLocation, Location } from "react-router-dom";

type Props = React.PropsWithChildren<{
  onChange: (location: Location) => void;
}>;

export const LocationChangeListener: React.FC<Props> = (props) => {
  const location = useLocation();

  React.useEffect(() => {
    props.onChange(location);
  }, [location]);

  return <React.Fragment>{props.children}</React.Fragment>;
};
