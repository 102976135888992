import React from "react";

import { AddEditProduct } from "./addEditProduct";
import { Input } from "../../formComponents/input";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  IInvoiceFocusInput,
  IInvoiceFormData,
  IProductData,
  InvoiceInputs,
} from "../invoiceInterface";

interface ICreateInvoiceDraft {
  languageText: ITranslationObject;
  fortnoxClientList: IDropdownList[];
  fortnoxInvoiceList: IDropdownList[];
  invoiceFormData: IInvoiceFormData;
  productFormData: IProductData;
  isEditMode: boolean;
  productsList: IDropdownList[];
  focusInput: IInvoiceFocusInput;
  showFortnoxInvoiceInputs: boolean;
  handleFortnoxClientSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleInvoiceSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleProductSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleProductInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddProduct: () => void;
  handleSaveProduct: () => void;
  handleCancelProduct: () => void;
}

export const CreateInvoiceDraft = (props: ICreateInvoiceDraft) => {
  const {
    languageText,
    fortnoxClientList,
    fortnoxInvoiceList,
    invoiceFormData,
    productFormData,
    isEditMode,
    productsList,
    focusInput,
    showFortnoxInvoiceInputs,
    handleFortnoxClientSelect,
    handleInvoiceSelect,
    handleBlurEvent,
    handleProductSelect,
    handleProductInputChange,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
  } = props;

  return (
    <>
      <div className="border border-top mb-3" />

      <div className="row">
        <div className="col-lg-3 col-12">
          <DropdownSelect
            name={InvoiceInputs.fortnoxCustomerNumber}
            label={`${getLanguageValue(languageText, "Fortnox Client")}:`}
            defaultLabel={getLanguageValue(languageText, "Select Client")}
            list={fortnoxClientList}
            value={invoiceFormData.fortnoxCustomerNumber}
            focusInput={focusInput.fortnoxCustomerNumber}
            handleDropdownSelect={handleFortnoxClientSelect}
            searchOption
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <DropdownSelect
            name={InvoiceInputs.fortnoxInvoiceNumber}
            label={`${getLanguageValue(languageText, "NewExisting Invoice")}:`}
            defaultLabel={getLanguageValue(languageText, "Select Invoice")}
            list={fortnoxInvoiceList}
            value={invoiceFormData.fortnoxInvoiceNumber}
            focusInput={focusInput.fortnoxInvoiceNumber}
            handleDropdownSelect={handleInvoiceSelect}
            disabled={!invoiceFormData.fortnoxCustomerNumber}
            searchOption
            required
          />
        </div>

        {showFortnoxInvoiceInputs && (
          <div className="row col-lg-6 col-12">
            <div className="col-lg-6 col-12">
              <Input
                label={`${getLanguageValue(languageText, "Invoice date")}:`}
                disable={true}
                value={invoiceFormData.date}
              />
            </div>
            <div className="col-lg-3 col-12">
              <Input
                label={`${getLanguageValue(languageText, "Invoice")}:`}
                disable={true}
                value={invoiceFormData.fortnoxInvoiceNumber}
              />
            </div>
            <div className="col-lg-3 col-12">
              <Input
                label={getLanguageValue(languageText, "Currency")}
                disable={true}
                value={invoiceFormData.currency}
              />
            </div>
          </div>
        )}
      </div>

      {invoiceFormData.invoiceId > 0 && (
        <AddEditProduct
          languageText={languageText}
          isEditMode={isEditMode}
          productFormData={productFormData}
          productsList={productsList}
          focusInput={focusInput}
          handleProductInputChange={handleProductInputChange}
          handleProductSelect={handleProductSelect}
          handleBlurEvent={handleBlurEvent}
          handleAddProduct={handleAddProduct}
          handleSaveProduct={handleSaveProduct}
          handleCancelProduct={handleCancelProduct}
        />
      )}
    </>
  );
};
