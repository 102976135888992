import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWordPairsByCultureId } from "./actions";
import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { CustomActionsCell } from "../../components/reactTable/customActionsCell";
import { BooleanCell } from "../../components/reactTable/booleanCell";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { getAllCountries } from "../clientList/addEditModal/action";
import { getFacilitatorCultures } from "../languageList/actions";
import { getBehaveOptions } from "./interface";
import { setSpinner } from "../actions";
import { ICountry, ICulture } from "../commonInterfaces";

export enum Behave {
  Affiliation = 1253,
  Directiveness = 1255,
  Adaptability = 1256,
}

export interface IWords {
  id: number;
  name: string;
  wordNo: string;
  leftWord: string;
  rightWord: string;
  calculate: boolean;
  display: boolean;
  behave: string;
  isScoredLeftToRight: boolean;
  cultureId: number;
  englishLeftWord: string;
  englishRightWord: string;
  countryCode: number;
}

export interface IRequestWordBody {
  filter: string;
  cultureId: number;
  sorting: string;
  maxResultCount: number;
  skipCount: number;
}

export const useWordsList = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [data, setData] = useState<Array<IWords>>([]);
  const [filter, setFilter] = useState<string>("");
  const [filterCulture, setFilterCulture] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openWordsModal, setOpenWordsModal] = useState<boolean>(false); // Add/Edit Words popup
  const [wordsUpdated, setWordsUpdated] = useState<boolean>(false); // This flag is used to re-reder the words data on requirement
  const [cultures, setCultures] = useState<IDropdownList[]>([]);
  const [countriesList, setCountriesList] = useState<IDropdownList[]>([]);

  const bhaveOptions = useMemo(
    () => getBehaveOptions(languageText),
    [languageText],
  );

  const tableHeader = useMemo<ColumnDef<IWords, any>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            languageText={languageText}
            id={props.row.original.id}
            name={props.row.original.name}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "ID"),
        accessorKey: "id",
      },
      {
        header: getLanguageValue(languageText, "Word No."),
        accessorKey: "wordNo",
      },
      {
        header: getLanguageValue(languageText, "English Leftword"),
        accessorKey: "englishLeftWord",
        width: "min-w-200px",
      },
      {
        header: getLanguageValue(languageText, "Left word"),
        accessorKey: "leftWord",
      },
      {
        header: getLanguageValue(languageText, "English Rightword"),
        accessorKey: "englishRightWord",
        width: "min-w-200px",
      },
      {
        header: getLanguageValue(languageText, "Right word"),
        accessorKey: "rightWord",
      },
      {
        header: getLanguageValue(languageText, "Culture"),
        accessorKey: "cultureId",
        cell: ({ ...props }) => (
          <span>
            {
              cultures.find(
                (item) => item.value === String(props.row.original.cultureId),
              )?.displayName
            }
          </span>
        ),
      },
      {
        header: getLanguageValue(languageText, "Country"),
        accessorKey: "countryCode",
        cell: ({ ...props }) => (
          <span>
            {
              countriesList.find(
                (item) => item.id === props.row.original.countryCode,
              )?.displayName
            }
          </span>
        ),
      },
      {
        header: getLanguageValue(languageText, "Calculate"),
        accessorKey: "calculate",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.calculate}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Display"),
        accessorKey: "display",
        cell: ({ ...props }) => (
          <BooleanCell
            boolValue={props.row.original.display}
            languageText={languageText}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Behave"),
        accessorKey: "behave",
        cell: ({ ...props }) => (
          <span>
            {
              bhaveOptions.find(
                (item) => item.value === String(props.row.original.behave),
              )?.displayName
            }
          </span>
        ),
      },
      {
        header: getLanguageValue(languageText, "Is scored left-to-right"),
        accessorKey: "isScoredLeftToRight",
        cell: ({ row }) => (
          <BooleanCell
            boolValue={row.original.isScoredLeftToRight}
            languageText={languageText}
            trueValueText={getLanguageValue(languageText, "Plus")}
            falseValueText={getLanguageValue(languageText, "Minus")}
          />
        ),
      },
    ],
    [languageText, cultures, countriesList],
  );

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchWords(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchWords(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, filterCulture, pageLimit]);

  useEffect(() => {
    if (wordsUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchWords(skipCount);
    }
  }, [wordsUpdated]);

  const fetchWords = (skipCount: number): void => {
    dispatch(setSpinner(true));

    const body: IRequestWordBody = {
      filter: filter,
      cultureId: Number(filterCulture),
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: skipCount,
    };

    getWordPairsByCultureId(body, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setData(response.items.slice());
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (wordsUpdated) setWordsUpdated(false);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const countriesResponse: ICountry[] = await getAllCountries(dispatch);
      const cultureResponse = await getFacilitatorCultures(dispatch);

      let countries: IDropdownList[] = [];
      let cultures: IDropdownList[] = [];

      if (countriesResponse?.length > 0) {
        countries = countriesResponse.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }));
      }
      if (cultureResponse?.length > 0) {
        cultures = cultureResponse.map((item) => ({
          id: item.id,
          displayName: item.displayName,
          value: String(item.id),
        }));
      }
      setCultures(cultures);
      setCountriesList(countries);
    };

    fetchDetails();
  }, []);

  const addWordsClick = (): void => {
    setOpenWordsModal(true);
  };

  const closeWordsModal = (refreshWordsList: boolean): void => {
    if (refreshWordsList) {
      setWordsUpdated(true);
    }
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenWordsModal(false);
  };

  const handleEditClick = (id: number): void => setEditedRowId(id);

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  const handleDeleteWord = (): void => {
    {
      /* ------------------ Will be added in future task ----------------- */
    }
    // deleteWord(deleteRowId, dispatch).then(() => {
    //   setDeleteRowId(0)
    //   setSelectedActionRowName('')
    //   setWordsUpdated(true)
    // })
  };

  const closeDeleteModal = (): void => setDeleteRowId(0);

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openWordsModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    cultures,
    countriesList,
    setFilter,
    setFilterCulture,
    setSorting,
    setPageLimit,
    setPageSelected,
    addWordsClick,
    closeWordsModal,
    handleDeleteWord,
    closeDeleteModal,
  };
};
