import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { ICurrency, ISubscriptionBody } from "./addEditSubscription/hooks";
import { ApiResponse, ApiResult } from "@app/types";

export const getAllSubscriptions = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch,
): Promise<any> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  };

  try {
    const response = await api.get(apiEndPoints.getAllSubscriptions, {
      params: body,
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getSubscriptionById = async (
  subscriptionId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getSubscriptionById, {
      params: { id: subscriptionId },
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createSubscription = async (
  body: ISubscriptionBody,
  dispatch: Dispatch,
): Promise<unknown> => {
  try {
    const response = await api.post<ApiResponse<unknown>>(
      apiEndPoints.createSubscription,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateSubscription = async (
  body: ISubscriptionBody,
  dispatch: Dispatch,
): Promise<unknown> => {
  try {
    const response = await api.put<ApiResponse<unknown>>(
      apiEndPoints.updateSubscription,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteSubscription = async (
  id: number,
  dispatch: Dispatch,
): Promise<unknown> => {
  const paramQuery = {
    id: id,
  };
  try {
    const response = await api.delete<ApiResponse<unknown>>(
      apiEndPoints.deleteSubscription,
      {
        params: paramQuery,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllCurrencies = async (
  dispatch: Dispatch,
): Promise<ApiResult<ICurrency>> => {
  try {
    const response = await api.get<ApiResponse<ApiResult<ICurrency>>>(
      apiEndPoints.getAllCurrencies,
      {
        params: { maxResultCount: 100 },
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
