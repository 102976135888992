import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store/configureStore";
import { getUserDetails } from "../auth/signUp/actions";
import { useNavigate } from "react-router-dom";
import { RoleName, Roles } from "../commonEnums";
import { switchUserRole } from "../twoFactorAuthenticationPage/actions";
import { MultiRoleBehavior, useAuthFlow } from "@app/auth";

export const useSwitchRoles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const authFlow = useAuthFlow(dispatch, navigate);

  useEffect(() => {
    // Required on page reload
    if (!userDetails.userId) {
      // if this shitty thing is so important for the entire app why don't
      // we load it at the root level? if we can't live without it, why not
      // just always load it? wtf?
      getUserDetails(dispatch, userLanguage.userLanguageCode);
    }
  }, []);

  const handleSubmit = async (value: RoleName) => {
    if (value !== loggedInUserRole) {
      // Here we are clearing the logged in user role and setting the new after switching
      // Because if we don't clear the logged in user role, till the new role is set,
      // the api's in navigation menu will run before role is updated and will fail
      const userRole = userDetails.userRoles.find(
        (r) => r.assignedRoleName === value,
      );
      if (!userRole) {
        return;
      }

      await switchUserRole(userRole.userRoleId!, dispatch);
    }

    const nextDetailsBecauseWeNeedThemAgain = await getUserDetails(
      dispatch,
      userLanguage.userLanguageCode,
    );
    authFlow.redirectToStartPageByRole(
      value,
      nextDetailsBecauseWeNeedThemAgain,
      MultiRoleBehavior.Ignore,
    );
  };

  return {
    languageText,
    handleSubmit,
  };
};
