import React from "react";
import { E164Number } from "libphonenumber-js/types";

import { SelectExistingInvoiceAddress } from "./selectExistingInvoiceAddress";
import { Input } from "../../formComponents/input";
import { TextArea } from "../../formComponents/textArea";
import { PhoneNumberInput } from "../../../containers/employeeList/addEmployeeModal/phoneNumberInput";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  IInvoiceExistingAddress,
  IInvoiceFocusInput,
  IInvoiceFormData,
  InvoiceInputs,
} from "../invoiceInterface";

interface IBillingAddressProps {
  languageText: ITranslationObject;
  isActivity: boolean;
  countries: IDropdownList[];
  existingAddresses: IInvoiceExistingAddress[];
  selectAddressModal: boolean;
  invoiceAddressFormData: IInvoiceFormData;
  focusInput: IInvoiceFocusInput;
  openSelectAddressModal: () => void;
  closeSelectAddressModal: () => void;
  onSelectAddressClick: (data: IInvoiceExistingAddress) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneInputChange: (value: E164Number) => void;
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleCountrySelect: (selectedItem: IDropdownSelectedItem) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const InvoiceAddress = (props: IBillingAddressProps) => {
  return (
    <div className="mt-10">
      {props.isActivity && (
        <div
          className="fs-5 fw-bold my-4 text-decoration-underline d-inline-block"
          role="button"
          onClick={props.openSelectAddressModal}
        >
          {getLanguageValue(props.languageText, "Choose address")}
        </div>
      )}
      <form className="row">
        <div className="col-lg-3 col-12">
          <Input
            name={InvoiceInputs.company}
            label={getLanguageValue(props.languageText, "Company name")}
            placeholder={getLanguageValue(props.languageText, "Company name")}
            value={props.invoiceAddressFormData.company}
            errorMessage={props.focusInput.company.errorMessage}
            handleBlurEvent={props.handleBlurEvent}
            handleInputChange={props.handleInputChange}
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <Input
            name={InvoiceInputs.businessIdentification}
            label={getLanguageValue(
              props.languageText,
              "Business identification",
            )}
            placeholder={getLanguageValue(
              props.languageText,
              "Business identification",
            )}
            value={props.invoiceAddressFormData.businessIdentification}
            errorMessage={props.focusInput.businessIdentification.errorMessage}
            handleBlurEvent={props.handleBlurEvent}
            handleInputChange={props.handleInputChange}
            required
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            name={InvoiceInputs.invoiceReference}
            label={getLanguageValue(props.languageText, "Invoice reference")}
            placeholder={getLanguageValue(
              props.languageText,
              "Invoice reference",
            )}
            value={props.invoiceAddressFormData.invoiceReference}
            errorMessage={props.focusInput.invoiceReference.errorMessage}
            handleBlurEvent={props.handleBlurEvent}
            handleInputChange={props.handleInputChange}
            required
          />
        </div>
        <div className="col-lg-9 col-12">
          <Input
            name={InvoiceInputs.email}
            label={getLanguageValue(props.languageText, "Email")}
            placeholder={getLanguageValue(props.languageText, "Email")}
            value={props.invoiceAddressFormData.email}
            errorMessage={props.focusInput.email.errorMessage}
            handleBlurEvent={props.handleBlurEvent}
            handleInputChange={props.handleInputChange}
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <PhoneNumberInput
            languageText={props.languageText}
            name={InvoiceInputs.telephone}
            label={getLanguageValue(props.languageText, "Phone")}
            placeholder="+123 45 67 89"
            value={props.invoiceAddressFormData.telephone as E164Number}
            phoneNumberErrorMsg={props.focusInput.telephone.errorMessage}
            onChange={props.handlePhoneInputChange}
            onBlur={props.handleBlurEvent}
          />
        </div>
        <div className="col-12">
          <TextArea
            name={InvoiceInputs.address}
            label={getLanguageValue(props.languageText, "Postal Address")}
            placeholder={getLanguageValue(props.languageText, "Address")}
            value={props.invoiceAddressFormData.address}
            focusInput={props.focusInput.address}
            onChange={props.handleTextAreaChange}
            handleBlurEvent={props.handleTextAreaBlurEvent}
            checkMarkNotRequired={true}
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <Input
            name={InvoiceInputs.city}
            label={getLanguageValue(props.languageText, "City")}
            placeholder={getLanguageValue(props.languageText, "City")}
            value={props.invoiceAddressFormData.city}
            errorMessage={props.focusInput.city.errorMessage}
            handleBlurEvent={props.handleBlurEvent}
            handleInputChange={props.handleInputChange}
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <Input
            name={InvoiceInputs.zip}
            label={getLanguageValue(props.languageText, "Zip Code")}
            placeholder={getLanguageValue(props.languageText, "Zip Code")}
            type="number"
            value={props.invoiceAddressFormData.zip}
            errorMessage={props.focusInput.zip.errorMessage}
            handleBlurEvent={props.handleBlurEvent}
            handleInputChange={props.handleInputChange}
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <DropdownSelect
            name={InvoiceInputs.countryId}
            label={getLanguageValue(props.languageText, "Country")}
            defaultLabel={getLanguageValue(
              props.languageText,
              "Select Country",
            )}
            list={props.countries}
            value={props.invoiceAddressFormData.countryId}
            focusInput={props.focusInput.countryId}
            handleDropdownSelect={props.handleCountrySelect}
            searchOption
            required
          />
        </div>
        <div className="col-lg-3 col-12">
          <Input
            name={InvoiceInputs.euvatid}
            label={getLanguageValue(props.languageText, "EU VAT ID (optional)")}
            placeholder={getLanguageValue(props.languageText, "EU VAT ID")}
            value={props.invoiceAddressFormData.euvatid}
            handleInputChange={props.handleInputChange}
          />
        </div>
      </form>

      {/* open select Address Modal */}
      {props.selectAddressModal && (
        <SelectExistingInvoiceAddress
          languageText={props.languageText}
          existingAddresses={props.existingAddresses}
          onSelectAddressClick={props.onSelectAddressClick}
          closeSelectAddressModal={props.closeSelectAddressModal}
        />
      )}
    </div>
  );
};
