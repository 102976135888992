export enum RoleEnum {
  Admin = 2,
  User = 3,
  SuperAdministrator = 5,
  Agent = 6,
  Facilitator = 7,
  Participant = 9,
  Respondent = 10,
  Manager = 11,
}

export type RoleName = keyof typeof RoleEnum;

export const Roles: { [K in RoleName]: K } = {
  Admin: "Admin",
  User: "User",
  SuperAdministrator: "SuperAdministrator",
  Agent: "Agent",
  Facilitator: "Facilitator",
  Participant: "Participant",
  Respondent: "Respondent",
  Manager: "Manager",
};

export const getRoleName = (roleId: RoleEnum): RoleName => {
  // this works because typescript's enums are two-way mappings.
  return RoleEnum[roleId] as RoleName;
};

export enum LanguageCode {
  English = "en-GB",
  Swedish = "sv-SE",
}
export enum LanguageId {
  English = 1,
  Swedish = 2,
}

export enum SocialLoginsEnum {
  Google = "Google",
  Microsoft = "Microsoft",
  LinkedIn = "LinkedIn",
}

export enum SocialProviderTypes {
  MICROSOFT = "microsoft",
  GOOGLE = "google",
}

export enum LoginTypeEnum {
  Unknown = 0,
  Email = 1,
  Microsoft = 2,
  Google = 3,
}

export enum UserGeneratedMediaType {
  AcademyCourse = 1,
  Delivery = 2,
  Documents = 3,
  News = 4,
}

// Max length for forms fields
export enum MaxLength {
  L400 = 400,
  L124 = 124,
}

// FileTypes
export enum FileType {
  PDF = "PDF",
  PPT = "PPT",
  WORD = "WORD",
}
export enum FileExtentionType {
  PDF = "pdf",
  PPT = "ppt",
  DOC = "doc",
  DOCX = "docx",
}

// Will add more classnames as we go on
export enum ClassNames {
  Grey_bg = "grey-bg",
  White_bg = "bg-light",
}

export enum GuidePages {
  Edit_Profile = "Edit_Profile",
  Edit_Profile_New_Status = "Edit_Profile_New_Status",
  Edit_Profile_OnGoing_Status = "Edit_Profile_OnGoing_Status",
  Edit_Profile_Completed_Status = "Edit_Profile_Completed_Status",
  Edit_Profile_Delivered_Status = "Edit_Profile_Delivered_Status",
  Edit_Profile_Participant = "Edit_Profile_Participant",
  Academy = "Academy",
  Participant_Role = "Participant_Role",
  Participant_SelfForm = "Participant_SelfForm",
  Participant_Invite_Respondents = "Participant_Invite_Respondents",
  Respondent_Form = "Respondent_Form",
}
