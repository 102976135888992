import React, { useState } from "react";

import { IFilterObject } from "../interface";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";

export const useMultiFilters = () => {
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const initialFiltersObject = {
    startDate: startDate,
    endDate: new Date(),
    userName: "",
    serviceName: "",
    methodName: "",
    browserInfo: "",
    hasException: true,
    minExecutionDuration: 0,
    maxExecutionDuration: 100000,
    clientIpAddress: "",
  };
  const [filterOptions, setFilterOptions] =
    useState<IFilterObject>(initialFiltersObject);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateSelect = (name: string, date: Date) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;

    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
  };

  const handleResetClick = () => {
    setFilterOptions(initialFiltersObject);
  };

  return {
    languageText,
    filterOptions,
    handleInputChange,
    handleDateSelect,
    handleCheckboxChange,
    handleResetClick,
  };
};
