export const LOGGED_IN_USER_DATA = "LOGGED_IN_USER_DATA";
export const LOGGED_IN_USER_ID = "LOGGED_IN_USER_ID";
export const LOGGED_IN_USER_DETAILS = "LOGGED_IN_USER_DETAILS";
export const SET_DEFAULT_CLIENT = "SET_DEFAULT_CLIENT";
export const CLEAR_DEFAULT_CLIENT = "CLEAR_DEFAULT_CLIENT";
export const PASSWORD_RESET_CODE = "PASSWORD_RESET_CODE";
export const GET_SOCIAL_LOGIN_STATUS = "GET_SOCIAL_LOGIN_STATUS";
export const SET_LOGGED_IN_USER_ROLE = "SET_LOGGED_IN_USER_ROLE";
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";
export const NEEDS_USER_CONSENT = "NEEDS_USER_CONSENT";
