import React from "react";

import { DeleteModal } from "../../components/modals/deleteModal";
import { ReactTable } from "../../components/reactTable/reactTable";
import { AddEditSubscription } from "./addEditSubscription/addEditSubscription";
import { useSubscriptionsList } from "./hooks";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";

export const SubscriptionsList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    deleteRowId,
    editRowId,
    isAddEditModalOpen,
    selectedActionRowName,
    filter,
    pageLimit,
    pageSelected,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    closeDeleteModal,
    closeAddEditModal,
    handleAddSubscription,
    handleDeleteSubscription,
    handleRefetchSubscriptions,
  } = useSubscriptionsList();

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Subscriptions")}
        searchPlaceholder={getLanguageValue(
          languageText,
          "Search Subscription",
        )}
        buttonText={getLanguageValue(languageText, "Add Subscription")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={handleAddSubscription}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {deleteRowId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, "Subscription")}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteSubscription}
          closeDeleteModal={closeDeleteModal}
        />
      ) : (
        <></>
      )}

      {isAddEditModalOpen && (
        <AddEditSubscription
          subscriptionId={editRowId}
          closeModal={closeAddEditModal}
          handleRefetchSubscriptions={handleRefetchSubscriptions}
        />
      )}
    </>
  );
};
