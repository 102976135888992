import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { INewsList, NewsType } from "./types";
import { ApiResponse, ApiResult } from "@app/types";

export const getNewsList = async (
  filter: string,
  sorting: string,
  newsType: NewsType,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch,
): Promise<ApiResult<INewsList>> => {
  const body = {
    filter,
    sorting,
    newsType,
    maxResultCount,
    skipCount,
  };
  try {
    const response = await api.get<ApiResponse<ApiResult<INewsList>>>(
      apiEndPoints.getNewsList,
      {
        params: body,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteNews = async (
  newsId: number,
  dispatch: Dispatch,
): Promise<unknown> => {
  const paramQuery = {
    id: newsId,
  };
  try {
    return await api.delete(apiEndPoints.deleteNewsPost, {
      params: paramQuery,
    });
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
