import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HIDE_CONFIRM_DIALOG,
  SHOW_CONFIRM_DIALOG,
} from "./containers/constants";
import { RootState } from "./store/configureStore";
import { getLanguageValue } from "./commonUtils/languageFunctionsHelper";
import { CustomModal } from "./components/modals/customModal";

export function useConfirm() {
  const dispatch = useDispatch();

  return function (title: string, message: string = ""): Promise<unknown> {
    const promise = new Promise((resolve, reject) => {
      dispatch({
        type: SHOW_CONFIRM_DIALOG,
        payload: {
          title,
          message,
          resolve,
          reject,
        },
      });
    });
    promise.finally(() => {
      dispatch({
        type: HIDE_CONFIRM_DIALOG,
      });
    });
    return promise;
  };
}

type ConfirmDialogProps = {
  title: string;
  message?: string;
  onAccept: () => unknown;
  onReject: () => unknown;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  return (
    <CustomModal
      headerText={getLanguageValue(languageText, props.title)}
      bodyText={
        props.message ? getLanguageValue(languageText, props.message) : ""
      }
      submitButtonText={getLanguageValue(languageText, "Ok")}
      handleSubmitClick={props.onAccept}
      handleCancelClick={props.onReject}
      cancelButtonText={getLanguageValue(languageText, "Cancel")}
    />
  );
};
