import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import clsx from "clsx";
import { IFocusError, KeyCode } from "./input";
import { classNames } from "@app/containers/utils";

export interface IDropdownList<T = string> {
  id: number;
  displayName: string | JSX.Element;
  value: T;
}

export interface IDropdownSelectedItem<T = string> {
  id: number;
  value: T;
  name: string;
}

interface IDropdownSelectProps<T = string> {
  label?: string;
  name: string;
  required?: boolean;
  displayClassName?: string;
  list: ReadonlyArray<IDropdownList<T>>;
  defaultLabel?: string;
  value?: T;
  disabled?: boolean;
  searchOption?: boolean;
  focusInput?: IFocusError;
  dropdownHeight?: string;
  handleDropdownSelect: (selectedItem: IDropdownSelectedItem<T>) => void;
  narrow?: boolean;
}

export function DropdownSelect<T>(props: IDropdownSelectProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [filter, setFilter] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const searchRef = React.useRef<HTMLInputElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const selectedItemRef = React.useRef<HTMLLIElement>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (props.disabled) {
      return;
    }

    // highlight the currently selected item, if there is one.
    const index = filteredList.findIndex((item) => item.value === props.value);
    setSelectedIndex(index ?? 0);
    setShowDropdown(!showDropdown);
  };

  const handleSelect = (item: IDropdownList<T>): void => {
    if (props.disabled) {
      return;
    }

    const selectedItem: IDropdownSelectedItem<T> = {
      id: item.id,
      value: item.value,

      // this is surely a typo or logic error, no? when we select
      // something from the dropdown menu the 'name' field is taken
      // from the outer component? what does that mean?
      //   -johan, 2024-11-21
      name: props.name,
    };
    props.handleDropdownSelect(selectedItem);
    setShowDropdown(!showDropdown);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilter(e.target.value);
    setSelectedIndex(0);
  };

  React.useEffect(() => {
    if (showDropdown) {
      searchRef.current?.focus();
    }
  }, [showDropdown]);

  const filteredList = props.list.filter((item) => {
    if (!filter) {
      return true;
    }
    if (typeof item.displayName === "string") {
      return item.displayName.toLowerCase().includes(filter.toLowerCase());
    } else if (typeof item.displayName === "object") {
      return item.displayName.props.value
        .toLowerCase()
        .includes(filter.toLowerCase());
    } else {
      return item;
    }
  });

  function handleKeyDown(event: React.KeyboardEvent): void {
    switch (event.keyCode) {
      case KeyCode.Enter: {
        event.preventDefault();
        event.stopPropagation();

        const item = filteredList[selectedIndex];
        if (!item) {
          return;
        }
        handleSelect(item);
        break;
      }
      case KeyCode.Escape:
        event.stopPropagation();
        setShowDropdown(false);
        break;
      case KeyCode.ArrowUp: {
        event.preventDefault();
        event.stopPropagation();
        let next = selectedIndex - 1;

        // wrap around to the end of the list.
        if (next < 0) {
          next = filteredList.length - 1;
        }
        setSelectedIndex(next);
        break;
      }
      case KeyCode.ArrowDown: {
        event.preventDefault();
        event.stopPropagation();

        // wrap around to the start of the list.
        const next = (selectedIndex + 1) % filteredList.length;
        setSelectedIndex(next);
        break;
      }
    }
  }

  React.useEffect(() => {
    selectedItemRef.current?.scrollIntoView({
      block: "nearest",
    });
  }, [selectedIndex]);

  const clazz = classNames({
    "position-relative": true,
    "mb-3": !props.narrow,
  });

  const displayName =
    props.list.find((x) => x.value === props.value)?.displayName ??
    props.defaultLabel;

  return (
    <div className={clazz} onKeyDown={handleKeyDown}>
      {props.label && (
        <label className={clsx("mb-1", { required: props.required })}>
          {props.label}
        </label>
      )}

      <div
        ref={ref}
        className={clsx(
          "d-flex justify-content-between align-items-center form-control",
          props.displayClassName,
        )}
        onClick={toggleDropdown}
        role="button"
      >
        {displayName}
        <i className="bi bi-chevron-down" />
      </div>

      <div
        className={classNames({
          "border-start border-end border-bottom position-absolute w-100 shadow z-1":
            true,
          "d-none": !showDropdown,
          "d-block": showDropdown,
        })}
        ref={dropdownRef}
      >
        <div className="p-2 bg-white">
          <div className="input-group">
            <span className="input-group-text">
              <i className="bi bi-search" />
            </span>
            <input
              type="text"
              className="form-control"
              value={filter}
              onChange={handleSearch}
              ref={searchRef}
              placeholder="Search"
            />
          </div>
        </div>

        {/* the 'overflow-y-scroll' class is documented but not implemented in BS 5.3. resort to manual styling for now. */}
        <ul
          className="list-group list-group-flush"
          style={{ maxHeight: 300, overflowY: "scroll" }}
        >
          {filteredList.length > 0 ? (
            filteredList.map((item, idx) => {
              const clazz = classNames({
                "list-group-item list-group-item-action": true,
                active: idx === selectedIndex,
              });

              return (
                <li
                  key={item.id}
                  className={clazz}
                  ref={idx === selectedIndex ? selectedItemRef : undefined}
                  onClick={() => handleSelect(item)}
                  role="button"
                >
                  {item.displayName}
                </li>
              );
            })
          ) : (
            <li className="list-group-item">No Items to display</li>
          )}
        </ul>
      </div>

      {/* TODO: not implemented */}
      {props.focusInput?.errorMessage && (
        <div className="invalid-feedback d-inline-block">
          {props.focusInput.errorMessage}
        </div>
      )}
    </div>
  );
}
