import React from "react";
import clsx from "clsx";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { E164Number } from "libphonenumber-js/types";
import {
  ITableHeaders,
  IUploadEmployeeModalProps,
  TabEnum,
  useUploadEmployee,
} from "./hooks";
import { RoleSettingsModal } from "../../profileList/addProfile/roleSettings/roleSettingsModal";
import { PhoneNumberInput } from "../addEmployeeModal/phoneNumberInput";
import { TabNavItem } from "../../../components/multiTabComponent/tabNavItem";
import ImportTemplate from "./ImportTemplate.csv";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { Input } from "@app/components/formComponents/input";
import { IParticipantRoleData } from "@app/containers/profileList/addProfile/activityParticipantsStep";
import { getTypeOfRoleButtonList } from "@app/commonUtils/roleSettingsFunctionsHelper";

export const UploadEmployeeModal: React.FC<IUploadEmployeeModalProps> = (
  props,
) => {
  const {
    languageText,
    employees,
    uploadFromActivityPage,
    isIndividualActivity,
    errorMessage,
    inputRef,
    file,
    tableHeaders,
    selectedNewParticipantIndexForRole,
    navTabs,
    activeTab,
    selectedParticipants,
    participantsList,
    participantsFilter,
    selectedExistingParticipantIndexForRole,
    isRolesRequired,
    isRoleSettingsError,
    validateEmail,
    setActiveTab,
    handleRoleSettings,
    closeRoleSettingsModal,
    onSaveRoleSettings,
    deleteRow,
    addRow,
    onSubmitClick,
    validateFirstName,
    validateLastName,
    closeModal,
    handleFileUpload,
    handleUploadFile,
    handleChange,
    validatePhoneNumber,
    handlePhoneInputChange,
    handleParticipantSearch,
    handleSelectAllParticipants,
    handleSelectParticipant,
    handleRemoveAllParticipants,
    handleRemoveParticipant,
    onRoleSettingsClick,
    saveExistingParticipantRoleSettings,
    handleSaveExistingParticipants,
  } = useUploadEmployee(props);

  const renderRoleSettings = (
    participant: IParticipantRoleData,
    index: number,
  ) => {
    const { roleId, roleText, noOfRespondents } = participant;

    if (roleId || roleText || noOfRespondents) {
      let updatedRoleText: string = roleText;
      let rolesFilled: boolean = false;
      if (roleId) {
        updatedRoleText =
          getTypeOfRoleButtonList(languageText).find(
            (role) => role.id === roleId,
          )?.title ?? "";
      }
      if (updatedRoleText && noOfRespondents) rolesFilled = true;

      return (
        <button
          className={clsx(
            "btn btn-sm",
            rolesFilled ? "bg-success text-white" : "bg-white border",
          )}
          onClick={() => onRoleSettingsClick(index)}
        >
          {(updatedRoleText || noOfRespondents) && updatedRoleText}
          {rolesFilled && ", "}
          {noOfRespondents > 0 && noOfRespondents}
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-sm bg-white border fw-bold px-3"
          onClick={() => onRoleSettingsClick(index)}
        >
          {getLanguageValue(languageText, "Role setting")}
        </button>
      );
    }
  };

  const shouldShowSubmitButton =
    ((activeTab === TabEnum.TAB_2 || !props.activityIdFromEditActivityPage) &&
      employees.length > 0 &&
      employees.every((e) => !e.isEmailExists && !e.emailError)) ||
    (activeTab === TabEnum.TAB_1 && props.activityIdFromEditActivityPage);

  const shouldShowAddRowButton =
    activeTab === TabEnum.TAB_2 || !props.activityIdFromEditActivityPage;

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          languageText,
          uploadFromActivityPage ? "Add Participant" : "Upload Employee",
        )}
        submitButtonText={
          shouldShowSubmitButton
            ? getLanguageValue(languageText, "Submit")
            : undefined
        }
        secondarySubmitButtonText={
          shouldShowAddRowButton && !isIndividualActivity
            ? getLanguageValue(languageText, "Add Row")
            : undefined
        }
        cancelButtonText={getLanguageValue(languageText, "Close")}
        handleSubmitClick={
          activeTab === TabEnum.TAB_1 && props.activityIdFromEditActivityPage
            ? handleSaveExistingParticipants
            : onSubmitClick
        }
        handleSecondarySubmitClick={addRow}
        handleCancelClick={closeModal}
        width="xl"
        isModalBodyScrollable
      >
        {props.activityIdFromEditActivityPage && (
          <TabNavItem
            navTabs={navTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {/* SelectParticipant tab */}
        {activeTab === TabEnum.TAB_1 &&
          props.activityIdFromEditActivityPage && (
            <div className="d-flex justify-content-between flex-wrap">
              <div className="col-md-6 col-12 border- border-secondary">
                <div className="d-flex align-items-center gap-3">
                  {participantsList.length === 0 &&
                  selectedParticipants.length > 0 ? (
                    <i
                      className="bi bi-check-square text-success fs-3"
                      onClick={handleRemoveAllParticipants}
                    />
                  ) : (
                    <i
                      className="bi bi-square text-secondary fs-3"
                      onClick={() =>
                        handleSelectAllParticipants(participantsList)
                      }
                    />
                  )}

                  <div className="mt-3">
                    <Input
                      placeholder={getLanguageValue(languageText, "Search")}
                      value={participantsFilter}
                      handleInputChange={handleParticipantSearch}
                    />
                  </div>
                </div>

                <div>
                  {!participantsFilter && !participantsList.length && (
                    <div className="py-3">
                      {getLanguageValue(
                        languageText,
                        "There are no users under this account",
                      )}
                      .
                    </div>
                  )}

                  {participantsFilter && !participantsList.length && (
                    <div className="py-3">
                      {getLanguageValue(
                        languageText,
                        "No matching records found",
                      )}
                    </div>
                  )}
                  {participantsList.length > 0 && (
                    <>
                      {participantsList
                        .filter((participant) =>
                          participantsFilter
                            ? `${participant.name.toLowerCase()} ${participant.surname.toLowerCase()}`.includes(
                                participantsFilter.toLowerCase(),
                              )
                            : participant,
                        )
                        .map((participant) => (
                          <div
                            key={participant.id}
                            className="py-1 d-flex align-items-center"
                          >
                            <i
                              className="bi bi-square text-secondary fs-3"
                              onClick={() =>
                                handleSelectParticipant(participant)
                              }
                              role="button"
                            />
                            <span className="ms-4">
                              {participant.name} {participant.surname}
                            </span>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>

              {selectedParticipants.length > 0 && (
                <div className="col-12 col-md-5 overflow-auto">
                  <h5 className="pt-4 pb-2">
                    {getLanguageValue(languageText, "Selected participants")}
                  </h5>
                  {selectedParticipants.map((participant, index) => (
                    <div
                      key={participant.id}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div
                        key={participant.id}
                        className="py-1 d-flex align-items-center"
                      >
                        <i
                          className="bi bi-check-square text-success fs-3"
                          onClick={() => handleRemoveParticipant(participant)}
                          role="button"
                        />
                        <span className="text-success ms-4">
                          {participant.name} {participant.surname}
                        </span>
                      </div>

                      {isRolesRequired && (
                        <div>{renderRoleSettings(participant, index)}</div>
                      )}
                    </div>
                  ))}

                  {isRoleSettingsError && (
                    <div className="invalid-feedback d-inline-block">
                      {getLanguageValue(
                        languageText,
                        "Set role setting for all participants",
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

        {(activeTab === TabEnum.TAB_2 ||
          !props.activityIdFromEditActivityPage) && (
          <>
            {!isIndividualActivity && (
              <>
                <label className={clsx("fs-6", errorMessage && "text-danger")}>
                  {errorMessage
                    ? errorMessage
                    : getLanguageValue(languageText, "Upload .CSV file here")}
                </label>
                <div className="d-flex gap-2 fs-6 my-3">
                  <div className="fw-bold">
                    {getLanguageValue(languageText, "Note")}:
                  </div>
                  <div>
                    {getLanguageValue(
                      languageText,
                      "Please note that the first row of the table is designated for headers and will not be included in the data processing.",
                    )}
                    <br />
                    {getLanguageValue(
                      languageText,
                      "Please refer to the sample file for clarification.",
                    )}{" "}
                    <a
                      className=""
                      href={ImportTemplate}
                      download="ImportTemplate.csv"
                    >
                      ImportTemplate.csv
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <input
                    type="file"
                    name="file"
                    onChange={handleFileUpload}
                    multiple
                    accept=".csv"
                    ref={inputRef}
                    className={clsx(
                      "form-control form-control-lg border",
                      errorMessage && "border-danger bg-light-danger",
                    )}
                  />
                  {file.length > 0 && (
                    <button
                      className="btn btn-success btn-sm p-1 ms-2"
                      onClick={handleUploadFile}
                    >
                      {getLanguageValue(languageText, "Upload file")}
                    </button>
                  )}
                </div>
              </>
            )}

            <div className="table-responsive mt-4 overflow-x-unset">
              <table className="table">
                <thead>
                  {employees.length > 0 && (
                    <tr>
                      {tableHeaders.map(
                        (header: ITableHeaders, idx: number) => (
                          <th
                            key={idx}
                            className={header.required ? "required" : ""}
                          >
                            <i className={header.icon}></i>
                            <span className="p-2">{header.name}</span>
                          </th>
                        ),
                      )}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr key={index}>
                      <td className="px-1">
                        <Input
                          placeholder={getLanguageValue(
                            languageText,
                            "Firstname",
                          )}
                          value={employee.name}
                          handleInputChange={(e) =>
                            handleChange(e, index, "name")
                          }
                          handleBlurEvent={(e) =>
                            validateFirstName(e.target.value, index)
                          }
                          errorMessage={employee.firstNameError ?? ""}
                        />
                      </td>
                      <td className="px-1">
                        <Input
                          placeholder={getLanguageValue(
                            languageText,
                            "Lastname",
                          )}
                          value={employee.surname}
                          handleInputChange={(e) =>
                            handleChange(e, index, "surname")
                          }
                          handleBlurEvent={(e) =>
                            validateLastName(e.target.value, index)
                          }
                          errorMessage={employee.lastNameError ?? ""}
                        />
                      </td>
                      <td className="px-1">
                        <Input
                          placeholder={getLanguageValue(languageText, "Email")}
                          value={employee.emailAddress}
                          handleInputChange={(e) =>
                            handleChange(e, index, "emailAddress")
                          }
                          handleBlurEvent={(e) =>
                            validateEmail(e.target.value, index)
                          }
                          errorMessage={
                            employee.isEmailExists
                              ? getLanguageValue(
                                  languageText,
                                  "Email already exists",
                                )
                              : (employee.emailError ?? "")
                          }
                        />
                      </td>
                      <td className="px-1">
                        <PhoneNumberInput
                          languageText={languageText}
                          placeholder="+123 45 67 89"
                          value={(employee.phoneNumber as E164Number) || ""}
                          onChange={(value) =>
                            handlePhoneInputChange(value, index, "phoneNumber")
                          }
                          onBlur={(e) =>
                            validatePhoneNumber(
                              e.target.value as E164Number,
                              index,
                            )
                          }
                          phoneNumberErrorMsg={employee.phoneNumberError}
                        />
                      </td>

                      {/* Role settings icon */}
                      {isRolesRequired && (
                        <td className="px-1 ">
                          <button
                            id="roleIcon"
                            className={clsx(
                              "btn",
                              (employee.roleId || employee.roleText) &&
                                employee.noOfRespondents
                                ? "bg-success-subtle"
                                : "bg-dark bg-opacity-10",
                            )}
                            onClick={() => handleRoleSettings(index)}
                          >
                            <i className="bi bi-ui-radios-grid" />
                          </button>
                        </td>
                      )}
                      <td>
                        <button
                          className="btn text-danger"
                          onClick={() => deleteRow(index)}
                        >
                          <i className="bi bi-trash3 pe-0"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {isRoleSettingsError && (
                <div className="invalid-feedback d-inline-block">
                  {getLanguageValue(
                    languageText,
                    "Set role setting for all participants",
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {/* RoleSettingsModal for existing participant (edit profile page) */}
        {selectedExistingParticipantIndexForRole !== null && (
          <RoleSettingsModal
            languageText={languageText}
            required={true}
            selectedProfileForRole={
              selectedParticipants[selectedExistingParticipantIndexForRole]
            }
            handleCancelClick={closeRoleSettingsModal}
            handleSaveClick={saveExistingParticipantRoleSettings}
          />
        )}

        {/* RoleSettingsModal for new participant (edit profile page) */}
        {selectedNewParticipantIndexForRole !== null && (
          <RoleSettingsModal
            languageText={languageText}
            required={true}
            selectedProfileForRole={
              employees[selectedNewParticipantIndexForRole]
            }
            handleCancelClick={closeRoleSettingsModal}
            handleSaveClick={onSaveRoleSettings}
          />
        )}
      </ModalComponent>
    </>
  );
};
