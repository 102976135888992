import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store/configureStore";
import { getMyIDIProfiles } from "./actions";
import { setSpinner } from "../../actions";
import { IGroupReportAdaptabilities } from "../../profileList/editProfile/interface";
import { ProfileStatus } from "@app/containers/profileList/profileEnums";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { NavigateFunction } from "react-router-dom";
import { routePath } from "@app/containers/routePaths";
import { ucQueryKey } from "../useParticipantProfileAuth";

/** @see IParticipantProfileDetails */
export interface IMyProfiles {
  activityId: ActivityId;
  profileId: ProfileId;
  title: string;
  noOfRespondents: number;
  roleId: number;
  roleText: string;
  profileDate: string;
  calculatedDate: string;
  status: ProfileStatus;
  isDemographicsFilled: boolean;
  isRoleFilled: boolean;
  isSelfFormFilled: boolean;
  isRespondentsInvited: boolean;
  presentationId: number;
  uniqueCode: string;
  oDirPos: number | null;
  oAffPos: number | null;
  oFlex: number | null;
  sFlex: number | null;
}

type DrawableMyProfile = {
  oDirPos: number;
  oAffPos: number;
  oFlex: number;
  sFlex: number;
};

export function canDrawProfileInGraph(
  profile: IMyProfiles,
): profile is IMyProfiles & DrawableMyProfile {
  return (
    profile.status === ProfileStatus.Delivery &&
    profile.oDirPos !== null &&
    profile.oAffPos !== null &&
    profile.oFlex !== null &&
    profile.sFlex !== null
  );
}

export function handleProfileClick(
  profile: IMyProfiles,
  navigate: NavigateFunction,
): void {
  if (profile.status === ProfileStatus.Delivery) {
    navigate(
      routePath.participantProfileReport.replace(
        ":profileId",
        profile.profileId.toString(),
      ),
    );
  } else {
    navigate({
      pathname: routePath.participantCreate,
      search: new URLSearchParams({
        [ucQueryKey]: profile.uniqueCode,
      }).toString(),
    });
  }
}

export function getProfileGraphLabel(
  profile: IMyProfiles,
  profiles: ReadonlyArray<IMyProfiles>,
): string {
  const index = profiles.indexOf(profile);
  if (index !== -1) {
    return (profiles.length - index).toString();
  }
  return "";
}

export interface IMyIDIProfiles {
  profiles: ReadonlyArray<IMyProfiles>;
  profilesOtherFormsAdaptabilites: IGroupReportAdaptabilities[];
}

export const useMyIDIProfiles = () => {
  const dispatch = useDispatch();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );

  const [myProfilesDetails, setMyProfilesDetails] = useState<IMyIDIProfiles>({
    profiles: [],
    profilesOtherFormsAdaptabilites: [],
  });

  useEffect(() => {
    if (userLanguage.userLanguageCode) {
      dispatch(setSpinner(true));
      getMyIDIProfiles(userLanguage.userLanguageCode, dispatch)
        .then((response) => {
          if (response) {
            const updatedProfileDetails: IMyIDIProfiles = {
              profiles: response.profiles ?? [],
              profilesOtherFormsAdaptabilites:
                response.profilesOtherFormsAdaptabilites ?? [],
            };
            setMyProfilesDetails(updatedProfileDetails);
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  }, [userLanguage.userLanguageCode]);

  return {
    languageText,
    myProfilesDetails,
  };
};
