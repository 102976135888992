import React from "react";

import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";

import { deleteDocument, getDocumentsList } from "./actions";
import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { IDocumentList, IDocument } from "./types";
import { CustomActionsCell } from "../../../components/reactTable/customActionsCell";
import { BooleanCell } from "../../../components/reactTable/booleanCell";
import { setSpinner } from "../../actions";
import { getDocumentDownloadFile } from "../../../commonUtils/downloadDocumentHepler";

export const useDocumentList = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [pageSelected, setPageSelected] = useState<number>(0);
  const [sorting, setSorting] = useState<string>("");
  const [openDocModal, setOpenDocModal] = useState<boolean>(false);
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [documentUpdated, setDocumentUpdated] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [documents, setDocuments] = useState<IDocumentList[]>([]);

  const getAllDocuments = async (skipCount: number): Promise<void> => {
    dispatch(setSpinner(true));
    getDocumentsList(filter, sorting, pageLimit, skipCount, dispatch)
      .then((fetchedDocuments: IDocument) => {
        if (fetchedDocuments) {
          setDocuments(fetchedDocuments.items);
          const pageSize = Math.ceil(fetchedDocuments.totalCount / pageLimit);
          setTotalCount(fetchedDocuments.totalCount);
          setPageCount(pageSize);
          if (documentUpdated) setDocumentUpdated(false);
        }
        if (documentUpdated) setDocumentUpdated(false);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      getAllDocuments(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    if (documentUpdated) {
      const skipCount = pageLimit * pageSelected;
      getAllDocuments(skipCount);
    }
  }, [documentUpdated]);

  useEffect(() => {
    setPageSelected(0);
    getAllDocuments(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  const handleEditClick = (id: number): void => {
    setEditedRowId(id);
  };

  const addDocsClick = (): void => {
    setOpenDocModal(true);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  //fns for documents list
  const tableHeader = useMemo<ColumnDef<IDocumentList, any>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            deleteOption
            languageText={languageText}
            id={props.row.original.id}
            name={props.row.original.title}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "ID"),
        accessorKey: "id",
      },
      {
        header: getLanguageValue(languageText, "Title"),
        accessorKey: "title",
      },
      {
        header: getLanguageValue(languageText, "Excerpt"),
        accessorKey: "excerpt",
        cell: ({ ...props }) => (
          <div className="w-250px text-truncate">
            {props.row.original.excerpt}
          </div>
        ),
      },
      {
        header: getLanguageValue(languageText, "Published Date"),
        accessorKey: "publishedDate",
        cell: ({ ...props }) => (
          <div>{props.row.original.publishedDate?.toString().slice(0, -8)}</div>
        ),
      },
      {
        header: getLanguageValue(languageText, "Published"),
        accessorKey: "published",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.published}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Document File"),
        accessorKey: "documentFile",
        enableSorting: false,
        cell: ({ ...props }) => (
          <div className="mw-100px">
            <div className="">
              <a
                href={props.row.original.file}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  getDocumentDownloadFile(
                    props.row.original.id,
                    props.row.original.originalFileName,
                    dispatch,
                  );
                }}
                rel="noreferrer"
              >
                {props.row.original.originalFileName}
              </a>
            </div>
          </div>
        ),
      },
    ],
    [languageText],
  );

  const handleDeleteNews = (): void => {
    deleteDocument(deleteRowId, dispatch).then(() => {
      setDeleteRowId(0);
      setSelectedActionRowName("");
      setDocumentUpdated(true);
    });
  };

  const closeDeleteModal = (): void => {
    setDeleteRowId(0);
  };

  const closeDocumentModal = (refreshDocList: boolean): void => {
    if (refreshDocList) {
      setDocumentUpdated(true);
    }
    setOpenDocModal(false);
    if (editedRowId) {
      setEditedRowId(0);
    }
  };

  return {
    filter,
    editedRowId,
    deleteRowId,
    languageText,
    documents,
    openDocModal,
    pageCount,
    pageLimit,
    pageSelected,
    totalCount,
    tableHeader,
    selectedActionRowName,
    setFilter,
    setPageSelected,
    setSorting,
    setPageLimit,
    handleDeleteNews,
    addDocsClick,
    closeDeleteModal,
    closeDocumentModal,
  };
};
