import React from "react";

import { AddEditCourse } from "./addEditCourse/addEditCourse";
import { CoursePart } from "./addEditCoursePart/coursePart";
import { AddQuiz } from "./addQiuz/addQuiz";
import { ReorderModal } from "../../../components/reorderingModel/reorderModal";
import {
  IAddEditCourseModalProps,
  ReorderOption,
  useAddEditCourseModal,
} from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "../../../components/modals/modalComponent";

export const AddEditCourseModal = (
  props: IAddEditCourseModalProps,
): JSX.Element => {
  const {
    languageText,
    editCourseId,
    courseInfo,
    focusInput,
    isSubmitting,
    imgErrorMessage,
    languages,
    categories,
    tagNames,
    courseCreated,
    isCourseSaved,
    courseParts,
    bottomRef,
    canAddNewCoursePart,
    existingIconModal,
    baseUrl,
    uniqueIcons,
    isEditMode,
    quizCreated,
    quizPart,
    isQuizEdited,
    editedQuizIndex,
    openReorderModel,
    dataToReorder,
    canAddNewQuizPart,
    reorderOption,
    handleAddPart,
    handleDeletePart,
    handleFileUpload,
    handleInputChange,
    handleTextAreaChange,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleDropdownSelect,
    handleAutoInputChange,
    handleCourseSubmit,
    onCoursePartInputChange,
    handleCoursePartDropdownSelect,
    handleCoursePartSubmit,
    closeModal,
    openReorderingModel,
    closeReorderingModel,
    onSelectIcon,
    handleSaveReorder,
    onCloseExistingIconModal,
    onOpenExistingIconModal,
    removeIcon,
    onQuestionPartInputChange,
    handleOptionChange,
    onQuestionInputChange,
    handleAddQuestions,
    handleAddQuizOption,
    handleQuestionPartSubmit,
    handleDeleteQuiz,
    handleDeleteQuizOption,
    handleTextEditorChange,
    onCoursePartTextEditorChange,
    onQuestionTextEditorChange,
    handleCheckboxChange,
  } = useAddEditCourseModal(props);

  const isCourseParts = reorderOption === ReorderOption.courseParts;

  return (
    <>
      <ModalComponent
        width="fullscreen"
        headerText={
          editCourseId
            ? getLanguageValue(languageText, "Edit Course")
            : getLanguageValue(languageText, "Add Course")
        }
        cancelButtonText={getLanguageValue(languageText, "Close")}
        handleCancelClick={closeModal}
      >
        <AddEditCourse
          languageText={languageText}
          courseInfo={courseInfo}
          focusInput={focusInput}
          languages={languages}
          categories={categories}
          tagNames={tagNames}
          isSubmitting={isSubmitting}
          imgErrorMessage={imgErrorMessage}
          isCourseSaved={isCourseSaved}
          existingIconModal={existingIconModal}
          baseUrl={baseUrl}
          uniqueIcons={uniqueIcons}
          isEditMode={isEditMode}
          editCourseId={editCourseId}
          handleFileUpload={handleFileUpload}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          handleTextAreaChange={handleTextAreaChange}
          handleTextAreaBlurEvent={handleTextAreaBlurEvent}
          handleDropdownSelect={handleDropdownSelect}
          handleAutoInputChange={handleAutoInputChange}
          handleCourseSubmit={handleCourseSubmit}
          onSelectIcon={onSelectIcon}
          onCloseExistingIconModal={onCloseExistingIconModal}
          onOpenExistingIconModal={onOpenExistingIconModal}
          removeIcon={removeIcon}
          handleTextEditorChange={handleTextEditorChange}
          handleCheckboxChange={handleCheckboxChange}
        />

        {/* -------------------- Adding Parts Section Start -------------------- */}

        {courseCreated && (
          <div ref={bottomRef}>
            <CoursePart
              courseParts={courseParts}
              languageText={languageText}
              isSubmitting={isSubmitting}
              onCoursePartInputChange={onCoursePartInputChange}
              handleCoursePartDropdownSelect={handleCoursePartDropdownSelect}
              handleCoursePartSubmit={handleCoursePartSubmit}
              handleDeletePart={handleDeletePart}
              onCoursePartTextEditorChange={onCoursePartTextEditorChange}
            />

            <div className="row justify-content-between mx-0 mt-4 gap-2">
              <button
                className="btn btn-secondary text-white col-12 col-md-5"
                onClick={handleAddPart}
                disabled={!canAddNewCoursePart}
              >
                {getLanguageValue(languageText, "Add part")}
              </button>
              <button
                className="btn btn-secondary text-white col-12 col-md-6"
                onClick={() => openReorderingModel(ReorderOption.courseParts)}
                disabled={courseParts.length <= 1}
              >
                {getLanguageValue(languageText, "Reorder parts")}
              </button>
            </div>
            <div className="grey-bg-darker mx-n7 h-1px" />
          </div>
        )}
        {/* -------------------- Adding Parts Section End -------------------- */}

        {/* -------------------- Adding Questions Section -------------------- */}
        {quizCreated && (
          <>
            <AddQuiz
              languageText={languageText}
              quizPart={quizPart}
              editedQuizIndex={editedQuizIndex}
              isQuizEdited={isQuizEdited}
              onQuestionInputChange={onQuestionInputChange}
              onQuestionPartInputChange={onQuestionPartInputChange}
              handleOptionChange={handleOptionChange}
              handleAddQuizOption={handleAddQuizOption}
              handleQuestionPartSubmit={handleQuestionPartSubmit}
              handleDeleteQuiz={handleDeleteQuiz}
              handleDeleteQuizOption={handleDeleteQuizOption}
              onQuestionTextEditorChange={onQuestionTextEditorChange}
            />
            <div className="row justify-content-between mx-0 mt-4 gap-2">
              <button
                className="btn btn-secondary text-white col-12 col-md-5"
                onClick={handleAddQuestions}
                disabled={!canAddNewQuizPart}
              >
                {getLanguageValue(languageText, "Add question")}
              </button>
              <button
                className="btn btn-secondary text-white col-12 col-md-5"
                onClick={() => openReorderingModel(ReorderOption.quiz)}
                disabled={quizPart.length <= 1}
              >
                {getLanguageValue(languageText, "Reorder questions")}
              </button>
            </div>
          </>
        )}
        {/* --------- Adding Questions Section End --------- */}

        {/* Reordering Model */}
        {openReorderModel && (
          <ReorderModal
            languageText={languageText}
            headerText={getLanguageValue(
              languageText,
              isCourseParts ? "Reorder Parts" : "Reorder Questions",
            )}
            dataToReorder={dataToReorder}
            closeReorderModal={closeReorderingModel}
            handleSaveReorder={handleSaveReorder}
          />
        )}
      </ModalComponent>
    </>
  );
};
