import React from "react";

import { AddEditAccountModal } from "./addEditModal/addEditAccountModal";
import { ReactTable } from "../../components/reactTable/reactTable";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { useClientList } from "./hooks";

export const ClientsList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    sorting,
    pageLimit,
    pageSelected,
    openClientModal,
    editedRowId,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addClientClick,
    refreshClientList,
    closeClientModal,
  } = useClientList();

  let rowData = {};
  if (editedRowId) {
    rowData = data.find((item) => item.id === editedRowId) ?? {};
  }

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Accounts")}
        searchPlaceholder={getLanguageValue(languageText, "Search Client")}
        buttonText={getLanguageValue(languageText, "Add Client")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        sorting={sorting}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        storePageConfiguredInfo={true}
        handleButtonClick={addClientClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openClientModal || editedRowId ? (
        <AddEditAccountModal
          rowData={rowData}
          refreshClientList={refreshClientList}
          closeClientModal={closeClientModal}
        />
      ) : (
        ""
      )}
    </>
  );
};
