import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";
import { useNavigate } from "react-router-dom";
import { routePath } from "../routePaths";
import { isLoggedInRoleParticipant } from "@app/commonUtils/roleHelper";

type Props = {
  setIsNotFound: (value: boolean) => unknown;
};

export const NotFoundPage = ({ setIsNotFound }: Props): JSX.Element => {
  const navigate = useNavigate();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  useEffect(() => {
    setIsNotFound(true);
    return () => {
      setIsNotFound(false);
    };
  }, [setIsNotFound]);

  const returnHome = (): void => {
    setIsNotFound(false);
    if (isLoggedInRoleParticipant(loggedInUserRole))
      navigate(routePath.participantDashboard);
    else navigate(routePath.home);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="bg-white p-20 rounded shadow">
          <h1 className="fs-1">
            {getLanguageValue(languageText, "404 - Page Not Found")}
          </h1>
          <p className="fs-3 mt-5">
            {getLanguageValue(
              languageText,
              "The page you are looking for cannot be found",
            )}
          </p>

          <div className="fs-6 mt-10">
            <p>{getLanguageValue(languageText, "Possible reasons")}</p>
            <ul>
              <li>
                {getLanguageValue(
                  languageText,
                  "The address may have been typed incorrectly",
                )}
              </li>
              <li>
                {getLanguageValue(
                  languageText,
                  "It may be a broken or outdated link",
                )}
              </li>
            </ul>
          </div>
          <div className="mt-10">
            <button
              className="btn btn-primary btn-sm w-50"
              onClick={returnHome}
            >
              {getLanguageValue(languageText, "Return home")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
