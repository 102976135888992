// This is the input field select component where list of options is dynamically fetched from an API based on user input

import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { IDropdownList } from "./dropdownSelect";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { classNames } from "@app/containers/utils";
import { useDebounce } from "@app/api/debounce";

interface IAutocompleteInputProps {
  languageText: ITranslationObject;
  id?: string;
  name?: string;
  label?: string;
  placeholder: string;
  list: IDropdownList[];
  selectedValue: IDropdownList;
  disable?: boolean;
  required?: boolean;
  errorMessage?: string;
  fetchList: (e: string) => Promise<void>;
  handleSelect: (selectedValue: IDropdownList) => void;
  handleUnSelect?: () => void;
}

export const DynamicSearchInputField = (
  props: IAutocompleteInputProps,
): JSX.Element => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [listData, setListData] = useState<IDropdownList[]>([]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setListData(props.list);
  }, [props.list]);

  const handleClickOutside = (e: MouseEvent): void => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setListData([]);
      setInputValue("");
    }
  };

  const debouncedFetchOptions = useDebounce((value) => {
    props.fetchList(value);
  }, 300);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInputValue(value);

    if (value.length >= 3) {
      debouncedFetchOptions(value);
    } else {
      setListData([]);
    }
  };

  const onSelectClick = (selectedValue: IDropdownList): void => {
    setInputValue("");
    setListData([]);
    props.handleSelect(selectedValue);
  };

  return (
    <div className="mb-3">
      {props.label && (
        <label
          className={clsx("fs-6 mb-2", {
            required: props.required,
          })}
        >
          {props.label}
        </label>
      )}

      <div ref={inputRef} className="position-relative">
        <div className="d-flex justify-content-end align-items-center">
          {props.selectedValue.value && (
            <i
              className="bi bi-x-lg position-absolute pe-4 cursor-pointer"
              role="button"
              onClick={props.handleUnSelect}
            />
          )}
          <input
            className={clsx(
              "form-control form-control-solid",
              props.selectedValue.value && "dynamic-search-input-field",
            )}
            id={props.id}
            type="text"
            name={props.name}
            placeholder={
              props.selectedValue.value
                ? props.selectedValue.displayName.toString()
                : props.placeholder
            }
            value={inputValue}
            disabled={props.disable}
            autoComplete="off"
            onChange={onInputChange}
          />
        </div>

        <div
          className={classNames({
            "border-start border-end border-bottom position-absolute w-100 shadow z-1":
              true,
            "d-none": !listData.length,
            "d-block": listData.length,
          })}
        >
          <ul
            className="list-group list-group-flush"
            style={{ maxHeight: 300, overflowY: "scroll" }}
          >
            {listData.length ? (
              listData.map((item) => {
                const clazz = classNames({
                  "list-group-item list-group-item-action": true,
                });
                return (
                  <li
                    key={item.id}
                    className={clazz}
                    onClick={() => onSelectClick(item)}
                    role="button"
                  >
                    {item.displayName}
                  </li>
                );
              })
            ) : (
              <li className="list-group-item">
                {getLanguageValue(props.languageText, "No Items to display")}
              </li>
            )}
          </ul>
        </div>

        {props.errorMessage && (
          <div className="invalid-feedback d-inline-block">
            {props.errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};
