import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IPresentationDataResponse } from "./hooks";
import { ApiResponse, ApiResult } from "@app/types";
import { IPresentationSlide } from "../facilitatorDelivery/hooks";
import { ProfileId } from "../reducer";

export const getProfileParticipantPresentation = async (
  presentationPublicId: string,
  dispatch: Dispatch,
): Promise<IPresentationDataResponse> => {
  try {
    const response = await api.get(
      apiEndPoints.profileParticipantPresentation,
      {
        params: { publicId: presentationPublicId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const idiV2SendOnlineStatusToFacilitator = async (
  cpId: number,
  profileId: ProfileId,
  isOnlineStatus: boolean,
  deliveryStatus: number,
  dispatch: Dispatch,
): Promise<any> => {
  const queryParmas = {
    coursePresentationId: cpId,
    profileId,
    isOnlineStatus,
  };
  try {
    const response = await api.post(
      apiEndPoints.idiV2SendOnlineStatusToFacilitator,
      deliveryStatus,
      {
        params: queryParmas,
      },
    );

    if (response.data.success) {
      return response.data.success;
    }
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const idiV2GetParticipantPresentationSlides = async (
  templateId: number,
  profileId: ProfileId,
  languageCode: string,
  dispatch: Dispatch,
): Promise<ApiResult<IPresentationSlide>> => {
  try {
    const endpointWithParams = `${apiEndPoints.idiV2GetParticipantPresentationSlides}?profileId=${profileId}&languageCode=${languageCode}&templateId=${templateId}`;
    const response = await api.post<ApiResponse<ApiResult<IPresentationSlide>>>(
      endpointWithParams,
      {},
      {},
    );

    if (!response.data.success) {
      return Promise.reject(response.data);
    }

    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getIDIV2CurrentPresentationSlideId = async (
  cpId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.get(
      apiEndPoints.getIDIV2CurrentPresentationSlideId,
      {
        params: { coursePresentationId: cpId },
      },
    );

    if (response.data.success) {
      return response.data.result;
    }
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
