import React from "react";
import { useNavigate } from "react-router-dom";

import { useCourseLessons } from "./hooks";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { CourseCard } from "../../coursesList/courseCard";
import { routePath } from "@app/containers/routePaths";
import { CourseLanguageSelector } from "../../courseLanguageSelector/courseLanguageSelector";

export const CourseLessons = (): JSX.Element => {
  const { languageText, lessons } = useCourseLessons();
  const navigate = useNavigate();

  return (
    <>
      <div className="p-2 pt-4 p-md-4">
        <CourseLanguageSelector />

        <h4 className="mb-2 fw-bold">
          <span
            role="button"
            onClick={() => navigate(routePath.participantAcademy)}
          >
            {getLanguageValue(languageText, "Academy")}
          </span>{" "}
          {lessons.categoryCourse.categoryId ? (
            <>
              /{" "}
              <span
                role="button"
                onClick={() =>
                  navigate(
                    routePath.participantCategoryCourses.replace(
                      ":parentId",
                      lessons.categoryCourse.parentId.toString(),
                    ),
                  )
                }
              >
                {lessons.categoryCourse.parentCategoryName}
              </span>{" "}
              / {lessons.categoryCourse.name}
            </>
          ) : (
            ""
          )}
        </h4>

        <div className="row">
          {/* Course Info */}
          <div className="col-12 col-md-4">
            <div className="p-4 mt-2 mt-md-4 mb-4 bg-primary bg-gradient bg-opacity-10 rounded">
              <span className="p-2 rounded bg-primary bg-opacity-75 text-white small">
                {lessons.categoryCourse.topic ??
                  getLanguageValue(languageText, "No courses")}
              </span>
              <h4 className="mt-4 fw-bold">{lessons.categoryCourse.name}</h4>
              <div className="d-flex flex-wrap mt-3">
                <div className="d-flex align-items-center me-5 mb-2">
                  <i className="bi bi-file-text me-2 fs-5" />
                  {lessons.categoryCourse.lessonsCount}{" "}
                  {getLanguageValue(languageText, "lessons")}
                  <i className="bi bi-stopwatch me-2 fs-4 ms-4" />
                  {lessons.categoryCourse.totalLessonsDuration}{" "}
                  {getLanguageValue(languageText, "min")}
                </div>
                <div className="pt-3">{lessons.categoryCourse.description}</div>
              </div>
            </div>
          </div>

          {/* Lessons */}
          <div className="col-12 col-md-8 p-md-4">
            {lessons.categoryCourseLessons &&
            lessons.categoryCourseLessons.length > 0 ? (
              lessons.categoryCourseLessons.map((lesson, i) => (
                <div key={lesson.id} className="mb-4">
                  <CourseCard
                    index={i}
                    languageText={languageText}
                    course={lesson}
                  />
                </div>
              ))
            ) : (
              <div className="fs-3 fw-bold">
                {getLanguageValue(languageText, "No lessons")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
