// This file includes helper functions for date
import { formatDate } from "@app/containers/utils";

// get Date in YYYY-MM-DD format
export const getFormattedDateOnly = (date: Date | string): string => {
  const formattedDate = formatDate(date, "yyyy-MM-dd");
  return formattedDate;
};

// Convert from '2023-07-24T14:44:53.684Z' to '2023 July 24'('YYYY MMMM DD')
export const getDateWithMonthFormat = (date: Date | string): string => {
  const formattedDate = formatDate(date, "yyyy MMMM dd");
  return formattedDate;
};

// get Date in 'YYYY-MM-DD HH:mm:ss' format
export const getFullDateTimeFormat = (date: Date | string): string => {
  const formattedDate = formatDate(date, "yyyy-MM-dd HH:mm:ss");
  return formattedDate;
};

// Convert from '2023-08-21 15:00:00' to '21 August 2023 - 15:00'('DD MMMM YYYY - HH:mm')
export const getDateWithMonthTimeFormat = (date: string): string => {
  const formattedDate = formatDate(date, "dd MMMM yyyy - HH:mm");
  return formattedDate;
};

// Convert date into 'YYYY-MM-DDTHH:mm:ssZ' i.e., ISO format
export const getISOFormattedDate = (date: Date | string): string => {
  const formattedDate = new Date(date).toISOString();
  return formattedDate;
};

// Convert from '2023-09-24T14:44:53.684Z' to 'Sep 24'('MMM DD')
export const getDateAndShortMonthFormat = (date: string): string => {
  const formattedDate = formatDate(date, "MMM dd");
  return formattedDate;
};
