import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { ReactTable } from "../../reactTable/reactTable";
import { IInvoiceExistingAddress } from "../invoiceInterface";
import { ModalComponent } from "../../modals/modalComponent";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface ISelectBillingAddress {
  languageText: ITranslationObject;
  existingAddresses: IInvoiceExistingAddress[];
  onSelectAddressClick: (data: IInvoiceExistingAddress) => void;
  closeSelectAddressModal: () => void;
}

export const SelectExistingInvoiceAddress = (props: ISelectBillingAddress) => {
  const {
    languageText,
    existingAddresses,
    onSelectAddressClick,
    closeSelectAddressModal,
  } = props;

  const tableHeader = useMemo<ColumnDef<IInvoiceExistingAddress, any>[]>(
    () => [
      {
        header: getLanguageValue(languageText, "Select"),
        disableSortBy: true,
        cell: ({ ...props }) => (
          <button
            className="btn btn-success btn-sm"
            onClick={() => onSelectAddressClick(props.row.original)}
          >
            {getLanguageValue(languageText, "Select")}
          </button>
        ),
      },
      {
        header: getLanguageValue(languageText, "Company"),
        accessorKey: "company",
        width: "min-w-100px",
      },
      {
        header: getLanguageValue(languageText, "Address"),
        cell: ({ ...props }) => {
          return (
            <>
              {props.row.original?.address},{props.row.original?.city},
              {props.row.original?.country},{props.row.original?.zip}
            </>
          );
        },
      },
    ],
    [languageText],
  );

  return (
    <ModalComponent
      width="lg"
      headerText={getLanguageValue(languageText, "Select Invoice Address")}
      cancelButtonText={getLanguageValue(languageText, "Close")}
      handleCancelClick={closeSelectAddressModal}
    >
      <ReactTable
        tableHeader={tableHeader}
        data={existingAddresses}
        minHeight="150px"
      />
    </ModalComponent>
  );
};
