import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";

interface ITermsFooterProps {
  languageText?: ITranslationObject;
  footerContainerClassName?: string;
  footerWrapperClassName?: string;
  footerLinksContainerClassName?: string;
}

export const TermsFooter = (props: ITermsFooterProps): JSX.Element => {
  const { footerContainerClassName, footerWrapperClassName, languageText } =
    props;

  const defaultLanguageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  return (
    <div className={footerContainerClassName ? footerContainerClassName : ""}>
      <div
        className={clsx(
          "text-center my-4",
          footerWrapperClassName && footerWrapperClassName,
        )}
      >
        &copy;{" "}
        {getLanguageValue(
          languageText ? languageText : defaultLanguageText,
          "IDI Profiling AB",
        )}
        <a
          href="https://idi.se/privacy/"
          target="_blank"
          className="fw-light mx-4"
          rel="noreferrer"
        >
          {getLanguageValue(
            languageText ? languageText : defaultLanguageText,
            "Privacy",
          )}
        </a>
        <a
          href="https://idi.se/terms/"
          target="_blank"
          className="fw-light"
          rel="noreferrer"
        >
          {getLanguageValue(
            languageText ? languageText : defaultLanguageText,
            "Terms",
          )}
        </a>
      </div>
      <div className="p-4 m-4"></div>
    </div>
  );
};
