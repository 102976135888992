import { NavigateFunction } from "react-router-dom";
import { Dispatch } from "redux";
import { RoleName, Roles } from "./containers/commonEnums";
import { IUserDetails } from "./containers/commonInterfaces";
import { routePath } from "./containers/routePaths";
import { getParticipantProfileInfo } from "./commonUtils/participantProfileHelper";
import { IProfileDetails } from "./containers/participantPages/reducer";
import { ucQueryKey } from "./containers/participantPages/useParticipantProfileAuth";

export enum MultiRoleBehavior {
  /** Sends the user to the 'switch role' page. */
  AskUser,

  /** Ignores any other roles and sends the user to the start page of the given role. */
  Ignore,
}

export function useAuthFlow(dispatch: Dispatch, navigate: NavigateFunction) {
  const self = {
    // TODO: add methods for logging in and switching roles.

    redirectToStartPageByRole: async (
      roleName: RoleName,
      details: IUserDetails,
      behavior: MultiRoleBehavior,
    ): Promise<unknown> => {
      sessionStorage.removeItem("returnTo"); // fuck this thing. never again.

      // it's technically possible for the admin to belong to multiple
      // roles. our 'switch role' page only allows switching between
      // participant and facilitator so we can't really send the admins
      // there.
      const isBigBadAdminWithTopPriority = roleName === Roles.Admin;

      if (isBigBadAdminWithTopPriority) {
        navigate(routePath.home);
        return;
      }

      const needsExplicitRoleSwich =
        behavior === MultiRoleBehavior.AskUser &&
        details.userRoles.some(
          (r) => r.assignedRoleName === Roles.Facilitator,
        ) &&
        details.userRoles.some((r) => r.assignedRoleName === Roles.Participant);

      // TODO: we should only ask the user to switch roles after logging in.
      //   currently this method executes both after logins and role switches. but
      //   if the has explicitly asked to switch to some role we shouldn't send them back
      //   to the switcheroo page again like a maniac.
      if (needsExplicitRoleSwich) {
        navigate(routePath.switchRoles);
        return;
      }

      switch (roleName) {
        case Roles.Facilitator:
          navigate(routePath.home);
          break;
        case Roles.Participant:
          if (details.isOverviewPage || !details.profileId) {
            // how could 'profileId' ever be falsy?
            // i guess it's possible to have the participant role
            // without a profile, but really? are we sure?
            navigate(routePath.participantDashboard);
          } else {
            const moreDetails = await getParticipantProfileInfo(
              details.uniqueCode,
              dispatch,
              false,
            );
            const url = self.guessURLToCurrentParticipantStep(
              details.uniqueCode,
              moreDetails,
            );
            navigate(url);
          }
          break;
      }

      return Promise.resolve();
    },
    /**
     * Attempts to guess the current participant URL depending on some giant
     * blob of data.
     *
     * Use 'redirectToStartPageByRole' whenever possible. This is just a shitty
     * utility method to cover cases where the redirect happens through some other
     * means.
     *
     * There are at least 3 or 4 copy-pastas of this giant ass-blob - it's a mess.
     * Do as search for 'routePath.participantSelfForm' or any of the cases in this
     * function to find them.
     *   -johan, 2024-09-28
     */
    guessURLToCurrentParticipantStep(
      uniqueCode: string,
      profileDetails: IProfileDetails,
    ): string {
      // For new participant, if language is not set, first select language page, then demographic page
      const wantsProfileWizard =
        profileDetails.presentationId ||
        profileDetails.isFormFilled ||
        profileDetails.isFormFilledData ||
        profileDetails.isDemographicsFilled;

      if (!profileDetails.languageName) {
        return `${routePath.dr}?${ucQueryKey}=${uniqueCode}`;
      } else if (!profileDetails.isPasswordSet) {
        return `${routePath.participantChangePassword}?${ucQueryKey}=${uniqueCode}`;
      } else if (wantsProfileWizard) {
        return `${routePath.participantCreate}?${ucQueryKey}=${uniqueCode}`;
      }
      return `${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`;
    },
  };

  return self;
}
