import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";
import { ProfileId } from "@app/containers/reducer";

interface IParticipantProfileDropdownProps {
  languageText: ITranslationObject;
  id?: string;
  profileId?: ProfileId;
  isGroup?: boolean;
  isDeliver?: boolean;
  isUnDeliver?: boolean;
  handlePreviewClick?: (profileId: ProfileId, isSelf: boolean) => void;
  handleDeliverOrUnDeliverClick?: (
    profileId: ProfileId,
    isSelf?: boolean,
  ) => void;
  handleDownloadClick?: (profileId: ProfileId) => void;
  handleDownloadGroupClick?: () => void;
}

export const ParticipantProfileDropdown: React.FC<
  IParticipantProfileDropdownProps
> = (props) => {
  const onDownloadClick = (): void => {
    if (props.handleDownloadClick) props.handleDownloadClick(props.profileId!);
  };

  const onPreviewClick = (): void => {
    if (props.handlePreviewClick)
      props.handlePreviewClick(props.profileId!, false);
  };

  const onDeliverOrUnDeliverClick = (): void => {
    if (props.handleDeliverOrUnDeliverClick)
      props.handleDeliverOrUnDeliverClick(props.profileId!);
  };

  const dropdownItems: Array<DropdownMenuItem> = [
    {
      name: getLanguageValue(props.languageText, "Download"),
      onClick: onDownloadClick,
    },
  ];

  if (props.isGroup && props.handleDownloadGroupClick) {
    dropdownItems.push({
      name: getLanguageValue(props.languageText, "Group Report"),
      onClick: props.handleDownloadGroupClick,
    });
  }

  dropdownItems.push({
    name: getLanguageValue(props.languageText, "Preview"),
    onClick: onPreviewClick,
  });

  if (props.isDeliver || props.isUnDeliver) {
    dropdownItems.push({
      name: getLanguageValue(
        props.languageText,
        props.isDeliver ? "Deliver" : "Undeliver",
      ),
      onClick: onDeliverOrUnDeliverClick,
    });
  }

  return <DropdownMenu id={props.id} items={dropdownItems} />;
};
