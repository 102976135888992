import { SelectLanguageDropdownModal } from "@app/components/languagePageComponent/selectLanguageDropdownModal";
import { LanguageCode } from "@app/containers/commonEnums";
import { ILanguage } from "@app/containers/commonInterfaces";
import { ILanguageText } from "@app/containers/reducer";
import * as React from "react";

type HasInstructionLanguage = {
  instructionLanguageId: number | null;
};

type Props<T> = {
  languageText: ILanguageText;
  languages: ReadonlyArray<ILanguage>;
  onChange: (next: T) => unknown;
  onClose: () => unknown;
  value: T;
};

export function InstructionLanguageSelector<T extends HasInstructionLanguage>(
  props: Props<T>,
): JSX.Element {
  const language = props.languages.find(
    (it) => it.id === props.value.instructionLanguageId,
  );

  return (
    <SelectLanguageDropdownModal
      languageText={props.languageText}
      languages={props.languages}
      languageValue={language?.name || LanguageCode.English}
      closeModal={props.onClose}
      handleSaveLanguage={(languageCode) => {
        const lang = props.languages.find((x) => x.name === languageCode);
        const next: T = {
          ...props.value,
          instructionLanguageId: lang?.id,
        };
        props.onChange(next);
      }}
    />
  );
}
