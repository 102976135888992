import React from "react";

import { ReactTable } from "../../components/reactTable/reactTable";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { useAuditLogs } from "./hooks";
import { ModalComponent } from "../../components/modals/modalComponent";
import { getFullDateTimeFormat } from "../../commonUtils/dateFunctionsHelper";
import { MultiFilters } from "./multiFilters/multiFilters";

export const AuditLogs = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    pageLimit,
    pageSelected,
    auditLogDetailRowId,
    auditLogDetails,
    setSorting,
    setPageLimit,
    setPageSelected,
    handleUpdatedFilterOptions,
    closeDetailsModal,
  } = useAuditLogs();

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Audit Logs list")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
        handleUpdatedFilterOptions={handleUpdatedFilterOptions}
        customFilters={
          <MultiFilters
            handleUpdatedFilterOptions={handleUpdatedFilterOptions}
          />
        }
      />

      {auditLogDetailRowId > 0 && (
        <ModalComponent
          width="lg"
          headerText={getLanguageValue(languageText, "Audit Log Details")}
          cancelButtonText={getLanguageValue(languageText, "Close")}
          handleCancelClick={closeDetailsModal}
        >
          <div className="container mb-10">
            <p className="fs-5 fw-bold my-auto mb-5">
              {getLanguageValue(languageText, "User information")}
            </p>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "User Name")}
              </div>
              <div className="col-8">{auditLogDetails.userName}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "IP Address")}
              </div>
              <div className="col-8">{auditLogDetails.clientIpAddress}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Client")}
              </div>
              <div className="col-8">{auditLogDetails.clientName}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Browser")}
              </div>
              <div className="col-8">{auditLogDetails.browserInfo}</div>
            </div>
          </div>

          <div className="container">
            <p className="fs-5 fw-bold my-auto mb-5">
              {getLanguageValue(languageText, "Action information")}
            </p>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Service")}
              </div>
              <div className="col-8">{auditLogDetails.serviceName}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Action")}
              </div>
              <div className="col-8">{auditLogDetails.methodName}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Time")}
              </div>
              <div className="col-8">
                {getFullDateTimeFormat(auditLogDetails.executionTime)}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Duration")}
              </div>
              <div className="col-8">{`${auditLogDetails.executionDuration} ms`}</div>
            </div>

            <div className="row mb-2">
              <div className="col-4">
                {getLanguageValue(languageText, "Parameters")}
              </div>
              <div className="col-8">{auditLogDetails.parameters}</div>
            </div>
          </div>
        </ModalComponent>
      )}
    </>
  );
};
