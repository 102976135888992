import React from "react";
import clsx from "clsx";

import { IFocusError } from "./input";

interface ITeaxtAreaProps {
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  areaClass?: string;
  focusInput?: IFocusError;
  checkMarkNotRequired?: boolean;
  disable?: boolean;
  required?: boolean;
  maxLength?: number;
  containerClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleBlurEvent?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  rows?: number;
}

export const TextArea = (props: ITeaxtAreaProps): JSX.Element => {
  return (
    <>
      <div
        className={clsx("mb-3", props.containerClass && props.containerClass)}
      >
        {props.label && (
          <label className={clsx("fs-6 mb-2", { required: props.required })}>
            {props.label}
          </label>
        )}
        <textarea
          className={clsx(
            props.areaClass
              ? props.areaClass
              : "form-control form-control-solid min-h-100px",
            {
              "is-invalid":
                props.focusInput?.touched && props.focusInput?.errorMessage,
            },
            {
              "is-valid":
                !props.focusInput?.errorMessage &&
                props.focusInput?.touched &&
                props.value &&
                !props.checkMarkNotRequired,
            },
          )}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.handleBlurEvent}
          maxLength={props.maxLength ? props.maxLength : undefined}
          disabled={props.disable ?? false}
          rows={props.rows}
        />

        {props.focusInput?.errorMessage && (
          <div className="invalid-feedback d-inline-block">
            {props.focusInput.errorMessage}
          </div>
        )}
      </div>

      {props.maxLength! > 0 && (
        <>
          <div className="d-flex justify-content-end">
            <div className="badge rounded-pill text-bg-secondary">
              {props.maxLength! - props.value!.length}/{props.maxLength}
            </div>
          </div>
        </>
      )}
    </>
  );
};
