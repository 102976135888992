import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { ICreateOrUpdateUserBody } from "./hooks";
import { ApiResponse } from "@app/types";

export const createOrUpdateUser = async (
  body: ICreateOrUpdateUserBody,
  dispatch: Dispatch,
): Promise<unknown> => {
  try {
    const response = await api.post<ApiResponse<unknown>>(
      apiEndPoints.createOrUpdateUser,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

type UserExists = {
  isUserExists: boolean;
  isAdmin: boolean;
  isFacilitator: boolean;
  isParticipant: boolean;
};

export const verifyUser = async (
  email: string,
  dispatch: Dispatch,
): Promise<UserExists> => {
  try {
    const response = await api.post<ApiResponse<UserExists>>(
      apiEndPoints.verifyUserExists,
      {},
      {
        params: { email },
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
