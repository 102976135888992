import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter, Location } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { routePath } from "../routePaths";
import { RootState } from "@app/store/configureStore";
import { ErrorToaster, SuccessToast } from "../../components/toaster";
import { Spinner } from "../../components/spinner/spinner";
import { RatingModal } from "../rating/modal";
import { RegisterPage } from "../auth/signUp/registerPage/registerPage";
import { removeToast } from "../actions";
import { AlertAction, IAlert } from "../alertList/types";
import { InteractAlertModal } from "../alertList/interactAlertModal";

import { TemplateRoutes } from "./templateRoutes";
import { ParticipantRoutes } from "./participantRoutes/participantRoutes";
import { RespondentRoutes } from "./respondentRoutes/respondentRoutes";
import { FacilitatorDelivery } from "../facilitatorDelivery";
import { ParticipantsDelivery } from "../participantDelivery";
import { SignUp } from "../auth/signUp";
import { ResetPasswordPage } from "../resetPasswordPage";
import { VerifyEmail } from "../verifyEmailPage";
import { PreviewPresentationTemplate } from "../presentationsList/preview";
import { pushToDataLayer } from "@app/commonUtils/google";
import { LocationChangeListener } from "./locationChangeListener";
import { PreviewPresentationTemplateSelfDelivery } from "../presentationsList/previewSelfDelivery";
import { NEEDS_USER_CONSENT } from "@app/containers/auth/signUp/constants";
import { ConsentAnswerModal } from "@app/containers/consent/consentAnswerModal";
import { Roles } from "../commonEnums";
import { ITableCommonParams } from "../commonInterfaces";
import { useApiEndpoints } from "@app/api/end-points";
import { SelectLanguagePage } from "../participantPages/selectLanguagePage/selectLanguagePage";
import { SignInPage } from "../participantPages/signInPage/signInPage";
import { ChangePasswordPage } from "../participantPages/changePasswordPage/changePasswordPage";
import { SwitchRolesPage } from "../switchRoles/switchRolesPage";
import { ConfirmDialog } from "@app/confirm";

export const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const notificationMessage: string = useSelector(
    (state: RootState) => state.mainReducer.error,
  );
  const toasts = useSelector((state: RootState) => state.mainReducer.toasts);
  const spinner: boolean = useSelector(
    (state: RootState) => state.mainReducer.spinner,
  );
  const ratingTarget = useSelector((state: RootState) => state.rating.target);
  const needsUserConsent = useSelector(
    (state: RootState) => state.loginReducer.needsUserConsent,
  );
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const confirm = useSelector((state: RootState) => state.mainReducer.confirm);
  const api = useApiEndpoints(dispatch);

  function handleLocationChange(location: Location): void {
    pushToDataLayer({
      event: "page_view",
      path: location.pathname,
      query: location.search,
    });
  }

  const alert: IAlert | undefined = useSelector((state: RootState) => {
    const alerts = state.mainReducer.alerts;

    // when action is taken on the alert it is removed from 'triggeredAlerts'.
    // the list will be treated as a first-in-first-out queue, so it's possible
    // to trigger many alerts in sequence.
    for (const trigger of state.mainReducer.triggeredAlerts) {
      const found = alerts.find((x) => {
        return (
          x.trigger === trigger &&
          x.isActive &&
          x.actionTaken === AlertAction.None
        );
      });

      if (found) {
        return found;
      }
    }

    return undefined;
  });

  React.useEffect(() => {
    // we can't guard against the 'Admin' role here, or more specifically, it doesn't
    // matter. the admin will be a 'Facilitator' for short while anyways. that also
    // means this method will be called twice... yeah...
    if (!loggedInUserRole) {
      return;
    }

    const params: ITableCommonParams = {
      filter: "",
      sorting: "",
      maxResultCount: 500,
      skipCount: 0,
    };
    api.getConsents(params).then((res) => {
      dispatch({ type: NEEDS_USER_CONSENT, payload: res.items });
    });
  }, [loggedInUserRole]);

  return (
    <>
      <BrowserRouter>
        <LocationChangeListener onChange={handleLocationChange}>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route index path={routePath.signIn} element={<SignUp />} />
              <Route
                index
                path={routePath.register}
                element={<RegisterPage />}
              />
              <Route
                index
                path={routePath.registerFacilitator}
                element={<RegisterPage />}
              />
              <Route
                index
                path={routePath.registerOrganization}
                element={<RegisterPage />}
              />
              <Route
                index
                path={routePath.registerParticipant}
                element={<RegisterPage />}
              />

              <Route
                path={routePath.facilitatorDelivery}
                element={<FacilitatorDelivery />}
              />
              <Route
                path={routePath.participantDelivery}
                element={<ParticipantsDelivery />}
              />
              <Route
                path={routePath.previewPresentationTemplate}
                element={<PreviewPresentationTemplate />}
              />
              <Route
                path={routePath.previewPresentationTemplateSelfDelivery}
                element={<PreviewPresentationTemplateSelfDelivery />}
              />
              <Route
                path={routePath.resetPassword}
                element={<ResetPasswordPage />}
              />
              <Route path={routePath.verifyEmail} element={<VerifyEmail />} />
              <Route
                path={routePath.verificationcode}
                element={<SignInPage />}
              />
              <Route path={routePath.dr} element={<SelectLanguagePage />} />
              <Route
                path={routePath.switchRoles}
                element={<SwitchRolesPage />}
              />
              <Route
                path={routePath.participantLogIn}
                element={<SignInPage />}
              />
              <Route
                path={routePath.participantChangePassword}
                element={<ChangePasswordPage />}
              />

              <Route path="/participant/*" element={<ParticipantRoutes />} />
              <Route path="/r/*" element={<RespondentRoutes />} />
              <Route path="/*" element={<TemplateRoutes />} />
            </Routes>
          </Suspense>
        </LocationChangeListener>
      </BrowserRouter>

      {notificationMessage && (
        <ErrorToaster notificationMessage={notificationMessage} />
      )}

      {ratingTarget ? (
        <RatingModal
          modelType={ratingTarget.modelType}
          modelId={ratingTarget.modelId}
          modelName={ratingTarget.modelName}
        />
      ) : undefined}

      {alert && <InteractAlertModal alert={alert} />}

      {toasts.map((toast, index) => (
        <SuccessToast
          id={toast.id}
          key={toast.id}
          index={index}
          message={toast.message}
          onClose={() => dispatch(removeToast(toast.id))}
        />
      ))}

      {needsUserConsent.length &&
      loggedInUserRole &&
      loggedInUserRole !== Roles.Admin ? (
        <ConsentAnswerModal consent={needsUserConsent[0]} />
      ) : undefined}

      {spinner && <Spinner />}
      {!!confirm && (
        <ConfirmDialog
          title={confirm.title}
          message={confirm.message}
          onAccept={confirm.resolve}
          onReject={confirm.reject}
        />
      )}
    </>
  );
};
