import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCategoryCourseLessons } from "../../actions";
import { RootState } from "@app/store/configureStore";
import { ICategoryCourse, ICourseAndLesson, ILesson } from "../../types";
import { getParticipantMedia, setSpinner } from "../../../actions";
import { UserGeneratedMediaType } from "../../../commonEnums";
import { ConvertCourseDurationToMinutes } from "../../../../commonUtils/coursesHelper";

export const useCourseLessons = () => {
  const dispatch = useDispatch();

  const categoryId = Number(useParams().categoryId) || 0;

  const defaultClient = useSelector(
    (state: RootState) => state.loginReducer.defaultClient,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const selectedCourseLanguageId: number = useSelector(
    (state: RootState) => state.academyReducer.selectedCourseLanguageId,
  );
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const initialLessonsState: ICourseAndLesson = {
    categoryCourse: {
      categoryId: 0,
      parentId: 0,
      parentCategoryName: "",
      name: "",
      description: "",
      topic: "",
      lessonsCount: 0,
      totalLessonsDuration: 0,
      progress: 0,
    },
    categoryCourseLessons: [],
  };
  const [lessons, setLessons] = useState<ICourseAndLesson>(initialLessonsState);

  useEffect(() => {
    if (categoryId && userId && defaultClient.defaultClientId) {
      dispatch(setSpinner(true));
      getCategoryCourseLessons(
        categoryId,
        defaultClient.defaultClientId,
        selectedCourseLanguageId,
        dispatch,
      )
        .then(async (response) => {
          if (response) {
            // CategoryCourse update
            let totalDuration = 0;
            if (response.categoryCourseLessons.length > 0) {
              response.categoryCourseLessons.map((lessons) => {
                let durationInMins = 0;
                durationInMins = ConvertCourseDurationToMinutes(
                  lessons.duration,
                );
                totalDuration += durationInMins;
              });
            }
            const updatedCategoryCourse: ICategoryCourse = {
              ...response.categoryCourse,
              totalLessonsDuration: totalDuration,
            };

            // Lessons update
            const updatedLessons: Promise<ILesson>[] =
              response.categoryCourseLessons.map(async (course) => {
                const fetchedImage: string = await getParticipantMedia(
                  course.id,
                  UserGeneratedMediaType.AcademyCourse,
                  dispatch,
                );
                return { ...course, logo: fetchedImage };
              });

            Promise.all(updatedLessons).then((lessons) => {
              const updatedCourseLessons: ICourseAndLesson = {
                categoryCourse: updatedCategoryCourse,
                categoryCourseLessons: lessons,
              };
              setLessons(updatedCourseLessons);
            });
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  }, [categoryId, selectedCourseLanguageId, userId, defaultClient]);

  return {
    languageText,
    lessons,
  };
};
