import { combineReducers } from "redux";

import { facilitatorDeliveryReducer } from "../containers/facilitatorDelivery/reducer";
import { loginReducer } from "../containers/auth/signUp/reducer";
import { mainReducer } from "../containers/reducer";
import { participantReducer } from "../containers/participantPages/reducer";
import { respondentReducer } from "../containers/respondentPage/reducer";
import { academyReducer } from "../containers/academy/reducer";
import { ratingReducer } from "@app/containers/rating/reducer";
import { RESET_STATE } from "./actions";

export function createReducer() {
  const rootReducer = combineReducers({
    loginReducer,
    facilitatorDeliveryReducer,
    mainReducer,
    participantReducer,
    respondentReducer,
    academyReducer,
    rating: ratingReducer,
  });

  const reducer: typeof rootReducer = (state, action) => {
    switch (action.type) {
      case RESET_STATE:
        // feeding the inner reducers with an undefined state will
        // make them assign the 'initialState' as the next output.
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  };
  return reducer;
}
