import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  clearNotifications,
  setSpinner,
  reloadLanguageStrings,
} from "../../containers/actions";
import { getFacilitatorLanguages } from "../../containers/languageList/languageTexts/actions";
import { updateUser } from "../../containers/usersProfile/edit-user/actions";
import { getLoggedInUserData } from "../../containers/auth/signUp/actions";
import { langKey } from "@app/consts";
import { ILanguage, IUserProfile } from "@app/containers/commonInterfaces";
import { routePath } from "@app/containers/routePaths";
import { LanguageCode, LanguageId } from "@app/containers/commonEnums";
import {
  ILanguageObject,
  updateURLLanguage,
} from "@app/commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";

export interface ILanguageResponse {
  defaultLanguageName: string;
  items: ILanguage[];
}

export const useLanguageNavbar = (languageCodeInURL: boolean) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const languageCode = queryParams.get(langKey) || "";

  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );

  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [languages, setLanguages] = useState<ILanguageObject[]>([]);

  useEffect(() => {
    dispatch(setSpinner(true));
    const fetchData = async () => {
      const languagesResponse = await getFacilitatorLanguages(dispatch);
      let languages: ILanguageObject[] = [];
      if (languagesResponse && languagesResponse?.length > 0) {
        languages = languagesResponse.map((item) => {
          const displayName = item.displayName.split(" ")[0];
          return {
            id: item.id,
            icon: item.icon,
            name: item.name,
            displayName: displayName,
          };
        });
      }
      setLanguages(languages);
    };
    fetchData().finally(() => dispatch(setSpinner(false)));
  }, []);

  useEffect(() => {
    if (languageCodeInURL) {
      if (!languageCode) {
        if (userLanguage.userLanguageCode) {
          updateURLLanguage(userLanguage.userLanguageCode, navigate);
          reloadLanguageStrings(
            userLanguage.userLanguageCode,
            false,
            dispatch,
            userLanguage.userLanguageId,
          );
        } else {
          updateURLLanguage(LanguageCode.English, navigate);
          reloadLanguageStrings(
            LanguageCode.English,
            false,
            dispatch,
            LanguageId.English,
          );
        }
      } else if (languageCode && !userLanguage.userLanguageCode) {
        reloadLanguageStrings(languageCode, false, dispatch, 0);
      }
    }
  }, []);

  useEffect(() => {
    if (
      (languageCode || (!languageCode && userLanguage.userLanguageCode)) &&
      languages.length > 0
    ) {
      const language = languageCode || userLanguage.userLanguageCode;

      // seems incorrect. the display name has no relation to the language ID or code.
      const selectedLang = String(
        languages.find((item) => item.name === language)?.displayName,
      );
      if (selectedLang) {
        setSelectedLanguage(selectedLang);
      }
    }
  }, [languageCode, languages, userLanguage.userLanguageCode]);

  const handleChangeLanguage = async ({
    id,
    displayName,
    name,
  }: ILanguageObject): Promise<void> => {
    if (selectedLanguage === displayName) return;
    const selectedLanguageId: number = id;
    reloadLanguageStrings(name, false, dispatch, selectedLanguageId);
    dispatch(clearNotifications(""));

    if (location.pathname === routePath.switchRoles) {
      const response = await getLoggedInUserData(false, dispatch);

      const body: IUserProfile = {
        ...response,
        selectedLanguageId: selectedLanguageId,
      };
      await updateUser(body, false, dispatch).then((response) => {
        if (response) {
          updateURLLanguage(name, navigate);
        }
      });
    } else if (languageCodeInURL) updateURLLanguage(name, navigate);
  };

  return {
    selectedLanguage,
    languages,
    handleChangeLanguage,
  };
};
