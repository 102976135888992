import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { RootState } from "@app/store/configureStore";
import { routePath } from "../../routePaths";
import {
  useParticipantProfileAuth,
  ucQueryKey,
} from "../useParticipantProfileAuth";

export const useWelcomePage = () => {
  const navigate = useNavigate();
  const { uniqueCode } = useParticipantProfileAuth();

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails,
  );

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const handleNextClick = (): void => {
    navigate(`${routePath.participantCreate}?${ucQueryKey}=${uniqueCode}`);
  };

  return {
    languageText,
    participantProfileDetails,
    handleNextClick,
  };
};
