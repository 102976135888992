import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

interface IAutocompleteInputProps {
  id?: string;
  name: string;
  label?: string;
  labelClassname?: string;
  required?: boolean;
  containerClassname?: string;
  inputWrapperClass?: string;
  inputClassname?: string;
  placeholder?: string;
  value: string;
  list: string[];
  disable?: boolean;
  dropdownHeight?: string;
  handleAutoInputChange: (name: string, value: string) => void;
}

export const AutocompleteInput = (
  props: IAutocompleteInputProps,
): JSX.Element => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent): void => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setShowList(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setShowList(true);
    props.handleAutoInputChange(props.name, value);
  };

  const handleSelect = (selectedValue: string): void => {
    const updatedValue = props.value.split(",");
    updatedValue[updatedValue.length - 1] = selectedValue;
    setShowList(false);
    props.handleAutoInputChange(props.name, updatedValue.join(","));
  };

  let lastItem: string = "";
  if (props.value) {
    const items = props.value.split(",");
    if (items.length > 0) {
      const lastElement = items.pop();
      if (lastElement) {
        lastItem = lastElement.trimStart();
      }
    }
  }

  let filteredList: string[] = [];
  if (props.list && props.list.length > 0) {
    filteredList = props.list.filter((item) =>
      item.toLowerCase().includes(lastItem.toLowerCase()),
    );
  }

  return (
    <div
      className={clsx(
        "mb-6",
        props.containerClassname && props.containerClassname,
      )}
    >
      {props.label && (
        <label
          className={clsx(
            "fs-6 mb-2",
            props.labelClassname && props.labelClassname,
            {
              required: props.required,
            },
          )}
        >
          {props.label}
        </label>
      )}

      <div
        ref={inputRef}
        className={clsx(
          "position-relative",
          props.inputWrapperClass && props.inputWrapperClass,
        )}
      >
        <input
          className={clsx(
            "form-control form-control-solid mb-3 mb-lg-0",
            props.inputClassname && props.inputClassname,
          )}
          id={props.id}
          type="text"
          name={props.name ?? ""}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disable}
          autoComplete="off"
          onChange={handleInputChange}
        />
        {showList && filteredList.length > 0 && (
          <div
            className={clsx(
              "d-flex menu menu-sub menu-sub-dropdown menu-rounded position-absolute w-100 menu-gray-600 menu-state-bg-light-primary mh-300px overflow-scroll fw-bold fs-7",
              props.dropdownHeight && props.dropdownHeight,
            )}
          >
            {filteredList.map((item: string, index: number) => (
              <div key={index} className="menu-item px-3">
                <div
                  className="menu-link px-3"
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
