import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";

import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { INewsList, NewsInputs, IFocusInput, NewsType } from "../types";
import { createNews, updateNews } from "./actions";
import { IDropdownSelectedItem } from "@app/components/formComponents/dropdownSelect";
import { addToast, getMediaImageFile, setSpinner } from "../../../actions";
import { UserGeneratedMediaType } from "../../../commonEnums";
import { AnyAction } from "redux";

export interface IAddEditNewsModalProps {
  rowData: INewsList;
  closeNewsModal: (refreshNews: boolean) => void;
}

export const useAddEditNews = (props: IAddEditNewsModalProps) => {
  const dispatch = useDispatch();

  const [imgErrorMessage, setImgErrorMessage] = useState<string>("");
  const [isEditClicked, setIsEditClicked] = useState<boolean>(false);
  const [imageFileName, setImageFileName] = useState<string>("");

  const newsTypeList = useMemo(
    () => [
      {
        id: NewsType.News,
        displayName: "News",
        value: NewsType.News.toString(),
      },
      {
        id: NewsType.Blogs,
        displayName: "Blogs",
        value: NewsType.Blogs.toString(),
      },
    ],
    [],
  );

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    title: {
      touched: false,
      errorMessage: "",
    },
    image: {
      touched: false,
      errorMessage: "",
    },
    excerpt: {
      touched: false,
      errorMessage: "",
    },
    post: {
      touched: false,
      errorMessage: "",
    },
    publishedDate: {
      touched: false,
      errorMessage: "",
    },
    type: {
      touched: false,
      errorMessage: "",
    },
  });

  const [newsData, setNewsData] = useState<INewsList>({
    id: 0,
    title: "",
    publishedDate: new Date().toISOString(),
    excerpt: "",
    image: "",
    imageFile: null,
    post: "",
    published: false,
    type: "",
    tenantId: null,
  });

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  //useeffects for addedit news modal
  useEffect(() => {
    const fetchDetails = async () => {
      //This used to check for properties on the rowData item, but let's just check if there is an ID
      if (props.rowData!.id) {
        updateNewsData();
        setIsEditClicked(true);
      }
    };
    fetchDetails();
  }, []);

  //fns for addedit news modals
  const closeAddEditRoleModal = (value: boolean): void => {
    props.closeNewsModal(value);
    setIsEditClicked(false);
  };

  const updateNewsData = async (): Promise<void> => {
    let fetchedImages = "";
    //fetch image
    //TODO: Danger, danger - rewrite this shit - Joakim, 241127
    if (props.rowData!.image) {
      setImageFileName(props.rowData!.image);
      fetchedImages = await getMediaImageFile(
        props.rowData!.id,
        UserGeneratedMediaType.News,
        dispatch,
      );
    }

    setNewsData({
      ...newsData,
      id: props.rowData!.id,
      title: props.rowData!.title,
      excerpt: props.rowData!.excerpt,
      image: fetchedImages,
      post: props.rowData!.post,
      published: props.rowData!.published,
      publishedDate: props.rowData!.publishedDate,
      type: props.rowData!.type,
      tenantId: props.rowData!.tenantId,
    });
  };

  const closeModal = (): void => closeAddEditRoleModal(false);

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";
    switch (name) {
      case NewsInputs.title:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Name is required");
        }
        break;
      case NewsInputs.excerpt:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Description is required",
          );
        }
        break;
      case NewsInputs.image:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Image Url is required",
          );
        }
        break;
      case NewsInputs.post:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Article is required");
        }
        break;
      case NewsInputs.publishedDate:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Published Date is required",
          );
        }
        break;
      case NewsInputs.type:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Type is required");
        }
        break;
      default:
        break;
    }
    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    const value = e.target.checked;

    setNewsData({
      ...newsData,
      [name]: value,
    });
  };

  const onNewsTextEditorChange = (content: string, name: string): void => {
    setNewsData({
      ...newsData,
      [name]: content,
    });

    handleFormErrors(name, content);
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setNewsData({
      ...newsData,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setNewsData({
      ...newsData,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  //handle file upload
  const handleFileUpload = (file: File): void => {
    if (file) {
      setImgErrorMessage("");
      const reader = new FileReader();
      reader.onload = (event) => {
        setNewsData({
          ...newsData,
          imageFile: file,
          image: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setNewsData({
        ...newsData,
        imageFile: null,
        image: "",
      });
    }
  };

  const removeIcon = (): void => {
    setNewsData({
      ...newsData,
      image: "",
      imageFile: null,
    });
  };

  const handleValidationOnSubmit = (): boolean => {
    const invalidFileError = getLanguageValue(
      languageText,
      "The selected file is not a valid image file",
    );
    const noFileProvidedError = getLanguageValue(
      languageText,
      "Image is required",
    );

    if (
      !newsData.title ||
      focusInput.title.errorMessage ||
      !newsData.publishedDate ||
      focusInput.publishedDate.errorMessage ||
      !newsData.excerpt ||
      focusInput.excerpt.errorMessage ||
      !newsData.post ||
      focusInput.post.errorMessage ||
      !newsData.type ||
      focusInput.type.errorMessage ||
      (!isEditClicked && (!newsData.image || focusInput.image.errorMessage))
    ) {
      for (const item of Object.values(NewsInputs)) {
        handleFormErrors(item, newsData[item]);
      }
      //image upload check validation
      if (!newsData.image?.match(/\.(png|jpe?g|svg)$/i)) {
        if (newsData.id && newsData.imageFile) {
          if (
            !newsData.imageFile ||
            !newsData.imageFile.name.match(/\.(png|jpe?g|svg)$/i)
          ) {
            setImgErrorMessage(invalidFileError);
          }
        } else if (
          !newsData.id &&
          !newsData.imageFile?.name.match(/\.(png|jpe?g|svg)$/i)
        ) {
          setImgErrorMessage(invalidFileError);
        }

        if (!newsData.id && !newsData.imageFile) {
          setImgErrorMessage(noFileProvidedError);
        } else if (newsData.id && !newsData.image) {
          setImgErrorMessage(noFileProvidedError);
        }
      }
      return false;
    }
    return true;
  };

  const handleDateSelect = (name: string, date: Date): void => {
    setNewsData({
      ...newsData,
      publishedDate: date.toISOString(),
    });
    if (focusInput[name as keyof IFocusInput]?.touched) {
      handleFormErrors(name, date.toString());
    }
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setNewsData({
      ...newsData,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleNewsSubmit = async (): Promise<void> => {
    if (!handleValidationOnSubmit()) return;
    dispatch(setSpinner(true));

    //this is to set imageFile and image name
    const checkIfImageChanged = newsData.image.startsWith("data:");
    let updatedImageFile: File | null = null;
    let updatedImage = newsData.image;

    if (checkIfImageChanged) {
      const blobResponse = await fetch(newsData.image);
      const blobData = await blobResponse.blob();
      updatedImageFile = new File([blobData], "image", { type: blobData.type });
    } else {
      updatedImage = imageFileName;
    }

    const body: INewsList = {
      id: newsData.id ?? 0,
      title: newsData.title,
      imageFile: updatedImageFile,
      excerpt: newsData.excerpt,
      published: newsData.published,
      publishedDate: newsData.publishedDate,
      post: newsData.post,
      image: updatedImage,
      type: newsData.type,
      tenantId: newsData.tenantId,
    };
    //adding the news
    if (!isEditClicked) {
      createNews(body, dispatch)
        .then((response) => {
          if (response) {
            dispatch(addToast("News created successfully") as AnyAction);
            closeAddEditRoleModal(true);
          }
        })
        .finally(() => {
          dispatch(setSpinner(false));
        });
    } else {
      //update news
      updateNews(body, dispatch)
        .then((response) => {
          if (response) {
            dispatch(addToast("News updated successfully") as AnyAction);
            closeAddEditRoleModal(true);
          }
        })
        .finally(() => {
          dispatch(setSpinner(false));
        });
    }
  };

  return {
    focusInput,
    isEditClicked,
    imgErrorMessage,
    languageText,
    newsData,
    newsTypeList,
    closeModal,
    handleNewsSubmit,
    handleDateSelect,
    handleDropdownSelect,
    handleBlurEvent,
    handleInputChange,
    handleTextAreaChange,
    onNewsTextEditorChange,
    handleCheckboxChange,
    handleFileUpload,
    removeIcon,
  };
};
