import React, { useEffect } from "react";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { useMultiFilters } from "./hooks";
import { Input } from "../../../components/formComponents/input";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import { ReactDatePicker } from "../../../components/datePicker/ReactDatePicker";
import { IFilterObject } from "../interface";

export interface IMultiFiltersProps {
  handleUpdatedFilterOptions?: (options: IFilterObject) => void;
}

export const MultiFilters = (props: IMultiFiltersProps): JSX.Element => {
  const { handleUpdatedFilterOptions } = props;

  const {
    languageText,
    filterOptions,
    handleInputChange,
    handleDateSelect,
    handleCheckboxChange,
    handleResetClick,
  } = useMultiFilters();

  useEffect(() => {
    if (handleUpdatedFilterOptions) {
      handleUpdatedFilterOptions(filterOptions);
    }
  }, [filterOptions]);

  return (
    <>
      <div>
        <form id="multi_filter_form" className="row g-3">
          <div className="col-md-2">
            <ReactDatePicker
              className="d-block"
              label="Start Date"
              placeholder={getLanguageValue(languageText, "Select Date")}
              name="startDate"
              date={filterOptions.startDate}
              handleDateSelect={handleDateSelect}
            />
          </div>
          <div className="col-md-2">
            <ReactDatePicker
              label="End Date"
              placeholder={getLanguageValue(languageText, "Select Date")}
              name="endDate"
              date={filterOptions.endDate}
              handleDateSelect={handleDateSelect}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="userName"
              label={getLanguageValue(languageText, "User Name")}
              placeholder={getLanguageValue(languageText, "User Name")}
              value={filterOptions && filterOptions.userName}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="serviceName"
              label={getLanguageValue(languageText, "Service Name")}
              placeholder={getLanguageValue(languageText, "Service Name")}
              value={filterOptions && filterOptions.serviceName}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="methodName"
              label={getLanguageValue(languageText, "Action")}
              placeholder={getLanguageValue(languageText, "Action")}
              value={filterOptions && filterOptions.methodName}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="browserInfo"
              label={getLanguageValue(languageText, "Browser")}
              placeholder={getLanguageValue(languageText, "Browser")}
              value={filterOptions && filterOptions.browserInfo}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="clientIpAddress"
              label={getLanguageValue(languageText, "IP Address")}
              placeholder={getLanguageValue(languageText, "IP Address")}
              value={filterOptions && filterOptions.clientIpAddress}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="minExecutionDuration"
              label={getLanguageValue(languageText, "Min Duration")}
              placeholder={getLanguageValue(languageText, "Min Duration")}
              value={filterOptions && filterOptions.minExecutionDuration}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <Input
              name="maxExecutionDuration"
              label={getLanguageValue(languageText, "Max Duration")}
              placeholder={getLanguageValue(languageText, "Max Duration")}
              value={filterOptions && filterOptions.maxExecutionDuration}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <ToggleCheckbox
              label={getLanguageValue(languageText, "Exceptions Only")}
              name="hasException"
              value={filterOptions.hasException}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={handleResetClick}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
