import React from "react";
import { useWelcomePage } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";

export const ParticipantWelcomePage = (): JSX.Element => {
  const { languageText, participantProfileDetails, handleNextClick } =
    useWelcomePage();

  return (
    <div className="p-2 pt-4 p-md-4">
      <h4 className="fw-bold">
        {`${getLanguageValue(languageText, "Hi")} ${participantProfileDetails.participantName}, 
          ${getLanguageValue(languageText, "Welcome to IDI")}`}
      </h4>
      <div className="shadow-sm rounded bg-white mt-4">
        <div className="p-4">
          <div className="container">
            <div className="d-flex flex-column flex-md-row align-items-center">
              {/* Image on the Left */}
              <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
                <img
                  src="/DeliveryImages/startpoint-1.webp"
                  className="img-fluid"
                  style={{ width: "60%" }} // Adjust width to make the image smaller
                  alt="Starting Point"
                />
              </div>

              {/* Text on the Right */}
              <div className="col-12 col-md-6">
                <h3 className="fw-bold mt-2">
                  {getLanguageValue(languageText, "A Specific Starting Point")}
                </h3>
                <div className="fs-5 my-3">
                  {getLanguageValue(languageText, "Developing soft skills")}
                </div>

                {/* Line Separator */}
                <div className="my-3">
                  <div
                    className="w-100"
                    style={{
                      height: "4px",
                      background:
                        "linear-gradient(to right, transparent, #e0e0e0, transparent)",
                    }}
                  ></div>
                </div>

                {/* Steps */}
                <div className="fs-5 mt-2">
                  <p className="mb-2">
                    {getLanguageValue(languageText, "Step1")}.
                  </p>
                  <p className="mb-2">
                    {getLanguageValue(languageText, "Step2")}.
                  </p>
                  <p className="mb-2">
                    {getLanguageValue(languageText, "Step3")}.
                  </p>
                  <p className="mb-2">
                    {getLanguageValue(languageText, "Step4")}.
                  </p>
                  <p className="mb-2">
                    {getLanguageValue(languageText, "Step5")}.
                  </p>
                  <p>{getLanguageValue(languageText, "Start your journey")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end bg-success-subtle rounded-bottom p-4">
          <button
            type="submit"
            className="btn btn-success btn-lg"
            onClick={handleNextClick}
          >
            {getLanguageValue(languageText, "Continue")}
            <i className="bi bi-arrow-right ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
