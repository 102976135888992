import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IRequestBody } from "./hooks";

export const getAllPlacements = async (
  body: IRequestBody,
  dispatch: Dispatch,
) => {
  try {
    const response = await api.get(apiEndPoints.getAllPlacements, {
      params: body,
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
