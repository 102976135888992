import { RoleName, Roles } from "../containers/commonEnums";

export function isLoggedInRoleAdmin(
  loggedInUserRole: RoleName | null,
): boolean {
  return loggedInUserRole === Roles.Admin;
}

export function isLoggedInRoleFacilitator(
  loggedInUserRole: RoleName | null,
): boolean {
  return loggedInUserRole === Roles.Facilitator;
}

export function isLoggedInRoleParticipant(
  loggedInUserRole: RoleName | null,
): boolean {
  return loggedInUserRole === Roles.Participant;
}
