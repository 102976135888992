import * as React from "react";

import { useParticipantProfilePage } from "./hooks";
import {
  getLanguageValue,
  TranslateFn,
} from "../../../../commonUtils/languageFunctionsHelper";
import { getDateWithMonthTimeFormat } from "../../../../commonUtils/dateFunctionsHelper";
import {
  CoursePresentationStatus,
  ProfileStatus,
} from "@app/containers/profileList/profileEnums";
import { Step, StepComponent } from "../../createProfilePage";
import {
  CompletedStepPanel,
  CurrentStepPanel,
  UpcomingStepPanel,
} from "../../panels";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { idiV2GetParticipantPresentationSlides } from "@app/containers/participantDelivery/actions";
import { IPresentationSlide } from "@app/containers/facilitatorDelivery/hooks";
import { SelfDeliveryScroller } from "@app/containers/presentationsList/previewSelfDelivery";
import { routePath } from "@app/containers/routePaths";

enum DeliveryKind {
  Facilitator,
  Self,
}

export const PresentationStep: StepComponent = (props) => {
  const dispatch = useDispatch();
  const { languageText, presentationData, handleJoinMeeting } =
    useParticipantProfilePage();
  const __t = props.__t;
  const title = __t("Profile presentation");
  const translate: TranslateFn = getLanguageValue.bind(undefined, languageText);

  const hasNoPresentations =
    presentationData.status === CoursePresentationStatus.Unknown ||
    presentationData.status === CoursePresentationStatus.Cancelled;

  const details = props.profile;
  const deliveryKind = details.selfDeliveryTemplateId
    ? DeliveryKind.Self
    : DeliveryKind.Facilitator;
  const [selfDeliverySlides, setSelfDeliverySlides] = React.useState<
    Array<IPresentationSlide>
  >([]);

  React.useEffect(() => {
    if (deliveryKind !== DeliveryKind.Self || props.status !== "current") {
      return;
    }

    const templateId = details.selfDeliveryTemplateId!;
    idiV2GetParticipantPresentationSlides(
      templateId,
      props.profile.profileId,
      props.languageCode,
      dispatch,
    ).then((res) => {
      setSelfDeliverySlides(res.items.slice());
    });
  }, [deliveryKind, props.status]);

  switch (props.status) {
    case "upcoming":
      return <UpcomingStepPanel title={title} />;
    case "current":
      return (
        <CurrentStepPanel __t={__t} title={title}>
          <div className="p-4">
            {deliveryKind === DeliveryKind.Facilitator && (
              <React.Fragment>
                {hasNoPresentations && (
                  <>{__t("Profile presentation is not planned yet")}.</>
                )}
                {!!presentationData.presentationDate && (
                  <>
                    <div className="fs-5 my-3">
                      {__t("A presentation of your profile is scheduled at")}:
                    </div>

                    <div className="fs-4 fw-bold mb-3">
                      {__t(presentationData.dayOfWeek)},{" "}
                      {getDateWithMonthTimeFormat(
                        presentationData.presentationDate,
                      )}
                    </div>

                    <button
                      className="btn btn-success"
                      onClick={handleJoinMeeting}
                      disabled={
                        presentationData.status !==
                        CoursePresentationStatus.Ongoing
                      }
                    >
                      {__t("Join the meeting")}
                    </button>
                  </>
                )}
              </React.Fragment>
            )}
            {deliveryKind === DeliveryKind.Self && (
              <SelfDeliveryScroller
                slides={selfDeliverySlides}
                onPositionChange={(pos, didMovePastEnd) => {
                  if (didMovePastEnd) {
                    // if the user moves backwards and re-watches the presentation
                    // the profile might already be delivered.
                    const needsDelivery =
                      props.profile.status !== ProfileStatus.Delivery;
                    const promise = needsDelivery
                      ? props.api.publishProfile(props.profile.profileId)
                      : Promise.resolve();

                    promise.then(() => props.setStep(Step.Profile));
                  }
                }}
                __t={translate}
              />
            )}
          </div>
        </CurrentStepPanel>
      );
    case "completed":
      return <CompletedStepPanel title={title} />;
  }
};
