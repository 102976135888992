import * as React from "react";
import { Step, StepComponent } from "./createProfilePage";
import { CurrentStepPanel, UpcomingStepPanel } from "./panels";
import { routePath } from "../routePaths";
import { IParticipantProfileReportHtml } from "../profileList/editProfile/interface";

export const ProfileStep: StepComponent = (props) => {
  const __t = props.__t;
  const title = __t("IDI-Profile");
  const isSelfDelivery = !!props.profile.selfDeliveryTemplateId;
  const [report, setReport] = React.useState<
    IParticipantProfileReportHtml | undefined
  >(undefined);

  React.useEffect(() => {
    if (props.status !== "current") {
      return;
    }
    props.api
      .getParticipantProfileReportHtml(
        props.profile.profileId,
        props.languageCode,
        "All",
      )
      .then(setReport);
  }, [props.status]);

  switch (props.status) {
    case "upcoming":
      return <UpcomingStepPanel title={title} />;
    case "current": {
      return (
        <CurrentStepPanel
          __t={__t}
          title={title}
          onPreviousClick={
            isSelfDelivery
              ? (event) => {
                  event.preventDefault();
                  props.setStep(Step.Presentation);
                }
              : undefined
          }
        >
          <div className="p-4">
            {!!report && (
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{ __html: report.html }}
              />
            )}

            <button
              className="btn btn-primary"
              onClick={(event) => {
                event.preventDefault();

                const url = routePath.participantProfileReport.replace(
                  ":profileId",
                  props.profile.profileId.toString(),
                );

                window.open(url, "__blank");
              }}
            >
              {__t("Go to profile")}
            </button>
          </div>
        </CurrentStepPanel>
      );
    }
    case "completed": {
      return null;
    }
  }
};
