import * as React from "react";
import { ReactTable } from "@app/components/reactTable/reactTable";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { setNotification, setSpinner } from "../actions";
import { CustomActionsCell } from "@app/components/reactTable/customActionsCell";
import { DeleteModal } from "@app/components/modals/deleteModal";
import { EditModal } from "./modal";
import { IEmailTemplate } from "./types";
import {
  createTemplate,
  deleteTemplate,
  getEmailTemplates,
  updateTemplate,
} from "./actions";

export const EmailTemplateList: React.FC<unknown> = (props) => {
  const dispatch = useDispatch();
  const isLanguageChanged: boolean = useSelector(
    (state: RootState) => state.mainReducer.isLanguageChanged,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const [data, setData] = React.useState<IEmailTemplate[]>([]);
  const [filter, setFilter] = React.useState<string>("");
  const [sorting, setSorting] = React.useState<string>("");
  const [totalCount, setTotalCount] = React.useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = React.useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = React.useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = React.useState<number>(0); // page_number_selected
  const [editingTemplate, setEditingTemplate] = React.useState<
    IEmailTemplate | undefined
  >(undefined);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState<number | undefined>(undefined);

  function handleEditClick(id: number): void {
    const item = data.find((t) => t.id === id);
    if (item) {
      setEditingTemplate(item);
    }
  }

  function handleDeleteClick(id: number): void {
    setDeleteId(id);
  }

  const tableHeader: Array<ColumnDef<IEmailTemplate>> = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <CustomActionsCell
          editOption
          deleteOption
          languageText={languageText}
          id={props.row.original.id}
          name={props.row.original.name}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(languageText, "Name"),
      accessorKey: "name",
    },
  ];
  function fetchEmailTemplates(skipCount: number): Promise<unknown> {
    dispatch(setSpinner(true));

    const query: unknown = {
      filter: filter,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: skipCount,
    };

    return getEmailTemplates(query)
      .then((response) => {
        const pageSize = Math.ceil(response.totalCount / pageLimit);
        setData(response.items.slice());
        setTotalCount(response.totalCount);
        setPageCount(pageSize);
      })
      .catch((e) => {
        dispatch(setNotification(e));
        return Promise.reject(e);
      })
      .finally(() => dispatch(setSpinner(false)));
  }

  function handleCreateOrUpdate(): void {
    if (!editingTemplate) {
      return;
    }

    dispatch(setSpinner(true));
    setIsSubmitting(true);

    const res = editingTemplate.id
      ? updateTemplate(editingTemplate.id, editingTemplate)
      : createTemplate(editingTemplate);

    res
      .then((res) => {
        fetchEmailTemplates(0);
      })
      .catch((e) => {
        dispatch(setNotification(e));
        return Promise.reject(e);
      })
      .finally(() => {
        dispatch(setSpinner(false));
        setIsSubmitting(false);
        setEditingTemplate(undefined);
      });
  }

  React.useEffect(() => {
    fetchEmailTemplates(pageSelected * pageLimit);
  }, [filter, sorting, pageSelected, pageLimit, isLanguageChanged]);

  function handleCloseEditModal() {
    setEditingTemplate(undefined);
  }

  function handleConfirmDelete() {
    if (!deleteId) {
      return;
    }
    dispatch(setSpinner(true));
    deleteTemplate(deleteId).finally(() => {
      dispatch(setSpinner(false));
      setDeleteId(undefined);
      setPageSelected(0);
      fetchEmailTemplates(0);
    });
  }

  function handleCloseDeleteModal() {
    setDeleteId(undefined);
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    if (!editingTemplate) {
      return;
    }
    const name = event.target.name as keyof IEmailTemplate;
    const next: IEmailTemplate = {
      ...editingTemplate,
      [name]: event.target.value,
    };
    setEditingTemplate(next);
  }

  return (
    <React.Fragment>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Email templates")}
        searchPlaceholder={getLanguageValue(languageText, "Search")}
        buttonText={getLanguageValue(languageText, "Add email template")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setEditingTemplate({
            name: "",
            content: "",
          });
        }}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />
      {editingTemplate ? (
        <EditModal
          close={handleCloseEditModal}
          kind={editingTemplate.id ? "edit" : "create"}
          languageText={languageText}
          isSubmitting={isSubmitting}
          onChange={handleInputChange}
          onSave={handleCreateOrUpdate}
          value={editingTemplate}
        />
      ) : undefined}

      {deleteId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, "Email template")}
          bodyText={data.find((t) => t.id === deleteId)?.name ?? ""}
          handleDeleteClick={handleConfirmDelete}
          closeDeleteModal={handleCloseDeleteModal}
        />
      ) : undefined}
    </React.Fragment>
  );
};
