import React from "react";

import { useDocumentList } from "./hooks";
import { AddEditDocumentModal } from "./addEditDocument";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ReactTable } from "../../../components/reactTable/reactTable";
import { DeleteModal } from "../../../components/modals/deleteModal";

export const AdminDocumentList = (): JSX.Element => {
  const {
    filter,
    editedRowId,
    deleteRowId,
    languageText,
    documents,
    openDocModal,
    pageCount,
    pageLimit,
    pageSelected,
    totalCount,
    tableHeader,
    selectedActionRowName,
    setFilter,
    setPageSelected,
    setSorting,
    setPageLimit,
    handleDeleteNews,
    addDocsClick,
    closeDocumentModal,
    closeDeleteModal,
  } = useDocumentList();

  let rowData = {};
  if (editedRowId) {
    rowData = documents.find((item) => item.id === editedRowId) ?? {};
  }

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Documents list")}
        searchPlaceholder={getLanguageValue(languageText, "Search Documents")}
        buttonText={getLanguageValue(languageText, "Add Document")}
        tableHeader={tableHeader}
        data={documents}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
        handleButtonClick={addDocsClick}
      />

      {openDocModal || editedRowId ? (
        <AddEditDocumentModal
          rowData={rowData}
          closeDocsModal={closeDocumentModal}
        />
      ) : (
        ""
      )}

      {deleteRowId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, "Document")}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteNews}
          closeDeleteModal={closeDeleteModal}
        />
      ) : (
        ""
      )}
    </>
  );
};
