import React from "react";
import clsx from "clsx";
import { ProfileId, UserId } from "@app/containers/reducer";

interface IEpNoOfRespondentsProps {
  id: string;
  profileId: ProfileId;
  noOfRespondents: number;
  respondentsInvited: number;
  respondentsAnswered: number;
  isActivityCancelled: boolean;
  isActivityInvoiced: boolean;
  onNoOfRespondentsClick: (profileId: ProfileId) => void;
}

export const EpNoOfRespondents = (
  props: IEpNoOfRespondentsProps,
): JSX.Element => {
  const {
    id,
    profileId,
    noOfRespondents,
    respondentsInvited,
    respondentsAnswered,
    isActivityCancelled,
    isActivityInvoiced,
    onNoOfRespondentsClick,
  } = props;

  let respInvitedPercent = (respondentsInvited / noOfRespondents) * 100;
  let respAnsweredPercent = (respondentsAnswered / noOfRespondents) * 100;

  if (respInvitedPercent > 100) respInvitedPercent = 100;
  if (respAnsweredPercent > 100) respAnsweredPercent = 100;

  return (
    <div
      id={id}
      className={clsx(
        "bg-dark bg-opacity-10 rounded p-2 px-3",
        !isActivityCancelled && !isActivityInvoiced && "cursor-pointer",
      )}
      onClick={() =>
        !isActivityCancelled &&
        !isActivityInvoiced &&
        onNoOfRespondentsClick(profileId)
      }
    >
      <div className="pt-md-1 text-center">
        <span className="fs-6 fw-bold">{`${respondentsAnswered ?? 0}/${
          respondentsInvited ?? 0
        } (${noOfRespondents})`}</span>
      </div>
      <div className={clsx("ep-progress-bar align-bottom align-content-end")}>
        <div
          className="ep-progress-bar-1"
          style={{ width: `${respInvitedPercent}%` }}
        />
        <div
          className="ep-progress-bar-2"
          style={{ width: `${respAnsweredPercent}%` }}
        />
      </div>
    </div>
  );
};
