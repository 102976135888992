const getCookieValue = (name: string) =>
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || "";

export function isLoggedIn(): boolean {
  // FIXME:
  // usually the backend gives us an 'logged_in' cookie, but if we're
  // running a localhost frontend against a non-localhost backend we
  // need it in localStorage.
  //
  // a better solution would be to just send a request to the backend
  // and see if we're logged in.
  //   -johan, 2024-09-11
  return (
    !!getCookieValue("logged_in") || !!window.localStorage.getItem("logged_in")
  );
}
