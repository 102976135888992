import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store/configureStore";
import { TermsFooter } from "../../components/termsFooter/termsFooter";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { RespondentLanguageNavbar } from "./respondentLanguageNavbar/respondentLanguageNavbar";
import { useApiEndpoints } from "@app/api/end-points";

export const RespondentThankYouPage = (): JSX.Element => {
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const respondentCode: string = useSelector(
    (state: RootState) => state.respondentReducer.respondentCode,
  );
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const [title, setTitle] = useState<string>("");
  const [validLink, setValidLink] = useState(true);

  useEffect(() => {
    if (!respondentCode) {
      return;
    }
    api
      .checkValidParticipantOrRespondentLink(respondentCode)
      .then((response) => {
        if (response.isFormFilled) {
          setTitle("The form is filled. Thank you!");
        } else {
          setValidLink(false);
          setTitle("This link is no longer valid");
        }
      });
  }, [respondentCode]);

  return (
    <div className="container my-md-5">
      <div className="row p-3">
        <div className="col-lg-10 rounded shadow mx-auto p-md-5 p-4 bg-white mb-3">
          <RespondentLanguageNavbar
            languageText={languageText}
            hideLanguageSelection={true}
          />
          <div className="my-5">
            <h1 className="fs-4 fw-bold">
              {getLanguageValue(languageText, title)}
            </h1>
            {!validLink && (
              <p>
                {getLanguageValue(
                  languageText,
                  "The link you try to access is no longer valid. This could be due to a couple of reasons, like the profile is already completed or you have been removed as a respondent. Please reach out to the person you where to fill out the form for.",
                )}
              </p>
            )}
          </div>

          <div className="row">
            <div className="col-12 col-md-7 order-2 order-md-1">
              <h2 className="fw-bold mt-3 mt-md-0 d-none d-md-block">
                {getLanguageValue(languageText, "About Us")}
              </h2>
              <div className="fs-5 mt-3">
                {getLanguageValue(
                  languageText,
                  /* NOTE: this is a long text block with a shortened key. */
                  "Thank you page text",
                )}
                .
              </div>
              <div className="fs-5 mt-3">
                {getLanguageValue(
                  languageText,
                  `Want to dive deeper? Head over to our website!`,
                )}
              </div>
              <a
                className="btn btn-success my-4 w-50"
                href="https://www.idi.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                {getLanguageValue(languageText, "Visit our website!")}
              </a>
            </div>

            <h2 className="fw-bold mb-4 d-md-none">
              {getLanguageValue(languageText, "About Us")}
            </h2>

            <div className="col-12 col-md-5 order-1 order-md-2">
              <img
                src="/DeliveryImages/Functional-role-5.webp"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <TermsFooter languageText={languageText} />
      </div>
    </div>
  );
};
