import React from "react";

import { Input } from "../../../../components/formComponents/input";
import { TextEditor } from "../../../../components/textEditor/textEditor";
import { TextArea } from "../../../../components/formComponents/textArea";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import { useAddEditSlideModal } from "./hooks";
import { ModalComponent } from "../../../../components/modals/modalComponent";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";

export interface IAddEditSlideModalProps {
  slideId: number;
  templateId: number;
  refreshSlideList: () => void;
  closeSlideModal: () => void;
}

export const AddEditSlideModal = (props: IAddEditSlideModalProps) => {
  const {
    languageText,
    isEditClicked,
    SlideInputs,
    slideInfo,
    focusInput,
    handleInputChange,
    handleTextEditorChange,
    handleTextAreaChange,
    handleBlurEvent,
    handleCheckboxChange,
    handleSaveClick,
  } = useAddEditSlideModal(props);

  return (
    <>
      <ModalComponent
        width="fullscreen"
        headerText={getLanguageValue(
          languageText,
          isEditClicked ? "Edit Slide" : "Create Slide",
        )}
        cancelButtonText={getLanguageValue(languageText, "Cancel")}
        submitButtonText={getLanguageValue(languageText, "Save")}
        handleCancelClick={props.closeSlideModal}
        handleSubmitClick={handleSaveClick}
      >
        <Input
          name={SlideInputs.slideNumber}
          label={getLanguageValue(languageText, "Slide No.")}
          placeholder={getLanguageValue(languageText, "Slide No.")}
          type="number"
          value={slideInfo.slideNumber}
          errorMessage={focusInput.slideNumber.errorMessage}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
        <Input
          name={SlideInputs.partNumber}
          label={getLanguageValue(languageText, "Part No.")}
          placeholder={getLanguageValue(languageText, "Part No.")}
          type="number"
          value={slideInfo.partNumber}
          errorMessage={focusInput.partNumber.errorMessage}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
        <Input
          name={SlideInputs.description}
          label={getLanguageValue(languageText, "Description")}
          placeholder={getLanguageValue(languageText, "Description")}
          value={slideInfo.description}
          errorMessage={focusInput.description.errorMessage}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
        <TextEditor
          name={SlideInputs.html}
          label={getLanguageValue(languageText, "HTML")}
          placeholder={getLanguageValue(languageText, "HTML")}
          value={slideInfo.html}
          focusInput={focusInput.html}
          handleTextEditor={handleTextEditorChange}
          required
        />
        <TextArea
          name={SlideInputs.rules}
          label={getLanguageValue(languageText, "Rules")}
          placeholder={getLanguageValue(languageText, "Rules")}
          value={slideInfo.rules}
          onChange={handleTextAreaChange}
          checkMarkNotRequired={true}
          rows={8}
        />
        <TextArea
          name={SlideInputs.notes}
          label={getLanguageValue(languageText, "Notes")}
          placeholder={getLanguageValue(languageText, "Notes")}
          value={slideInfo.notes}
          onChange={handleTextAreaChange}
          checkMarkNotRequired={true}
          rows={8}
        />
        <ToggleCheckbox
          name={SlideInputs.isActive}
          label={getLanguageValue(languageText, "Active")}
          value={slideInfo.isActive}
          handleCheckboxChange={handleCheckboxChange}
        />
      </ModalComponent>
    </>
  );
};
