import React from "react";

import { EditProfileDescriptionStep } from "./descriptionStep/editProfileDescriptionStep";
import { EditActivityRoleStep } from "./roleStep/editActivityRoleStep";
import { EditProfileRespondentStep } from "./respondentStep/editProfileRespondentStep";
import { IRoleStepData } from "../../addProfile/activityRoleStep";
import { IEditActivity } from "../interface";
import { ITranslationObject } from "../../../../commonUtils/languageFunctionsHelper";
import { GuidePages } from "@app/containers/commonEnums";
import { ActivityId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IEditActivityFirstStepProps {
  id: ActivityId;
  languageText: ITranslationObject;
  clientIdFromAccount: number;
  profileData: IEditActivity;
  cultures: ReadonlyArray<ILanguage>;
  isActivityCancelled: boolean;
  isActivityInvoiced: boolean;
  handleProfileCancel: () => void;
  onBillingButtonClick: () => void;
  handleInstructionHelpClick: (guidePage: GuidePages) => void;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  handleClientSelect: (
    clientId: number,
    clientName: string,
    isFacilitatorRemoved: boolean,
  ) => void;
  handleLanguageSelect: (languageId: number) => void;
  handleRoleChanges: (roleSettings: IRoleStepData) => void;
  refetchFullProfile: () => void;
}

export const EditActivityFirstStep = (props: IEditActivityFirstStepProps) => {
  return (
    <>
      <EditProfileDescriptionStep
        id={props.id}
        languageText={props.languageText}
        clientIdFromAccount={props.clientIdFromAccount}
        profileData={props.profileData}
        cultures={props.cultures}
        isActivityCancelled={props.isActivityCancelled}
        isActivityInvoiced={props.isActivityInvoiced}
        handleProfileCancel={props.handleProfileCancel}
        onBillingButtonClick={props.onBillingButtonClick}
        handleInstructionHelpClick={props.handleInstructionHelpClick}
        handleExitGuide={props.handleExitGuide}
        handleClientSelect={props.handleClientSelect}
        handleLanguageSelect={props.handleLanguageSelect}
        refetchFullProfile={props.refetchFullProfile}
      />
      <EditActivityRoleStep
        id={props.id}
        languageText={props.languageText}
        profileData={props.profileData}
        isActivityCancelled={props.isActivityCancelled}
        isActivityInvoiced={props.isActivityInvoiced}
        handleRoleChanges={props.handleRoleChanges}
      />
      <EditProfileRespondentStep
        id={props.id}
        languageText={props.languageText}
        profileData={props.profileData}
        isActivityCancelled={props.isActivityCancelled}
        isActivityInvoiced={props.isActivityInvoiced}
      />
    </>
  );
};
