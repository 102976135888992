import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { RoleSettingsWithEditOption } from "../../../addProfile/roleSettings/roleSettingsWithEditOption";
import { ProfileId } from "@app/containers/reducer";

interface IProfileParticipantRoleProps {
  languageText: ITranslationObject;
  profileId: ProfileId;
  status: number;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  respondentsAnswered?: number;
  refetchParticipants: () => void;
  refetchOngoingParticipants?: () => void;
  refetchCompletedParticipants?: () => void;
}

export const ProfileParticipantRole = (
  props: IProfileParticipantRoleProps,
): JSX.Element => {
  return (
    <div id="editParticipantRole" className="mt-2">
      <div className="fs-5 fw-bold mb-3">
        {getLanguageValue(props.languageText, "Participant Role")}
      </div>

      <RoleSettingsWithEditOption {...props} canEditNoOfRespondents={true} />
      <div className="border-bottom my-4" />
    </div>
  );
};
