import React from "react";
import { Route, Routes } from "react-router-dom";

import { useRespondentRoute } from "./hooks";

import { LanguagePageComponent } from "../../../components/languagePageComponent/languagePageComponent";
import { RespondentStartPage } from "../../respondentPage/respondentStartPage";
import { RespondentSelfForm } from "../../respondentPage/respondentSelfForm/respondentSelfForm";
import { RespondentThankYouPage } from "../../respondentPage/respondentThankYouPage";

export const RespondentRoutes = (): JSX.Element => {
  const { languageText, languages, handleChangeLanguage } =
    useRespondentRoute();

  return (
    <>
      <Routes>
        <Route
          path={"/:c"}
          element={
            <LanguagePageComponent
              languageText={languageText}
              languages={languages}
              handleChangeLanguage={handleChangeLanguage}
            />
          }
        />
        <Route path={"/:c/startPage"} element={<RespondentStartPage />} />
        <Route path={"/:c/selfForm"} element={<RespondentSelfForm />} />
        <Route path={"/:c/thankYou"} element={<RespondentThankYouPage />} />
      </Routes>
    </>
  );
};
