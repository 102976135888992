import React, { useState } from "react";
import clsx from "clsx";
import { PopoverTooltip } from "../tooltip/popoverTooltip";
import { classNames } from "@app/containers/utils";

export interface IFocusError {
  touched: boolean;
  errorMessage: string;
}

interface IInputProps {
  label?: string;
  id?: string;
  required?: boolean;
  type?: "text" | "number" | "password" | "new-password" | "date";
  placeholder?: string;
  name?: string;
  value?: string | number;
  tooltipText?: string;
  disable?: boolean;
  errorMessage?: string;

  /** @deprecated This doesn't make any sense. Remove. */
  requireLeadingZero?: boolean;
  checkMarkNotRequired?: boolean;
  invalidMarkNotRequired?: boolean;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurEvent?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => unknown;

  /** Removes the 'mb-3' margin on the container. */
  narrow?: boolean;
  size?: "sm" | "md" | "lg";
  inputRef?: React.RefObject<HTMLInputElement>;
  tabIndex?: number;
  inputGroupLeft?: React.ReactNode;
  inputGroupRight?: React.ReactNode;
  inputClass?: string;
}

export enum KeyCode {
  Unknown = 0,
  Backspace = 8,
  Enter = 13,
  Escape = 27,
  ArrowUp = 38,
  ArrowDown = 40,
}

export const Input = (props: IInputProps): JSX.Element => {
  const [showClearTextPassword, setShowClearTextPassword] =
    useState<boolean>(false);

  const handleToggleShowPassword = (): void => {
    setShowClearTextPassword(!showClearTextPassword);
  };

  // Prevent the default scroll behavior for number type fields
  const handleWheelEvent = (e: React.WheelEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const onNumberInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const numberRegex = /^([0-9]+)$/;
    let value = e.target.value;

    // Remove leading zero if it exists
    //
    // FIXME: what the fudge does this mean? why are we modifying user input?
    //   if we don't give this component 'requireLeadingZero' the user can't
    //   type zeros. what does that have to do with requiring leading zeroes?
    //   -johan, 2024-09-17
    if (!props.requireLeadingZero && value[0] === "0") {
      value = value.slice(1);
    }

    if (numberRegex.test(value) || value === "") {
      e.target.value = value;
      props.handleInputChange?.(e);
    }
  };

  const wantsPasswordIcon =
    props.type === "password" || props.type === "new-password";
  let inputType: string = props.type || "text";

  switch (inputType) {
    case "password":
    case "new-password":
      inputType = showClearTextPassword ? "text" : "password";
      break;
    case "number":
      inputType = "text"; // huh?
      break;
  }

  const clazz = classNames({
    "mb-3": !props.narrow,
  });
  const inputClazz = classNames({
    "form-control": true,
    "form-control-sm": props.size === "sm",
    "form-control-lg": props.size === "lg",
    [props.inputClass || ""]: true,
  });

  return (
    <div className={clazz}>
      {props.label && (
        <React.Fragment>
          <label className={clsx("mb-1", { required: props.required })}>
            {props.label}
          </label>
          {props.tooltipText && (
            <PopoverTooltip tooltipText={props.tooltipText} />
          )}
        </React.Fragment>
      )}
      <div className="input-group">
        {props.inputGroupLeft && (
          <span className="input-group-text">{props.inputGroupLeft}</span>
        )}
        <input
          className={inputClazz}
          type={inputType}
          placeholder={props.placeholder ?? "Type here"}
          name={props.name}
          value={props.value}
          onChange={
            props.type === "number"
              ? onNumberInputChange
              : props.handleInputChange
          }
          autoComplete={props.type === "new-password" ? "new-password" : "off"}
          disabled={props.disable}
          onBlur={props.handleBlurEvent}
          onWheel={handleWheelEvent}
          onKeyDown={props.handleKeyDown}
          id={props.id}
          ref={props.inputRef}
          tabIndex={props.tabIndex}
        />
        {props.inputGroupRight && (
          <span className="input-group-text">{props.inputGroupRight}</span>
        )}
        {wantsPasswordIcon && (
          <div
            className="input-group-text"
            onClick={handleToggleShowPassword}
            role="button"
            title="Show password"
          >
            {showClearTextPassword ? (
              <i className="bi bi-eye" />
            ) : (
              <i className="bi bi-eye-slash" />
            )}
          </div>
        )}
      </div>
      {props.errorMessage && (
        <div className="invalid-feedback d-inline-block">
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};
