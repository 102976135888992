import React from "react";

import { ReactTable } from "../../components/reactTable/reactTable";
import { DeleteModal } from "../../components/modals/deleteModal";
import { AddEditProfileDescriptionModal } from "./addEditModal/addEditProfileDescriptionModal";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { useProfileDescription } from "./hooks";

export const ProfileDescription = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openProfileDescriptionModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addProfileDecriptionClick,
    refetchProfileDescriptionList,
    closeProfileDecriptionModal,
    handleDeleteProfileDecription,
    closeDeleteModal,
  } = useProfileDescription();

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Profile Description list")}
        searchPlaceholder={getLanguageValue(
          languageText,
          "Search Profile Description",
        )}
        buttonText={getLanguageValue(languageText, "Add Profile Description")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
        handleButtonClick={addProfileDecriptionClick}
      />

      {openProfileDescriptionModal || editedRowId > 0 ? (
        <AddEditProfileDescriptionModal
          editId={editedRowId}
          closeProfileDecriptionModal={closeProfileDecriptionModal}
          refetchProfileDescriptionList={refetchProfileDescriptionList}
        />
      ) : (
        ""
      )}

      {deleteRowId > 0 && (
        <DeleteModal
          languageText={languageText}
          headerText={""}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteProfileDecription}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </>
  );
};
