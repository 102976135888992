import React from "react";
import clsx from "clsx";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";

interface IRespondentsStatusProgressBarProps {
  languageText: ITranslationObject;
  noOfRespondents: number;
  respondentsInvited: number;
  respondentsAnswered: number;
}

export const RespondentsStatusProgressBar = (
  props: IRespondentsStatusProgressBarProps,
) => {
  const isAllInvited = props.respondentsInvited >= props.noOfRespondents;
  const isAllAnswered = props.respondentsAnswered >= props.noOfRespondents;

  return (
    <>
      <div className="d-flex gap-2">
        <div
          className={clsx(
            "rounded col-3 p-1",
            isAllInvited ? "bg-success" : "bg-secondary-subtle",
          )}
        />
        <div
          className={clsx(
            "rounded col-3 p-1",
            isAllInvited && isAllAnswered
              ? "bg-success"
              : "bg-secondary-subtle",
          )}
        />
      </div>
      <div className="d-flex my-3 gap-2 fs-6">
        <div className="col-3 d-flex align-items-center">
          {isAllInvited ? (
            <i className="bi bi-check-circle-fill me-2 text-success" />
          ) : (
            <i className="bi bi-x-circle-fill me-2 text-danger" />
          )}
          {props.respondentsInvited}/{props.noOfRespondents}{" "}
          {getLanguageValue(props.languageText, "invited")}.
        </div>
        <div className="col-3 d-flex align-items-center">
          {isAllAnswered ? (
            <i className="bi bi-check-circle-fill me-2 text-success" />
          ) : (
            <i className="bi bi-x-circle-fill me-2 text-danger" />
          )}
          {props.respondentsAnswered}/{props.noOfRespondents}{" "}
          {getLanguageValue(props.languageText, "received")}.
        </div>
      </div>
    </>
  );
};
