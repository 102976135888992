import React from "react";
import clsx from "clsx";

interface ICourseProgressBarProps {
  id: string;
  progress: number;
  bgColor: string;
  className?: string;
}

export const CourseProgressBar = (
  props: ICourseProgressBarProps,
): JSX.Element => {
  const { id, progress, bgColor, className } = props;

  return (
    <div
      id={id}
      className={clsx("w-100 progress", bgColor, className && className)}
      role="progressbar"
      aria-valuemin={0}
      aria-valuenow={progress}
      aria-valuemax={100}
    >
      <div
        className={`progress-bar p-2 ${
          progress == 100
            ? "bg-success"
            : progress <= 50
              ? "bg-warning"
              : "bg-success bg-opacity-50"
        }`}
        style={{ width: `${progress.toFixed(0)}%`, minWidth: "1px" }}
      >
        {progress.toFixed(0)}%
      </div>
    </div>
  );
};
