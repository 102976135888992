import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store/configureStore";
import { IRespondentData } from "../reducer";
import { respondentAuthenticate } from "../actions";
import { CheckValidProfileOrRespondentLink } from "@app/types";

export const useRespondentSelfForm = () => {
  const dispatch = useDispatch();
  const respondentData: CheckValidProfileOrRespondentLink = useSelector(
    (state: RootState) => state.respondentReducer.respondentData,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [respondentToken, setRespondentToken] = useState<string>("");

  useEffect(() => {
    if (respondentData.profileRespondentId) {
      respondentAuthenticate(
        respondentData.emailAddress,
        respondentData.profileRespondentId,
        dispatch,
      ).then((response) => {
        setRespondentToken(response.accessToken?.token);
      });
    }
  }, [respondentData.profileRespondentId]);

  return {
    languageText,
    respondentData,
    respondentToken,
  };
};
