import React from "react";

import { Input } from "../../formComponents/input";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  IInvoiceFocusInput,
  IProductData,
  ProductInput,
} from "../invoiceInterface";

interface IAddEditProduct {
  languageText: ITranslationObject;
  isEditMode: boolean;
  productFormData: IProductData;
  productsList: IDropdownList[];
  focusInput: IInvoiceFocusInput;
  handleProductInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleProductSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleAddProduct: () => void;
  handleSaveProduct: () => void;
  handleCancelProduct: () => void;
}

export const AddEditProduct = (props: IAddEditProduct) => {
  return (
    <div className="row border rounded align-items-center m-0 mb-3 pt-2">
      <div className="col-lg-3 col-12">
        <DropdownSelect
          name={ProductInput.articleId}
          label={getLanguageValue(props.languageText, "Product")}
          list={props.productsList}
          defaultLabel={getLanguageValue(props.languageText, "Select Product")}
          value={
            props.productFormData.articleId
              ? String(props.productFormData.articleId)
              : ""
          }
          focusInput={props.focusInput.articleId}
          handleDropdownSelect={props.handleProductSelect}
          searchOption
          required
        />
      </div>
      <div className="col-lg-3 col-12">
        <Input
          name={ProductInput.description}
          label={getLanguageValue(props.languageText, "Details")}
          placeholder={getLanguageValue(props.languageText, "Details")}
          checkMarkNotRequired={true}
          value={props.productFormData.description ?? ""}
          errorMessage={props.focusInput.description.errorMessage}
          handleInputChange={props.handleProductInputChange}
          handleBlurEvent={props.handleBlurEvent}
          required
        />
      </div>

      <div className="col-lg-1 col-4">
        <Input
          name={ProductInput.quantity}
          type="number"
          label={getLanguageValue(props.languageText, "Quantity")}
          placeholder={getLanguageValue(props.languageText, "Quantity")}
          value={props.productFormData.quantity ?? 0}
          errorMessage={props.focusInput.quantity.errorMessage}
          handleInputChange={props.handleProductInputChange}
          handleBlurEvent={props.handleBlurEvent}
          requireLeadingZero
          checkMarkNotRequired
          invalidMarkNotRequired
          required
        />
      </div>
      <div className="col-lg-2 col-8">
        <Input
          name={ProductInput.price}
          type="number"
          label={getLanguageValue(props.languageText, "Price")}
          placeholder={getLanguageValue(props.languageText, "Price")}
          value={props.productFormData.price ?? 0}
          errorMessage={props.focusInput.price.errorMessage}
          handleInputChange={props.handleProductInputChange}
          handleBlurEvent={props.handleBlurEvent}
          requireLeadingZero
          checkMarkNotRequired
          invalidMarkNotRequired
          required
        />
      </div>
      <div className="col-lg-1 col-4">
        <Input
          name={ProductInput.discount}
          type="number"
          label={getLanguageValue(props.languageText, "Discount")}
          placeholder={getLanguageValue(props.languageText, "Discount")}
          value={props.productFormData.discount ?? 0}
          handleInputChange={props.handleProductInputChange}
          requireLeadingZero
          checkMarkNotRequired
          invalidMarkNotRequired
        />
      </div>
      <div className="col-lg-2 col-8">
        {!props.isEditMode ? (
          <button
            onClick={props.handleAddProduct}
            className="btn btn-success mt-8 w-75"
          >
            {getLanguageValue(props.languageText, "Add")}
          </button>
        ) : (
          <div className="d-flex gap-2 mt-2">
            <button
              onClick={props.handleSaveProduct}
              className="btn btn-success btn-sm"
            >
              {getLanguageValue(props.languageText, "Save")}
            </button>
            <button
              onClick={props.handleCancelProduct}
              className="btn btn-secondary btn-sm"
            >
              {getLanguageValue(props.languageText, "Cancel")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
