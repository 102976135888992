import React from "react";
import clsx from "clsx";

import { IProfileHeaderProps, useCurrentUserData } from "./hooks";

export const ProfileHeader = (): JSX.Element => {
  const { profileElements, activePage, userDetails, handleTabToggle } =
    useCurrentUserData();

  return (
    <React.Fragment>
      <h1 className="fs-4 fw-bold">
        {userDetails.name} {`/ ${activePage}`}
      </h1>

      <ul className="nav nav-tabs mt-3 mb-4">
        {profileElements.map(({ id, name, href }: IProfileHeaderProps) => (
          <li key={id} className="nav-item">
            <a
              className={clsx("nav-link", {
                active: location.pathname === href,
              })}
              onClick={() => handleTabToggle(href)}
              href="#"
            >
              {name}
            </a>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};
