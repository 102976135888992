import React from "react";
import clsx from "clsx";

import { KTSVG } from "@app/commonUtils/metronic";

interface IToggleCardCheckboxProps {
  header: string;
  description: string;
  checked: boolean;
  icon?: string;
  containerClass?: string;
  additionalInfoHeader?: string;
  strikeThroughHeader?: string;
  additionalInfoDescription?: string;
  handleClick: () => void;
}

export const ToggleCardCheckbox = (props: IToggleCardCheckboxProps) => {
  return (
    <div className={props.containerClass}>
      <div
        onClick={props.handleClick}
        className={clsx(
          "p-3 border rounded",
          props.checked ? "border-success" : "",
        )}
        role="button"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {props.icon && (
              <KTSVG
                path={props.icon}
                className="svg-icon-1 me-4 svg-icon-dark"
              />
            )}
            <div className="d-flex flex-column">
              <h5>{props.header}</h5>
              <span className="text-secondary">{props.description}</span>
            </div>
          </div>
          <i
            className={clsx(
              "bi fs-3",
              props.checked
                ? "text-success  bi-check-circle-fill"
                : "bi-circle",
            )}
          />
        </div>
        {props.additionalInfoHeader && (
          <div className="mt-2">
            <span className="fw-bold me-2">{props.additionalInfoHeader}</span>
            {props.strikeThroughHeader && (
              <s className="me-2">{props.strikeThroughHeader}</s>
            )}
            <span className="text-secondary fw-bold">
              {props.additionalInfoDescription}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
