import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { PresentationInputKeys } from "./hooks";
import { ModalComponent } from "@app/components/modals/modalComponent";

interface ISendPresentationInvitationModalProps {
  languageText: ITranslationObject;
  handleCloseInvitationModal: () => void;
  handleSaveClick: (buttonClicked: keyof typeof PresentationInputKeys) => void;
}

export const SendPresentationInvitationModal = (
  props: ISendPresentationInvitationModalProps,
): JSX.Element => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Send invitation")}
        submitButtonText={getLanguageValue(props.languageText, "Send")}
        secondarySubmitButtonText={getLanguageValue(
          props.languageText,
          `${`Don't send`}`,
        )}
        cancelButtonText={getLanguageValue(props.languageText, "Back")}
        secondarySubmitButtonColor="btn-secondary text-white"
        handleSubmitClick={() =>
          props.handleSaveClick(PresentationInputKeys.send)
        }
        handleSecondarySubmitClick={() =>
          props.handleSaveClick(PresentationInputKeys.dontSend)
        }
        handleCancelClick={props.handleCloseInvitationModal}
      >
        {getLanguageValue(
          props.languageText,
          "Would you like to send invitations to the participants at this time or at a later stage",
        )}
        ?
      </ModalComponent>
    </>
  );
};
