import { IClients } from "@app/containers/commonInterfaces";
import { IFocusError } from "../formComponents/input";
import { IEditActivity } from "@app/containers/profileList/editProfile/interface";
import { IClientSubscription } from "@app/containers/clientList/hooks";
import { ActivityId } from "@app/containers/reducer";

export const InvoiceInputs = {
  company: "company",
  invoiceReference: "invoiceReference",
  address: "address",
  city: "city",
  zip: "zip",
  countryId: "countryId",
  businessIdentification: "businessIdentification",
  email: "email",
  telephone: "telephone",
  fortnoxCustomerNumber: "fortnoxCustomerNumber",
  fortnoxInvoiceNumber: "fortnoxInvoiceNumber",
  euvatid: "euvatid",
} as const;

export const ProductInput = {
  articleId: "articleId",
  price: "price",
  quantity: "quantity",
  description: "description",
  sum: "sum",
  discount: "discount",
} as const;

export enum FortnoxInvoiceStatus {
  Booked = 1,
  UnBooked = 2,
  FullyPaid = 3,
  UnPaid = 4,
  UnPaidOverdue = 5,
  Cancelled = 6,
}

export interface IInvoiceExistingAddress {
  company: string;
  businessIdentification: string;
  invoiceReference: string;
  email: string;
  telephone: string;
  address: string;
  city: string;
  zip: string;
  countryId: number;
  country: string;
  euvatid: string;
}

export interface IProductData {
  idiInvoiceId: number;
  id: number;
  articleId: number;
  articleNumber: string;
  description: string;
  quantity: number;
  price: number;
  discount: number;
  sum: number;
  taxPercentage: number;
  temporaryId?: number;
}

export interface IDIInvoice {
  idiClientId: number;
  activityId: ActivityId;
  clientSubscriptionId?: number;
  idiLanguageId: number;
  date: string;
  company: string;
  address: string;
  city: string;
  zip: string;
  country: number;
  fortnoxInvoiceNumber: number;
  fortnoxCustomerNumber: number;
  fortnoxCustomerWithName: string;
  reference: string;
  businessIdentification: string;
  email: string;
  telephone: string;
  euvatid: string;
  subTotal: number;
  taxAmount: number;
  total: number;
  currency: string;
  linkedToExistingFortnoxInvoice: boolean;
  fortnoxInvoiceStatus: FortnoxInvoiceStatus | null;
  id: number;
  invoice: boolean;
  creditCard: boolean;
  client?: IClients;
  clientSubscription?: IClientSubscription;
  activity?: IEditActivity;
}

export interface IProfileInvoiceDetails {
  name: string;
  clientId: number;
  status: 1;
  idiInvoice: IDIInvoice;
  profileInvoiceRows: IProductData[];
  id: ActivityId;
}

export interface ISubscriptionInvoiceDetails {
  idiInvoice: IDIInvoice;
  clientSubscriptionInvoiceRows: IProductData[];
  id: number;
}

export interface IInvoiceFormData {
  paymentId: number;
  company: string;
  businessIdentification: string;
  invoiceReference: string;
  email: string;
  telephone: string;
  address: string;
  city: string;
  zip: string;
  countryId: string;
  euvatid: string;
  invoiceId: number;
  fortnoxCustomerNumber: string;
  fortnoxInvoiceNumber: string;
  date: string;
  currency: string;
  profileInvoiceRows: IProductData[];
}

export interface IInvoiceFocusInput {
  company: IFocusError;
  invoiceReference: IFocusError;
  address: IFocusError;
  city: IFocusError;
  zip: IFocusError;
  countryId: IFocusError;
  businessIdentification: IFocusError;
  email: IFocusError;
  telephone: IFocusError;
  articleId: IFocusError;
  price: IFocusError;
  quantity: IFocusError;
  description: IFocusError;
  sum: IFocusError;
  fortnoxCustomerNumber: IFocusError;
  fortnoxInvoiceNumber: IFocusError;
  discount: IFocusError;
}
