import React from "react";

import { DropdownInputs, useDemographicPage } from "./hooks";
import { DropdownSelect } from "@app/components/formComponents/dropdownSelect";
import { Input } from "../../../../components/formComponents/input";
import { StepComponent } from "../../createProfilePage";
import {
  CompletedStepPanel,
  CurrentStepPanel,
  UpcomingStepPanel,
} from "../../panels";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";

export const DemographicPage: StepComponent = (props) => {
  const {
    languageText,
    ageDropdownList,
    genderList,
    sectorList,
    seniorityList,
    educationList,
    demographicInfo,
    handleDropdownSelect,
    handleInputChange,
    goToPrevious,
    onSubmitClick,
  } = useDemographicPage(props);

  const __t = props.__t;
  const title = __t("Voluntary demographic questions");

  switch (props.status) {
    case "upcoming":
      return null;
    case "current":
      return (
        <CurrentStepPanel
          __t={__t}
          title={title}
          onPreviousClick={goToPrevious}
          onNextClick={onSubmitClick}
        >
          <form className="">
            <div className="row p-4 pb-3">
              <div className="col-12 mb-4 pb-1">
                {getLanguageValue(
                  languageText,
                  "Please answer the voluntary demographic questions below and contribute to our statistics",
                )}
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  label={getLanguageValue(languageText, "Age")}
                  defaultLabel={getLanguageValue(languageText, "Select age")}
                  name={DropdownInputs.age}
                  list={ageDropdownList}
                  handleDropdownSelect={handleDropdownSelect}
                  value={demographicInfo.age}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  label={getLanguageValue(languageText, "Legal Gender")}
                  defaultLabel={getLanguageValue(languageText, "Select gender")}
                  name={DropdownInputs.gender}
                  list={genderList}
                  handleDropdownSelect={handleDropdownSelect}
                  value={demographicInfo.gender}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  name={DropdownInputs.sector}
                  label={getLanguageValue(languageText, "Sector")}
                  defaultLabel={getLanguageValue(languageText, "Select sector")}
                  value={demographicInfo.sector}
                  list={sectorList}
                  handleDropdownSelect={handleDropdownSelect}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  name={DropdownInputs.seniority}
                  label={getLanguageValue(languageText, "Seniority")}
                  defaultLabel={getLanguageValue(
                    languageText,
                    "Select Seniority",
                  )}
                  value={demographicInfo.seniority}
                  list={seniorityList}
                  handleDropdownSelect={handleDropdownSelect}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  name={DropdownInputs.education}
                  label={getLanguageValue(languageText, "Education")}
                  defaultLabel={getLanguageValue(
                    languageText,
                    "Select Education",
                  )}
                  value={demographicInfo.education}
                  list={educationList}
                  handleDropdownSelect={handleDropdownSelect}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <Input
                  name={DropdownInputs.occupation}
                  label={getLanguageValue(languageText, "Occupation")}
                  value={demographicInfo.occupation}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </CurrentStepPanel>
      );
    case "completed":
      return null;
  }
};
