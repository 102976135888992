import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { IFocusError } from "../../../../components/formComponents/input";

export enum RegisterParticipantTypeOfUser {
  Individual = 1,
  Facilitator = 2,
  Organization = 3,
}

export const getTypeOfUsers = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: RegisterParticipantTypeOfUser.Individual,
    displayName: getLanguageValue(languageText, "Personal Use"),
    value: RegisterParticipantTypeOfUser.Individual.toString(),
  },
  {
    id: RegisterParticipantTypeOfUser.Facilitator,
    displayName: getLanguageValue(languageText, "Facilitator"),
    value: RegisterParticipantTypeOfUser.Facilitator.toString(),
  },
  {
    id: RegisterParticipantTypeOfUser.Organization,
    displayName: getLanguageValue(languageText, "Organization"),
    value: RegisterParticipantTypeOfUser.Organization.toString(),
  },
];

export const RegisterParticipantInputs = {
  name: "name",
  surname: "surname",
  emailAddress: "emailAddress",
  phoneNumber: "phoneNumber",
  password: "password",
  confirmPassword: "confirmPassword",
  country: "country",
  company: "company",
  plan: "plan",
} as const;

// this type looks identical to 'IRegisterParticipantAccountInfo' except
// that it has some optional keys for the social login. maybe we can
// de-copy-pasta it.
export interface IRegisterParticipantPayload {
  name: string;
  surname: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  countryId: number;
  company: string;
  typeOfUser: number;
  isSocialLogin: boolean;
  authProvider: string;
  providerAccessToken: string;
  providerKey: string;
}

export interface IRegisterParticipantAccountInfo {
  name: string;
  surname: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  country: string;
  company: string;
  plan: string;
}

export type IRegisterParticipantFocusInput = {
  [K in keyof IRegisterParticipantAccountInfo]: IFocusError;
};

export interface IRegisterParticipantResponse {
  isSocialLogin: boolean;
  isRegistered: boolean;
  verificationEmailSent: boolean;
  isUserExists: boolean;
}
