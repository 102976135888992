import React from "react";
import { Input } from "../../../components/formComponents/input";
import { CustomModal } from "../../../components/modals/customModal";
import { setNotification } from "../../actions";
import { ExternalUserLogin } from "../../auth/signUp/externalUserLogin";
import { PasswordInput, useSignInPage } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  IResolveParams,
  LoginSocialGoogle,
  LoginSocialMicrosoft,
} from "reactjs-social-login";
import { VerificationCode } from "./verificationCode";
import { AuthLayout } from "@app/containers/auth/signUp/layout";

export const SignInPage = (): JSX.Element => {
  const {
    languageText,
    password,
    formError,
    profile,
    provider,
    participantProfileDetails,
    isVerificationCode,
    isActivationCode,
    resetPasswordModal,
    uniqueCode,
    dispatch,
    setProfile,
    setProvider,
    setIsVerificationCode,
    setIsActivationCode,
    handleBlurEvent,
    handlePasswordChange,
    handleLogin,
    handleResetPassword,
    cancelResetPassword,
    handlePasswordSend,
    userId,
    handleNavigation,
  } = useSignInPage();

  return (
    <>
      <AuthLayout
        page="login"
        language={languageText}
        hideLanguageSelection={true}
      >
        {participantProfileDetails.isPasswordSet && !isActivationCode ? (
          <>
            <h3>{getLanguageValue(languageText, "Sign in")}</h3>
            <p className="fs-5">
              {getLanguageValue(languageText, "Enter password")}
            </p>
            <form onSubmit={handleLogin} className="form">
              <Input
                label={getLanguageValue(languageText, "Password")}
                handleInputChange={handlePasswordChange}
                value={password || ""}
                errorMessage={formError.password.errorMessage}
                handleBlurEvent={handleBlurEvent}
                placeholder={getLanguageValue(languageText, "Password")}
                name={PasswordInput.password}
                id="password"
                required={true}
                type="password"
              />
              <div className="d-flex justify-content-between align-items-center mt-4">
                <button type="submit" className="btn btn-success">
                  {getLanguageValue(languageText, "Login")}
                </button>
                <div
                  className="fw-semibold text-primary"
                  role="button"
                  onClick={handleResetPassword}
                >
                  {getLanguageValue(languageText, "Reset password")}
                </div>
              </div>
            </form>
          </>
        ) : (
          <VerificationCode
            languageText={languageText}
            userId={userId}
            password={password}
            uniqueCode={uniqueCode}
            isVerificationCode={
              isActivationCode ? isActivationCode : isVerificationCode
            }
            isEmailVerification={isActivationCode}
            setIsVerificationCode={
              isActivationCode ? setIsActivationCode : setIsVerificationCode
            }
            handleNavigation={handleNavigation}
          />
        )}

        {!isVerificationCode && (
          <>
            <div className="text-center my-4 pt-4 border-top">
              {getLanguageValue(languageText, "Or connect and login with")}
            </div>
            <div className="row">
              <div className="col-6">
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GG_APP_ID || ""}
                  redirect_uri={process.env.REDIRECT_URI}
                  scope="openid profile email"
                  onResolve={({ provider, data }: IResolveParams) => {
                    if (!data || (!data.access_token && !data.code)) {
                      dispatch(setNotification("User cancelled"));
                    } else {
                      setProvider(provider);
                      setProfile(data);
                    }
                  }}
                  onReject={(error: any) => {
                    dispatch(setNotification("User cancelled"));
                  }}
                >
                  <button className="btn border border-secondary-subtle p-2 w-100">
                    <i className="bi bi-google me-2"></i>
                    <span className="fw-bold">Google</span>
                  </button>
                </LoginSocialGoogle>
              </div>

              <div className="col-6">
                <LoginSocialMicrosoft
                  client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
                  redirect_uri={process.env.REDIRECT_URI || ""}
                  isOnlyGetToken={true}
                  scope="profile openid email user.read"
                  onResolve={({ provider, data }: IResolveParams) => {
                    setProvider(provider);
                    setProfile(data);
                  }}
                  onReject={(error: any) => {
                    dispatch(setNotification(error));
                    throw error;
                  }}
                >
                  <button className="btn border border-secondary-subtle p-2 w-100">
                    <i className="bi bi-microsoft me-2"></i>
                    <span className="fw-bold ">Microsoft</span>
                  </button>
                </LoginSocialMicrosoft>
              </div>
            </div>
          </>
        )}

        {!isVerificationCode && provider && profile && (
          <ExternalUserLogin provider={provider} profile={profile} />
        )}
      </AuthLayout>

      {resetPasswordModal && (
        <CustomModal
          headerText={getLanguageValue(languageText, "Send password")}
          bodyText={`${getLanguageValue(languageText, "Send new password to your email")}?`}
          cancelButtonText={getLanguageValue(languageText, "Cancel")}
          submitButtonText={getLanguageValue(languageText, "Send")}
          handleCancelClick={cancelResetPassword}
          handleSubmitClick={handlePasswordSend}
        />
      )}
    </>
  );
};
