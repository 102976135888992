import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routePath } from "../routePaths";
import { IAcademyCategories, ICourse } from "./types";
import {
  getAcademyCategories,
  getAcademyCoursesLanguages,
  getCoursesList,
  setAcademyCourseLanguages,
} from "./actions";
import { RootState } from "@app/store/configureStore";
import { getParticipantMedia, setSpinner } from "../actions";
import { UserGeneratedMediaType } from "../commonEnums";

export const useParticipantAcademy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultClient = useSelector(
    (state: RootState) => state.loginReducer.defaultClient,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const selectedCourseLanguageId: number = useSelector(
    (state: RootState) => state.academyReducer.selectedCourseLanguageId,
  );
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [categories, setCategories] = useState<IAcademyCategories[]>([]);
  const [allCourses, setAllCourses] = useState<ICourse[]>([]);

  const fetchAcademyCoursesLanguages = async (): Promise<void> => {
    await getAcademyCoursesLanguages(dispatch).then((response) => {
      if (response && response.length > 0) {
        dispatch(setAcademyCourseLanguages(response));
      }
    });
  };

  useEffect(() => {
    fetchAcademyCoursesLanguages();
  }, []);

  const fetchCategories = async (): Promise<void> => {
    await getAcademyCategories(
      defaultClient.defaultClientId,
      selectedCourseLanguageId,
      dispatch,
    ).then((response) => {
      if (response && response.length > 0) {
        setCategories(response);
      } else setCategories([]);
    });
  };

  const fetchCourses = async (): Promise<void> => {
    await getCoursesList(
      defaultClient.defaultClientId,
      selectedCourseLanguageId,
      dispatch,
    ).then((fetchedCourses) => {
      if (fetchedCourses && fetchedCourses.length > 0) {
        const updatedCourseItems = fetchedCourses.map(async (course) => {
          const fetchedImage = await getParticipantMedia(
            course.id,
            UserGeneratedMediaType.AcademyCourse,
            dispatch,
          );
          return { ...course, logo: fetchedImage };
        });

        Promise.all(updatedCourseItems).then((updatedCourses) =>
          setAllCourses(updatedCourses),
        );
      } else setAllCourses([]);
    });
  };

  const fetchCategoriesAndCourses = async (): Promise<void> => {
    dispatch(setSpinner(true));
    await Promise.all([fetchCategories(), fetchCourses()]);
    dispatch(setSpinner(false));
  };

  useEffect(() => {
    if (userId && defaultClient.defaultClientId) {
      fetchCategoriesAndCourses();
    }
  }, [selectedCourseLanguageId, userId, defaultClient]);

  const handleContinueClick = (id: number): void => {
    navigate(
      routePath.participantCategoryCourses.replace(":parentId", id.toString()),
    );
  };

  return {
    languageText,
    categories,
    allCourses,
    handleContinueClick,
  };
};
