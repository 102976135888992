import {
  ITranslationObject,
  getLanguageValue,
} from "./languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import {
  BillingPeriodType,
  Payment,
  PaymentAfterEnd,
  PaymentStatus,
  PaymentTerms,
  PaymentTermsType,
  PaymentType,
} from "../containers/clientList/addEditModal/clientEnums";

interface IPaymentTypes {
  id: number;
  name: string;
  description: string;
  icon: string;
}

const getPaymentTermsList = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: 1,
    displayName: getLanguageValue(
      languageText,
      PaymentTerms[PaymentTermsType.D30],
    ),
    value: PaymentTermsType.D30.toString(),
  },
  {
    id: 2,
    displayName: getLanguageValue(
      languageText,
      PaymentTerms[PaymentTermsType.D60],
    ),
    value: PaymentTermsType.D60.toString(),
  },
  {
    id: 3,
    displayName: getLanguageValue(
      languageText,
      PaymentTerms[PaymentTermsType.D90],
    ),
    value: PaymentTermsType.D90.toString(),
  },
];

const getBillingPeriodList = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: BillingPeriodType.Annual,
    displayName: getLanguageValue(languageText, "Annual"),
    value: BillingPeriodType.Annual.toString(),
  },
  {
    id: BillingPeriodType.Monthly,
    displayName: getLanguageValue(languageText, "Monthly"),
    value: BillingPeriodType.Monthly.toString(),
  },
];

const getPaymentTypes = (languageText: ITranslationObject): IPaymentTypes[] => [
  {
    id: PaymentType.Invoice,
    name: getLanguageValue(languageText, "Invoice"),
    description: getLanguageValue(languageText, "Manually pay invoices"),
    icon: "/DeliveryImages/email-filled.svg",
  },
  {
    id: PaymentType.CreditCard,
    name: getLanguageValue(languageText, "Credit card"),
    description: getLanguageValue(languageText, "Automatically pay invoices"),
    icon: "/DeliveryImages/credit-card.svg",
  },
];

const getPaymentStatus = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: PaymentStatus.Trail,
    displayName: getLanguageValue(languageText, "Trial"),
    value: PaymentStatus.Trail.toString(),
  },
  {
    id: PaymentStatus.Active,
    displayName: getLanguageValue(languageText, "Active"),
    value: PaymentStatus.Active.toString(),
  },
  {
    id: PaymentStatus.Paused,
    displayName: getLanguageValue(languageText, "Paused"),
    value: PaymentStatus.Paused.toString(),
  },
  {
    id: PaymentStatus.Cancelled,
    displayName: getLanguageValue(languageText, "Cancelled"),
    value: PaymentStatus.Cancelled.toString(),
  },
];

const getPayment = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: Payment.NotInvoiced,
    displayName: getLanguageValue(languageText, "NotInvoiced"),
    value: Payment.NotInvoiced.toString(),
  },
  {
    id: Payment.Invoiced,
    displayName: getLanguageValue(languageText, "Invoiced"),
    value: Payment.Invoiced.toString(),
  },
  {
    id: Payment.Paid,
    displayName: getLanguageValue(languageText, "Paid"),
    value: Payment.Paid.toString(),
  },
  {
    id: Payment.Overdue,
    displayName: getLanguageValue(languageText, "Overdue"),
    value: Payment.Overdue.toString(),
  },
  {
    id: Payment.Probono,
    displayName: getLanguageValue(languageText, "Probono"),
    value: Payment.Probono.toString(),
  },
];

const getPaymentAfterEnd = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: PaymentAfterEnd.AskToRenew,
    displayName: getLanguageValue(languageText, "AskToRenew"),
    value: PaymentAfterEnd.AskToRenew.toString(),
  },
  {
    id: PaymentAfterEnd.AskToStart,
    displayName: getLanguageValue(languageText, "AskToStart"),
    value: PaymentAfterEnd.AskToStart.toString(),
  },
  {
    id: PaymentAfterEnd.AutoRenew,
    displayName: getLanguageValue(languageText, "AutoRenew"),
    value: PaymentAfterEnd.AutoRenew.toString(),
  },
  {
    id: PaymentAfterEnd.Cancel,
    displayName: getLanguageValue(languageText, "Cancel"),
    value: PaymentAfterEnd.Cancel.toString(),
  },
];

export {
  IPaymentTypes,
  PaymentTerms,
  getPaymentTermsList,
  getPayment,
  getBillingPeriodList,
  getPaymentTypes,
  getPaymentStatus,
  getPaymentAfterEnd,
};
