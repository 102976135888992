import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import {
  AddManually,
  IManualRespondentInfo,
  ManualRespondentInputs,
} from "./addManually";
import { IRespondentList, SelectFromList } from "./selectFromList";
import { PreviewPopup } from "../../../profileList/addProfile/emailSMSInvitation/previewPopup";
import { RespondentStatus } from "./respondentStatus";
import {
  GetTypeOfRoleName,
  ProfileStatus,
} from "../../../profileList/profileEnums";
import { CustomModal as RespondentsLimitReachedModal } from "../../../../components/modals/customModal";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages } from "@app/containers/commonEnums";
import {
  Step,
  StepComponent,
  StepComponentProps,
} from "../../createProfilePage";
import {
  CompletedStepPanel,
  CurrentStepPanel,
  UpcomingStepPanel,
} from "../../panels";
import { RoleSettingsWithEditOption } from "@app/containers/profileList/addProfile/roleSettings/roleSettingsWithEditOption";
import { useRolePage } from "../rolePage/hooks";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParticipantProfileAuth } from "../../useParticipantProfileAuth";
import { RootState } from "@app/store/configureStore";
import { inviteRespondentsInstructionSteps } from "@app/components/instruction/instructionSteps";
import { IFocusError } from "@app/components/formComponents/input";
import { ProfileId, UserId } from "@app/containers/reducer";
import { getParticipantRespondentsByParticipantId } from "@app/containers/profileList/editProfile/actions";
import {
  SendRespondentsInvitation,
  ICreateManualRespondentsBody,
  ICreateRespondentsParams,
  IRespondentEmailPreviewBody,
} from "./sendRespondentsInvitation";
import {
  createManualProfileParticipantRespondents,
  getRespondentEmailPreview,
  getUserForRespondents,
} from "../../actions";
import { setSpinner } from "@app/containers/actions";
import { IProfileRespondentsList } from "@app/containers/profileList/editProfile/interface";
import { ValidationHelper } from "@app/containers/validationHelper";
import { useApiEndpoints } from "@app/api/end-points";

/**
 * TODO: we probably have this kind of logic duplicated elsewhere - i just don't know where.
 */
function getShortName(name: string): string {
  const parts = name.trim().split(/\s+/);
  if (parts.length === 1) {
    return name;
  }

  // first name and the initial of the last name. should work
  // for people with more than two names.
  return [parts[0], parts[parts.length - 1].charAt(0)]
    .filter((x) => !!x)
    .join(" ");
}

type InviteRespondentsStepProps = StepComponentProps & {
  /**
   * This is a trashy workaround because this component is _extremely_ tightly
   * coupled to the hook 'useInviteRespondent'. It's so coupled that the hook
   * itself is useless - it could just be inline code. To pull the state
   * upwards would require massive changes, and I'm not in a mood to do that.
   *   -johan, 2024-10-17
   */
  variant:
    | { kind: "step" }
    | { kind: "modal"; onSubmit: () => unknown; onClose: () => unknown };
};

enum NavTabEnums {
  None = 0,
  Select_From_List = 1,
  Add_Manually = 2,
}

export interface IUserRespondentsBody {
  profileId: ProfileId;
  searchText: string;
}

const initialErrorState: IFocusError = { errorMessage: "", touched: false };
const initialInfoState: IManualRespondentInfo = {
  id: 1,
  name: "",
  emailAddress: "",
  phoneNumber: "",
  nameError: initialErrorState,
  emailError: initialErrorState,
  phoneNumberError: initialErrorState,
  instructionLanguageId: null,
};
const initialRespondentState: IRespondentList = {
  id: 0 as UserId,
  name: "",
  emailAddress: "",
  phoneNumber: "",
  instructionLanguageId: null,
};

export const InviteRespondents: React.FC<InviteRespondentsStepProps> = (
  props,
) => {
  function onInvited() {
    if (props.variant.kind === "step") {
      props.setStep(Step.SelfAssessment);
    }
  }

  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  // Required to get data from the URL unique code
  // Also has a nasty side effect that sets 'participantProfileDetails' in Redux state.
  useParticipantProfileAuth();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const profileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails,
  );
  const currentUserLanguages = useSelector(
    (state: RootState) => state.mainReducer.currentUserLanguages,
  );
  const instructionSteps = useMemo(
    () => inviteRespondentsInstructionSteps(languageText),
    [languageText],
  );
  const [respondentsList, setRespondentsList] = useState<IRespondentList[]>([
    initialRespondentState,
  ]);
  const [originalRespondentsList, setOriginalRespondentsList] = useState<
    IRespondentList[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<NavTabEnums>(
    NavTabEnums.Select_From_List,
  );
  const [respondentsLimitReachedModal, setRespondentsLimitReachedModal] =
    useState<boolean>(false);

  // Select from list states
  const [searchText, setSearchText] = useState<string>("");
  const [selectedRespondents, setSelectedRespondents] = useState<
    IRespondentList[]
  >([]);
  const [refetchList, setRefetchList] = useState<boolean>(false);
  const [instructionMessage, setInstructionMessage] = useState<string>("");
  // Add Manual states
  const [respondentInfo, setRespondentInfo] = useState<IManualRespondentInfo[]>(
    [initialInfoState],
  );
  const [previewHTML, setPreviewHTML] = useState<string>("");

  // FIXME: this state is super confusing. when we're at the 'invite respondents' step
  //   this will be empty, because we haven't invited anyone yet (of course). when this
  //   component is reused in the 'waiting for respondents' step this thing will have data.
  //   -johan, 2024-11-27
  // Respondents status states
  const [invitedRespondentsList, setInvitedRespondentsList] =
    useState<IProfileRespondentsList>({
      participantLink: "",
      respondents: [],
      noOfRespondents: 0,
      respondentsAnswered: 0,
      respondentsInvited: 0,
    });
  const [refetchInvitedRespondentsList, setRefetchInvitedRespondentsList] =
    useState<boolean>(false);

  function setInstructionLanguageForAllRespondents(languageCode: string): void {
    const lang = currentUserLanguages.find((it) => it.name === languageCode);
    const next = respondentInfo.map((r) => {
      return {
        ...r,
        instructionLanguageId: lang?.id || null,
      };
    });
    setRespondentInfo(next);
  }

  // Select from list , Add manually useEffect
  useEffect(() => {
    if (profileDetails.participantInviteExternal) {
      if (profileDetails.noOfRespondents) {
        const updatedArr: IManualRespondentInfo[] = Array.from(
          { length: profileDetails.noOfRespondents },
          (_, index) => ({ ...initialInfoState, id: index + 1 }),
        );
        setRespondentInfo(updatedArr);
      }
      if (userLanguage.userLanguageCode) {
        setInstructionLanguageForAllRespondents(userLanguage.userLanguageCode);
      }
    }
  }, [profileDetails, userLanguage.userLanguageCode]);

  // Select from list useEffect
  useEffect(() => {
    if (props.status !== "current") {
      return;
    }

    if (profileDetails.profileId && !profileDetails.isLocked) {
      const body: IUserRespondentsBody = {
        profileId: profileDetails.profileId,
        searchText,
      };
      getUserForRespondents(body, dispatch).then((response) => {
        if (response) {
          setOriginalRespondentsList(response);
          // Removing already selected participants as respondents from list
          if (selectedRespondents.length > 0) {
            const updatedRespondentsList = response.filter(
              (respondent) =>
                !selectedRespondents.some((res) => res.id === respondent.id),
            );
            setRespondentsList(updatedRespondentsList);
          } else {
            setRespondentsList(response);
          }
        }
      });
    }
  }, [searchText, refetchList, profileDetails, props.status]);

  // Respondents status useEffect
  useEffect(() => {
    if (props.status !== "current") {
      return;
    }

    if (profileDetails.profileId && profileDetails.noOfRespondents) {
      getParticipantRespondentsByParticipantId(
        profileDetails.profileId,
        dispatch,
      ).then((response) => {
        if (response) {
          setInvitedRespondentsList(() => ({
            ...response,
            respondentsInvited: response.respondentsInvited ?? 0,
            respondentsAnswered: response.respondentsAnswered ?? 0,
            participantRespondents: response.respondents ?? [],
          }));
        }
      });
    }
  }, [refetchInvitedRespondentsList, profileDetails, props.status]);

  // Setting no of respondents remaining
  useEffect(() => {
    if (profileDetails.participantInviteExternal) {
      const noOfRespondentsSelected =
        selectedRespondents.length + invitedRespondentsList.respondents.length;
      const remainingNoOfRespondents =
        profileDetails.noOfRespondents - noOfRespondentsSelected;
      const updateNumber =
        remainingNoOfRespondents > 1 ? remainingNoOfRespondents : 1;
      if (updateNumber > respondentInfo.length) {
        const reqExtraNumber = updateNumber - respondentInfo.length;
        const updatedArr: IManualRespondentInfo[] = Array.from(
          { length: reqExtraNumber },
          () => ({
            ...initialInfoState,

            // what the fuck is going on here?
            id: respondentInfo.length + 1,
          }),
        );
        setRespondentInfo((prevInfo) => [...prevInfo, ...updatedArr]);
      } else if (updateNumber < respondentInfo.length) {
        const extraNumber = respondentInfo.length - updateNumber;
        const updatedArr = [...respondentInfo].slice(0, -extraNumber);
        setRespondentInfo(updatedArr);
      }
    }
  }, [selectedRespondents, profileDetails]);

  useEffect(() => {
    if (profileDetails.participantInviteExternal) {
      const remainingNoOfRespondents =
        profileDetails.noOfRespondents -
        invitedRespondentsList.respondents.length;
      const updateNumber =
        remainingNoOfRespondents > 1 ? remainingNoOfRespondents : 1;
      const updatedArr: IManualRespondentInfo[] = Array.from(
        { length: updateNumber },
        (_, index) => ({
          ...initialInfoState,

          // what the fuck is going on here?
          id: index + 1,
        }),
      );
      setRespondentInfo(updatedArr);
    }
    if (
      invitedRespondentsList.respondents.length >=
      profileDetails.noOfRespondents
    ) {
      setSelectedTab(NavTabEnums.None);
    }
    if (
      profileDetails.participantInviteColleagues ||
      profileDetails.participantInviteOtherParticipant
    ) {
      if (userLanguage.userLanguageCode) {
        setInstructionLanguageForAllRespondents(userLanguage.userLanguageCode);
      }
      if (
        invitedRespondentsList.respondents.length <
        profileDetails.noOfRespondents
      ) {
        setSelectedTab(NavTabEnums.Select_From_List);
      }
    } else if (
      invitedRespondentsList.respondents.length < profileDetails.noOfRespondents
    ) {
      setSelectedTab(NavTabEnums.Add_Manually);
    }
  }, [invitedRespondentsList, profileDetails, userLanguage.userLanguageCode]);

  const handleTabSelection = (tabStatus: number): void => {
    setSelectedTab(tabStatus);
  };

  // Select from list fn's
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
  };

  const handleSelectRespondent = (respondent: IRespondentList): void => {
    const updatedSelectedRespondents = [...selectedRespondents];
    const index = updatedSelectedRespondents.findIndex(
      (r) => r.id === respondent.id,
    );

    if (index !== -1) {
      updatedSelectedRespondents[index] = respondent;
    } else {
      updatedSelectedRespondents.push(respondent);
    }

    setSelectedRespondents(updatedSelectedRespondents);

    const updatedRespondentsList = originalRespondentsList.filter(
      (respondent) =>
        !updatedSelectedRespondents.some((res) => res.id === respondent.id),
    );
    setRespondentsList(updatedRespondentsList);
  };

  const handleRemoveRespondent = (respondent: IRespondentList): void => {
    const updatedSelectedRespondents = [...selectedRespondents].filter(
      (res) => res.id !== respondent.id,
    );
    setSelectedRespondents(updatedSelectedRespondents);

    const updatedRespondentsList = [...originalRespondentsList].filter(
      (respondent) =>
        !updatedSelectedRespondents.some((res) => res.id === respondent.id),
    );
    setRespondentsList(updatedRespondentsList);
  };

  const handlePreviewModal = (): void => {
    const body: IRespondentEmailPreviewBody = {
      profileId: profileDetails.profileId,
      languageCode: userLanguage.userLanguageCode,
      ownMsg: instructionMessage,
    };
    getRespondentEmailPreview(body, dispatch).then((response) => {
      if (response) {
        setPreviewHTML(response);
      }
    });
  };

  const isRespondentsLimitReached = (selectedLength: number): boolean => {
    if (
      profileDetails.status === ProfileStatus.New &&
      profileDetails.roleCanBeChangedByParticipant
    ) {
      // if the profile owner is allowed to change the 'role'
      // they're also allowed to invite however many respondents
      // they want.
      return false;
    }

    // If selected more than limit, show limit message
    if (
      selectedLength + invitedRespondentsList.respondents.length >
      profileDetails.noOfRespondents
    ) {
      setRespondentsLimitReachedModal(true);
      return true;
    } else return false;
  };

  // Add manually fn's
  const handleFormErrors = (
    id: number | string,
    name: string,
    value: string,
    onBlur = false,
  ): void => {
    let errorMessage: string = "";
    let errorInputName: "nameError" | "emailError" | undefined;

    switch (name) {
      case ManualRespondentInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Name is required");
        }
        errorInputName = ManualRespondentInputs.nameError;
        break;
      case ManualRespondentInputs.emailAddress:
        if (value) {
          if (!ValidationHelper.isEmailValid(value)) {
            errorMessage = getLanguageValue(languageText, "Invalid email");
          } else {
            // check if email already exists in invited respondents
            const isEmailExistsInAddManually = respondentInfo
              .filter((respondent) => `${name}${respondent.id}` !== id)
              .some((respondent) => respondent.emailAddress === value);

            // check if email already added manually
            const isEmailExistsInInvitedRespondents =
              invitedRespondentsList.respondents.some(
                (respondent) => respondent.email === value,
              );
            if (
              isEmailExistsInAddManually ||
              isEmailExistsInInvitedRespondents
            ) {
              errorMessage = getLanguageValue(
                languageText,
                "Email already exists",
              );
            }
            if (value === profileDetails.emailAddress) {
              errorMessage = getLanguageValue(
                languageText,
                "You cannot invite yourself as respondent",
              );
            }
          }
        }
        errorInputName = ManualRespondentInputs.emailError;
        break;
      default:
        break;
    }

    setRespondentInfo((prevInfo) =>
      prevInfo.map((item) => {
        // what the fuck are we doing here? can anyone read this?
        if (
          id === `${name}${item.id}` &&
          errorInputName &&
          ((!onBlur && item[errorInputName].touched) || onBlur)
        ) {
          return {
            ...item,
            [errorInputName]: { errorMessage: errorMessage, touched: true },
          };
        }
        return item;
      }),
    );
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const id = e.target.id;
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(id, name, value, true);
  };

  const onDeleteRowClick = (index: number): void => {
    const next = respondentInfo.slice();
    next.splice(index, 1);
    setRespondentInfo(next);
  };

  const onAddRowClick = (): void => {
    setRespondentInfo((prevInfo) => [
      ...prevInfo,
      { ...initialInfoState, id: respondentInfo.length + 1 },
    ]);
  };

  const closePreviewModal = (): void => {
    setPreviewHTML("");
  };

  function buildManualRespondentsRequestBody(): ICreateManualRespondentsBody[] {
    return respondentInfo
      .filter((r) => {
        const hasErrors =
          r.nameError.errorMessage ||
          r.emailError.errorMessage ||
          r.phoneNumberError.errorMessage;

        return r.name && (r.emailAddress || r.phoneNumber) && !hasErrors;
      })
      .map((info) => {
        return {
          profileId: profileDetails.profileId,
          name: info.name,
          email: info.emailAddress,
          telephone: info.phoneNumber,
          instructionLanguageId: info.instructionLanguageId,
        };
      });
  }

  const closeRespondentsLimitReachedModal = (): void => {
    setRespondentsLimitReachedModal(false);
  };

  const showAddManualOption = profileDetails.participantInviteExternal;
  const showSelectFormListOption =
    profileDetails.participantInviteColleagues ||
    profileDetails.participantInviteOtherParticipant;
  const respondentsLimitReached =
    invitedRespondentsList.respondents.length >= profileDetails.noOfRespondents;

  const rolePage = useRolePage(props);

  const __t = props.__t;
  const title = __t("Invite respondents");

  const validManualRespondents = respondentInfo.filter(
    (r) => r.name && (r.emailAddress || r.phoneNumber),
  );

  const canMoveToNextStep =
    validManualRespondents.length + selectedRespondents.length >=
    profileDetails.noOfRespondents;

  const roleNameOrText = profileDetails.roleId
    ? getLanguageValue(languageText, GetTypeOfRoleName[profileDetails.roleId])
    : profileDetails.roleText
      ? profileDetails.roleText
      : "";

  const theActualFormAndStuff = (
    <React.Fragment>
      <div className="p-4">
        <RoleSettingsWithEditOption
          languageText={languageText}
          profileId={rolePage.participantProfileDetails.profileId}
          status={rolePage.participantProfileDetails.status}
          roleId={rolePage.participantProfileDetails.roleId}
          roleText={rolePage.participantProfileDetails.roleText}
          noOfRespondents={rolePage.participantProfileDetails.noOfRespondents}
          isEditable={rolePage.roleCanBeChangedByParticipant}
          isParticipant={true}
          errorMessage={rolePage.errorMessage}
          handleTypeOfRoleSave={rolePage.handleTypeOfRoleSave}
          handleNoOfRespondentsSave={rolePage.handleNoOfRespondentsSave}
          canEditNoOfRespondents={false}
        />

        <p className="mt-4">
          {getLanguageValue(
            languageText,
            "Invite the respondents you want to engage to create your IDI profile",
          )}
          .
        </p>
        {!profileDetails.roleCanBeChangedByParticipant && (
          <p>
            {__t("Number of respondents required")}:{" "}
            {profileDetails.noOfRespondents}
          </p>
        )}
        {!respondentsLimitReached ? (
          <>
            <div className="invite-respondents-tab fs-5 fw-bold mb-3">
              {showSelectFormListOption && showAddManualOption && (
                <div
                  id="inviteRespondentInstruction2"
                  className={clsx(
                    `me-2 btn`,
                    selectedTab === NavTabEnums.Select_From_List
                      ? "btn-dark"
                      : "btn-light",
                  )}
                  onClick={() =>
                    handleTabSelection(NavTabEnums.Select_From_List)
                  }
                >
                  <i className="bi bi-list-task me-2"></i>
                  {getLanguageValue(languageText, "Select from list")}
                </div>
              )}
              {showAddManualOption && showSelectFormListOption && (
                <div
                  id="inviteRespondentInstruction3"
                  className={clsx(
                    `btn`,
                    selectedTab === NavTabEnums.Add_Manually
                      ? "btn-dark"
                      : "btn-light",
                  )}
                  onClick={() => handleTabSelection(NavTabEnums.Add_Manually)}
                >
                  <i className="bi bi-pencil-fill me-2"></i>
                  {getLanguageValue(languageText, "Add manually")}
                </div>
              )}
            </div>

            <div className="mt-3 p-2 p-md-4 border rounded mb-4">
              {/* Select from list */}
              {showSelectFormListOption &&
                selectedTab === NavTabEnums.Select_From_List && (
                  <SelectFromList
                    languageText={languageText}
                    languages={currentUserLanguages}
                    respondentsList={respondentsList}
                    searchText={searchText}
                    selectedRespondents={selectedRespondents}
                    handleSearch={handleSearch}
                    handleSelectRespondent={handleSelectRespondent}
                    handleRemoveRespondent={handleRemoveRespondent}
                  />
                )}

              {/* Add Manually */}
              {showAddManualOption &&
                selectedTab === NavTabEnums.Add_Manually && (
                  <>
                    <AddManually
                      languageText={languageText}
                      languages={currentUserLanguages}
                      respondentInfo={respondentInfo}
                      handleChange={(respondent, index) => {
                        const next = respondentInfo.slice();
                        next[index] = respondent;
                        setRespondentInfo(next);
                      }}
                      handleBlurEvent={handleBlurEvent}
                      onDeleteRowClick={onDeleteRowClick}
                      onAddRowClick={onAddRowClick}
                    />
                    {profileDetails.roleCanBeChangedByParticipant && (
                      // there are a shitload of conditions for when this button should _akshually_
                      // show up, but this seems at least partially correct. if the profile owner
                      // is allowed to edit the "role" (or whatever) is also allowed to add more respondents.
                      <div className="text-center">
                        <button
                          className="btn btn-light"
                          onClick={(event) => {
                            event.preventDefault();

                            const next = respondentInfo.concat({
                              ...initialInfoState,

                              // the delete method needs an ID for some reason.
                              id: respondentInfo.length + 1,
                            });
                            setRespondentInfo(next);
                          }}
                        >
                          <i className="bi bi-plus-lg" />{" "}
                          {__t("Add respondent")}
                        </button>
                      </div>
                    )}
                  </>
                )}
            </div>
          </>
        ) : (
          <div className="text-danger d-none">
            {
              getLanguageValue(
                languageText,
                "Respondents limit reached. Cannot add further respondents",
              )
              //AM: not sure if we will keep this
            }
            .
          </div>
        )}

        <SendRespondentsInvitation
          languageText={languageText}
          messageValue={instructionMessage}
          handleSaveMessage={(message) => {
            setInstructionMessage(message);
            api.saveParticipantFieldValue({
              id: props.profile.profileId,
              fieldName: "InstructionMessage",
              fieldValue: message,
            });
          }}
          onPreviewClick={handlePreviewModal}
        />
      </div>
      {previewHTML && (
        <PreviewPopup
          languageText={languageText}
          previewHTML={previewHTML}
          onCloseClick={closePreviewModal}
        />
      )}

      {respondentsLimitReachedModal && (
        <RespondentsLimitReachedModal
          headerText={getLanguageValue(languageText, "Limit Reached")}
          bodyText={`${getLanguageValue(languageText, "Maximum")} ${
            profileDetails.noOfRespondents
          } ${getLanguageValue(languageText, "respondents can be invited")}.`}
          cancelButtonText={getLanguageValue(languageText, "Ok")}
          handleCancelClick={closeRespondentsLimitReachedModal}
        />
      )}
    </React.Fragment>
  );

  /**
   * This first checks if the total sum of invitees (list and manual) is higher
   * than the allowed and then sends the request to add them.
   * @returns
   */
  function saveRespondentsAndMaybeSendInvites(): Promise<void> {
    const manualRespondents = buildManualRespondentsRequestBody();
    if (selectedRespondents.length > 0 || manualRespondents.length > 0) {
      if (
        isRespondentsLimitReached(
          manualRespondents.length + selectedRespondents.length,
        )
      ) {
        return Promise.reject();
      }

      dispatch(setSpinner(true));
      const params: ICreateRespondentsParams = {
        profileId: profileDetails.profileId,
      };

      // Create body from the selected respondents
      const respondentsFromList: Array<ICreateManualRespondentsBody> =
        selectedRespondents.map((r) => {
          return {
            name: r.name,
            email: r.emailAddress,
            telephone: r.phoneNumber,
            instructionLanguageId: r.instructionLanguageId,
          };
        });

      const respondentsFromManual = buildManualRespondentsRequestBody();
      const allRespondents = [...respondentsFromList, ...respondentsFromManual];

      return createManualProfileParticipantRespondents(
        allRespondents,
        params,
        dispatch,
      )
        .then((response) => {
          if (response?.success) {
            setRefetchList(!refetchList);
            setRespondentInfo([initialInfoState]);
            setRefetchInvitedRespondentsList(!refetchInvitedRespondentsList);
            onInvited();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
    return Promise.resolve();
  }

  if (props.variant.kind === "modal") {
    // for an explanation for why this garbage is here, see the rant above in the props declaration.
    // this component should obviously not "be" two things at once, but the form is inherently
    // tighly coupled to the hook 'useInviteRespondent'.

    const variant = props.variant;
    return (
      <ModalComponent
        headerText={__t("Add respondent")}
        submitButtonText={__t("Submit")}
        handleSubmitClick={(event) => {
          event.preventDefault();
          saveRespondentsAndMaybeSendInvites().then(() => {
            variant.onSubmit();
          });
        }}
        cancelButtonText={__t("Cancel")}
        handleCancelClick={(event) => {
          event.preventDefault();
          variant.onClose();
        }}
        width="xl"
      >
        {theActualFormAndStuff}
      </ModalComponent>
    );
  }

  switch (props.status) {
    case "upcoming":
      return <UpcomingStepPanel title={title} />;
    case "current":
      return (
        <CurrentStepPanel
          __t={__t}
          title={
            <div className="d-flex">
              <div className="flex-grow-1">{title}</div>
              <div className="flex-grow-0">
                <Instruction
                  targetElement="instructionBtn"
                  //Shouldn't this be GuidePages.Participant_Invite_Respondents? - Joakim, 241112
                  guidePage={GuidePages.Participant_Role}
                  instructionSteps={instructionSteps}
                  /* is the '4' here accurate? is it an index? is it a step 'number'? */
                  stepsNotToSkip={
                    !rolePage.roleCanBeChangedByParticipant ? [4] : []
                  }
                  manualTrigger={true}
                />
              </div>
            </div>
          }
          onPreviousClick={(event) => {
            event.preventDefault();
            props.setStep(Step.DemographicSurvey);
          }}
          onNextClick={(event) => {
            event.preventDefault();

            saveRespondentsAndMaybeSendInvites();
          }}
          nextDisabled={!canMoveToNextStep}
        >
          {theActualFormAndStuff}
        </CurrentStepPanel>
      );
    case "completed": {
      const title = (
        <span>
          {__t("My respondents are")}{" "}
          <span className="fw-bold">
            {props.profile.respondents.length} {roleNameOrText}
          </span>
          {props.profile.respondents.map((r, index) => {
            return (
              <span
                key={index}
                className="ms-2 py-1 px-2 small bg-dark bg-opacity-10 rounded"
              >
                {getShortName(r.name)}
              </span>
            );
          })}
        </span>
      );

      return <CompletedStepPanel title={title} />;
    }
  }
};
