import { CustomActionsCell } from "@app/components/reactTable/customActionsCell";
import { ReactTable } from "@app/components/reactTable/reactTable";
import { ColumnDef } from "@tanstack/react-table";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";

import { EditConsentModal } from "./editConsentModal";
import { DeleteModal } from "@app/components/modals/deleteModal";
import { ITableCommonParams } from "../commonInterfaces";
import { IConsent } from "../auth/signUp/reducer";
import { useApiEndpoints } from "@app/api/end-points";

type ConsentListProps = unknown;

export const ConsentList: React.FC<ConsentListProps> = () => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const lang = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const [data, setData] = React.useState<Array<IConsent>>([]);
  const [filter, setFilter] = React.useState<string>("");
  const [sorting, setSorting] = React.useState<string>("");
  const [totalCount, setTotalCount] = React.useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = React.useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = React.useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = React.useState<number>(0); // page_number_selected
  const [editingConsent, setEditingConsent] = React.useState<
    IConsent | undefined
  >(undefined);
  const [deletingConsent, setDeletingConsent] = React.useState<
    IConsent | undefined
  >(undefined);
  const header: Array<ColumnDef<IConsent>> = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <CustomActionsCell
          languageText={lang}
          id={props.row.original.id}
          editOption={true}
          deleteOption={true}
          handleEditClick={(id) => {
            const item = data.find((x) => x.id === id);
            if (item) {
              setEditingConsent(item);
            }
          }}
          handleDeleteClick={handleDeleteConsentClick}
        />
      ),
    },
    {
      header: getLanguageValue(lang, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(lang, "Title"),
      accessorKey: "title",
    },
    {
      header: getLanguageValue(lang, "Version"),
      accessorKey: "version",
    },
    {
      header: getLanguageValue(lang, "Is active"),
      accessorKey: "isActive",
    },
  ];

  function loadConsentsAndUpdateState(): Promise<void> {
    const params: ITableCommonParams = {
      filter: filter,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: pageSelected * pageLimit,
    };

    return api.getConsents(params).then((x) => {
      setData(x.items.slice());
      setTotalCount(x.totalCount);
      setPageCount(Math.ceil(x.totalCount / pageLimit));
    });
  }

  React.useEffect(() => {
    loadConsentsAndUpdateState();
  }, [pageSelected, sorting, filter]);

  function onAddConsentClick(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();

    setEditingConsent({
      title: "",
      body: "",
      version: "",
      isActive: true,
    });
  }

  function handleSaveConsentClick(
    event: React.MouseEvent<HTMLElement>,
  ): Promise<unknown> {
    event.preventDefault();
    event.stopPropagation();

    if (!editingConsent) {
      return Promise.reject();
    }

    const promise = editingConsent.id
      ? api.updateConsent(editingConsent.id, editingConsent)
      : api.createConsent(editingConsent);

    return promise.then(() => {
      setEditingConsent(undefined);
      loadConsentsAndUpdateState();
    });
  }

  function handleDeleteConsentClick(id: number): void {
    const alert = data.find((x) => x.id === id);
    if (alert) {
      setDeletingConsent(alert);
    }
  }

  function handleActuallyDeleteConsentClick(): Promise<unknown> {
    if (!deletingConsent) {
      return Promise.reject();
    }

    return api.deleteConsent(deletingConsent.id!).then(() => {
      loadConsentsAndUpdateState();
      setDeletingConsent(undefined);
    });
  }

  return (
    <React.Fragment>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(lang, "Consents")}
        buttonText={getLanguageValue(lang, "Add consent")}
        handleButtonClick={onAddConsentClick}
        tableHeader={header}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {typeof editingConsent !== "undefined" && (
        <EditConsentModal
          lang={lang}
          consent={editingConsent}
          handleClose={() => setEditingConsent(undefined)}
          handleSave={handleSaveConsentClick}
          onConsentChange={setEditingConsent}
        />
      )}

      {typeof deletingConsent !== "undefined" && (
        <DeleteModal
          languageText={lang}
          headerText={deletingConsent.title}
          bodyText={deletingConsent.title}
          handleDeleteClick={handleActuallyDeleteConsentClick}
          closeDeleteModal={() => setDeletingConsent(undefined)}
        />
      )}
    </React.Fragment>
  );
};
