import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import * as React from "react";
import { ALERT_TRIGGERS, AlertTrigger, IAlert } from "./types";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { Input } from "@app/components/formComponents/input";
import { TextArea } from "@app/components/formComponents/textArea";
import { Checkbox } from "@app/components/formComponents/checkbox";
import { deleteUserInteractions } from "./actions";
import { useDispatch } from "react-redux";
import { DropdownSelect } from "@app/components/formComponents/dropdownSelect";
import { addToast } from "../actions";
import { AnyAction } from "redux";

type AlertModalProps = {
  lang: ITranslationObject;
  alert: IAlert;
  onAlertChange: (alert: IAlert) => void;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  handleSave: (event: React.MouseEvent<HTMLElement>) => void;
};

export const EditAlertModal: React.FC<AlertModalProps> = (props) => {
  const isEditing = typeof props.alert.id !== "undefined";
  const dispatch = useDispatch();

  function onResetUserInteractionsClick(
    event: React.MouseEvent<HTMLElement>,
  ): void {
    event.preventDefault();
    event.stopPropagation();

    if (!isEditing) {
      return;
    }

    deleteUserInteractions(dispatch, props.alert.id!).then(() => {
      dispatch(addToast("Success") as AnyAction);
      props.handleClose(event);
    });
  }

  return (
    <ModalComponent
      width="xl"
      headerText={getLanguageValue(
        props.lang,
        isEditing ? "Edit alert" : "Create alert",
      )}
      cancelButtonText={getLanguageValue(props.lang, "Dismiss")}
      handleCancelClick={props.handleClose}
      submitButtonText={getLanguageValue(props.lang, "Save")}
      handleSubmitClick={props.handleSave}
    >
      <DropdownSelect
        name=""
        label={getLanguageValue(props.lang, "Trigger")}
        value={props.alert.trigger}
        list={ALERT_TRIGGERS.map((trigger, idx) => {
          return {
            id: idx,
            displayName: trigger,
            value: trigger,
          };
        })}
        handleDropdownSelect={(item) => {
          props.onAlertChange({
            ...props.alert,
            trigger: item.value as AlertTrigger,
          });
        }}
      />

      <Input
        label={getLanguageValue(props.lang, "Title")}
        value={props.alert.title}
        placeholder={getLanguageValue(props.lang, "Title")}
        handleInputChange={(event) => {
          props.onAlertChange({
            ...props.alert,
            title: event.target.value,
          });
        }}
      />
      <TextArea
        label={getLanguageValue(props.lang, "Body")}
        value={props.alert.body}
        placeholder={getLanguageValue(props.lang, "Body")}
        onChange={(event) => {
          props.onAlertChange({
            ...props.alert,
            body: event.target.value,
          });
        }}
        rows={8}
      />
      <Checkbox
        label={getLanguageValue(props.lang, "Is active")}
        value={props.alert.isActive}
        handleCheckboxChange={(box) => {
          props.onAlertChange({
            ...props.alert,
            isActive: box.target.checked,
          });
        }}
      />
      {isEditing && (
        <div className="form-group mt-4">
          <button
            className="btn btn-light"
            onClick={onResetUserInteractionsClick}
          >
            {getLanguageValue(props.lang, "Reset user interactions")}
          </button>
        </div>
      )}
    </ModalComponent>
  );
};
