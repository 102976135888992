import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";

export const changePassword = async (
  dispatch: Dispatch,
  currentPassword: string,
  newPassword: string,
) => {
  const body = {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };
  try {
    const response = await api.post(
      apiEndPoints.changePassword,
      JSON.stringify(body),
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
