import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface IDeliveryStatusProps {
  languageText: ITranslationObject;
  isFinished: boolean;
  handleClosePresentation: (isFinished: boolean) => void;
}

export const DeliveryStatus = (props: IDeliveryStatusProps): JSX.Element => {
  const { languageText, isFinished, handleClosePresentation } = props;

  const handleClick = (): void => handleClosePresentation(isFinished);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <span style={{ fontSize: "30px", margin: "5px" }}>
        {getLanguageValue(
          languageText,
          isFinished ? "Presentation Finished" : "Presentation Cancelled",
        )}
      </span>
      <button className="btn btn-primary" onClick={handleClick}>
        {getLanguageValue(languageText, "Ok")}
      </button>
    </div>
  );
};
