import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IProfileTimelines } from "../interface";
import { GetTypeOfRoleName } from "../../../profileList/profileEnums";
import { getFormattedDateOnly } from "../../../../commonUtils/dateFunctionsHelper";
import { IMarkAsFinish } from "../../../academy/courseSummary/hooks";
import {
  courseStarted,
  getUserCourseParts,
  setCourseDetails,
} from "../../../academy/actions";
import { routePath } from "../../../routePaths";
import { ICourse } from "../../../academy/types";
import { setNotification } from "../../../actions";
import { RootState } from "@app/store/configureStore";
import { ProfileId } from "@app/containers/reducer";

interface IProgressTimelineProps {
  languageText: ITranslationObject;
  profileTimelines: IProfileTimelines[];
  readOnly?: boolean;
}

export const ProgressTimeline = (
  props: IProgressTimelineProps,
): JSX.Element => {
  const { languageText, profileTimelines, readOnly = false } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.loginReducer.userId);

  const handleProfileReport = (profileId: ProfileId): void => {
    if (readOnly) return;

    navigate(
      routePath.participantProfileReport.replace(
        ":profileId",
        profileId.toString(),
      ),
    );
  };

  const handleCourseNavigation = async (courseId: number): Promise<void> => {
    if (readOnly) return;

    const body: IMarkAsFinish = {
      userId: userId,
      courseId: courseId,
    };

    const res: ICourse = await getUserCourseParts(courseId, dispatch);

    if (res && res.courseParts && res.courseParts.length > 0) {
      dispatch(setCourseDetails(res));
      const firstPartId = res.courseParts[0].id;

      const courseUrl = routePath.participantCourse
        .replace(":courseId", courseId.toString())
        .replace(":partId", firstPartId.toString());

      const response = await courseStarted(body, dispatch);

      if (response) {
        navigate(courseUrl);
      }
    } else if (res && !res.courseParts?.length) {
      dispatch(setNotification("No course parts available"));
    }
  };

  return (
    <div className="card h-100 shadow-sm bg-white rounded border-0">
      <div className="card-header border-0 p-3 fs-5 text-body-tertiary">
        {getLanguageValue(languageText, "Progress timeline")}
      </div>

      <div className="card-body p-4">
        <div className="overflow-scroll idi-participant-progress-mh">
          {profileTimelines.length > 0 &&
            profileTimelines.map((timeline, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    "p-3 rounded border mb-2 me-2",
                    timeline.isActivity ? "" : "",
                  )}
                >
                  {timeline.isActivity ? (
                    <>
                      <div
                        className={clsx(
                          "d-flex justify-content align-items-center",
                          !readOnly && "cursor-pointer",
                        )}
                        onClick={() => handleProfileReport(timeline.id)}
                        role="button"
                      >
                        <i className="bi bi-border-all me-3 fs-4 text-dark opacity-25"></i>
                        <span className="p-1 px-2 rounded bg-light text-secondary me-3 small">
                          {getFormattedDateOnly(timeline.date)}
                        </span>
                        <div className="fw-bold">
                          {timeline.name}
                          <br />
                          <div className="text-success small fw-normal">
                            {timeline.numberOfRespondents}{" "}
                            {timeline.roleId
                              ? getLanguageValue(
                                  languageText,
                                  GetTypeOfRoleName[timeline.roleId],
                                )
                              : timeline.roleText
                                ? timeline.roleText
                                : getLanguageValue(languageText, "Respondents")}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={clsx(
                          "d-flex justify-content align-items-center",
                          !readOnly && "cursor-pointer",
                        )}
                        onClick={() => handleCourseNavigation(timeline.id)}
                        role="button"
                      >
                        <i className="bi bi-book me-3 fs-4 text-dark opacity-25"></i>
                        <span className="p-1 px-2 rounded bg-light text-secondary me-3 small">
                          {getFormattedDateOnly(timeline.date)}
                        </span>
                        <div className="fw-bold">
                          {timeline.name}
                          <div className="text-primary small fw-normal">
                            {getLanguageValue(languageText, "Academy")}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
