import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { DropdownMenu, DropdownMenuItem } from "../formComponents/dropdownMenu";

interface ICustomActionsCellProps {
  id?: number;
  email?: string;
  name?: string;
  languageText: ITranslationObject;
  changeTextValue?: string;
  editOption?: boolean;
  deleteOption?: boolean;
  resetPasswordOption?: boolean;
  changeTextOption?: boolean;
  handleEditClick?: (id: number) => void;
  handleDeleteClick?: (id: number, name: string) => void;
  handleResetPasswordClick?: (name: string, email: string) => void;
  handleChangeTextClick?: (changeTextValue: string) => void;
}

export const CustomActionsCell: React.FC<ICustomActionsCellProps> = (props) => {
  const onEditClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleEditClick?.(props.id!);
  };

  const onDeleteClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleDeleteClick?.(props.id!, props.name!);
  };

  const onResetPasswordClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleResetPasswordClick?.(props.name!, props.email!);
  };

  const onChangeTextClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleChangeTextClick?.(props.changeTextValue!);
  };

  const dropdownItems: Array<DropdownMenuItem> = [];

  if (props.editOption) {
    dropdownItems.push({
      name: getLanguageValue(props.languageText, "Edit"),
      onClick: onEditClick,
    });
  }

  if (props.deleteOption) {
    dropdownItems.push({
      name: getLanguageValue(props.languageText, "Delete"),
      onClick: onDeleteClick,
    });
  }

  if (props.resetPasswordOption) {
    dropdownItems.push({
      name: getLanguageValue(props.languageText, "Reset Password"),
      onClick: onResetPasswordClick,
    });
  }

  if (props.changeTextOption) {
    dropdownItems.push({
      name: getLanguageValue(props.languageText, "Change Text"),
      onClick: onChangeTextClick,
    });
  }

  return <DropdownMenu items={dropdownItems} />;
};
