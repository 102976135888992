import React from "react";
import { useNavigate } from "react-router-dom";

import { useCategoryCourses } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { limitTextAndEllipses } from "../../../commonUtils/limitTextAndEllipsesHelper";
import { ICourse } from "../types";
import { CourseCard } from "../coursesList/courseCard";
import { CourseProgressBar } from "../coursesList/courseCard/courseProgressBar";
import { ClassNames } from "@app/containers/commonEnums";
import { routePath } from "@app/containers/routePaths";
import { CourseLanguageSelector } from "../courseLanguageSelector/courseLanguageSelector";

export const CategoryCourses = (): JSX.Element => {
  const { languageText, subCategories, categoryCourses, handleStartCourse } =
    useCategoryCourses();
  const navigate = useNavigate();

  return (
    <>
      <div className="p-2 pt-4 p-md-4">
        <CourseLanguageSelector />

        <h4 className="mb-4 fw-bold">
          <span
            role="button"
            onClick={() => navigate(routePath.participantAcademy)}
          >
            {getLanguageValue(languageText, "Academy")}
          </span>{" "}
          / {subCategories.category.name}
        </h4>

        <div className="row mx-0 bg-primary bg-gradient bg-opacity-10 rounded">
          {/* Category Info */}
          <div className="col-12 col-md-4">
            <div className="p-2 p-md-3 mb-1">
              <div className="py-2">{subCategories.category.topic}</div>
              <h4 className="fw-bold">{subCategories.category.name}</h4>
              <div className="d-flex flex-wrap mt-2">
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-folder me-2" />
                  {subCategories.category.coursesCount}{" "}
                  {getLanguageValue(languageText, "subCategories")}
                  <i className="bi bi-book me-2 ms-4" />
                  {subCategories.category.lessonsCount}{" "}
                  {getLanguageValue(languageText, "lessons")}
                  <i className="bi bi-bookmark me-2 ms-4" />
                  {subCategories.category.topic}
                </div>
                <div className="py-2">{subCategories.category.description}</div>
              </div>
            </div>
          </div>
          {/* Courses */}
          <div className="clearfix"></div>
          <div className="row mx-0 px-md-3 mb-2 px-2">
            {subCategories.categoryCourses &&
            subCategories.categoryCourses.length > 0 ? (
              subCategories.categoryCourses.map((category, i) => (
                <div
                  key={category.categoryId}
                  className="col-12 col-md-4 mb-3 px-2"
                >
                  <div
                    id={i === 0 ? "academyInstruction5" : ""}
                    className="card bg-white border-0 h-100"
                  >
                    <div className="card-body p-3">
                      <h4 className="fw-bold text-primary">{category.name}</h4>
                      <div className="d-flex flex-wrap mt-3">
                        <div className="d-flex align-items-center mb-3 small text-secondary">
                          <i className="bi bi-file-text me-1 fs-6" />
                          {category.lessonsCount}{" "}
                          {getLanguageValue(languageText, "lessons")}
                          <i className="bi bi-asterisk me-1 fs-6 ms-3" />
                          {category.topic}
                        </div>
                        <div className="pb-4">
                          <span className="ellipsis-text">
                            {category.description &&
                              limitTextAndEllipses({
                                text: category.description,
                                limit: 150,
                              })}
                          </span>
                        </div>
                      </div>

                      <CourseProgressBar
                        id={i === 0 ? "academyInstruction6" : ""}
                        bgColor={ClassNames.Grey_bg}
                        progress={category.progress}
                      />
                    </div>
                    <div
                      className="card-footer p-3 border-0 bg-primary bg-opacity-25 btn-lg"
                      onClick={() => handleStartCourse(category.categoryId)}
                      role="button"
                    >
                      {getLanguageValue(languageText, "Start course")}{" "}
                      <i className="bi bi-arrow-right ps-2 text-dark" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="fs-3 fw-bold">
                {getLanguageValue(languageText, "No subCategories")}
              </div>
            )}
          </div>
        </div>
        {categoryCourses && categoryCourses.length > 0 && (
          <div className="row g-md-4 mt-3">
            {categoryCourses.map((course: ICourse, i: number) => (
              <div className="col-md-4" key={i}>
                <CourseCard
                  key={course.id}
                  index={i}
                  languageText={languageText}
                  course={course}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
