import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";

import { BooleanCell } from "../../../components/reactTable/booleanCell";
import { CustomActionsCell } from "../../../components/reactTable/customActionsCell";
import { RootState } from "@app/store/configureStore";
import {
  deletePresentationTemplateSlide,
  getPresentationSlides,
  getPresentationTemplateById,
} from "../actions";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { setSpinner } from "../../actions";

export interface IPresentationSlideList {
  id: number;
  slideNumber: number;
  partNumber: number;
  description: string;
  html: string;
  rules: string;
  htmlUrl: string;
  isActive: boolean;
  notes: string;
}

export const usePresentationSlideList = () => {
  const dispatch = useDispatch();
  const templateId = Number(useParams().id) || 0;
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [templateName, setTemplateName] = useState<string>("");
  const [data, setdata] = useState<IPresentationSlideList[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openSlideModal, setOpenSlideModal] = useState<boolean>(false); // Add/Edit slide popup
  const [slideUpdated, setSlideUpdated] = useState<boolean>(false); // This flag is used to re-reder the presentation slide data on requirement
  const [openReorderModal, setOpenReorderModal] = useState<boolean>(false);

  useEffect(() => {
    if (templateId) {
      getPresentationTemplateById(templateId, dispatch).then((response) => {
        if (response) {
          setTemplateName(response.description);
        }
      });
    }
  }, [templateId]);

  const fetchPresentationSlides = (skipCount: number): void => {
    dispatch(setSpinner(true));
    getPresentationSlides(
      filter,
      sorting,
      pageLimit,
      skipCount,
      templateId,
      dispatch,
    )
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setdata(response.items);
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (slideUpdated) setSlideUpdated(false);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchPresentationSlides(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    if (templateId) {
      setPageSelected(0);
      fetchPresentationSlides(0);
      if (!isMounted.current) isMounted.current = true;
    }
  }, [filter, pageLimit, templateId]);

  useEffect(() => {
    if (slideUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchPresentationSlides(skipCount);
    }
  }, [slideUpdated]);

  const createSlideClick = (): void => {
    setOpenSlideModal(true);
  };

  const refreshSlideList = (): void => setSlideUpdated(true);

  const closeSlideModal = (): void => {
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenSlideModal(false);
  };

  const handleEditClick = (id: number): void => {
    setEditedRowId(id);
    setOpenSlideModal(true);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  const handleDeletePresentationSlide = (): void => {
    deletePresentationTemplateSlide(deleteRowId, dispatch).then((response) => {
      if (response) {
        setDeleteRowId(0);
        setSelectedActionRowName("");
        setSlideUpdated(true);
      }
    });
  };

  const closeDeleteModal = (): void => {
    setDeleteRowId(0);
  };

  const reorderModalClick = (): void => {
    setOpenReorderModal(true);
  };

  const closeReorderModal = (): void => {
    setOpenReorderModal(false);
  };

  const tableHeader = useMemo<ColumnDef<IPresentationSlideList, unknown>[]>(
    () => [
      {
        header: getLanguageValue(languageText, "Actions"),
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            deleteOption
            languageText={languageText}
            id={props.row.original.id}
            name={props.row.original.description}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Slide No."),
        accessorKey: "slideNumber",
      },
      {
        header: getLanguageValue(languageText, "Part No."),
        accessorKey: "partNumber",
      },
      {
        header: getLanguageValue(languageText, "Description"),
        accessorKey: "description",
      },
      {
        header: getLanguageValue(languageText, "Rules"),
        accessorKey: "rules",
        width: "w-200px",
      },
      {
        header: getLanguageValue(languageText, "URL"),
        accessorKey: "htmlUrl",
      },
      {
        header: getLanguageValue(languageText, "Active"),
        accessorKey: "isActive",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.isActive}
          />
        ),
      },
    ],
    [languageText],
  );

  return {
    languageText,
    templateId,
    templateName,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openSlideModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    openReorderModal,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    createSlideClick,
    refreshSlideList,
    closeSlideModal,
    handleDeletePresentationSlide,
    closeDeleteModal,
    reorderModalClick,
    closeReorderModal,
    fetchPresentationSlides,
  };
};
