import { ITabNavItem } from "../components/multiTabComponent/tabNavItem";
import {
  ITranslationObject,
  getLanguageValue,
} from "./languageFunctionsHelper";

export enum InvitationNavTabs {
  email = "email",
  sms = "sms",
}

export const getInvitationNavTabs = (
  languageText: ITranslationObject,
): ITabNavItem<InvitationNavTabs>[] => [
  {
    id: InvitationNavTabs.email,
    title: getLanguageValue(languageText, "Email"),
  },
  {
    id: InvitationNavTabs.sms,
    title: getLanguageValue(languageText, "SMS"),
  },
];
