import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";
import clsx from "clsx";

interface IReactTooltipProps {
  id: string;
  tooltipText: string;
  className?: string;
  children: string;
}

export const ReactTooltip = (props: IReactTooltipProps) => {
  const { id, tooltipText, className, children } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentElement = ref.current;

    if (contentElement) {
      // Check if the content overflows its container
      if (contentElement.scrollWidth > contentElement.clientWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  }, [children]);

  return (
    <>
      <div
        data-tooltip-id={id}
        className={clsx("text-truncate", className && className)}
        ref={ref}
      >
        {children}
      </div>
      {showTooltip && (
        <Tooltip
          id={id}
          place="top"
          className="mw-250px fw-normal"
          content={tooltipText}
        />
      )}
    </>
  );
};
