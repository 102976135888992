import React from "react";

import { AffiliationDirectivenessGraph } from "../../components/affiliationDirectivenessGraph";
import { Adaptability } from "../../components/adaptability";
import { Directiveness } from "../../components/directiveness";
import { Affiliation } from "../../components/affiliation";
import { CreatedDate } from "../../components/createdDate";
import { CultureNorm } from "../../components/cultureNorm";
import { Respondents } from "../../components/respondents";
import { IProfileReport } from "../../../../interface";
import { ITranslationObject } from "@app/commonUtils/languageFunctionsHelper";

interface IIndividualPreviewProps {
  languageText: ITranslationObject;
  participantReport: IProfileReport;
}

export const IndividualPreview = (
  props: IIndividualPreviewProps,
): JSX.Element => {
  return (
    <div className="d-flex justify-content-between flex-wrap gap-3 ms-4">
      <div className="individual-matris">
        <div className="individual-matris-affDir border p-2 ps-4 pb-4">
          <div className="fs-5 fw-bold p-2">
            {props.participantReport.title}
          </div>
          <AffiliationDirectivenessGraph
            languageText={props.languageText}
            oAffPos={props.participantReport.oAffPos}
            oDirPos={props.participantReport.oDirPos}
            sAffPos={props.participantReport.sAffPos}
            sDirPos={props.participantReport.sDirPos}
            outsideStdDevA={props.participantReport.outsideStdDevA}
            outsideStdDevD={props.participantReport.outsideStdDevD}
          />
        </div>

        <div className="individual-matris-dir border border-top-0 p-2">
          <Directiveness
            languageText={props.languageText}
            profileMeasure={props.participantReport.directivenessMeasure!}
            directivenessMessage={props.participantReport.directivenessMessage!}
            directivenessSpreadText={
              props.participantReport.directivenessSpreadText ?? ""
            }
          />
        </div>

        <div className="individual-matris-aff border border-top-0 p-2">
          <Affiliation
            languageText={props.languageText}
            profileMeasure={props.participantReport.affiliationMeasure!}
            affiliationMessage={props.participantReport.affiliationMessage!}
            affiliationSpreadText={
              props.participantReport.affiliationSpreadText ?? ""
            }
          />
        </div>

        <div className="individual-matris-adap border border-top-0 p-2">
          <Adaptability
            languageText={props.languageText}
            oFlex={props.participantReport.oFlex}
            sFlex={props.participantReport.sFlex}
            outsideStdDevF={props.participantReport.outsideStdDevF}
            profileMeasure={props.participantReport.flexMeasure!}
            flexMessage={props.participantReport.flexMessage!}
            adaptabilitySpreadText={
              props.participantReport.adaptabilitySpreadText ?? ""
            }
          />
        </div>
      </div>

      <div>
        <CreatedDate
          languageText={props.languageText}
          createdDate={props.participantReport.date}
        />

        <div className="my-3">
          <CultureNorm
            languageText={props.languageText}
            language={props.participantReport.culture}
          />
        </div>

        <Respondents
          languageText={props.languageText}
          roleId={props.participantReport.roleId}
          roleText={props.participantReport.roleText}
          noOfRespondents={props.participantReport.noOfRespondents}
          respondents={props.participantReport.respondents}
        />
      </div>
    </div>
  );
};
