import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../../commonUtils/languageFunctionsHelper";
import { GroupPreview } from "./groupPreview";
import { IEditActivity } from "../../../../interface";
import { ReportTypes } from "../../../../../../../types";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ActivityId, ProfileId } from "@app/containers/reducer";

interface IGroupPreviewModalProps {
  id: ActivityId;
  languageText: ITranslationObject;
  profileData: IEditActivity;
  isSelf: boolean;
  selectedProfileIds: Array<ProfileId>;
  handleCloseClick: () => void;
}

export const GroupPreviewModal = (
  props: IGroupPreviewModalProps,
): JSX.Element => {
  const {
    languageText,
    profileData,
    isSelf,
    selectedProfileIds,
    handleCloseClick,
  } = props;

  //TODO: The report type should be passed directly from the parent component
  //but for now, we are using the isSelf to determine the report type
  const reportType = isSelf ? ReportTypes.SELF : ReportTypes.OTHER;

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, "Group Profile")}
        cancelButtonText={getLanguageValue(languageText, "Close")}
        handleCancelClick={handleCloseClick}
        width="lg"
      >
        <div className="border p-5">
          <GroupPreview
            id={props.id}
            languageText={languageText}
            profileData={profileData}
            reportType={reportType}
            selectedProfileIds={selectedProfileIds}
            messageTranslation={languageText}
          />
        </div>
      </ModalComponent>
    </>
  );
};
