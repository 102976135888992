import React from "react";
import clsx from "clsx";

interface ISwitchboxProps {
  label?: string;
  labelFirstText?: string;
  id?: number;
  name?: string;
  value?: boolean;
  inputClass?: string;
  labelClass?: string;
  disable?: boolean;
  handleSwitchboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Switchbox = (props: ISwitchboxProps) => {
  return (
    <div className="mb-6">
      {props.labelFirstText && (
        <div>
          <span
            className={clsx("fs-6 mb-2", props.labelClass && props.labelClass)}
          >
            {props.labelFirstText}
          </span>
        </div>
      )}

      <div className="form-switch">
        <input
          id={props.id ? String(props.id) : ""}
          className={clsx(
            "form-check-input cursor-pointer",
            props.inputClass && props.inputClass,
          )}
          type="checkbox"
          name={props.name ?? "name"}
          role="switch"
          checked={props.value}
          onChange={props.handleSwitchboxChange}
          disabled={props.disable ?? false}
        />
        {props.label && (
          <span
            className={clsx(
              "ms-2 form-check-label fs-6",
              props.labelClass && props.labelClass,
            )}
          >
            {props.label}
          </span>
        )}
      </div>
    </div>
  );
};
