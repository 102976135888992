import React, { useState } from "react";
import clsx from "clsx";

import { IPresentationProfile, IPresentationSlide } from "../hooks";
import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { classNames } from "@app/containers/utils";
import { ProfileId } from "@app/containers/reducer";

interface IPresentationHeaderProps {
  languageText: ITranslationObject;
  slideNumber: number;
  facilitatorSlides?: IPresentationSlide[];
  profileSlideCount?: number;
  profiles?: IPresentationProfile[];
  facilitatorName: string;
  participantName?: string;
  previousSlide?: () => void;
  nextSlide?: () => void;
  handleSlideClick?: (slideNumber: number) => void;
  handleCancelClick: () => void;
  openProfileDynamicSlides?: (profileId: ProfileId) => void;
  isConnected: (profileId: ProfileId) => boolean;
}

export const PresentationHeader = (props: IPresentationHeaderProps) => {
  const {
    languageText,
    slideNumber,
    facilitatorSlides,
    profileSlideCount,
    profiles,
    facilitatorName,
    participantName,
    previousSlide,
    nextSlide,
    handleSlideClick,
    handleCancelClick,
    openProfileDynamicSlides,
  } = props;

  const [showSlideList, setShowSlideList] = useState<boolean>(false);
  const [showSettingss, setShowSettings] = useState<boolean>(false);

  const handleProfileClick = (profileId: ProfileId): void => {
    openProfileDynamicSlides?.(profileId);
  };

  let totalSlideCount = 0;
  if (profileSlideCount) {
    // For Participant presentation
    totalSlideCount = profileSlideCount;
  } else {
    totalSlideCount = Math.max(
      ...(facilitatorSlides?.map((s) => s.slideNumber) ?? [0]),
    );
  }

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-lg bg-white border-bottom border-secondary-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="/DeliveryImages/IDI_Logotyp.webp" className="idi-logo" />
          </a>

          <div className="btn-group" role="group">
            {!participantName && (
              <div
                className="btn d-flex align-items-center"
                role="button"
                onClick={previousSlide}
              >
                <i className="bi bi-caret-left-fill fs-4" />
              </div>
            )}
            <button
              className="btn"
              onClick={
                !participantName
                  ? () => setShowSlideList(!showSlideList)
                  : undefined
              }
            >
              <i className="bi bi-easel-fill pe-2" />
              {slideNumber}/{totalSlideCount}
            </button>
            {!participantName && (
              <div
                className="btn d-flex align-items-center"
                role="button"
                onClick={!participantName ? nextSlide : undefined}
              >
                <i className="bi bi-caret-right-fill fs-4" />
              </div>
            )}
          </div>

          <div
            className="btn d-flex align-items-center"
            onClick={() => setShowSettings(true)}
          >
            <i className="bi bi-people-fill pe-2" />
            {profiles && (
              <>
                {
                  profiles.filter((profilePresentation) =>
                    props.isConnected(profilePresentation.profileId),
                  ).length
                }{" "}
                /{profiles.length}
              </>
            )}
          </div>
        </div>
      </nav>

      {/* Left Slide List */}
      <div
        className={clsx("offcanvas offcanvas-start", showSlideList && "show")}
        id="slides"
      >
        <div className="offcanvas-header border-bottom border-secondary-light">
          <h5 className="offcanvas-title" id="offcanvasLeftLabel">
            {getLanguageValue(languageText, "Slides")}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowSlideList(false)}
          />
        </div>
        <div className="offcanvas-body">
          {facilitatorSlides?.map((slide, idx) => {
            const clazz = classNames({
              "p-3 mb-1 border text-center": true,
              "border-primary": slide.slideNumber === slideNumber,
              "border-secondary-light": slide.slideNumber !== slideNumber,
            });

            return (
              <div
                key={idx}
                className={clazz}
                onClick={
                  slideNumber !== slide.slideNumber
                    ? () => handleSlideClick!(slide.slideNumber)
                    : () => {}
                }
                role="button"
              >
                {slide.slideNumber}. {slide.description}
              </div>
            );
          })}
        </div>
      </div>

      {/* Right Settings Menu */}
      <div
        className={clsx("offcanvas offcanvas-end", showSettingss && "show")}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header border-bottom border-secondary-light">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {getLanguageValue(languageText, "Settings")}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowSettings(false)}
          />
        </div>
        <div className="offcanvas-body">
          <div className="mb-3 p-3 rounded bg-secondary bg-opacity-25 fw-bold">
            <div
              className="float-end btn btn-sm btn-light"
              onClick={handleCancelClick}
            >
              <i className="bi bi-box-arrow-right pe-1" />
              {getLanguageValue(languageText, "Leave")}
            </div>
            <div className="py-1">
              {!participantName ? facilitatorName : participantName}
            </div>
          </div>

          {!participantName ? (
            profiles && (
              <>
                <div className="pt-3 pb-2">
                  {getLanguageValue(languageText, "Participants")} -{" "}
                  {
                    profiles.filter((profilePresentation) =>
                      props.isConnected(profilePresentation.profileId),
                    ).length
                  }
                  /{profiles.length}{" "}
                  {getLanguageValue(languageText, "connected")}:
                </div>
                {profiles.map((presentationProfile) => (
                  <div
                    key={presentationProfile.profileId}
                    className={clsx(
                      "p-2 mt-1 rounded bg-opacity-25",
                      props.isConnected(presentationProfile.profileId)
                        ? "bg-success"
                        : "border border-secondary-light",
                    )}
                    role="button"
                    onClick={() =>
                      handleProfileClick(presentationProfile.profileId)
                    }
                  >
                    <div className="dropdown-item">
                      <i
                        className={clsx(
                          "bi pe-1",
                          props.isConnected(presentationProfile.profileId)
                            ? "bi-circle-fill text-success"
                            : "bi-circle",
                        )}
                      />
                      {presentationProfile.forename}{" "}
                      {presentationProfile.surname}
                    </div>
                  </div>
                ))}
              </>
            )
          ) : (
            <div className="pt-3 pb-2">
              {getLanguageValue(languageText, "Facilitator")} -{" "}
              <span className="fw-bold">{facilitatorName}</span>
            </div>
          )}
        </div>
      </div>

      <div
        className={clsx(
          (showSlideList || showSettingss) && "offcanvas-backdrop show",
        )}
      ></div>
    </>
  );
};
