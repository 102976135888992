import { api } from "@app/api";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { Input } from "@app/components/formComponents/input";
import { RootState } from "@app/store/configureStore";
import { ApiResponse } from "@app/types";
import * as React from "react";
import { useSelector } from "react-redux";

type Props = unknown;

type FortnoxSetupWizardBody = {
  step: 1 | 2 | 3;
  clientId: string;
  clientSecret: string;
  redirectUri: string;
  scope: string;
  authorizationUrl: string;
  authorizationCode: string;
  currentCredentials: string;
};

const fieldNames: { [K in keyof FortnoxSetupWizardBody]: K } = {
  step: "step",
  clientId: "clientId",
  clientSecret: "clientSecret",
  redirectUri: "redirectUri",
  scope: "scope",
  authorizationUrl: "authorizationUrl",
  authorizationCode: "authorizationCode",
  currentCredentials: "currentCredentials",
};

const BUTTON_TITLE_BY_STEP = {
  1: "Get authorization URL",
  2: "Get access token",
  3: "Setup complete",
};

export const FortnoxSetup: React.FC<Props> = (props) => {
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const [body, setBody] = React.useState<FortnoxSetupWizardBody>({
    step: 1,
    clientId: "",
    clientSecret: "",
    redirectUri: "",
    scope: "",
    authorizationUrl: "",
    authorizationCode: "",
    currentCredentials: "",
  });

  React.useEffect(() => {
    api<ApiResponse<FortnoxSetupWizardBody>>({
      method: "GET",
      url: "/api/fortnox/setup",
    }).then((res) => {
      if (!res.data.success) {
        return Promise.reject();
      }
      setBody(res.data.result);
      return;
    });
  }, []);

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setBody({
      ...body,
      [event.target.name]: event.target.value,
    });
  }

  function onSubmitClick(event: React.MouseEvent): void {
    event.preventDefault();

    api<ApiResponse<FortnoxSetupWizardBody>>({
      method: "POST",
      url: "/api/fortnox/setup",
      data: body,
    }).then((res) => {
      if (!res.data.success) {
        return Promise.reject();
      }
      setBody(res.data.result);
      if (res.data.result.step === 2) {
        window.open(res.data.result.authorizationUrl, "_blank");
      }
      return;
    });
  }

  const isButtonDisabled =
    (body.step === 1 && (!body.clientId || !body.clientSecret)) ||
    (body.step === 2 && !body.authorizationCode) ||
    body.step === 3;

  return (
    <div className="p-2 pt-4 p-md-4">
      <h3 className="mb-4 fw-bold">
        {getLanguageValue(languageText, "Fortnox setup")}
      </h3>

      <div className="row">
        <div className="col-6">
          <Input
            type="text"
            label="Client Id"
            name={fieldNames.clientId}
            handleInputChange={onChange}
            value={body.clientId}
            disable={body.step > 1}
          />
          <Input
            type="text"
            label="Client Secret"
            name={fieldNames.clientSecret}
            handleInputChange={onChange}
            value={body.clientSecret}
            disable={body.step > 1}
          />
          <Input
            type="text"
            label="Redirect URI"
            name={fieldNames.redirectUri}
            handleInputChange={onChange}
            value={body.redirectUri}
            disable={body.step > 1}
          />
          <Input
            type="text"
            label="Scope"
            name={fieldNames.scope}
            handleInputChange={onChange}
            value={body.scope}
            disable={body.step > 1}
          />
          {body.step > 1 && (
            <>
              <div className="mb-3">
                <label>Authorization URL</label>
                <div>
                  {body.authorizationUrl && (
                    <a
                      href={body.authorizationUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {body.authorizationUrl}
                    </a>
                  )}
                  {!body.authorizationUrl && <span>N/A</span>}
                </div>
              </div>
              <Input
                type="text"
                label="Authorization Code"
                name={fieldNames.authorizationCode}
                handleInputChange={onChange}
                value={body.authorizationCode}
                disable={body.step > 2}
              />
            </>
          )}
          <button
            className="btn btn-primary"
            onClick={onSubmitClick}
            disabled={isButtonDisabled}
          >
            {BUTTON_TITLE_BY_STEP[body.step]}
          </button>
        </div>
        <div className="col-6">
          <div className="alert alert-info">
            <ol>
              <li>
                Enter your client id, client secret and optionally the redirect
                URI. Submit the form to generate an authorization URL.
              </li>
              <li>
                Click the generated authorization URL, which will take you to
                Fortnox.
              </li>
              <li>Login to Fortnox and accept the permissions.</li>
              <li>
                You will be redirected to the redirect URI. Copy the URL
                parameter 'code' and type it into 'Authorization Code'
              </li>
              <li>Submit the form again to complete installation.</li>
            </ol>
          </div>
          <div className="alert alert-info">
            Current credentials:
            <br />
            <pre>{body.currentCredentials || "N/A"}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
