import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IAddEditSlideModalProps } from "./addEditSlideModal";
import { RootState } from "@app/store/configureStore";
import { IFocusError } from "../../../../components/formComponents/input";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { setSpinner } from "../../../actions";
import {
  createPresentationTemplateSlide,
  getPresentationTemplateSlideById,
  updatePresentationTemplateSlide,
} from "../../actions";

const SlideInputs = {
  slideNumber: "slideNumber",
  partNumber: "partNumber",
  description: "description",
  html: "html",
  rules: "rules",
  isActive: "isActive",
  notes: "notes",
} as const;

export interface ICreateOrUpdateSlide {
  id: number;
  presentationTemplateId: number;
  slideNumber: number;
  partNumber: number;
  description: string;
  html: string;
  rules: string;
  isActive: boolean;
  notes: string;
}

interface ISlideInfo {
  slideNumber: number;
  partNumber: number;
  description: string;
  html: string;
  rules: string;
  isActive: boolean;
  notes: string;
}

export interface IFocusInput {
  slideNumber: IFocusError;
  partNumber: IFocusError;
  description: IFocusError;
  html: IFocusError;
  notes: IFocusError;
}

export const useAddEditSlideModal = (props: IAddEditSlideModalProps) => {
  const { slideId, templateId, refreshSlideList, closeSlideModal } = props;

  const dispatch = useDispatch();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const initialSlideInfoState: ISlideInfo = {
    slideNumber: 0,
    partNumber: 0,
    description: "",
    html: "",
    rules: "",
    isActive: true,
    notes: "",
  };
  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: "",
  };
  const [slideInfo, setSlideInfo] = useState<ISlideInfo>(initialSlideInfoState);
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    slideNumber: initialFocusInputState,
    partNumber: initialFocusInputState,
    description: initialFocusInputState,
    html: initialFocusInputState,
    notes: initialFocusInputState,
  });

  // Check for edit click
  let isEditClicked: boolean = false;
  if (slideId > 0) isEditClicked = true;

  useEffect(() => {
    if (isEditClicked) {
      getPresentationTemplateSlideById(slideId, dispatch).then(
        (response: ISlideInfo) => {
          if (response) {
            setSlideInfo({
              slideNumber: response.slideNumber,
              partNumber: response.partNumber,
              description: response.description ?? "",
              html: response.html,
              rules: response.rules ?? "",
              isActive: response.isActive ?? true,
              notes: response.notes,
            });
          }
        },
      );
    }
  }, []);

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [SlideInputs.slideNumber]: "Slide No. is required",
        [SlideInputs.partNumber]: "Part No. is required",
        [SlideInputs.description]: "Description is required",
        [SlideInputs.html]: "HTML is required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setSlideInfo({
      ...slideInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextEditorChange = (content: string) => {
    setSlideInfo({
      ...slideInfo,
      html: content,
    });

    if (focusInput.html.touched) {
      handleFormErrors(SlideInputs.html, content);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setSlideInfo({
      ...slideInfo,
      [name]: value,
    });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSlideInfo({
      ...slideInfo,
      [e.target.name]: e.target.checked,
    });
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !slideInfo.slideNumber ||
      focusInput.slideNumber.errorMessage ||
      !slideInfo.partNumber ||
      focusInput.partNumber.errorMessage ||
      !slideInfo.description ||
      focusInput.description.errorMessage ||
      !slideInfo.html ||
      focusInput.html.errorMessage
    ) {
      for (const item of Object.values(SlideInputs)) {
        if (item === SlideInputs.rules) continue;
        handleFormErrors(item, slideInfo[item]);
      }
      return false;
    }
    return true;
  };

  const handleSaveClick = (): void => {
    if (!handleValidationOnSubmit()) return;

    dispatch(setSpinner(true));
    const body: ICreateOrUpdateSlide = {
      id: slideId ?? 0,
      presentationTemplateId: templateId,
      slideNumber: slideInfo.slideNumber,
      partNumber: slideInfo.partNumber,
      description: slideInfo.description,
      html: slideInfo.html,
      rules: slideInfo.rules,
      isActive: slideInfo.isActive,
      notes: slideInfo.notes,
    };

    if (!slideId) {
      createPresentationTemplateSlide(body, dispatch)
        .then((response) => {
          if (response) {
            refreshSlideList();
            closeSlideModal();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    } else {
      updatePresentationTemplateSlide(body, dispatch)
        .then((response) => {
          if (response) {
            refreshSlideList();
            closeSlideModal();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  };

  return {
    languageText,
    isEditClicked,
    SlideInputs,
    slideInfo,
    focusInput,
    handleInputChange,
    handleTextEditorChange,
    handleTextAreaChange,
    handleBlurEvent,
    handleCheckboxChange,
    handleSaveClick,
  };
};
