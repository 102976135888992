import React from "react";
import clsx from "clsx";

export interface IButtonGroupList {
  id: number;
  name: string;
  title: string;
}

interface IButtonGroupProps {
  buttonList: IButtonGroupList[];
  className?: string;
  selectedBtn: string;
  unselectEnabled?: boolean; // To Unselect already selected button/option
  disabled?: boolean;
  handleButtonClick: (name: string) => void;
}

export const ToggleButtonGroup = (props: IButtonGroupProps): JSX.Element => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const name = (e.target as HTMLButtonElement).name;
    if (name === props.selectedBtn && !props.unselectEnabled) return;
    props.handleButtonClick(name);
  };

  return (
    <>
      {props.buttonList.map((list) => {
        const { id, name, title } = list;
        return (
          <button
            key={id}
            id={String(id)}
            name={name}
            className={clsx(
              "btn me-2",
              props.selectedBtn === name ? "btn-success" : "btn-light border",
              props.className && props.className,
            )}
            onClick={onButtonClick}
            disabled={props.disabled}
          >
            {title}
          </button>
        );
      })}
    </>
  );
};
