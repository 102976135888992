import clsx from "clsx";
import React from "react";

interface ICheckboxProps {
  label?: string;
  id?: string;
  name?: string;
  value?: boolean;
  className?: string;
  inputClass?: string;
  labelClass?: string;
  disable?: boolean;
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
}

export const Checkbox = (props: ICheckboxProps) => {
  return (
    <label
      className={clsx(
        "form-check d-flex align-items-center p-0",
        props.className && props.className,
      )}
      role={!props.disable ? "button" : ""}
      title={props.title}
    >
      <input
        id={props.id || ""}
        className={clsx(
          "form-check-input fs-3 m-0",
          props.inputClass && props.inputClass,
        )}
        type="checkbox"
        role="button"
        name={props.name ?? "name"}
        checked={props.value}
        onChange={props.handleCheckboxChange}
        disabled={props.disable ?? false}
      />
      {props.label && (
        <div
          className={clsx(
            "form-check-label ps-1 mt-1 ms-1",
            props.labelClass && props.labelClass,
          )}
        >
          {props.label}
        </div>
      )}
    </label>
  );
};
