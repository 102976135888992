import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import {
  getLanguageValue,
  ITranslationObject,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { TextArea } from "../../../../components/formComponents/textArea";
import { ISelectedProfilesList } from "../activityInviteParticipantsStep";
import {
  InvitationMessageListModal,
  ISavedMessage,
} from "./invitationMessageListModal";
import { PreviewPopup } from "./previewPopup";
import { MaxLength } from "../../../commonEnums";
import {
  getEmailStatusMessage,
  getSMSStatusMessage,
} from "../../editProfile/interface";
import { getLanguageTextByName } from "@app/containers/actions";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IEmailSMSInvitationProps {
  isEmail: boolean;
  languageText: ITranslationObject;
  messageTranslation: ITranslationObject;
  languages: ReadonlyArray<ILanguage>;
  languageValue: string;
  invitationText: string;
  sameInvitationForAll: boolean;
  invitationSent: boolean;
  profile?: ISelectedProfilesList;
  previewHTML?: string;
  sendingInvite: boolean;
  handleLanguageSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleInvitationTextChange: (value: string) => void;
  handleSelectedMessage: (selectedMessage: ISavedMessage) => void;
  handleSendClick: (isEmail: boolean, profile: ISelectedProfilesList) => void;
  handlePreviewClick?: (profile: ISelectedProfilesList) => void;
  closePreviewModal?: () => void;
}

export const EmailSMSInvitation = (props: IEmailSMSInvitationProps) => {
  const {
    isEmail,
    languageText,
    messageTranslation,
    languages,
    languageValue,
    invitationText,
    sameInvitationForAll,
    invitationSent,
    profile,
    previewHTML,
    sendingInvite,
    handleLanguageSelect,
    handleInvitationTextChange,
    handleSelectedMessage,
    handleSendClick,
    handlePreviewClick,
    closePreviewModal,
  } = props;

  const dispatch = useDispatch();

  const [updatedTranslation, setUpdatedTranslation] =
    useState<ITranslationObject>({} as ITranslationObject);
  const [invitationMessageModal, setInvitationMessageModal] =
    useState<boolean>(false);
  const [isDefaultSmsMessage, setIsDefaultSmsMessage] = useState<boolean>(true);
  const [smsDefaultMessage, setSmsDefaultMessage] = useState<string>("");
  const [isSmsText, setIsSmsText] = useState<boolean>(false);

  useEffect(() => {
    if (isEmail && isSmsText) setIsSmsText(false);
    else if (!isEmail && !isSmsText) setIsSmsText(true);
  }, [isEmail]);

  useEffect(() => {
    setUpdatedTranslation(messageTranslation);
  }, [messageTranslation]);

  useEffect(() => {
    if (!isEmail && isDefaultSmsMessage) {
      const defaultMessage = getLanguageValue(
        updatedTranslation,
        "SMS-Delegate-invite-message",
      );
      setSmsDefaultMessage(defaultMessage);
      handleInvitationTextChange(defaultMessage);
    }
  }, [updatedTranslation]);

  const onLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
    if (invitationSent) return;

    getLanguageTextByName(selectedItem.value, dispatch).then((lang) => {
      setUpdatedTranslation(lang);
    });

    handleLanguageSelect(selectedItem);
  };

  const onSelectMessageClick = (): void => {
    if (invitationSent) return;

    setInvitationMessageModal(true);
  };

  const onInvitationTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    if (invitationSent) return;
    handleInvitationTextChange(e.target.value);
    !isEmail && setIsDefaultSmsMessage(false);
    setSmsDefaultMessage(e.target.value.slice(0, isEmail ? undefined : 124));
    if (!isEmail) {
      setIsSmsText(true);
    }
  };

  const handleCancelClick = (): void => {
    setInvitationMessageModal(false);
  };

  const handleSaveClick = (selectedMessage: ISavedMessage): void => {
    handleCancelClick();
    handleSelectedMessage(selectedMessage);
    !isEmail && setIsDefaultSmsMessage(false);
  };

  const onSendClick = (): void => {
    handleSendClick(isEmail, profile!);
  };

  const onPreviewClick = (): void => {
    handlePreviewClick && handlePreviewClick(profile!);
  };

  const onCloseClick = (): void => {
    closePreviewModal && closePreviewModal();
  };

  const languageDropdownItems: Array<IDropdownList> = languages.map((l) => {
    return {
      id: l.id,
      displayName: l.displayName,
      value: l.name,
    };
  });

  return (
    <>
      <div className="row my-4">
        <div className="col-md-3">
          <label className="fw-normal">
            {getLanguageValue(languageText, "Language")}:
          </label>

          <DropdownSelect
            name="language"
            list={languageDropdownItems}
            value={languageValue}
            handleDropdownSelect={onLanguageSelect}
            searchOption
          />
        </div>

        {!sameInvitationForAll && (
          <>
            <div className="col-md-3">
              <div>{getLanguageValue(languageText, "Name")}:</div>
              <div className="fw-bold fs-5 mb-3">{profile?.name}</div>
            </div>
            <div className="col-md-6">
              {isEmail && (
                <>
                  <div>{getLanguageValue(languageText, "Email")}:</div>
                  <div className="fw-bold fs-5 mb-3">{profile?.email}</div>
                </>
              )}
              {
                // AM: Show Phonenumber here
              }
            </div>
          </>
        )}
      </div>

      {isEmail && (
        <div className="fs-5 mb-6 mt-3">
          {getLanguageValue(updatedTranslation, "EM-AL-CO-Greeting")}{" "}
          {profile && profile.id > 0 && `${profile.name}!`}
          <br />
          {profile && (profile.emailStatus ?? 0) > 1 && (
            <>
              {getLanguageValue(
                updatedTranslation,
                "EM-AL-CO-FriendlyReminder",
              )}
              <br />
            </>
          )}
          {getLanguageValue(updatedTranslation, "EM-DE-IN-Title")}
          <br />
          <br />
          {getLanguageValue(updatedTranslation, "EM-DE-IN-Text")}
        </div>
      )}

      <div
        className="fw-bold fs-5 my-4 text-decoration-underline"
        role="button"
        onClick={onSelectMessageClick}
      >
        {getLanguageValue(languageText, "Add a saved message")}
      </div>

      {invitationMessageModal && (
        <InvitationMessageListModal
          isEmail={isEmail}
          languageText={languageText}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
        />
      )}

      <TextArea
        name="template"
        value={
          isDefaultSmsMessage && smsDefaultMessage
            ? smsDefaultMessage
            : invitationText
        }
        onChange={onInvitationTextChange}
        maxLength={isSmsText ? MaxLength.L124 : 0}
      />
      <div className="my-4 d-flex align-items-center">
        <button
          className={clsx(
            "btn me-4",
            invitationSent || sendingInvite ? "btn-light" : "btn-success",
          )}
          onClick={onSendClick}
          disabled={invitationSent || sendingInvite}
        >
          <i className="bi bi-send-fill me-2"></i>
          {getLanguageValue(
            languageText,
            profile && (profile.emailStatus ?? 0) > 1 ? "Reminder" : "Send",
          )}
        </button>

        {isEmail && (
          <div
            className="text-decoration-underline"
            onClick={onPreviewClick}
            role="button"
          >
            {getLanguageValue(languageText, "Preview")}
          </div>
        )}
      </div>

      {isEmail && previewHTML && (
        <PreviewPopup
          languageText={languageText}
          previewHTML={previewHTML}
          onCloseClick={onCloseClick}
        />
      )}

      {invitationSent && !sameInvitationForAll && (
        <div className="fs-6 text-white mt-4 mb-2">
          <span className="bg-success rounded p-2">
            {isEmail
              ? getLanguageValue(
                  languageText,
                  getEmailStatusMessage(profile!.emailStatus!),
                )
              : getLanguageValue(
                  languageText,
                  getSMSStatusMessage(profile!.smsStatus!),
                )}
          </span>
        </div>
      )}
    </>
  );
};
