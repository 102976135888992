import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@app/store/configureStore";
import { routePath } from "../../containers/routePaths";
import { ILandingContent } from "./newsList/types";

export const useInformation = () => {
  const navigate = useNavigate();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const handleNavigateLatestNews = (): void => {
    navigate(routePath.latestNews);
  };

  const handleNavigateLatestBlogs = (): void => {
    navigate(routePath.latestBlogs);
  };

  const handleNavigateDocument = (): void => {
    navigate(routePath.latestDocument);
  };

  const landingContent = useMemo<ILandingContent[]>(
    () => [
      {
        id: 1,
        title: "Documents",
        details: "Find and download IDI documents for your facilitation.",
        imageSrc: "/DeliveryImages/doclogo.png",
        function: handleNavigateDocument,
      },
      {
        id: 2,
        title: "Latest News",
        details:
          "View the latest news and read the latest about our product updates.",
        imageSrc: "/DeliveryImages/latestnews.png",
        function: handleNavigateLatestNews,
      },
      {
        id: 3,
        title: "Latest Blogs",
        details: "Insights on people development and employee engagement.",
        imageSrc: "/DeliveryImages/blogs.png",
        function: handleNavigateLatestBlogs,
      },
    ],
    [languageText],
  );

  return {
    languageText,
    landingContent,
  };
};
