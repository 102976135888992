import React from "react";
import { IPresentationSlide, renderSlideDynamicHTML } from "../hooks";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";

interface IDynamicScreenModalProps {
  languageText: ITranslationObject;
  slideStep: number | null;
  participantDynamicSlide: IPresentationSlide;
  handleCloseModal: () => void;
}

export const DynamicScreenModal: React.FC<IDynamicScreenModalProps> = (
  props,
) => {
  return (
    <>
      <ModalComponent
        width="xl"
        headerText={getLanguageValue(props.languageText, "Participants Slide")}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseModal}
      >
        {renderSlideDynamicHTML(props.participantDynamicSlide, props.slideStep)}
      </ModalComponent>
    </>
  );
};
