import { TranslateFn } from "@app/commonUtils/languageFunctionsHelper";
import * as React from "react";

type CurrentStepPanelProps = React.PropsWithChildren<{
  __t: TranslateFn;
  title: React.ReactNode;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  onPreviousClick?: (event: React.MouseEvent) => unknown;
  onNextClick?: (event: React.MouseEvent) => unknown;
}>;

export const CurrentStepPanel: React.FC<CurrentStepPanelProps> = (props) => {
  const __t = props.__t;
  const wantsButtonSection = !!(props.onPreviousClick || props.onNextClick);

  return (
    <div className="bg-white border rounded mb-4">
      {props.title && (
        <div className="p-4 fs-5 fw-bold border-bottom d-flex">
          <i className="bi bi-exclamation-circle-fill me-2 flex-grow-0" />
          <div className="flex-grow-1">{props.title}</div>
        </div>
      )}
      {props.children}
      {wantsButtonSection && (
        <div className="d-flex bg-success bg-opacity-10 rounded-bottom p-4">
          {props.onPreviousClick && (
            <button
              className="btn"
              disabled={props.previousDisabled}
              onClick={props.onPreviousClick}
            >
              <i className="bi bi-arrow-left me-2" />
              {__t("Go back")}
            </button>
          )}
          <div className="flex-grow-1" />
          {props.onNextClick && (
            <button
              className="btn btn-success"
              disabled={props.nextDisabled}
              onClick={props.onNextClick}
            >
              {__t("Continue")}
              <i className="bi bi-arrow-right me-2" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

type UpcomingStepPanelProps = {
  title: React.ReactNode;
};

export const UpcomingStepPanel: React.FC<UpcomingStepPanelProps> = (props) => {
  return (
    <div className="bg-secondary bg-opacity-10 rounded p-4 mb-2 fw-bold">
      <i className="bi bi-circle-fill opacity-25 me-2" />
      {props.title}
    </div>
  );
};

type CompletedStepPanelProps = {
  title: React.ReactNode;
};

export const CompletedStepPanel: React.FC<CompletedStepPanelProps> = (
  props,
) => {
  return (
    <div className="bg-white rounded p-4 mb-2">
      <i className="bi bi-check-circle-fill text-success me-2" />
      {props.title}
    </div>
  );
};
