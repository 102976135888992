import React from "react";

import { ReactTable } from "../../../components/reactTable/reactTable";
import { DeleteModal } from "../../../components/modals/deleteModal";
import { AddEditSlideModal } from "./addEditSlideModal/addEditSlideModal";
import { usePresentationSlideList } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ImportExportModal } from "@app/components/importExportModal";
import {
  exportPresentationTemplateSlides,
  importPresentationTemplateSlides,
} from "../actions";
import { useDispatch } from "react-redux";
import { SlideReorderModal } from "./modals/slideReorderModal";

export const PresentationSlideList = (): JSX.Element => {
  const {
    languageText,
    templateId,
    templateName,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openSlideModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    openReorderModal,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    createSlideClick,
    refreshSlideList,
    closeSlideModal,
    handleDeletePresentationSlide,
    closeDeleteModal,
    reorderModalClick,
    closeReorderModal,
    fetchPresentationSlides,
  } = usePresentationSlideList();

  const dispatch = useDispatch();
  const [showImportExportModal, setShowImportExportModal] =
    React.useState(false);

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={templateName}
        searchPlaceholder={getLanguageValue(languageText, "Search Slide")}
        buttonText={getLanguageValue(languageText, `Create Slide`)}
        buttonSecondOptionalText={getLanguageValue(
          languageText,
          "Import & export",
        )}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={createSlideClick}
        handleSecondButtonClick={() => setShowImportExportModal(true)}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
        customButtons={
          <button
            type="button"
            className="btn btn-success"
            onClick={reorderModalClick}
          >
            {getLanguageValue(languageText, "Reorder Slides")}
          </button>
        }
      />

      {openSlideModal && (
        <AddEditSlideModal
          slideId={editedRowId}
          templateId={templateId}
          refreshSlideList={refreshSlideList}
          closeSlideModal={closeSlideModal}
        />
      )}

      {deleteRowId > 0 && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, "Slide")}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeletePresentationSlide}
          closeDeleteModal={closeDeleteModal}
        />
      )}

      {showImportExportModal && (
        <ImportExportModal
          name="presentation-template-slides"
          lang={languageText}
          handleClose={() => setShowImportExportModal(false)}
          handleExport={() => {
            return exportPresentationTemplateSlides(templateId, dispatch);
          }}
          handleImport={(data) => {
            return importPresentationTemplateSlides(
              templateId,
              data,
              dispatch,
            ).then(() => {
              fetchPresentationSlides(0);
            });
          }}
        />
      )}

      {openReorderModal && (
        <SlideReorderModal
          languageText={languageText}
          templateId={templateId}
          refreshSlideList={refreshSlideList}
          closeReorderModal={closeReorderModal}
        />
      )}
    </>
  );
};
