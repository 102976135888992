import React, { useEffect, useState } from "react";
import clsx from "clsx";

interface IFileUpload {
  errorMessage?: string;
  name: string;
  disabled?: boolean;
  label?: string;
  file?: string;
  isDocType?: boolean;
  fileName?: string;
  containerClassname?: string;
  imagePath?: string;
  removeIconBtnText?: string;
  selectIconBtnText?: string;
  isEditMode?: boolean;
  handleFileUpload: (file: File, docName?: string) => void;
  removeIcon?: () => void;
  onOpenExistingIconModal?: () => void;

  /**
   * Array of file suffixes that are allowed. For example '.jpg' and '.gif'.
   * Defaults to '.jpg, .png, .svg, .jpeg'
   */
  acceptFileTypes?: Array<string>;
}

export const FileUpload = (props: IFileUpload): JSX.Element => {
  const [isFileTypeImage, setIsFileTypeImage] = useState<boolean>(true);

  useEffect(() => {
    if (!props.isDocType) return;
    setIsFileTypeImage(false);
  }, [props.isDocType]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const uploadedFile = event.target.files && event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.type;

      if (fileType.startsWith("image/") || fileType === "image/svg+xml") {
        setIsFileTypeImage(true);
        props.handleFileUpload(uploadedFile!);
      } else {
        setIsFileTypeImage(false);
        props.handleFileUpload(uploadedFile!, uploadedFile.name);
      }
    }
  };

  const accept = (
    props.acceptFileTypes ?? [".jpg", ".png", ".svg", ".jpeg"]
  ).join(",");

  return (
    <>
      <div
        className={clsx(
          "mb-3",
          props.containerClassname && props.containerClassname,
        )}
      >
        {props.label && (
          <label htmlFor="file-upload" className="mb-2 required">
            {props.label}
          </label>
        )}
        {!props.imagePath ? (
          <div className="d-flex gap-4">
            <input
              disabled={props.disabled ?? false}
              id="file-upload"
              type="file"
              name={props.name}
              accept={accept}
              className={clsx(
                "form-control",
                props.errorMessage && "border-danger bg-light-danger",
              )}
              onChange={handleChange}
            />
            {props.selectIconBtnText ? (
              <div
                className="btn btn-sm btn-success text-nowrap text-center d-flex align-items-center"
                onClick={props.onOpenExistingIconModal}
              >
                {props.selectIconBtnText}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="d-flex gap-2 mb-6 align-items-end">
            <div>
              {isFileTypeImage ? (
                <img
                  src={props.imagePath}
                  width="150"
                  height="150"
                  className="img-thumbnail w-150px h-150px"
                  alt="Uploaded Image"
                />
              ) : (
                <span>{props.fileName}</span>
              )}
            </div>
            <>
              <div className="btn btn-sm btn-danger" onClick={props.removeIcon}>
                {props.removeIconBtnText}
              </div>
            </>
          </div>
        )}
        {props.errorMessage &&
          !props.imagePath?.match(/\.(png|jpe?g|svg)$/i) && (
            <div className="text-danger">{props.errorMessage}</div>
          )}
        {props.file && isFileTypeImage && (
          <div>
            <img
              src={props.file}
              className="rounded mx-auto mw-300px"
              alt="Uploaded Image"
            />
          </div>
        )}

        {props.file && !isFileTypeImage && (
          <div>
            <a
              className="rounded mx-auto mw-300px"
              href={props.imagePath}
              download={props.fileName}
            >
              {props.fileName}
            </a>
          </div>
        )}
      </div>
    </>
  );
};
