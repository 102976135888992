import * as React from "react";
import {
  IParticipantProfileGroupReport,
  IProfileReport,
  IProfileGroupReport,
} from "./profileList/editProfile/interface";
import { Map } from "@app/types";
import { ProfileId } from "./reducer";

export function truncate(message: string, n: number): string {
  return message.length > n ? `${message.slice(0, n - 1)}...` : message;
}

export function emptyParticipantProfileReport(): IProfileReport {
  return {
    name: "",
    title: "",
    culture: "",
    date: "",
    roleId: 0,
    roleText: "",
    noOfRespondents: 0,
    respondents: [],
    sFlex: 0,
    oFlex: 0,
    oDirPos: 0,
    sDirPos: 0,
    oAffPos: 0,
    sAffPos: 0,
    outsideStdDevD: 0,
    outsideStdDevA: 0,
    outsideStdDevF: 0,
    flexMessage: "",
    directivenessMessage: "",
    affiliationMessage: "",
    reportProfileDescription: "",
    directivenessSpreadText: "",
    affiliationSpreadText: "",
    adaptabilityText1: "",
    adaptabilityRespondentsText: "",
    adaptabilityText2: "",
    adaptabilitySpreadText: "",
    adaptabilityText3: "",
  };
}

export function emptyParticipantProfileGroupReport(): IParticipantProfileGroupReport {
  return {
    averageAdaptability: 0,
    averageAffiliation: 0,
    averageDirectiveness: 0,
    groupReportDirectivenesses: [
      {
        pointId: 0,
        affPos: 0,
        dirPos: 0,
        count: 0,
        profileIds: [],
      },
    ],
    groupReportAdaptabilities: [
      {
        adaptabilityIndex: "",
        flex: 0,
        count: 0,
        sMarginTop: "",
        oMarginTop: "",
        profileIds: [],
      },
    ],
    groupReportProfiles: [
      {
        pointId: 0,
        adaptabilityIndex: "",
        name: "",
        profileId: 0 as ProfileId,
      },
    ],
  };
}

export function emptyProfileGroupReport(): IProfileGroupReport {
  return {
    selfGroupReport: {
      averageAdaptability: 0,
      averageAffiliation: 0,
      averageDirectiveness: 0,
      groupReportDirectivenesses: [],
      groupReportAdaptabilities: [],
      groupReportProfiles: [],
    },
    otherGroupReport: {
      averageAdaptability: 0,
      averageAffiliation: 0,
      averageDirectiveness: 0,
      groupReportDirectivenesses: [],
      groupReportAdaptabilities: [],
      groupReportProfiles: [],
    },
  };
}

export function classNames(names: Map<unknown>): string {
  return Object.keys(names)
    .filter((key) => !!names[key])
    .join(" ");
}

function hasMatchingTypes<A>(known: A, other: unknown): other is A {
  if (known === null) {
    return other === null;
  }
  if (Array.isArray(known)) {
    return Array.isArray(other);
  }
  if (typeof known === "object") {
    if (typeof other !== "object" || other === null) {
      return false;
    }
    for (const key in known) {
      if (!hasMatchingTypes(known[key], (other as any)[key])) {
        return false;
      }
    }
    return true;
  }
  return typeof known === typeof other;
}

export function tryParseJson<T>(value: unknown, defaultValue: T): T {
  if (typeof value !== "string") {
    return defaultValue;
  }

  try {
    const parsed = JSON.parse(value);
    if (hasMatchingTypes(defaultValue, parsed)) {
      return parsed;
    }
  } catch (e) {
    // nope.
  }

  return defaultValue;
}

export function keyBy<T>(
  items: ReadonlyArray<T>,
  getKeyFn: (item: T) => unknown,
): { [key: string]: T } {
  const keyed: { [key: string]: T } = {};
  for (const item of items) {
    const key = String(getKeyFn(item));
    if (!(key in keyed)) {
      keyed[key] = item;
    }
  }
  return keyed;
}

export function uniqueBy<T>(
  items: ReadonlyArray<T>,
  getKeyFn: (item: T) => unknown,
): ReadonlyArray<T> {
  const keyed = keyBy(items, getKeyFn);
  return Object.values(keyed);
}

export function hasOwnProperty<K extends string | number>(
  value: unknown,
  prop: K,
): value is { [P in K]: unknown } {
  return Object.prototype.hasOwnProperty.call(value, prop);
}

export function range(
  start: number,
  endExclusive: number,
): ReadonlyArray<number> {
  const length = Math.max(0, endExclusive - start);
  return new Array(length).fill(0).map((x, i) => start + i);
}

export function joinPaths(...paths: string[]): string {
  return paths
    .map((path, index) => {
      if (index === 0) {
        // First element (protocol or domain) - remove only trailing slashes
        return path.replace(/\/+$/, "");
      } else {
        // All other elements - remove leading and trailing slashes
        return path.replace(/^\/+|\/+$/g, "");
      }
    })
    .join("/");
}

type Fn<T extends any[], R> = (...args: T) => R;

export function debounce<T extends any[]>(
  func: Fn<T, unknown>,
  wait: number,
): Fn<T, void> {
  let currentContext: unknown;
  let currentArguments: T;
  let timeout: number | undefined;

  function later(): void {
    timeout = undefined;
    func.apply(currentContext, currentArguments);
  }

  return function (this: unknown, ...args: T): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    currentContext = this;
    currentArguments = args;

    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
  };
}

type DateFormatter = (date: Date) => string;

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

// https://unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
const DATE_FORMATTERS: { [pattern: string]: DateFormatter } = {
  yyyy: (date) => date.getFullYear().toString().padStart(4, "0"),
  MMMM: (date) => MONTH_NAMES[date.getMonth()],
  MMM: (date) => MONTH_NAMES[date.getMonth()].substring(0, 3),

  // months are 0-indexed in js :)
  MM: (date) => (date.getMonth() + 1).toString().padStart(2, "0"),
  dd: (date) => date.getDate().toString().padStart(2, "0"),
  HH: (date) => date.getHours().toString().padStart(2, "0"),
  mm: (date) => date.getMinutes().toString().padStart(2, "0"),
  ss: (date) => date.getSeconds().toString().padStart(2, "0"),
};

/**
 * Formats a date according to a unicode-standard date pattern.
 *
 * @see https://unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
export function formatDate(date: Date | string, format: string): string {
  const dt = typeof date === "string" ? new Date(date) : date;
  const pattern = new RegExp(Object.keys(DATE_FORMATTERS).join("|"), "g");
  return format.replace(pattern, (match) => {
    return DATE_FORMATTERS?.[match](dt) ?? match;
  });
}

/**
 * A hook that returns the 'value' that was assigned on the previous render cycle.
 * Useful for comparing old to new state, for example.
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T | undefined>(undefined);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
