import React from "react";

import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { DropdownSelect } from "@app/components/formComponents/dropdownSelect";
import {
  CoursesPermissionAccess,
  DateLimit,
  PermissionType,
} from "../../../../commonUtils/coursePermissionHelper";
import { ReactDatePicker } from "../../../../components/datePicker/ReactDatePicker";
import { DynamicSearchInputField } from "../../../../components/formComponents/dynamicSearchInputField";
import { CoursePermission, useCoursePermissionModal } from "./hooks";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { getFormattedDateOnly } from "@app/commonUtils/dateFunctionsHelper";

interface ICoursePermissionPropType {
  courseId: number;
  courseTitle: string;
  closeModal: () => void;
}

export const CoursePermissionModal: React.FC<ICoursePermissionPropType> = (
  props,
) => {
  const {
    languageText,
    accessList,
    PermissionTypeList,
    dateLimitList,
    coursePermissionInfo,
    suggestions,
    allCoursePermissionInfo,
    editingIndex,
    selectedUser,
    errorMessage,
    isError,
    handleUnSelect,
    handleDateSelect,
    deleteCoursePermission,
    handlePermissionIdClick,
    handlePermissionUserInputChange,
    handlePermissionAccountInputChange,
    handlePermissionSelect,
    onSubmitPermission,
  } = useCoursePermissionModal(props);

  const truncatedText =
    props.courseTitle.length > 25
      ? `${props.courseTitle.slice(0, 25)}...`
      : props.courseTitle;

  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(
          languageText,
          "Academy Course Permission Settings",
        )}: ${truncatedText}`}
        cancelButtonText={getLanguageValue(languageText, "Close")}
        handleCancelClick={props.closeModal}
        width="xl"
      >
        <form>
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 row">
              <div className="col-lg-4 col-xl-3 col-12">
                <DropdownSelect
                  name={CoursePermission.access}
                  label={getLanguageValue(languageText, "Access")}
                  defaultLabel={getLanguageValue(languageText, "Select")}
                  list={accessList}
                  value={coursePermissionInfo.access}
                  handleDropdownSelect={handlePermissionSelect}
                  required
                />
              </div>
              <div className="col-lg-4 col-xl-4 col-12">
                <DropdownSelect
                  name={CoursePermission.permissionType}
                  label={getLanguageValue(languageText, "Type")}
                  defaultLabel={getLanguageValue(languageText, "Select")}
                  list={PermissionTypeList}
                  value={coursePermissionInfo.permissionType}
                  handleDropdownSelect={handlePermissionSelect}
                  required
                />
              </div>
              <div className="col-lg-4 col-xl-5 col-12">
                <DynamicSearchInputField
                  languageText={languageText}
                  label={getLanguageValue(languageText, "Name")}
                  placeholder={getLanguageValue(languageText, "Search")}
                  list={suggestions}
                  selectedValue={selectedUser}
                  disable={!coursePermissionInfo.permissionType}
                  fetchList={
                    coursePermissionInfo.permissionType &&
                    coursePermissionInfo.permissionType ===
                      String(PermissionType.User)
                      ? handlePermissionUserInputChange
                      : handlePermissionAccountInputChange
                  }
                  handleSelect={handlePermissionIdClick}
                  handleUnSelect={handleUnSelect}
                  required
                />
              </div>
            </div>

            <div className="col-lg-5 col-12 row">
              <div className="col-lg-4 col-12">
                <DropdownSelect
                  name={CoursePermission.dateLimit}
                  label={getLanguageValue(languageText, "Date limit")}
                  defaultLabel={getLanguageValue(languageText, "Select")}
                  list={dateLimitList}
                  value={coursePermissionInfo.dateLimit}
                  handleDropdownSelect={handlePermissionSelect}
                  required
                />
              </div>

              {Number(coursePermissionInfo.dateLimit) === DateLimit.Yes && (
                <div className="d-flex gap-4 col-lg-8 col-12 pe-6 pe-lg-0">
                  <ReactDatePicker
                    label="Start Date"
                    className="col-6"
                    placeholder={getLanguageValue(languageText, "Select Date")}
                    name={CoursePermission.fromDate}
                    date={coursePermissionInfo.fromDate}
                    maxDate={new Date(String(coursePermissionInfo.toDate))}
                    handleDateSelect={handleDateSelect}
                  />

                  <ReactDatePicker
                    label="End Date"
                    className="col-6"
                    placeholder={getLanguageValue(languageText, "Select Date")}
                    name={CoursePermission.toDate}
                    date={coursePermissionInfo.toDate}
                    minDate={new Date(String(coursePermissionInfo.fromDate))}
                    handleDateSelect={handleDateSelect}
                  />
                </div>
              )}
            </div>

            <div className="col-lg-1 col-12 d-flex justify-content-center ms-auto">
              <button className="btn btn-primary" onClick={onSubmitPermission}>
                <i className="bi bi-plus-lg" />
              </button>
            </div>
          </div>
          <div className="text-danger">{errorMessage}</div>
        </form>

        <div className="border-top border-bottom mb-6 mt-6" />
        {allCoursePermissionInfo?.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-striped align-middle">
              <thead>
                <tr>
                  <th>Access type</th>
                  <th>Permission type</th>
                  <th>Name</th>
                  <th>Date limit</th>
                  <th>Date range</th>
                </tr>
              </thead>
              <tbody>
                {allCoursePermissionInfo.map((coursePermission, i) => {
                  const displayName =
                    coursePermission.permissionType === PermissionType.User
                      ? coursePermission.userName
                      : coursePermission.clientName;

                  return (
                    <tr key={i}>
                      <td>
                        {coursePermission.access ===
                        CoursesPermissionAccess.Allow ? (
                          <div className="badge text-bg-success">Allow</div>
                        ) : (
                          <div className="badge text-bg-danger">Deny</div>
                        )}
                      </td>
                      <td>
                        {coursePermission.permissionType ===
                        PermissionType.Account ? (
                          <div>Account</div>
                        ) : (
                          <div>User</div>
                        )}
                      </td>
                      <td>{displayName}</td>

                      <td>{coursePermission.dateLimit ? "Yes" : "No"}</td>
                      <td>
                        {coursePermission.dateLimit &&
                          coursePermission.fromDate &&
                          coursePermission.toDate && (
                            <>
                              <span className="text-nowrap">
                                {getFormattedDateOnly(
                                  coursePermission.fromDate,
                                )}
                              </span>
                              <span>&nbsp;-&nbsp;</span>
                              <span className="text-nowrap">
                                {getFormattedDateOnly(coursePermission.toDate)}
                              </span>
                            </>
                          )}
                      </td>
                      <td className="col-12 col-lg-1">
                        <div className="float-end">
                          <div
                            className="btn btn-link"
                            role="button"
                            onClick={() =>
                              deleteCoursePermission(
                                Number(coursePermission.id),
                              )
                            }
                          >
                            <i className="bi bi-trash text-danger" />
                          </div>
                        </div>
                      </td>
                      {isError[i] && editingIndex.includes(i) && (
                        <div className="text-danger ">{isError[i]}</div>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            {getLanguageValue(languageText, "No data avaliable")}
          </div>
        )}
      </ModalComponent>
    </>
  );
};
