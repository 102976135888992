import React from "react";
import clsx from "clsx";

import { ICombinedGroupReportParticipants } from "../../../../interface";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../../commonUtils/languageFunctionsHelper";
import { ReportType, ReportTypes } from "../../../../../../../types";
import { ProfileId } from "@app/containers/reducer";

interface IGroupProfileParticipantsProps {
  languageText: ITranslationObject;
  profileParticipants: ICombinedGroupReportParticipants[];
  hoveredProfileId: ProfileId;
  handleHover: (profileId: ProfileId) => void;
  handleMouseLeave: () => void;
  showUsers?: boolean;
  reportType: ReportType;
}

export const GroupProfileParticipants = (
  props: IGroupProfileParticipantsProps,
): JSX.Element => {
  const {
    languageText,
    hoveredProfileId,
    handleHover,
    handleMouseLeave,
    showUsers,
  } = props;

  const sortCollator = new Intl.Collator();

  const isSelf = props.reportType === ReportTypes.SELF;
  const isOther = props.reportType === ReportTypes.OTHER;
  const isBoth = props.reportType === ReportTypes.BOTH;

  // Find the last participant to add right-border (only 1st column)
  const lastEvenIndex = props.profileParticipants
    ?.map((_, idx) => idx)
    .filter((idx) => idx % 2 === 0)
    .pop();

  return (
    <>
      <div className="fs-4 mb-3 fw-bold mt-5 mt-xl-0">
        {getLanguageValue(languageText, "Total Profiles")}:{" "}
        {props.profileParticipants.length}
      </div>
      {showUsers && (
        <div className="row p-0 m-0 border border-start-0">
          {props.profileParticipants
            .sort((a, b) => sortCollator.compare(a.name, b.name))
            .map((participants, index) => (
              <div
                key={index}
                className={clsx(
                  "border-bottom border-start col-6 p-2",
                  index !== 0 && "border-top-0",
                  lastEvenIndex && "border-end",
                  participants.profileId === hoveredProfileId &&
                    "bg-light-success",
                )}
                role="button"
                onMouseEnter={() => handleHover(participants.profileId)}
                onMouseLeave={handleMouseLeave}
              >
                {participants.name} -
                {isSelf || isBoth
                  ? `${participants.selfPointId}${participants.selfAdaptabilityIndex}`
                  : ""}
                &nbsp;
                {isOther || isBoth
                  ? `${participants.otherPointId}${participants.otherAdaptabilityIndex}`
                  : ""}
              </div>
            ))}
        </div>
      )}
    </>
  );
};
