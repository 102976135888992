import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { ParticipantAccountDetailsModal } from "../modals/participantAccountDetailsModal/participantAccountDetailsModal";
import { ReactTooltip } from "../../../../components/tooltip/reactTooltip";
import { PersonalizedInvitationModal } from "../../addProfile/emailSMSInvitation/personalizedInvitationModal";
import { RoleSettingsModalWithEditOption } from "../../addProfile/roleSettings/roleSettingsModalWithEditOption";
import { EditProfileParticipantModal } from "../modals/editProfileParticipantModal/editProfileParticipantModal";
import { Checkbox } from "../../../../components/formComponents/checkbox";
import { UploadEmployeeModal } from "../../../employeeList/uploadEmployeeModal/uploadEmployeeModal";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  GetTypeOfRoleName,
  ProfileStatus,
  ProfileRoleStatus,
} from "../../profileEnums";
import { ISelectedProfilesList } from "../../addProfile/activityInviteParticipantsStep";
import {
  EmailStatus,
  IEditActivity,
  IEditActivityCompletedProfile,
  IEditActivityDeliveredProfile,
  IEditActivityNewProfile,
  IEditActivityOngoingProfile,
  getEmailStatus,
} from "../interface";
import { IRoleData } from "../../addProfile/roleSettings/roleSettingsModal";
import { CustomModal } from "../../../../components/modals/customModal";
import { GroupInvitation } from "../../addProfile/emailSMSInvitation/groupInvitation";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages } from "@app/containers/commonEnums";
import { editProfileNewStatusInstructionSteps } from "@app/components/instruction/instructionSteps";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IEditProfileUnstartedParticipantProps {
  id: ActivityId;
  languageText: ITranslationObject;
  profileData: IEditActivity;
  isInstructionHelpClicked: boolean;
  editProfileGuideDone: boolean;
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  messageTranslation: ITranslationObject;
  isActivityCancelled: boolean;
  isActivityInvoiced: boolean;
  fetchActivity: () => void;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  refetchNewParticipants: () => void;
  handleTransferProfile: () => void;
}

export const EditProfileUnstartedParticipant = (
  props: IEditProfileUnstartedParticipantProps,
): JSX.Element => {
  const instructionSteps = useMemo(
    () => editProfileNewStatusInstructionSteps(props.languageText),
    [props.languageText],
  );

  const initialRoleState: IRoleData<ProfileId> = {
    id: 0 as ProfileId,
    roleId: 0,
    roleText: "",
    noOfRespondents: 0,
  };
  const initialInviteState: ISelectedProfilesList[] = [
    {
      id: 0 as ProfileId,
      name: "",
      email: "",
      phoneNumber: "",
      emailSent: false,
      smsSent: false,
    },
  ];
  const [addParticipantModal, setAddParticipantModal] =
    useState<boolean>(false);
  const [existingEmails, setExistingEmails] = useState<string[]>([]);
  const [editProfileId, setEditProfileId] = useState<ProfileId>(0 as ProfileId);
  const [selectedRoleSettings, setSelectedRoleSettings] =
    useState<IRoleData<ProfileId>>(initialRoleState);
  const [profileAccountDetailsId, setProfileAccountDetailsId] =
    useState<ProfileId>(0 as ProfileId);
  // Email feature States
  const [selectedParticipants, setSelectedParticipants] = useState<
    Array<number>
  >([]); // Checkbox selected participant
  const [invitedParticipants, setInvitedParticipants] = useState<Array<number>>(
    [],
  ); // Email sent once more for these participants
  const [participantsList, setParticipantsList] = useState<
    ISelectedProfilesList[]
  >([]); // List of participants that can be invited
  const [selectedParticipantsForInvite, setSelectedParticipantsForInvite] =
    useState<ISelectedProfilesList[]>(initialInviteState); // To send invite once more
  const [emailInvitationSent, setEmailInvitationSent] =
    useState<boolean>(false);
  const [smsInvitationSent, setSMSInvitationSent] = useState<boolean>(false);
  const [selectRoleMessage, setSelectRoleMessage] = useState<string>("");

  useEffect(() => {
    if (props.profileData.newProfileCount > 0) {
      const participantsList: ISelectedProfilesList[] =
        props.profileData.newProfiles.map((participant) => ({
          id: participant.id,
          name: participant.name,
          email: participant.emailAddress,
          phoneNumber: participant.phoneNumber ?? "",
          emailSent: false,
          smsSent: false,
          emailOwnMessage: participant.emailOwnMessage,
          smsOwnMessage: participant.smsOwnMessage,
          emailStatus: participant.emailStatus,
          smsStatus: participant.smsStatus,
        }));
      setParticipantsList(participantsList);
    }
  }, [props.profileData.newProfiles]);

  //  Add new participant fn's
  const onAddParticipantClick = (): void => {
    const updatedExistingEmails: string[] = [];

    // Extracting all participant emails present in current profile
    Object.values(props.profileData).forEach((value) => {
      if (Array.isArray(value)) {
        value.forEach(
          (
            participant:
              | IEditActivityNewProfile
              | IEditActivityOngoingProfile
              | IEditActivityCompletedProfile
              | IEditActivityDeliveredProfile,
          ) => {
            if (participant.emailAddress) {
              updatedExistingEmails.push(participant.emailAddress);
            }
          },
        );
      }
    });

    setExistingEmails(updatedExistingEmails);
    setAddParticipantModal(true);
  };

  const handleCancelClick = (): void => {
    setSelectRoleMessage("");
  };

  const closeAddParticipantModal = (): void => {
    setAddParticipantModal(false);
  };

  const handleNewlyAddedParticipant = (): void =>
    props.refetchNewParticipants();

  // Edit Settings fn's
  const onSettingsClick = (id: ProfileId): void => {
    setEditProfileId(id);
  };

  const closeSettingsModal = (): void => {
    setEditProfileId(0 as ProfileId);
  };

  // Role settings modal fn's
  const onRoleClick = (profile: IEditActivityNewProfile): void => {
    setSelectedRoleSettings({
      id: profile.id,
      roleId: profile.roleId,
      roleText: profile.roleText,
      noOfRespondents: profile.noOfRespondents,
    });
  };

  const closeRoleSettingsModal = (): void => {
    setSelectedRoleSettings(initialRoleState);
  };

  // Participant account details fn's
  const onProfileClick = (id: ProfileId): void => {
    setProfileAccountDetailsId(id);
  };

  const closeAccountDetailsModal = (): void => {
    setProfileAccountDetailsId(0 as ProfileId);
  };

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const filteredParticipants = props.profileData.newProfiles.filter(
        (participant) =>
          participant.emailStatus !==
            EmailStatus.InvitationSentWaitingForResult &&
          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult,
      );
      if (filteredParticipants.length > 0) {
        const allParticipants = filteredParticipants.map((p) => p.id);
        setSelectedParticipants(allParticipants);
      } else return;
    } else {
      // Unselect all participants
      setSelectedParticipants([]);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ): void => {
    setSelectedParticipants((prevSelectedParticipant) => {
      if (e.target.checked) {
        return [...prevSelectedParticipant, id];
      } else {
        return prevSelectedParticipant.filter((item) => item !== id);
      }
    });
  };

  const closeInvitationModal = (): void => {
    setSelectedParticipantsForInvite(initialInviteState);
    setSelectedParticipants([]);
  };

  // Individual Invitation fn
  const onInvitationClick = (id: number): void => {
    const isRoleSelected = props.profileData.newProfiles.find(
      (participant) => participant.id === id,
    );
    if (
      (!isRoleSelected?.roleId || !isRoleSelected.roleText) &&
      !isRoleSelected?.noOfRespondents &&
      !props.profileData.roleCanBeChangedByParticipant
    ) {
      setSelectRoleMessage(
        "Please select participant role before sending the invitation",
      );
    } else {
      const selectedParticipant = participantsList.find(
        (participant) => participant.id === id,
      );
      initialInviteState;
      setSelectedParticipantsForInvite(
        selectedParticipant ? [selectedParticipant] : initialInviteState,
      );
    }
  };

  const handleIndividualMessageSentSuccess = (
    isEmail: boolean,
    profile: ISelectedProfilesList,
    emailOrSMSStatus: number,
  ): void => {
    const updatedList: ISelectedProfilesList[] = participantsList.map(
      (item) => {
        if (item.id === profile.id) {
          return {
            ...item,
            emailStatus: isEmail ? emailOrSMSStatus : item.emailStatus,
            smsStatus: !isEmail ? emailOrSMSStatus : item.smsStatus,
            emailSent: isEmail ? true : item.emailSent,
            smsSent: !isEmail ? true : item.smsSent,
          };
        } else return item;
      },
    );

    setParticipantsList(updatedList);

    const updatedParticipant = updatedList.find(
      (item) => item.id === profile.id,
    );
    setSelectedParticipantsForInvite(
      updatedParticipant ? [updatedParticipant] : initialInviteState,
    );

    // Email sent once more for these participants
    setInvitedParticipants((prev) => [...prev, profile.id]);

    props.refetchNewParticipants();
  };

  // Group Invitation fn
  const inviteSelectedParticipants = (): void => {
    if (!selectedParticipants.length) return;
    let isRoleSelectedForAll = true;
    if (!props.profileData.roleCanBeChangedByParticipant) {
      isRoleSelectedForAll = props.profileData.newProfiles
        .filter((participant) => selectedParticipants.includes(participant.id))
        .every(
          (participant) =>
            (participant.roleId || participant.roleText) &&
            participant.noOfRespondents,
        );
    }

    if (!isRoleSelectedForAll) {
      setSelectRoleMessage(
        "Few participants are missing roles. Please select roles for all participants before sending the invitations",
      );
    } else {
      const selectedParticipantsToInvite = participantsList.filter((item) =>
        selectedParticipants.includes(item.id),
      );
      setSelectedParticipantsForInvite(selectedParticipantsToInvite);
    }
  };

  const handleSameMessageSentSuccess = (isEmail: boolean): void => {
    const updatedList: ISelectedProfilesList[] = participantsList.map(
      (item) => ({
        ...item,
        emailSent: isEmail ? true : item.emailSent,
        smsSent: !isEmail ? true : item.smsSent,
      }),
    );
    setParticipantsList(updatedList);

    const updatedParticipant =
      updatedList.filter((item) =>
        selectedParticipantsForInvite.filter(
          (participant) => participant.id === item.id,
        ),
      ) ?? initialInviteState;
    setSelectedParticipantsForInvite(updatedParticipant);

    // Email sent once more for these participants
    selectedParticipantsForInvite.map((participant) =>
      setInvitedParticipants((prev) => [...prev, participant.id]),
    );

    if (isEmail) {
      setEmailInvitationSent(true);
    } else setSMSInvitationSent(true);

    props.refetchNewParticipants();
  };

  let disableCheckbox = false;
  if (props.profileData.newProfileCount > 0) {
    disableCheckbox = props.profileData.newProfiles.every(
      (participant) =>
        participant.emailStatus ===
          EmailStatus.InvitationSentWaitingForResult ||
        participant.emailStatus === EmailStatus.ReminderSentWaitingForResult,
    );
  }

  let allParticipantsSelected = false;
  if (props.profileData.newProfileCount > 0 && !disableCheckbox) {
    allParticipantsSelected =
      selectedParticipants.length ===
      props.profileData.newProfiles.filter(
        // Shouldn't send email for participants waiting for email result
        (participant) =>
          participant.emailStatus !==
            EmailStatus.InvitationSentWaitingForResult &&
          participant.emailStatus !== EmailStatus.ReminderSentWaitingForResult,
      ).length;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
        <span className="fs-4 fw-bold">
          <span className="badge fs-5 text-bg-dark pt-2 me-1">
            {props.profileData.profileCount}
          </span>{" "}
          {getLanguageValue(props.languageText, "Participants")}
        </span>
        {/* Add new participant */}
        <button
          id="editProfileAddParticipants"
          className="btn btn-success ms-8"
          disabled={props.isActivityCancelled || props.isActivityInvoiced}
          onClick={onAddParticipantClick}
        >
          <i className="bi bi-plus-lg"></i>{" "}
          {getLanguageValue(props.languageText, "Add Participant")}
        </button>
      </div>

      <div className="rounded border mb-3 bg-white">
        <div
          id="editProfileNewStatusHeader"
          className="p-3 pe-4 bg-secondary-subtle d-flex justify-content-between align-items-center flex-wrap"
        >
          <div className="fs-5 fw-bold text-secondary">
            <span className="badge fs-5 bg-dark pt-2 me-2 bg-opacity-25">
              {props.profileData.newProfileCount}
            </span>{" "}
            {getLanguageValue(props.languageText, "Unstarted")}
          </div>
          {props.profileData.newProfileCount > 0 && (
            <>
              <div id="newMultiEmailSMS" className="d-flex align-items-center">
                <button
                  className={clsx(
                    "btn border me-4",
                    selectedParticipants.length > 0 && "btn-success",
                  )}
                  disabled={
                    props.isActivityCancelled ||
                    disableCheckbox ||
                    props.isActivityInvoiced
                  }
                  onClick={inviteSelectedParticipants}
                >
                  <i className="bi bi-send-fill fs-5"></i>
                </button>
                <Checkbox
                  className="fs-3"
                  inputClass={clsx("border border-secondary-subtle")}
                  value={allParticipantsSelected}
                  disable={props.isActivityCancelled}
                  handleCheckboxChange={handleSelectAll}
                />
              </div>

              {/* Instruction steps */}
              {props.editProfileGuideDone ? (
                <Instruction
                  showHelpButton={false}
                  targetElement="newIndividualCheckbox"
                  guidePage={GuidePages.Edit_Profile_New_Status}
                  instructionSteps={instructionSteps}
                  skipPostGuideRequest={true}
                  manualTrigger={props.isInstructionHelpClicked}
                  handleExitGuide={props.handleExitGuide}
                />
              ) : null}
            </>
          )}
        </div>

        {props.profileData.newProfileCount > 0 && (
          <div>
            {props.profileData.newProfiles.map((participant) => {
              const isSelected = selectedParticipants.includes(participant.id);
              return (
                <div
                  key={participant.id}
                  className="d-flex flex-wrap border-top p-4"
                >
                  <div
                    id="newParticipantDetails"
                    className="col-lg-6 d-flex col-12 p-0"
                  >
                    {/* Participant name */}
                    <div
                      className={clsx(
                        "w-100 m-auto fs-5 fw-bold",
                        !props.isActivityCancelled &&
                          !props.isActivityInvoiced &&
                          "cursor-pointer",
                      )}
                      role="button"
                      onClick={() =>
                        !props.isActivityCancelled &&
                        !props.isActivityInvoiced &&
                        onSettingsClick(participant.id)
                      }
                    >
                      <ReactTooltip
                        id={participant.id.toString()}
                        tooltipText={participant.name}
                      >
                        {participant.name}
                      </ReactTooltip>
                      {(participant.noOfRespondents ||
                        participant.roleId ||
                        participant.roleText) && (
                        <div className="fs-6 fw-normal">
                          {participant.noOfRespondents
                            ? participant.noOfRespondents
                            : ""}{" "}
                          {participant.roleId
                            ? getLanguageValue(
                                props.languageText,
                                GetTypeOfRoleName[participant.roleId],
                              )
                            : participant.roleText
                              ? participant.roleText
                              : getLanguageValue(
                                  props.languageText,
                                  "Respondents",
                                )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 d-flex col-12 p-0 d-flex justify-content-between">
                    <div className="d-flex py-1">
                      {/* Role settings icon */}
                      <button
                        id="roleIcon"
                        className={clsx(
                          "btn me-3",
                          !props.isActivityCancelled && "",
                          participant.isRoleSet
                            ? "bg-success-subtle"
                            : "bg-dark bg-opacity-10",
                        )}
                        onClick={() =>
                          !props.isActivityCancelled && onRoleClick(participant)
                        }
                      >
                        <i className="bi bi-ui-radios-grid fs-5"></i>
                      </button>

                      {/* Participant Link */}
                      <button
                        id="participantLink"
                        className={clsx(
                          "btn bg-dark bg-opacity-10",
                          !props.isActivityCancelled && " cursor-pointer",
                        )}
                        onClick={() =>
                          !props.isActivityCancelled &&
                          onProfileClick(participant.id)
                        }
                      >
                        <i className="bi bi-person fs-5"></i>
                      </button>
                    </div>

                    <div className="d-flex py-1 justify-content-end align-items-center">
                      {/* Invitation icon */}
                      <button
                        id="newInvitation"
                        className={clsx(
                          "btn",
                          !props.isActivityCancelled &&
                            participant.emailStatus !==
                              EmailStatus.InvitationSentWaitingForResult &&
                            participant.emailStatus !==
                              EmailStatus.ReminderSentWaitingForResult &&
                            " cursor-pointer",
                          getEmailStatus(participant.emailStatus),
                        )}
                        onClick={() =>
                          !props.isActivityCancelled &&
                          participant.emailStatus !==
                            EmailStatus.InvitationSentWaitingForResult &&
                          participant.emailStatus !==
                            EmailStatus.ReminderSentWaitingForResult &&
                          onInvitationClick(participant.id)
                        }
                      >
                        <i className="bi bi-send-fill fs-5"></i>
                      </button>

                      {/* Checkbox icon */}
                      <div className="mt-1">
                        <Checkbox
                          id="newIndividualCheckbox"
                          className="ms-4 fs-3"
                          inputClass={clsx("border border-secondary-subtle")}
                          value={isSelected}
                          disable={
                            props.isActivityCancelled ||
                            participant.emailStatus ===
                              EmailStatus.InvitationSentWaitingForResult ||
                            participant.emailStatus ===
                              EmailStatus.ReminderSentWaitingForResult
                          }
                          handleCheckboxChange={(e) =>
                            handleCheckboxChange(e, participant.id)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Edit Settings Modal */}
      {editProfileId > 0 && (
        <EditProfileParticipantModal
          languageText={props.languageText}
          profileId={editProfileId}
          languageCode={props.cultureValue}
          languages={props.languages}
          showDeleteButton={true}
          messageTranslation={props.messageTranslation}
          status={ProfileStatus.New}
          fetchActivity={props.fetchActivity}
          handleCloseClick={closeSettingsModal}
          refetchParticipants={props.refetchNewParticipants}
          handleTransferProfile={props.handleTransferProfile}
        />
      )}

      {/* Role Settings Modal */}
      {selectedRoleSettings.id > 0 && (
        <RoleSettingsModalWithEditOption
          languageText={props.languageText}
          profileId={selectedRoleSettings.id}
          status={ProfileStatus.New}
          roleId={selectedRoleSettings.roleId}
          roleText={selectedRoleSettings.roleText}
          noOfRespondents={selectedRoleSettings.noOfRespondents}
          unselectRoleEnabled={
            props.profileData.roleCanBeChangedByParticipant ||
            props.profileData.roleSetBy ===
              Number(ProfileRoleStatus.RoleSetByParticipant)
          }
          refetchParticipants={props.refetchNewParticipants}
          handleCloseClick={closeRoleSettingsModal}
        />
      )}

      {/* Participant account details Modal */}
      {profileAccountDetailsId > 0 && (
        <ParticipantAccountDetailsModal
          languageText={props.languageText}
          languages={props.languages}
          status={ProfileStatus.New}
          handleCloseClick={closeAccountDetailsModal}
          profileId={profileAccountDetailsId}
        />
      )}

      {/* Individual Invitation Modal */}
      {selectedParticipantsForInvite.length === 1 &&
        selectedParticipantsForInvite[0].id > 0 && (
          <PersonalizedInvitationModal
            languageText={props.languageText}
            messageTranslation={props.messageTranslation}
            languages={props.languages}
            cultureValue={props.cultureValue}
            profile={selectedParticipantsForInvite[0]}
            phoneNumber={selectedParticipantsForInvite[0].phoneNumber}
            handleCloseClick={closeInvitationModal}
            handleMessageSentSuccess={handleIndividualMessageSentSuccess}
          />
        )}

      {/* Group Invitation Modal */}
      {selectedParticipantsForInvite.length > 1 && (
        <GroupInvitation
          languageText={props.languageText}
          messageTranslation={props.messageTranslation}
          languages={props.languages}
          cultureValue={props.cultureValue}
          participants={selectedParticipantsForInvite}
          emailInvitationSent={emailInvitationSent}
          smsInvitationSent={smsInvitationSent}
          handleMessageSentSuccess={handleSameMessageSentSuccess}
          handleCloseClick={closeInvitationModal}
        />
      )}

      {/* Add Participant Modal */}
      {addParticipantModal && (
        <UploadEmployeeModal
          languageText={props.languageText}
          uploadFromActivityPage={true}
          clientIdForEdit={props.profileData.clientId}
          activityIdFromEditActivityPage={props.id}
          existingEmails={existingEmails}
          isRolesRequired={
            props.profileData.roleSetBy ===
              Number(ProfileRoleStatus.RoleSetByFacilitator) &&
            !props.profileData.roleSameForAll &&
            !props.profileData.roleCanBeChangedByParticipant
          }
          refetchNewParticipants={props.refetchNewParticipants}
          setNewlyAddedEmployees={handleNewlyAddedParticipant}
          closeUploadEmployeeModal={closeAddParticipantModal}
        />
      )}

      {selectRoleMessage !== "" && (
        <CustomModal
          headerText={getLanguageValue(
            props.languageText,
            "Select Participant Role",
          )}
          bodyText={getLanguageValue(props.languageText, selectRoleMessage)}
          submitButtonText={getLanguageValue(props.languageText, "Ok")}
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleCancelClick}
        />
      )}
    </>
  );
};
