import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { RootState } from "@app/store/configureStore";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  DateLimit,
  PermissionType,
  getAccsssList,
  getDateLimitList,
  getPermissionTypeList,
} from "../../../../commonUtils/coursePermissionHelper";
import {
  createAcademyCoursePermission,
  deleteAcademyCoursePermission,
  getCoursePermissionByCourseId,
  getParticipantUserNames,
  updateAcademyCoursePermission,
} from "../../actions";
import { getFormattedDateOnly } from "../../../../commonUtils/dateFunctionsHelper";
import { addToast } from "@app/containers/actions";
import { useApiEndpoints } from "@app/api/end-points";

export interface ICoursePermissionInfo {
  access: string;
  permissionType: string;
  clientId: string;
  dateLimit: string;
  fromDate: Date | null;
  toDate: Date | null;
  courseId: number;
}

export interface ISuggestionType {
  name: string;
  id: number;
}

export interface ICoursePermissionBody {
  courseId: number;
  access: number | string;
  permissionType: number | string;
  userId: number | string | null;
  clientId: number | string | null;
  dateLimit: boolean | string;
  fromDate: Date | string | null;
  toDate: Date | string | null;
  userName?: string;
  clientName?: string;
  id?: number;
}

export enum CoursePermission {
  access = "access",
  permissionType = "permissionType",
  dateLimit = "dateLimit",
  clientId = "clientId",
  fromDate = "fromDate",
  toDate = "toDate",
}

interface IUseCoursePermissionModalType {
  courseId: number;
}

export const useCoursePermissionModal = (
  props: IUseCoursePermissionModalType,
) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const accessList: IDropdownList[] = useMemo(
    () => getAccsssList(languageText),
    [],
  );
  const PermissionTypeList: IDropdownList[] = useMemo(
    () => getPermissionTypeList(languageText),
    [],
  );
  const dateLimitList: IDropdownList[] = useMemo(
    () => getDateLimitList(languageText),
    [],
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const InitialPermissionInfo: ICoursePermissionInfo = {
    access: "",
    permissionType: "",
    clientId: "",
    dateLimit: "",
    fromDate: null,
    toDate: null,
    courseId: props.courseId,
  };

  const initialSelectedUserState: IDropdownList = {
    id: 0,
    displayName: "",
    value: "",
  };

  const [coursePermissionInfo, setCoursePermissionInfo] =
    useState<ICoursePermissionInfo>(InitialPermissionInfo);
  const [allCoursePermissionInfo, setAllCoursePermissionInfo] = useState<
    ICoursePermissionBody[]
  >([]);
  const [suggestions, setSuggestions] = useState<IDropdownList[]>([]);
  const [selectedUser, setSelectedUser] = useState<IDropdownList>(
    initialSelectedUserState,
  );
  const [editingIndex, setEditingIndex] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState(
    Array(allCoursePermissionInfo.length).fill(""),
  );

  useEffect(() => {
    fetchCoursePermission();
  }, []);

  const fetchCoursePermission = (): void => {
    setIsLoading(true);
    getCoursePermissionByCourseId(props.courseId, dispatch)
      .then((response) => {
        if (response && response.length > 0) {
          setAllCoursePermissionInfo(response);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePermissionUserInputChange = async (
    value: string,
  ): Promise<void> => {
    if (coursePermissionInfo.permissionType || editingIndex.length > 0) {
      getParticipantUserNames(value, dispatch).then((response) => {
        if (response) {
          const list: IDropdownList[] = response.map((user) => ({
            id: user.id,
            displayName: user.name,
            value: String(user.id),
          }));
          setSuggestions(list);
        }
      });
    }
  };

  const handlePermissionAccountInputChange = async (
    value: string,
  ): Promise<void> => {
    if (coursePermissionInfo.permissionType || editingIndex.length > 0) {
      api.getClientNames(value).then((response) => {
        if (response) {
          const list: IDropdownList[] = response.map((user) => ({
            id: user.id,
            displayName: user.name,
            value: String(user.id),
          }));
          setSuggestions(list);
        }
      });
    }
  };

  const handlePermissionIdClick = (selectedValue: IDropdownList): void => {
    setSelectedUser(selectedValue);
    setCoursePermissionInfo({
      ...coursePermissionInfo,
      clientId: selectedValue.value,
    });
    setErrorMessage("");
  };

  const handleUnSelect = (): void => {
    setSelectedUser(initialSelectedUserState);
  };

  const handleUnSelectAll = (index: number): void => {
    const updatedData = [...allCoursePermissionInfo];
    updatedData[index] = {
      ...updatedData[index],
      userId: "",
      clientId: "",
      userName: "",
      clientName: "",
    };
    setAllCoursePermissionInfo(updatedData);
    setEditingIndex([...editingIndex, index]);
  };

  const handlePermissionAllIdClick = (
    selectedValue: IDropdownList,
    index: number,
  ) => {
    const updatedData = [...allCoursePermissionInfo];
    updatedData[index] = {
      ...updatedData[index],
      userId:
        Number(updatedData[index]?.permissionType) === PermissionType.User
          ? selectedValue.value
          : "",
      clientId:
        Number(updatedData[index]?.permissionType) === PermissionType.Account
          ? selectedValue.value
          : "",
      userName:
        Number(updatedData[index]?.permissionType) === PermissionType.User
          ? String(selectedValue.displayName)
          : "",
      clientName:
        Number(updatedData[index]?.permissionType) === PermissionType.Account
          ? String(selectedValue.displayName)
          : "",
    };

    setAllCoursePermissionInfo(updatedData);
    setEditingIndex([...editingIndex, index]);
    setErrorMessage("");
  };

  const handlePermissionSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    const { name, value } = selectedItem;

    setCoursePermissionInfo({
      ...coursePermissionInfo,
      [name]: value,
    });

    setErrorMessage("");

    if (name === CoursePermission.permissionType) {
      handleUnSelect();
    }
  };

  const handleAllPermissionSelect = (
    selectedItem: IDropdownSelectedItem,
    index: number,
  ): void => {
    const { name, value } = selectedItem;

    // Create a new array with the updated object
    const updatedData = [...allCoursePermissionInfo];
    updatedData[index] = { ...updatedData[index], [name]: value };

    setAllCoursePermissionInfo(updatedData);
    setEditingIndex([...editingIndex, index]);
  };

  const handleDateSelect = (name: string, date: Date) => {
    setCoursePermissionInfo({
      ...coursePermissionInfo,
      [name]: date,
    });
  };

  const handleAllDateSelect = (name: string, date: Date, index: number) => {
    const formattedDate: string | null = date
      ? getFormattedDateOnly(date)
      : null;

    // Create a new array with the updated object
    const updatedData = [...allCoursePermissionInfo];
    updatedData[index] = { ...updatedData[index], [name]: formattedDate };
    setAllCoursePermissionInfo(updatedData);
    setEditingIndex([...editingIndex, index]);
  };

  const onSubmitPermission = () => {
    const startDate: string | null = coursePermissionInfo.fromDate
      ? getFormattedDateOnly(coursePermissionInfo.fromDate)
      : null;
    const endDate: string | null = coursePermissionInfo.toDate
      ? getFormattedDateOnly(coursePermissionInfo.toDate)
      : null;

    if (
      !coursePermissionInfo.access ||
      !coursePermissionInfo.dateLimit ||
      !coursePermissionInfo.permissionType ||
      !selectedUser.value
    ) {
      if (!selectedUser.value) {
        setErrorMessage("Please select name from the list");
      } else {
        setErrorMessage("Please fill all the required fields");
      }
      return;
    }

    setErrorMessage("");
    const body: ICoursePermissionBody = {
      access: Number(coursePermissionInfo.access),
      dateLimit:
        Number(coursePermissionInfo.dateLimit) === DateLimit.Yes ? true : false,
      fromDate:
        Number(coursePermissionInfo.dateLimit) === DateLimit.Yes
          ? startDate
          : null,
      toDate:
        Number(coursePermissionInfo.dateLimit) === DateLimit.Yes
          ? endDate
          : null,
      permissionType: Number(coursePermissionInfo.permissionType),
      courseId: coursePermissionInfo.courseId,
      userId:
        Number(coursePermissionInfo.permissionType) === PermissionType.User
          ? Number(coursePermissionInfo.clientId) // this looks like a bug. -johan, 2024-10-08
          : null,
      clientId:
        Number(coursePermissionInfo.permissionType) === PermissionType.Account
          ? Number(coursePermissionInfo.clientId)
          : null,
    };
    createAcademyCoursePermission(body, dispatch).then((response) => {
      if (response?.success) {
        setCoursePermissionInfo(InitialPermissionInfo);
        handleUnSelect();
        fetchCoursePermission();

        dispatch(addToast("Saved successfully") as AnyAction);
      }
    });
  };

  const updatePermission = (index: number) => {
    const coursePermissionObj = allCoursePermissionInfo[index];

    const startDate: string | null = coursePermissionObj.fromDate
      ? getFormattedDateOnly(coursePermissionObj.fromDate)
      : null;
    const endDate: string | null = coursePermissionObj.toDate
      ? getFormattedDateOnly(coursePermissionObj.toDate)
      : null;
    const body: ICoursePermissionBody = {
      id: Number(coursePermissionObj.id),
      access: Number(coursePermissionObj.access),
      dateLimit:
        Number(coursePermissionObj.dateLimit) === DateLimit.Yes ? true : false,
      fromDate:
        Number(coursePermissionObj.dateLimit) === DateLimit.Yes
          ? startDate
          : null,
      toDate:
        Number(coursePermissionObj.dateLimit) === DateLimit.Yes
          ? endDate
          : null,
      permissionType: Number(coursePermissionObj.permissionType),
      courseId: coursePermissionObj.courseId,
      userId:
        Number(coursePermissionObj.permissionType) === PermissionType.User
          ? Number(coursePermissionObj.userId)
          : null,
      clientId:
        Number(coursePermissionObj.permissionType) === PermissionType.Account
          ? Number(coursePermissionObj.clientId)
          : null,
    };

    if (
      Number(coursePermissionObj.permissionType) === PermissionType.User &&
      !coursePermissionObj.userId
    ) {
      const newErrors = [...isError];
      newErrors[index] = "Please select a user";
      setIsError(newErrors);
      return;
    } else if (
      Number(coursePermissionObj.permissionType) === PermissionType.Account &&
      !coursePermissionObj.clientId
    ) {
      const newErrors = [...isError];
      newErrors[index] = "Please select a client";
      setIsError(newErrors);
      return;
    }

    updateAcademyCoursePermission(body, dispatch).then((response) => {
      if (response?.success) {
        dispatch(addToast("Changes saved successfully") as AnyAction);
        const updatedEditingIndex = editingIndex.filter(
          (number) => number !== index,
        );
        setEditingIndex(updatedEditingIndex);
      }
    });
  };

  const deleteCoursePermission = (id: number) => {
    deleteAcademyCoursePermission(id, dispatch).then((response) => {
      if (response?.success) {
        const updatedEditingIndex = allCoursePermissionInfo.filter(
          (Obj) => Obj.id !== id,
        );
        setAllCoursePermissionInfo(updatedEditingIndex);
      }
    });
  };

  return {
    languageText,
    accessList,
    PermissionTypeList,
    dateLimitList,
    coursePermissionInfo,
    suggestions,
    allCoursePermissionInfo,
    editingIndex,
    selectedUser,
    errorMessage,
    isError,
    handleUnSelectAll,
    handleUnSelect,
    handleAllDateSelect,
    handleDateSelect,
    updatePermission,
    handleAllPermissionSelect,
    deleteCoursePermission,
    handlePermissionAllIdClick,
    handlePermissionIdClick,
    handlePermissionUserInputChange,
    handlePermissionAccountInputChange,
    handlePermissionSelect,
    onSubmitPermission,
  };
};
