import * as React from "react";
import { PresentationHeader } from "../facilitatorDelivery/Utils/presentationHeader";
import {
  IPresentationSlide,
  renderSlideHTML,
} from "../facilitatorDelivery/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { useParams } from "react-router-dom";
import { getAllPresentationSlides } from "../facilitatorDelivery/actions";
import { PresentationSlideNotes } from "./notes";
import { usePresentationControls } from "./hooks";

function noop(): void {}

const EMPTY_SLIDE: IPresentationSlide = {
  id: 0,
  description: "",
  html: "",
  slideNumber: 0,
  slideSteps: [],
  notes: "",
};

export const PreviewPresentationTemplate: React.FC<unknown> = (props) => {
  const dispatch = useDispatch();
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const templateId = useParams<"id">().id;
  const slides = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.presentationSlides,
  );
  const controls = usePresentationControls(slides);

  const previousSlide = (): void => {
    const pos = controls.previous();
  };

  const nextSlide = (): void => {
    const pos = controls.next();
  };

  const handleSlideClick = (slideNumber: number): void => {
    const index = slides.findIndex(
      (slide) => slide.slideNumber === slideNumber,
    );

    if (index === -1) {
      return;
    }

    controls.setPosition({
      slideIndex: index,
      slideStepIndex: null,
    });
  };

  React.useEffect(() => {
    if (!templateId) {
      return;
    }
    getAllPresentationSlides(Number(templateId), dispatch);
  }, [templateId]);

  const currentSlide: IPresentationSlide =
    slides[controls.position.slideIndex] ?? EMPTY_SLIDE;

  return (
    <React.Fragment>
      <PresentationHeader
        languageText={languageText}
        slideNumber={currentSlide.slideNumber}
        facilitatorSlides={slides}
        profiles={[]}
        facilitatorName=""
        previousSlide={previousSlide}
        nextSlide={nextSlide}
        handleSlideClick={handleSlideClick}
        handleCancelClick={noop}
        openProfileDynamicSlides={noop}
        isConnected={() => false}
      />

      {renderSlideHTML(currentSlide, controls.position.slideStepIndex)}

      <PresentationSlideNotes
        languageText={languageText}
        slide={currentSlide}
      />
    </React.Fragment>
  );
};
