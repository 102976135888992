import React from "react";

import { toAbsoluteUrl } from "@app/commonUtils/metronic";
import { useInformation } from "./hooks";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";

export const Resources = (): JSX.Element => {
  const { languageText, landingContent } = useInformation();

  return (
    <>
      <div className="p-2 pt-4 p-md-4">
        <h3 className="mb-3 fw-bold">
          {getLanguageValue(languageText, "Resources")}
        </h3>
        <div className="row row-cols-1 row-cols-md-3 mt-8">
          {landingContent.map((content) => (
            <div
              key={content.id}
              className="col mb-4"
              role="button"
              onClick={content.function}
            >
              <div className="card h-100 border border-secondary">
                <img
                  className="card-img-top"
                  src={toAbsoluteUrl(content.imageSrc)}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    {getLanguageValue(languageText, content.title)}
                  </h5>
                  <p className="card-text">
                    {getLanguageValue(languageText, content.details)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
