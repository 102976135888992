import React, { useEffect, useMemo, useState } from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  ToggleButtonGroup,
  IButtonGroupList,
} from "../../../components/formComponents/toggleButtonGroup";
import { IndividualGroupBtnsEnum } from "../profileEnums";
import { ChangeProfileModal } from "./changeProfile/changeProfileModal";
import { ActivityId } from "@app/containers/reducer";
interface IActivityGroupSelectionStepProps {
  languageText: ITranslationObject;
  selectedBtn: string;
  showEditModeGroupSelect?: boolean;
  createdActivityId: ActivityId;
  stepsCompleted: number[];
  setShowEditModeGroupSelect?: React.Dispatch<React.SetStateAction<boolean>>;
  handleButtonChange: (name: string) => void;
  onSaveClick: () => void;
}

export const ActivityGroupSelectionStep = (
  props: IActivityGroupSelectionStepProps,
): JSX.Element => {
  const {
    languageText,
    selectedBtn,
    createdActivityId,
    stepsCompleted,
    showEditModeGroupSelect,
    handleButtonChange,
    onSaveClick,
    setShowEditModeGroupSelect,
  } = props;

  const individualGroupBtns = useMemo(
    () =>
      [
        {
          id: 1,
          name: IndividualGroupBtnsEnum.group,
          title: getLanguageValue(languageText, "Multiple Participants"),
        },
        {
          id: 2,
          name: IndividualGroupBtnsEnum.individual,
          title: getLanguageValue(languageText, "One Participant"),
        },
      ] as IButtonGroupList[],
    [languageText],
  );

  const [changeProfileTypeModal, setChangeProfileTypeModal] =
    useState<boolean>(false);
  const [groupTitle, setGrouptitle] = useState<string>("");

  useEffect(() => {
    if (selectedBtn !== "") {
      const filteredArray = individualGroupBtns.filter((value) =>
        selectedBtn.includes(value.name),
      );
      setGrouptitle(filteredArray[0].title);
    } else {
      setGrouptitle("");
    }
  }, [groupTitle, selectedBtn, languageText]);

  const handleButtonClick = (name: string): void => {
    if (createdActivityId) {
      setChangeProfileTypeModal(true);
    } else handleButtonChange(name);
    if (stepsCompleted.length == 2) {
      if (setShowEditModeGroupSelect) setShowEditModeGroupSelect(false);
    }
  };

  const closeChangeProfileTypeModal = (): void => {
    setChangeProfileTypeModal(false);
    if (setShowEditModeGroupSelect) setShowEditModeGroupSelect(false);
  };

  const onProfileChange = (): void => {
    handleButtonChange("");
    closeChangeProfileTypeModal();
  };

  const getChangeProfileMessage = (): string => {
    let message: string = "";

    if (selectedBtn === IndividualGroupBtnsEnum.group) {
      message =
        "Do you want to change profile type to individual? It will clear your selected profile details";
    } else {
      message = "Do you want to change profile type to group?";
    }

    return getLanguageValue(languageText, message);
  };

  const onSelectGroupBtnClick = () => {
    if (setShowEditModeGroupSelect) setShowEditModeGroupSelect(true);
  };

  return (
    <>
      <div className="mb-4 p-4 rounded shadow-sm bg-white">
        <div className="fs-5 fw-bold mb-3">
          {getLanguageValue(languageText, "Group or Individual IDI profile")}
        </div>
        {showEditModeGroupSelect ? (
          <ToggleButtonGroup
            buttonList={individualGroupBtns}
            selectedBtn={selectedBtn}
            className="me-2"
            handleButtonClick={handleButtonClick}
          />
        ) : (
          <div className="d-flex align-items-center">
            <div className="btn me-3 border">{groupTitle}</div>
            <button className="btn btn-light" onClick={onSelectGroupBtnClick}>
              <i className="bi bi-pencil ms-1"></i>
            </button>
          </div>
        )}

        {!stepsCompleted.includes(1) && (
          <div className="mt-4">
            <span
              className="fs-5 fw-bold text-decoration-underline"
              onClick={onSaveClick}
              role="button"
            >
              {getLanguageValue(languageText, "Save and continue")}
            </span>
          </div>
        )}
      </div>
      {changeProfileTypeModal && (
        <ChangeProfileModal
          languageText={languageText}
          bodyText={getChangeProfileMessage()}
          handleCancelClick={closeChangeProfileTypeModal}
          handleSaveClick={onProfileChange}
        />
      )}
    </>
  );
};
