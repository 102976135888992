import * as Redux from "redux";

import { IUserDetails, IUserProfile } from "@app/containers/commonInterfaces";
import {
  LOGGED_IN_USER_DATA,
  LOGGED_IN_USER_ID,
  LOGGED_IN_USER_DETAILS,
  SET_DEFAULT_CLIENT,
  PASSWORD_RESET_CODE,
  GET_SOCIAL_LOGIN_STATUS,
  CLEAR_DEFAULT_CLIENT,
  SET_LOGGED_IN_USER_ROLE,
  SET_USER_LANGUAGE,
  NEEDS_USER_CONSENT,
} from "./constants";
import { RoleName } from "@app/containers/commonEnums";
import { ActivityId, ProfileId, UserId } from "@app/containers/reducer";

export interface IDefaultClient {
  defaultClientId: number;
  defaultClientName: string;
}

export interface IUserLanguage {
  userLanguageCode: string;
  userLanguageId: number;
}

export type IConsent = {
  id?: number;
  title: string;
  body: string;
  version: string;
  isActive: boolean;
};

interface ILoginReducerState {
  userId: UserId;

  /** What is the difference between 'userDetails' and 'userData'? 🤔 */
  userData: IUserProfile;
  passwordResetCode: string;

  /** What is the difference between 'userDetails' and 'userData'? 🤔 */
  userDetails: IUserDetails;

  /** @deprecated 'defaultClient' should go away in favor of the current access token scope. */
  defaultClient: IDefaultClient;
  loggedInUserRole: RoleName | null;
  userLanguage: IUserLanguage;
  socialLoginStatus: Array<string>;

  /** If this array is not empty we will forcefully show a consent modal to the user. */
  needsUserConsent: ReadonlyArray<IConsent>;
}

const initialState: ILoginReducerState = {
  userId: 0 as UserId,
  userData: {
    name: "",
    surname: "",
    userName: "",
    emailAddress: "",
    phoneNumber: "",
    isPhoneNumberConfirmed: false,
    isTwoFactorEnabled: false,
    isEmailConfirmed: false,
    timezone: "",
    selectedLanguageId: null,
  },
  passwordResetCode: "",
  userDetails: {
    userId: 0 as UserId,
    name: "",
    userLanguageId: -1,
    userLanguageCode: "",
    userLanguageDisplayName: "",
    userRole: null,
    userRoleId: 0,
    userRoles: [],
    defaultClientId: null,
    defaultClientName: "",
    activityId: 0 as ActivityId,
    profileId: 0 as ProfileId,
    isOverviewPage: false,
    isConsultant: false,
    isProfileExists: false,
    enableParticipantCreateProfile: false,
    uniqueCode: "",
    isManager: false,
  },
  defaultClient: {
    defaultClientId: 0,
    defaultClientName: "",
  },
  loggedInUserRole: null,
  userLanguage: {
    userLanguageCode: "",
    userLanguageId: 0,
  },
  socialLoginStatus: [],
  needsUserConsent: [],
};

export const loginReducer: Redux.Reducer<ILoginReducerState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_SOCIAL_LOGIN_STATUS:
      return {
        ...state,
        socialLoginStatus: action.payload,
      };
    case LOGGED_IN_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case LOGGED_IN_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_DEFAULT_CLIENT:
      return {
        ...state,
        defaultClient: action.payload,
      };
    case CLEAR_DEFAULT_CLIENT:
      return {
        ...state,
        defaultClient: {
          defaultClientId: 0,
          defaultClientName: "",
        },
      };
    case PASSWORD_RESET_CODE:
      return {
        ...state,
        passwordResetCode: action.payload,
      };
    case SET_LOGGED_IN_USER_ROLE:
      return {
        ...state,
        loggedInUserRole: action.payload,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        userLanguage: {
          userLanguageCode: action.payload.userLanguageCode,
          userLanguageId: action.payload.userLanguageId,
        },
      };
    case NEEDS_USER_CONSENT:
      return {
        ...state,
        needsUserConsent: action.payload,
      };
    default:
      return state;
  }
};
