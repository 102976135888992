import { debounce } from "@app/containers/utils";
import { useRef, useEffect, useMemo } from "react";

//Stolen from https://www.developerway.com/posts/debouncing-in-react
export function useDebounce<T extends any[]>(
  callback: (...args: T) => unknown,
  timeout: number,
) {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: T) => {
      ref.current?.(...args);
    };

    return debounce(func, timeout);
  }, []);

  return debouncedCallback;
}
