import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routePath } from "../../../routePaths";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { RootState } from "@app/store/configureStore";
import {
  getProfileDemographicById,
  updateProfileDemographics,
} from "../../actions";
import { setSpinner } from "../../../actions";
import {
  useParticipantProfileAuth,
  ucQueryKey,
} from "../../useParticipantProfileAuth";
import { StepComponentProps, Step } from "../../createProfilePage";
import { ProfileId } from "@app/containers/reducer";

export interface IProfileDemographics {
  id: ProfileId;
  age: number;
  gender: number;
  sector: number;
  seniority: number;
  education: number;
  occupation?: string;
}

export enum DropdownInputs {
  age = "age",
  gender = "gender",
  sector = "sector",
  seniority = "seniority",
  education = "education",
  occupation = "occupation",
}

enum AgeRange {
  TWENTY_NINE_OR_LESS = "1",
  THIRTY_TO_THIRTY_NINE = "2",
  FORTY_TO_FORTY_NINE = "3",
  FIFTY_TO_FIFTY_NINE = "4",
  SIXTY_PLUS = "5",
}

enum Gender {
  MALE = "1",
  FEMALE = "2",
  OTHER = "3",
}

enum Sector {
  PRIVATE_SECTOR = "1",
  PUBLIC_SECTOR = "2",
  NON_PROFIT = "3",
  OTHER = "4",
}

enum Seniority {
  HAVE_SUBORDINATES = "1",
  DO_NOT_HAVE_SUBORDINATES = "2",
}

enum Education {
  SECONDARY = "1",
  DEGREE = "2",
  POST_GRADUATE = "3",
}
interface IDemographicDataProps {
  age: string;
  gender: string;
  sector: string;
  seniority: string;
  education: string;
  occupation?: string;
  id: number;
}

export const useDemographicPage = (props: StepComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uniqueCode } = useParticipantProfileAuth();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const profileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails,
  );

  const [demographicInfo, setDemographicInfo] = useState<IDemographicDataProps>(
    {
      age: "",
      gender: "",
      sector: "",
      seniority: "",
      education: "",
      occupation: "",
      id: 0,
    },
  );

  const ageDropdownList = useMemo(
    () =>
      [
        {
          id: AgeRange.TWENTY_NINE_OR_LESS,
          value: AgeRange.TWENTY_NINE_OR_LESS,
          displayName: getLanguageValue(languageText, "29 or less"),
        },
        {
          id: AgeRange.THIRTY_TO_THIRTY_NINE,
          value: AgeRange.THIRTY_TO_THIRTY_NINE,
          displayName: "30 - 39",
        },
        {
          id: AgeRange.FORTY_TO_FORTY_NINE,
          value: AgeRange.FORTY_TO_FORTY_NINE,
          displayName: "40 - 49",
        },
        {
          id: AgeRange.FIFTY_TO_FIFTY_NINE,
          value: AgeRange.FIFTY_TO_FIFTY_NINE,
          displayName: "50 - 59",
        },
        {
          id: AgeRange.SIXTY_PLUS,
          value: AgeRange.SIXTY_PLUS,
          displayName: "60+",
        },
      ] as unknown as IDropdownList[],
    [languageText],
  );

  const genderList = useMemo(
    () =>
      [
        {
          id: Gender.MALE,
          value: Gender.MALE,
          displayName: getLanguageValue(languageText, "Male"),
        },
        {
          id: Gender.FEMALE,
          value: Gender.FEMALE,
          displayName: getLanguageValue(languageText, "Female"),
        },
        {
          id: Gender.OTHER,
          value: Gender.OTHER,
          displayName: getLanguageValue(languageText, "Other"),
        },
      ] as unknown as IDropdownList[],
    [languageText],
  );

  const sectorList = useMemo(
    () =>
      [
        {
          id: Sector.PRIVATE_SECTOR,
          value: Sector.PRIVATE_SECTOR,
          displayName: getLanguageValue(languageText, "Private sector"),
        },
        {
          id: Sector.PUBLIC_SECTOR,
          value: Sector.PUBLIC_SECTOR,
          displayName: getLanguageValue(languageText, "Public sector"),
        },
        {
          id: Sector.NON_PROFIT,
          value: Sector.NON_PROFIT,
          displayName: getLanguageValue(languageText, "Non profit"),
        },
        {
          id: Sector.OTHER,
          value: Sector.OTHER,
          displayName: getLanguageValue(languageText, "Other"),
        },
      ] as unknown as IDropdownList[],
    [languageText],
  );

  const seniorityList = useMemo(
    () =>
      [
        {
          id: Seniority.HAVE_SUBORDINATES,
          value: Seniority.HAVE_SUBORDINATES,
          displayName: getLanguageValue(languageText, "Have subordinates"),
        },
        {
          id: Seniority.DO_NOT_HAVE_SUBORDINATES,
          value: Seniority.DO_NOT_HAVE_SUBORDINATES,
          displayName: getLanguageValue(
            languageText,
            "Do not have subordinates",
          ),
        },
      ] as unknown as IDropdownList[],
    [languageText],
  );

  const educationList = useMemo(
    () =>
      [
        {
          id: Education.SECONDARY,
          value: Education.SECONDARY,
          displayName: getLanguageValue(languageText, "Secondary"),
        },
        {
          id: Education.DEGREE,
          value: Education.DEGREE,
          displayName: getLanguageValue(languageText, "Degree"),
        },
        {
          id: Education.POST_GRADUATE,
          value: Education.POST_GRADUATE,
          displayName: getLanguageValue(languageText, "Post graduate"),
        },
      ] as unknown as IDropdownList[],
    [languageText],
  );

  const fetchData = async () => {
    let demographicResponse: any = "";
    demographicResponse = await getProfileDemographicById(
      profileDetails.profileId,
      dispatch,
    );
    if (demographicResponse) {
      const { age, gender, sector, seniority, occupation, education } =
        demographicResponse;
      setDemographicInfo({
        id: profileDetails.profileId,
        age: age ? String(age) : "",
        gender: gender ? String(gender) : "",
        sector: sector ? String(sector) : "",
        seniority: seniority ? String(seniority) : "",
        education: education ? String(education) : "",
        occupation: !occupation || occupation === "null" ? "" : occupation,
      });
    }
  };

  useEffect(() => {
    if (profileDetails.profileId) {
      dispatch(setSpinner(true));
      fetchData().finally(() => dispatch(setSpinner(false)));
    }
  }, [profileDetails.profileId]);

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem;

    setDemographicInfo({
      ...demographicInfo,
      [name]: value,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;

    setDemographicInfo({
      ...demographicInfo,
      [name]: value,
    });
  };

  const goToPrevious = (): void => {
    navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`);
  };

  const onSubmitClick = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    const body: IProfileDemographics = {
      id: profileDetails.profileId,
      age: Number(demographicInfo.age),
      gender: Number(demographicInfo.gender),
      sector: Number(demographicInfo.sector),
      seniority: Number(demographicInfo.seniority),
      education: Number(demographicInfo.education),
      occupation: String(demographicInfo.occupation),
    };
    updateProfileDemographics(body, dispatch).then((response) => {
      if (response?.status === 200) {
        props.setStep(Step.RoleAndRespondents);
      }
    });
  };

  return {
    languageText,
    ageDropdownList,
    genderList,
    sectorList,
    seniorityList,
    educationList,
    demographicInfo,
    handleDropdownSelect,
    handleInputChange,
    goToPrevious,
    onSubmitClick,
  };
};
