import clsx from "clsx";
import React from "react";
import { IFocusError } from "./input";
import { IDropdownList } from "./dropdownSelect";
import { classNames } from "@app/containers/utils";

interface IDropdownProps {
  label?: string;
  value: string;
  name?: string;
  required?: boolean;
  list: IDropdownList[];
  defaultLabel?: string;
  focusInput?: IFocusError;
  handleDropdownSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;

  /** Removes the 'mb-3' margin on the container. */
  narrow?: boolean;
}

/**
 * TODO: rename this to &lt;Select /&gt;
 */
export const Dropdown = (props: IDropdownProps) => {
  const clazz = classNames({
    "mb-3": !props.narrow,
  });

  return (
    <div className={clazz}>
      {props.label ? (
        <label className={clsx("fs-6 mb-1", { required: props.required })}>
          {props.label}
        </label>
      ) : (
        ""
      )}
      <select
        className="form-select form-select-solid"
        data-kt-select2="true"
        data-placeholder="Select option"
        data-allow-clear="true"
        value={props.value}
        name={props.name}
        onChange={props.handleDropdownSelect}
      >
        {props.defaultLabel && <option label={props.defaultLabel}></option>}

        {props.list.map((item, idx: number) => (
          <option key={idx} id={String(item.id)} value={item.value}>
            {item.displayName}
          </option>
        ))}
      </select>

      {props.focusInput?.errorMessage && (
        <div className="invalid-feedback d-inline-block">
          {props.focusInput.errorMessage}
        </div>
      )}
    </div>
  );
};
