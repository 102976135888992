import React from "react";
import { Link } from "react-router-dom";

import { KTCard } from "@app/commonUtils/metronic";
import { routePath } from "../../routePaths";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface ICourseNotFoundProps {
  title: string;
  text: string;
  languageText: ITranslationObject;
}

export const CourseNotFound = (props: ICourseNotFoundProps): JSX.Element => {
  const { title, text, languageText } = props;

  return (
    <div className="container">
      <KTCard className="rounded">
        <div className="p-3 p-md-4">
          <div className="d-flex flex-column gap-20 justify-items-center">
            <div className="d-flex flex-column gap-5">
              <h1 className="fs-26px color-444 fw-bold">{title}</h1>
              <p className="fs-5 color-444">{text}</p>
            </div>

            <Link to={routePath.participantAcademy}>
              <button className="btn btn-success">
                {getLanguageValue(languageText, "Back to academy")}
              </button>
            </Link>
          </div>
        </div>
      </KTCard>
    </div>
  );
};
