import React from "react";
import { E164Number } from "libphonenumber-js/types";

import { ProductDetails } from "./invoiceComponents/productDetails";
import { InvoiceAddress } from "./invoiceComponents/invoiceAddressForm";
import { CreateInvoiceDraft } from "./invoiceComponents/createInvoiceDraft";
import { ToggleCardCheckbox } from "../formComponents/toggleCardCheckbox";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { IPaymentTypes } from "../../commonUtils/planSuscriptionHelper";
import {
  IInvoiceExistingAddress,
  IInvoiceFocusInput,
  IInvoiceFormData,
  IProductData,
} from "./invoiceInterface";
import { CustomModal } from "../modals/customModal";

export interface IInvoiceBillingProps {
  languageText: ITranslationObject;
  isActivity: boolean;
  countries: IDropdownList[];
  paymentTypes: IPaymentTypes[];
  existingAddresses?: IInvoiceExistingAddress[];
  selectAddressModal?: boolean;
  invoiceFormData: IInvoiceFormData;
  focusInput: IInvoiceFocusInput;
  fortnoxClientList: IDropdownList[];
  fortnoxInvoiceList: IDropdownList[];
  selectedFortnoxCustomer: IDropdownSelectedItem;
  showFortnoxInvoiceInputs: boolean;
  productFormData: IProductData;
  isEditMode: boolean;
  productsList: IDropdownList[];
  handleSelectPaymentMethod: (paymentId: number) => void;
  openSelectAddressModal?: () => void;
  closeSelectAddressModal?: () => void;
  onSelectAddressClick?: (data: IInvoiceExistingAddress) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneInputChange: (value: E164Number) => void;
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleCountrySelect: (selectedItem: IDropdownSelectedItem) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleTextAreaBlurEvent: (
    e: React.FocusEvent<HTMLTextAreaElement, Element>,
  ) => void;
  handleFortnoxClientSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleInvoiceSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleCloseCustomerDefaultPopup: () => void;
  handleSaveCustomerAsDefault: () => void;
  handleProductSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleProductInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddProduct: () => void;
  handleSaveProduct: () => void;
  handleCancelProduct: () => void;
  handleEditProduct: (id: number) => void;
  handleDeleteProduct: (id: number) => void;
  createOrUpdateInvoiceDraft: () => void;
}

export const InvoiceBilling = (props: IInvoiceBillingProps) => {
  return (
    <>
      <div className="d-flex flex-column">
        <div className="row gap-5 fs-6">
          {props.paymentTypes.map((paymentType, index) => (
            <ToggleCardCheckbox
              key={index}
              header={paymentType.name}
              description={paymentType.description}
              containerClass="col-lg-4 col-12"
              icon={paymentType.icon}
              checked={paymentType.id === props.invoiceFormData.paymentId}
              handleClick={() =>
                props.handleSelectPaymentMethod(paymentType.id)
              }
            />
          ))}
        </div>

        <InvoiceAddress
          languageText={props.languageText}
          isActivity={props.isActivity}
          countries={props.countries}
          existingAddresses={props.existingAddresses!}
          selectAddressModal={props.selectAddressModal!}
          invoiceAddressFormData={props.invoiceFormData}
          focusInput={props.focusInput}
          openSelectAddressModal={props.openSelectAddressModal!}
          closeSelectAddressModal={props.closeSelectAddressModal!}
          onSelectAddressClick={props.onSelectAddressClick!}
          handleInputChange={props.handleInputChange}
          handlePhoneInputChange={props.handlePhoneInputChange}
          handleTextAreaChange={props.handleTextAreaChange}
          handleCountrySelect={props.handleCountrySelect}
          handleBlurEvent={props.handleBlurEvent}
          handleTextAreaBlurEvent={props.handleTextAreaBlurEvent}
        />

        <CreateInvoiceDraft
          languageText={props.languageText}
          fortnoxClientList={props.fortnoxClientList}
          fortnoxInvoiceList={props.fortnoxInvoiceList}
          invoiceFormData={props.invoiceFormData}
          productFormData={props.productFormData}
          isEditMode={props.isEditMode}
          productsList={props.productsList}
          focusInput={props.focusInput}
          showFortnoxInvoiceInputs={props.showFortnoxInvoiceInputs}
          handleFortnoxClientSelect={props.handleFortnoxClientSelect}
          handleInvoiceSelect={props.handleInvoiceSelect}
          handleBlurEvent={props.handleBlurEvent}
          handleProductSelect={props.handleProductSelect}
          handleProductInputChange={props.handleProductInputChange}
          handleAddProduct={props.handleAddProduct}
          handleSaveProduct={props.handleSaveProduct}
          handleCancelProduct={props.handleCancelProduct}
        />

        <ProductDetails
          languageText={props.languageText}
          invoiceId={props.invoiceFormData.invoiceId}
          profileInvoiceRows={props.invoiceFormData.profileInvoiceRows}
          handleEditProduct={props.handleEditProduct}
          handleDeleteProduct={props.handleDeleteProduct}
        />

        {/* Create / Update Invoice draft */}
        <div className="d-flex flex-end">
          <button
            className="btn btn-success"
            onClick={props.createOrUpdateInvoiceDraft}
          >
            {getLanguageValue(
              props.languageText,
              props.invoiceFormData.invoiceId
                ? "Update invoice draft"
                : "Create invoice draft",
            )}
          </button>
        </div>
      </div>

      {props.selectedFortnoxCustomer.id > 0 && (
        <CustomModal
          headerText={getLanguageValue(props.languageText, "Select as default")}
          bodyText={getLanguageValue(
            props.languageText,
            "Do you want to set selected fortnox customer as default",
          )}
          cancelButtonText={getLanguageValue(props.languageText, "No")}
          submitButtonText={getLanguageValue(props.languageText, "Yes")}
          handleCancelClick={props.handleCloseCustomerDefaultPopup}
          handleSubmitClick={props.handleSaveCustomerAsDefault}
        />
      )}
    </>
  );
};
