import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { IPayloadBody } from "./hooks";

export const updatePlacement = async (
  body: IPayloadBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.put(
      apiEndPoints.updatePlacements,
      JSON.stringify(body),
      {},
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createPlacement = async (
  body: IPayloadBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.createPlacements,
      JSON.stringify(body),
      {},
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
