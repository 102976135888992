import React from "react";
import { CustomModal } from "../../../../../components/modals/customModal";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";

interface IEditProfileConfirmationProps {
  languageText: ITranslationObject;
  completedAndDeliveredProfileCount: number;
  handleSaveClick: () => void;
  closeShowConfirmationModal: () => void;
}

export const EditProfileConfirmationModal = (
  props: IEditProfileConfirmationProps,
): JSX.Element => {
  const getConfirmationMessage = () => {
    const firstMessage = getLanguageValue(
      props.languageText,
      "If you change the norm then the profile will be recalculated",
    );
    let secondMessage = "";
    if (props.completedAndDeliveredProfileCount > 0) {
      secondMessage = getLanguageValue(
        props.languageText,
        "Note : The calculation is time taking process. So wait until complete",
      );
    }
    return (
      <div>
        <p>{firstMessage}</p>
        {secondMessage && <p>{secondMessage}</p>}
      </div>
    );
  };
  return (
    <>
      <CustomModal
        headerText={getLanguageValue(props.languageText, "Confirm")}
        bodyText={getConfirmationMessage()}
        submitButtonText={getLanguageValue(props.languageText, "Ok")}
        handleSubmitClick={props.handleSaveClick}
        handleCancelClick={props.closeShowConfirmationModal}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
      />
    </>
  );
};
