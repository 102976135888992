import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { getParticipantRespondentsByParticipantId } from "../../actions";
import { ProfileStatus } from "../../../profileEnums";
import { ParticipantsRespondentsStatus } from "./participantsRespondentsStatus";
import { setSpinner } from "../../../../actions";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ProfileId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IParticipantAccountDetailsModalProps {
  languageText: ITranslationObject;
  languages: ReadonlyArray<ILanguage>;
  profileId: ProfileId;
  status?: number;
  handleCloseClick: () => void;
}

export const ParticipantAccountDetailsModal = (
  props: IParticipantAccountDetailsModalProps,
) => {
  const dispatch = useDispatch();
  const isNewStatus = props.status === ProfileStatus.New;

  const [participantLink, setParticipantLink] = useState<string>("");

  useEffect(() => {
    if (isNewStatus) {
      dispatch(setSpinner(true));
      getParticipantRespondentsByParticipantId(props.profileId, dispatch)
        .then((response) => {
          if (response) {
            setParticipantLink(response.participantLink);
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  }, []);

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          props.languageText,
          "Participant account details",
        )}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
        width="lg"
      >
        {isNewStatus ? (
          <>
            <div className="fs-6">
              {getLanguageValue(
                props.languageText,
                "Please use the following link to open the participant interface",
              )}
            </div>
            <div className="fs-6 mt-3">{participantLink}</div>
          </>
        ) : (
          <>
            <ParticipantsRespondentsStatus
              languageText={props.languageText}
              languages={props.languages}
              profileId={props.profileId}
            />
          </>
        )}
      </ModalComponent>
    </>
  );
};
