import React from "react";

interface IMultiLabelToggleCheckboxProps {
  flabel: string;
  rlabel: string;
  name?: string;
  value?: boolean;
  className?: string;
  disable?: boolean;
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MultiLabelToggleCheckbox = (
  props: IMultiLabelToggleCheckboxProps,
) => {
  return (
    <div className="d-flex align-items-center mb-3">
      <span className="form-check-label text-dark fs-5 me-2">
        {props.flabel}
      </span>
      <div className="form-check form-switch fs-5">
        <input
          className="form-check-input bg-success"
          type="checkbox"
          name={name ?? "name"}
          onChange={props.handleCheckboxChange}
          disabled={props.disable ?? false}
          checked={props.value}
        />
      </div>
      <span className="form-check-label text-dark fs-5">{props.rlabel}</span>
    </div>
  );
};
