import React from "react";

import { EditProfileCompletedParticipant } from "./editProfileCompletedParticipant";
import { EditProfilePresentation } from "./editProfilePresentation";
import { IEditActivity } from "../interface";
import { ITranslationObject } from "../../../../commonUtils/languageFunctionsHelper";
import { GuidePages } from "@app/containers/commonEnums";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { ICulture, ILanguage } from "@app/containers/commonInterfaces";

interface IEditProfileCompletedOrPlannedParticipantProps {
  id: ActivityId;
  languageText: ITranslationObject;
  profileData: IEditActivity;
  isInstructionHelpClicked: boolean;
  onGoingStatusEditProfileGuideDone: boolean;
  cultures: ReadonlyArray<ICulture>;
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  messageTranslation: ITranslationObject;
  isActivityCancelled: boolean;
  isActivityInvoiced: boolean;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  refetchFullProfile: () => void;
  refetchCompletedParticipants: () => void;
  refetchOngoingParticipants: () => void;
  refetchPlannedPresentations: () => void;
  refetchDeliveredParticipants: () => void;
  handlePublishProfile: (profileId: ProfileId) => void;
  sendEmailForDeliveredProfiles: (profileIds: Array<ProfileId>) => void;
  handleTransferProfile?: () => void;
}

export const EditProfileCompletedOrPlannedParticipant: React.FC<
  IEditProfileCompletedOrPlannedParticipantProps
> = (props) => {
  return (
    <>
      <EditProfileCompletedParticipant
        id={props.id}
        languageText={props.languageText}
        profileData={props.profileData}
        isInstructionHelpClicked={props.isInstructionHelpClicked}
        onGoingStatusEditProfileGuideDone={
          props.onGoingStatusEditProfileGuideDone
        }
        languages={props.languages}
        cultureValue={props.cultureValue}
        messageTranslation={props.messageTranslation}
        isActivityCancelled={props.isActivityCancelled}
        isActivityInvoiced={props.isActivityInvoiced}
        handleExitGuide={props.handleExitGuide}
        refetchFullProfile={props.refetchFullProfile}
        refetchCompletedParticipants={props.refetchCompletedParticipants}
        refetchOngoingParticipants={props.refetchOngoingParticipants}
        refetchPlannedPresentations={props.refetchPlannedPresentations}
        refetchDeliveredParticipants={props.refetchDeliveredParticipants}
        handlePublishProfile={props.handlePublishProfile}
        sendEmailForDeliveredProfiles={props.sendEmailForDeliveredProfiles}
        handleTransferProfile={props.handleTransferProfile}
      />
      <EditProfilePresentation
        languageText={props.languageText}
        profileData={props.profileData}
        cultures={props.cultures}
        isActivityCancelled={props.isActivityCancelled}
        isActivityInvoiced={props.isActivityInvoiced}
        refetchCompletedParticipants={props.refetchCompletedParticipants}
        refetchPlannedPresentations={props.refetchPlannedPresentations}
        refetchDeliveredParticipants={props.refetchDeliveredParticipants}
      />
    </>
  );
};
