import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  ILanguageObject,
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import {
  ProfileStatus,
  ProfileOutputPage,
  ProfileOutputPages,
} from "@app/containers/profileList/profileEnums";
import { IEditProfileByIdAndStatus } from "../../interface";
//If we are giving @app path, it is throwing "File name differs from already included file name only in casing" error
import {
  downloadParticipantProfilePdfReport,
  updateProfileParticipantPdfDownload,
} from "../../actions";
import { addToast, setNotification, setSpinner } from "@app/containers/actions";
import { LanguageModal } from "@app/components/modals/languageModal";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { getParticipantLanguages } from "@app/containers/languageList/languageTexts/actions";
import { downloadFileFromBlob } from "@app/commonUtils/downloadHepler";
import { AnyAction } from "redux";
import { RootState } from "@app/store/configureStore";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { useApiEndpoints } from "@app/api/end-points";
import { useConfirm } from "@app/confirm";

interface IProfileParticipantProfileProps {
  languageText: ITranslationObject;
  status: number;
  profileId: ProfileId;
  isDownloaded?: boolean;
  pdfProfileDownloaded?: boolean;
  profileParticipantData: IEditProfileByIdAndStatus;
  handleProfileLock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatedownloadedStatus: () => void;
  setProfileParticipantData: (
    value: React.SetStateAction<IEditProfileByIdAndStatus>,
  ) => void;
  handleCloseClick: () => void;
  handlePublishProfile?: (profileId: ProfileId) => void;
}

export const ProfileParticipantProfile = (
  props: IProfileParticipantProfileProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const activityId = (Number(useParams().id) || 0) as ActivityId;

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const reportName = `IDIProfile_${props.profileParticipantData?.name}_${props.profileId}`;

  const [languages, setLanguages] = useState<ILanguageObject[]>([]);
  const [reportReady, setReportReady] = useState<boolean>(false);
  const [languageSelectionModal, setLanguageSelectionModal] =
    useState<boolean>(false);
  const [page, setPage] = useState<ProfileOutputPage>(ProfileOutputPages.All);
  const [file1Clicked, setFile1Clicked] = useState<boolean>(false);
  const [file2Clicked, setFile2Clicked] = useState<boolean>(false);
  const [selectedLanguageForReport, setSelectedLanguageForReport] =
    useState<string>("");
  const confirm = useConfirm();

  const handleCloseClick = (): void => {
    setLanguageSelectionModal(false);
    setPage(ProfileOutputPages.All);
    setReportReady(false);
  };

  useEffect(() => {
    if (file1Clicked && file2Clicked) {
      updateDownloadStatus();
    }
  }, [file1Clicked, file2Clicked]);

  const onLanguageClick = async (language: ILanguageObject) => {
    const languageCode = language.name;
    setSelectedLanguageForReport(languageCode);
    setReportReady(true);
  };

  const fetchLanguages = async () => {
    dispatch(setSpinner(true));
    const languagesResponse = await getParticipantLanguages(dispatch);
    let languages: ILanguageObject[] = [];
    if (languagesResponse && languagesResponse?.length > 0) {
      languages = languagesResponse.map((item) => {
        const displayName = item.displayName.split(" ")[0];
        return {
          key: item.id,
          id: item.id,
          icon: item.icon,
          value: String(item.id),
          name: item.name,
          displayName: displayName,
        };
      });
    }
    setLanguages(languages);
    dispatch(setSpinner(false));
  };

  const updateDownloadStatus = (): void => {
    if (!props.isDownloaded && !props.pdfProfileDownloaded) {
      updateProfileParticipantPdfDownload(
        activityId,
        [props.profileId],
        dispatch,
      ).then((response) => {
        if (response?.success) {
          props.updatedownloadedStatus();
          dispatch(addToast("Downloaded successfully") as AnyAction);
        }
      });
    }
  };

  function publishProfile() {
    api.publishProfile(props.profileId).then((response) => {
      if (response) {
        dispatch(addToast("Profile published") as AnyAction);
        props.handleCloseClick();
        props.handlePublishProfile?.(props.profileId);
      }
    });
  }

  const handlePublishClick = async () => {
    //isDownloaded is more state from editProfileParticipantModal.tsx.
    //Should be handled with making sure we refresh the profile instead. - Joakim, 241121
    if (
      props.profileParticipantData.pdfProfileDownloaded ||
      props.isDownloaded ||
      isLoggedInRoleAdmin(loggedInUserRole)
    ) {
      await confirm(
        getLanguageValue(props.languageText, "Confirm profile delivery"),
        getLanguageValue(
          props.languageText,
          "Delivering the profile means it will be shown on the user's profile page",
        ),
      ).then(() => {
        publishProfile();
      });
    } else {
      dispatch(
        setNotification(
          "Profile need to be downloaded before being able to deliver.",
        ),
      );
    }
  };

  const onDownloadClick = async (): Promise<void> => {
    dispatch(setSpinner(true));
    const fileNameSuffix =
      page == ProfileOutputPages.Page1
        ? "p1"
        : page == ProfileOutputPages.Page2
          ? "p2"
          : "";
    try {
      const blobData = await downloadParticipantProfilePdfReport(
        [props.profileId],
        selectedLanguageForReport,
        page,
        dispatch,
      );

      downloadFileFromBlob(blobData, `${reportName + fileNameSuffix}.pdf`);

      if (page === ProfileOutputPages.All) {
        updateDownloadStatus();
      }
      if (page === ProfileOutputPages.Page1) {
        setFile1Clicked(true);
      }
      if (page === ProfileOutputPages.Page2) {
        setFile2Clicked(true);
      }
    } catch (e: any) {
      //Error is suppressed as the error is aleady handled in the downloadParticipantProfilePdfReport function
    }
    dispatch(setSpinner(false));
  };

  return (
    <div id="editParticipantProfile">
      <div className="fs-5 fw-bold mb-3">
        {getLanguageValue(props.languageText, "IDI-profile")}
      </div>

      {props.status === ProfileStatus.New ||
      props.status === ProfileStatus.Active ? (
        getLanguageValue(props.languageText, "Not ready")
      ) : (
        <>
          <div className="row">
            <div className="col">
              <h6>
                {getLanguageValue(props.languageText, "Download profile")}
              </h6>
              <div className="btn-group">
                <div
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setLanguageSelectionModal(true);
                    fetchLanguages();
                    setPage(ProfileOutputPages.All);
                  }}
                >
                  {getLanguageValue(props.languageText, "Full Profile")}
                </div>

                <div
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setLanguageSelectionModal(true);
                    fetchLanguages();
                    setPage(ProfileOutputPages.Page1);
                  }}
                >
                  {getLanguageValue(props.languageText, "Page 1")}
                </div>

                <div
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setLanguageSelectionModal(true);
                    fetchLanguages();
                    setPage(ProfileOutputPages.Page2);
                  }}
                >
                  {getLanguageValue(props.languageText, "Page 2")}
                </div>
              </div>
            </div>

            {(props.status === ProfileStatus.Completed ||
              props.status === ProfileStatus.DeliveryPlanned) && (
              <div className="col">
                <h6>
                  {getLanguageValue(props.languageText, "Deliver profile")}
                </h6>
                <div
                  className="btn btn-outline-success"
                  onClick={() => handlePublishClick()}
                >
                  Deliver profile
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {languageSelectionModal && (
        <>
          <LanguageModal
            languageText={props.languageText}
            languages={languages}
            headerText={`${getLanguageValue(props.languageText, "Profile")} - ${
              props.profileParticipantData.name
            } ${props.profileParticipantData.surname}`}
            selectedProfileLanguageId={0}
            handleChangeLanguage={onLanguageClick}
            closeLanguageModal={handleCloseClick}
          />
        </>
      )}

      {reportReady && (
        <ModalComponent
          headerText={`${getLanguageValue(props.languageText, "Profile")} - ${
            props.profileParticipantData?.name
          } ${props.profileParticipantData.surname}`}
          cancelButtonText={getLanguageValue(props.languageText, "Close")}
          handleCancelClick={handleCloseClick}
        >
          <div className="d-flex flex-column gap-10">
            <div>
              <div className="fs-7 fw-bold text-gray-600 pb-2">
                {getLanguageValue(props.languageText, "Download File")}
              </div>
              <button onClick={onDownloadClick} className="btn btn-success">
                {getLanguageValue(props.languageText, "Download")}
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};
