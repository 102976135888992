import { configureStore } from "@reduxjs/toolkit";
import { createReducer } from "../reducers";

export function configureAppStore() {
  const store = configureStore({
    reducer: createReducer(),
    devTools: process.env.REACT_APP_ENV !== "Production",
  });

  return store;
}

export type AppDispatch = ReturnType<typeof configureAppStore>["dispatch"];
export type RootState = ReturnType<
  ReturnType<typeof configureAppStore>["getState"]
>;
