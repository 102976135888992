import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";

import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { getAuditLogList } from "./actions";
import { RootState } from "@app/store/configureStore";
import { setSpinner } from "../actions";
import {
  IAuditLog,
  IAuditLogDetails,
  IAuditLogGetData,
  IFilterObject,
} from "./interface";
import { truncate } from "../utils";
import { getFormattedDateOnly } from "../../commonUtils/dateFunctionsHelper";

export const useAuditLogs = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const initialFiltersObject = {
    startDate: startDate,
    endDate: new Date(),
    userName: "",
    serviceName: "",
    methodName: "",
    browserInfo: "",
    hasException: true,
    minExecutionDuration: 0,
    maxExecutionDuration: 100000,
    clientIpAddress: "",
  };

  const initialAuditLogDetails: IAuditLogDetails = {
    userName: "",
    serviceName: "",
    methodName: "",
    parameters: "",
    executionTime: new Date(),
    executionDuration: 0,
    clientIpAddress: "",
    clientName: "",
    browserInfo: "",
    exception: "",
    customData: "",
  };

  const [data, setdata] = useState<IAuditLog[]>([]);
  const [sorting, setSorting] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [auditLogsUpdated, setAuditLogsUpdated] = useState<boolean>(false); // This flag is used to re-reder the profile descriptions data on requirement
  const [updatedFilterOptions, setUpdatedFilterOptions] =
    useState<IFilterObject>(initialFiltersObject);
  const [auditLogDetailRowId, setAuditLogDetailRowId] = useState<number>(0);
  const [auditLogDetails, setAuditLogDetails] = useState<IAuditLogDetails>(
    initialAuditLogDetails,
  );

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchAuditLogs(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchAuditLogs(0);
    if (!isMounted.current) isMounted.current = true;
  }, [updatedFilterOptions, pageLimit]);

  useEffect(() => {
    if (auditLogsUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchAuditLogs(skipCount);
    }
  }, [auditLogsUpdated]);

  const fetchAuditLogs = (skipCount: number): void => {
    dispatch(setSpinner(true));

    const body: IAuditLogGetData = {
      startDate: getFormattedDateOnly(updatedFilterOptions.startDate),
      endDate: getFormattedDateOnly(updatedFilterOptions.endDate),
      userName: updatedFilterOptions.userName,
      serviceName: updatedFilterOptions.serviceName,
      clientIpAddress: updatedFilterOptions.clientIpAddress,
      methodName: updatedFilterOptions.methodName,
      browserInfo: updatedFilterOptions.browserInfo,
      hasException: updatedFilterOptions.hasException,
      minExecutionDuration: updatedFilterOptions.minExecutionDuration,
      maxExecutionDuration: updatedFilterOptions.maxExecutionDuration,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: skipCount,
    };

    getAuditLogList(body, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setdata(response.items);
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const refetchAuditLogsList = (): void => setAuditLogsUpdated(true);

  const handleUpdatedFilterOptions = (props: IFilterObject) => {
    setUpdatedFilterOptions(props);
  };

  const handleDetailsClick = (id: number, details: IAuditLogDetails): void => {
    setAuditLogDetailRowId(id);
    setAuditLogDetails(details);
  };

  const closeDetailsModal = (): void => {
    setAuditLogDetailRowId(0);
    setAuditLogDetails(initialAuditLogDetails);
  };

  const tableHeader = useMemo<ColumnDef<IAuditLog>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleDetailsClick(props.row.original.id, props.row.original)
            }
          >
            <i className="bi bi-search text-gray-400 ms-2 my-1 cursor-na " />
          </div>
        ),
      },
      {
        header: getLanguageValue(languageText, "ID"),
        accessorKey: "id",
      },
      {
        header: getLanguageValue(languageText, "User Name"),
        accessorKey: "userName",
      },
      {
        header: getLanguageValue(languageText, "Service"),
        accessorKey: "serviceName",
      },
      {
        header: getLanguageValue(languageText, "Action"),
        accessorKey: "methodName",
      },
      {
        header: getLanguageValue(languageText, "Duration"),
        accessorKey: "executionDuration",
      },
      {
        header: getLanguageValue(languageText, "IP Address"),
        accessorKey: "clientIpAddress",
      },
      {
        header: getLanguageValue(languageText, "Client"),
        accessorKey: "clientName",
      },
      {
        header: getLanguageValue(languageText, "Browser"),
        accessorKey: "browserInfo",
        cell: ({ ...props }) => (
          <span>{truncate(props.row.original.browserInfo, 25)}</span>
        ),
      },
      {
        header: getLanguageValue(languageText, "Time"),
        accessorKey: "executionTime",
      },
    ],
    [languageText],
  );

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    pageLimit,
    pageSelected,
    auditLogDetailRowId,
    auditLogDetails,
    setSorting,
    setPageLimit,
    setPageSelected,
    refetchAuditLogsList,
    handleUpdatedFilterOptions,
    closeDetailsModal,
  };
};
