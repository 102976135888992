import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../api";
import { setNotification, setSpinner } from "../../actions";
import {
  IUpdateLanguageParams,
  IUserProfile,
} from "@app/containers/commonInterfaces";

export const updateUser = async (
  body: IUserProfile,
  isParticipant: boolean,
  dispatch: Dispatch,
): Promise<boolean> => {
  const queryParmas = {
    isParticipant,
  };
  try {
    const response = await api.put(apiEndPoints.editUserData, body, {
      params: queryParmas,
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    dispatch(setSpinner(false));
    throw error;
  }
};

export const updateUserLanguage = async (
  body: IUpdateLanguageParams,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateUserLanguage,
      JSON.stringify(body),
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
