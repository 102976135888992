import * as React from "react";
import { ITranslationObject } from "@app/commonUtils/languageFunctionsHelper";
import { LanguageNavbar } from "@app/components/languageNavbarComponent/languageNavbar";

type Props = React.PropsWithChildren<{
  page: "login" | "register";
  language: ITranslationObject;
  hideLanguageSelection?: boolean;
}>;

export const AuthLayout: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className="container my-md-5">
        <div className="row p-3">
          <div className="col-12 col-md-8 col-lg-6 col-xl-4 shadow-sm p-4 p-md-5 bg-white rounded mx-auto">
            {
              <LanguageNavbar
                hideLanguageSelection={props.hideLanguageSelection}
                languageCodeInURL={true}
              />
            }
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
