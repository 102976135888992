import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { ClientSelectModal } from "../editProfile/modals/clientSelectModal/clientSelectModal";
import { IClients } from "@app/containers/commonInterfaces";

interface IActivityClientSelectProps {
  languageText: ITranslationObject;
  clientData: ReadonlyArray<IClients>;
  clientId: number;
  clientEditMode: boolean;
  refreshClientList: () => void;
  openSelectClientEditMode: () => void;
  closeClientSelectModal: () => void;
  handleClientSelect: (
    clientId: number,
    clientName: string,
    isFacilitatorRemoved: boolean,
  ) => void;
}

export const ActivityClientSelectionStep = (
  props: IActivityClientSelectProps,
) => {
  const {
    languageText,
    clientData,
    clientId,
    clientEditMode,
    refreshClientList,
    openSelectClientEditMode,
    handleClientSelect,
    closeClientSelectModal,
  } = props;

  return (
    <div className="mb-4 p-4 rounded shadow-sm bg-white">
      <div className="fs-5 fw-bold mb-3">
        {getLanguageValue(languageText, "Account")}
      </div>
      {clientEditMode ? (
        <ClientSelectModal
          languageText={languageText}
          clientData={clientData}
          clientId={clientId}
          handleClientSelect={handleClientSelect}
          closeClientSelectModal={closeClientSelectModal}
          refetchClientList={refreshClientList}
        />
      ) : (
        <>
          <button className="btn me-3 border">
            {clientData.find((item) => item.id === clientId)?.name}
          </button>
          <button className="btn btn-light" onClick={openSelectClientEditMode}>
            <i className="bi bi-pencil"></i>
          </button>
        </>
      )}
    </div>
  );
};
