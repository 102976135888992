import React from "react";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../commonUtils/languageFunctionsHelper";
import {
  IGroupReportDirectivenesses,
  IProfileGroupReport,
} from "../../../interface";
import { ReportType, ReportTypes } from "../../../../../../types";
import { classNames } from "@app/containers/utils";
import { ProfileId } from "@app/containers/reducer";

interface IGroupAffDirGraphProps {
  languageText: ITranslationObject;
  reportType: ReportType;
  hoveredProfileId?: ProfileId;
  profileGroupReport: IProfileGroupReport | undefined;
  selectedProfileIds?: Array<ProfileId>;
  enableMarkerDimming?: boolean; // If we don't have a list of participants, we want to show the markers with full opacity
}

export const GroupAffDirGraph = (
  props: IGroupAffDirGraphProps,
): JSX.Element => {
  const averageSelfAffiliationDirectivenesses =
    props.profileGroupReport?.selfGroupReport?.groupReportDirectivenesses ?? [];
  const averageOtherAffiliationDirectivenesses =
    props.profileGroupReport?.otherGroupReport?.groupReportDirectivenesses ??
    [];
  const selectedProfileIds = props.selectedProfileIds ?? [];
  const enableMarkerDimming = props.enableMarkerDimming ?? true;

  let averageAffiliation = 0;

  if (!props.profileGroupReport) {
    return <></>;
  }

  //Purposely not assigning value to BOTH here, since we just want to show the labels without values on those cases
  if (props.reportType === ReportTypes.SELF)
    averageAffiliation =
      props.profileGroupReport.selfGroupReport?.averageAffiliation;
  if (props.reportType === ReportTypes.OTHER)
    averageAffiliation =
      props.profileGroupReport.otherGroupReport?.averageAffiliation;

  let averageDirectiveness = 0;
  if (props.reportType === ReportTypes.SELF)
    averageDirectiveness =
      props.profileGroupReport.selfGroupReport?.averageDirectiveness;
  if (props.reportType === ReportTypes.OTHER)
    averageDirectiveness =
      props.profileGroupReport.otherGroupReport?.averageDirectiveness;

  return (
    <div className="position-relative">
      <div className="ratio ratio-1x1 border">
        {/* Others */}
        {(props.reportType === ReportTypes.OTHER ||
          props.reportType === ReportTypes.BOTH) &&
          averageOtherAffiliationDirectivenesses.map(
            (value: IGroupReportDirectivenesses, index: number) => {
              const hovered = props.hoveredProfileId
                ? value.profileIds.includes(props.hoveredProfileId)
                : false;

              //If the anyone in the participant group is in the selected list, we want to highlight the marker
              const selected: boolean = selectedProfileIds.some(
                (participantId) => value.profileIds.includes(participantId),
              );
              const highlighted =
                hovered ||
                selected ||
                !enableMarkerDimming ||
                (selectedProfileIds.length === 0 && enableMarkerDimming);
              // AM: This is not updated
              const clazzes = classNames({
                "idi-position-parent": true,
                "idi-position-parent-hovered": hovered,
                "font-size-9": value.pointId > 99,
                "marker-highlighted": highlighted,
                "marker-dimmed": !highlighted,
              });
              return (
                <div
                  key={index}
                  className={`${clazzes} y${value.affPos} x${value.dirPos}`}
                >
                  {/* We have more than one respondant with same value, let's add the gray indication. */}
                  {value.count > 1 && (
                    <div
                      className={clsx(
                        "position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark z-1",
                        hovered && "div-count-hovered",
                      )}
                    >
                      {value.count}
                    </div>
                  )}
                  <div className="idi-position-child bg-success">
                    {value.pointId}
                  </div>
                </div>
              );
            },
          )}

        {/* Self */}
        {(props.reportType === ReportTypes.SELF ||
          props.reportType === ReportTypes.BOTH) &&
          averageSelfAffiliationDirectivenesses.map(
            (value: IGroupReportDirectivenesses, index: number) => {
              const hovered = props.hoveredProfileId
                ? value.profileIds.includes(props.hoveredProfileId)
                : false;

              //If the anyone in the participant group is in the selected list, we want to highlight the marker
              const selected: boolean = selectedProfileIds.some(
                (participantId) => value.profileIds.includes(participantId),
              );

              const highlighted =
                hovered ||
                selected ||
                !enableMarkerDimming ||
                (selectedProfileIds.length === 0 && enableMarkerDimming);

              const clazzes = classNames({
                "idi-position-parent": true,
                "idi-position-parent-hovered": hovered,
                "marker-dimmed": !highlighted,
                "marker-highlighted": highlighted,
              });
              return (
                <div
                  key={index}
                  className={`${clazzes} y${value.affPos} x${value.dirPos}`}
                >
                  {/* We have more than one respondant with same value, let's add the gray indication. */}

                  {value.count > 1 && (
                    <div
                      className={clsx(
                        "position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark z-1",
                        hovered && "div-count-hovered",
                      )}
                    >
                      {value.count}
                    </div>
                  )}
                  <div className="idi-position-child bg-danger rounded-circle">
                    {value.pointId}
                  </div>
                </div>
              );
            },
          )}

        <div className="d-flex justify-content-between rotate90 ms-n4">
          <div>{getLanguageValue(props.languageText, "Task focus")}</div>
          <div className="fw-bold">
            {getLanguageValue(props.languageText, "Affiliation")}{" "}
            {averageAffiliation ? <>&Oslash; {averageAffiliation}%</> : ""}
          </div>
          <div>{getLanguageValue(props.languageText, "People focus")}</div>
        </div>
        <div className="d-flex flex-wrap">
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25"></div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-1">
        <div>{getLanguageValue(props.languageText, "Inquery")}</div>
        <div className="fw-bold">
          {getLanguageValue(props.languageText, "Directiveness")}{" "}
          {averageDirectiveness ? <>&Oslash; {averageDirectiveness}%</> : ""}
        </div>
        <div>{getLanguageValue(props.languageText, "Advocacy")}</div>
      </div>

      <div className="d-flex gap-4 mt-4">
        <div className="d-flex align-items-center gap-2">
          <div className="self-circle bg-danger" />
          {getLanguageValue(props.languageText, "Self")}
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="other-square bg-success" />
          {getLanguageValue(props.languageText, "Respondents")}
        </div>
      </div>
    </div>
  );
};
