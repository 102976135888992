import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@app/store/configureStore";

import { routePath } from "../../../routePaths";
import { setSpinner } from "../../../actions";
import { getAllDocumentCategory, getLatestDocumentsList } from "../actions";
import {
  IDocumentCategory,
  ILatestDocument,
  ILatestDocumentList,
} from "./types";
import { getDocumentDownloadFile } from "../../../../commonUtils/downloadDocumentHepler";

export const useLatestNews = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = 100;

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [totalCount, setTotalCount] = useState<number>(0);
  const [documentCategories, setDocumentCategories] = useState<
    IDocumentCategory[]
  >([]);
  const [documents, setDocuments] = useState<ILatestDocumentList[]>([
    {
      category: "",
      documentCategoryId: 0,
      documentFile: null,
      excerpt: "",
      file: "",
      originalFileName: "",
      id: 0,
      iDILanguageId: 0,
      language: "",
      post: "",
      published: false,
      publishedDate: new Date(),
      title: "",
      type: "",
    },
  ]);

  const documentCategoryParams = {
    filter: "",
    sorting: "",
    maxResultCount: 10,
    skipCount: 0,
    dispatch: dispatch,
  };

  useEffect(() => {
    dispatch(setSpinner(true));
    getAllDocumentCategory(
      documentCategoryParams.filter,
      documentCategoryParams.sorting,
      documentCategoryParams.maxResultCount,
      documentCategoryParams.skipCount,
      documentCategoryParams.dispatch,
    )
      .then((response) => {
        if (response) {
          setDocumentCategories(response.items);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  }, []);

  const getAllDocuments = async (skipCount: number): Promise<void> => {
    dispatch(setSpinner(true));
    getLatestDocumentsList("", "", pageLimit, skipCount, dispatch)
      .then((fetchedDocuments: ILatestDocument) => {
        if (fetchedDocuments) {
          setDocuments(fetchedDocuments.items);
          setTotalCount(fetchedDocuments.totalCount);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    getAllDocuments(0);
  }, []);

  const handleNavigateBack = (): void => {
    navigate(routePath.information);
  };

  const handleDocDownloadClick = (id: number, file: string) => {
    getDocumentDownloadFile(id, file, dispatch);
  };

  return {
    languageText,
    documentCategories,
    documents,
    handleNavigateBack,
    handleDocDownloadClick,
  };
};
