import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import {
  ILanguageObject,
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../../commonUtils/languageFunctionsHelper";
import {
  updateProfileParticipantPdfDownload,
  downloadParticipantProfilePdfReport,
} from "../../../../actions";
import { addToast, setSpinner } from "../../../../../../actions";
import { ProfileStatus, ProfileOutputPages } from "../../../../../profileEnums";
import { LanguageModal } from "../../../../../../../components/modals/languageModal";
import { getParticipantLanguages } from "../../../../../../languageList/languageTexts/actions";
import { downloadFileFromBlob } from "@app/commonUtils/downloadHepler";
import { AnyAction } from "redux";
import { ActivityId, ProfileId } from "@app/containers/reducer";

interface IMultipleParticipantDownloadModalProps {
  languageText: ITranslationObject;
  profileIds: Array<ProfileId>;
  status?: number;
  profilesToUpdateDownloadStatus?: Array<ProfileId>;
  handleCloseClick: () => void;
  refetchCompletedParticipants?: () => void;
}

export const MultipleParticipantDownloadModal = (
  props: IMultipleParticipantDownloadModalProps,
): JSX.Element => {
  const {
    languageText,
    profileIds,
    status,
    profilesToUpdateDownloadStatus,
    handleCloseClick,
    refetchCompletedParticipants,
  } = props;

  const dispatch = useDispatch();
  const activityId = (Number(useParams().id) || 0) as ActivityId;

  const [languages, setLanguages] = useState<ILanguageObject[]>([]);

  const updateDownloadStatus = () => {
    // Update profile downloaded status
    if (profilesToUpdateDownloadStatus) {
      updateProfileParticipantPdfDownload(
        activityId,
        profilesToUpdateDownloadStatus,
        dispatch,
      ).then((response) => {
        if (response?.success) {
          dispatch(addToast("Downloaded successfully") as AnyAction);
        }
        refetchCompletedParticipants && refetchCompletedParticipants();
        handleCloseClick();
      });
    }
  };

  const onLanguageClick = async (language: ILanguageObject): Promise<void> => {
    dispatch(setSpinner(true));

    try {
      const languageCode = language.name;
      const blobData = await downloadParticipantProfilePdfReport(
        profileIds,
        languageCode,
        ProfileOutputPages.All,
        dispatch,
      );

      downloadFileFromBlob(blobData, `IDIProfile.pdf`);

      if (
        (status === ProfileStatus.Completed ||
          status === ProfileStatus.Delivery) &&
        profilesToUpdateDownloadStatus &&
        profilesToUpdateDownloadStatus?.length > 0
      ) {
        updateDownloadStatus();
      }
    } catch (e: any) {
      //Error is suppressed as the error is aleady handled in the downloadParticipantProfilePdfReport function
    }
    dispatch(setSpinner(false));
  };

  const fetchLanguages = async () => {
    dispatch(setSpinner(true));
    const languagesResponse = await getParticipantLanguages(dispatch);
    let languages: ILanguageObject[] = [];
    if (languagesResponse && languagesResponse?.length > 0) {
      languages = languagesResponse.map((item) => {
        const displayName = item.displayName.split(" ")[0];
        return {
          id: item.id,
          icon: item.icon,
          name: item.name,
          displayName: displayName,
        };
      });
    }
    setLanguages(languages);
    dispatch(setSpinner(false));
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  return (
    <LanguageModal
      languageText={languageText}
      languages={languages}
      headerText={getLanguageValue(languageText, "Group Profile")}
      selectedProfileLanguageId={0}
      handleChangeLanguage={onLanguageClick}
      closeLanguageModal={handleCloseClick}
    />
  );
};
