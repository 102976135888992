import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IFocusError } from "../../../components/formComponents/input";
import { addToast, setSpinner } from "../../actions";
import { RootState } from "@app/store/configureStore";
import { ValidationHelper } from "../../validationHelper";
import { getLoggedInUserData } from "../../auth/signUp/actions";
import { updateUser } from "./actions";
import { E164Number } from "libphonenumber-js/types";
import { AnyAction } from "redux";
import { IUserProfile } from "@app/containers/commonInterfaces";
import { isLoggedInRoleParticipant } from "@app/commonUtils/roleHelper";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";

const UserInputs = {
  name: "name",
  surname: "surname",
  emailAddress: "emailAddress",
} as const;

export interface IFocusInput {
  name: IFocusError;
  surname: IFocusError;
  emailAddress: IFocusError;
}

export const useEditUser = () => {
  const dispatch = useDispatch();

  const userData = useSelector(
    (state: RootState) => state.loginReducer.userData,
  );
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [changeUserEmailModal, setChangeUserEmailModal] =
    useState<boolean>(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [userProfileData, setUserProfileData] = useState<IUserProfile>({
    ...userData,
  });
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    name: {
      touched: false,
      errorMessage: "",
    },
    surname: {
      touched: false,
      errorMessage: "",
    },
    emailAddress: {
      touched: false,
      errorMessage: "",
    },
  });

  useEffect(() => {
    if (userLanguage.userLanguageId) {
      setUserProfileData({
        ...userData,
        selectedLanguageId: userLanguage.userLanguageId,
      });
    }
  }, [userData, userLanguage.userLanguageId]);

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = "";

    switch (name) {
      case UserInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Name is required");
        }
        break;
      case UserInputs.surname:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Surname is required");
        }
        break;
      case UserInputs.emailAddress:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Email is required");
        } else {
          const isValid = ValidationHelper.isEmailValid(value);
          if (!isValid)
            errorMessage = getLanguageValue(
              languageText,
              "Incorrect email format",
            );
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !userProfileData.name ||
      focusInput.name.errorMessage ||
      !userProfileData.surname ||
      focusInput.surname.errorMessage ||
      !userProfileData.emailAddress ||
      focusInput.emailAddress.errorMessage
    ) {
      for (const item of Object.values(UserInputs)) {
        handleFormErrors(item, userProfileData[item]);
      }
      return false;
    }
    if (phoneNumberError) {
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;

    setUserProfileData({
      ...userProfileData,
      [name]: value,
    });

    if (focusInput[name as keyof typeof focusInput]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handlePhoneInputChange = (value: string): void => {
    setUserProfileData({
      ...userProfileData,
      phoneNumber: value,
    });
  };

  const validatePhonenumber = (value: E164Number): void => {
    const phoneNumber = parseInt(value);

    if (phoneNumber === 0) {
      setPhoneNumberError(false);
    } else if (value.length >= 1 && value.length <= 4) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleChangeUserEmail = (): void => {
    userData.isTwoFactorEnabled = false;
    userData.isEmailConfirmed = false;
    updateUserProfile();

    setChangeUserEmailModal(false);
  };

  const handleCancelClick = (): void => {
    setUserProfileData({
      ...userProfileData,
      emailAddress: userData.emailAddress,
    });
    setChangeUserEmailModal(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!handleValidationOnSubmit()) return;
    if (userProfileData.emailAddress !== userData.emailAddress) {
      setChangeUserEmailModal(true);
    } else {
      updateUserProfile();
    }
  };

  const updateUserProfile = (): void => {
    dispatch(setSpinner(true));

    const body: IUserProfile = {
      ...userProfileData,
    };
    const isParticipant = isLoggedInRoleParticipant(loggedInUserRole);

    updateUser(body, isParticipant, dispatch)
      .then((response) => {
        if (response) {
          dispatch(addToast("Changes saved successfully") as AnyAction);
        }
      })
      .finally(() => {
        getLoggedInUserData(isParticipant, dispatch);
        dispatch(setSpinner(false));
      });
  };

  const handleChangePasswordModal = (e: {
    preventDefault: () => void;
  }): void => {
    e.preventDefault();
    setOpenChangePasswordModal(true);
  };

  return {
    languageText,
    userProfileData,
    focusInput,
    UserInputs,
    openChangePasswordModal,
    phoneNumberError,
    changeUserEmailModal,
    handleCancelClick,
    handleChangeUserEmail,
    validatePhonenumber,
    setUserProfileData,
    setFocusInput,
    handleBlurEvent,
    handleInputChange,
    handlePhoneInputChange,
    handleChangePasswordModal,
    setOpenChangePasswordModal,
    handleSubmit,
  };
};
