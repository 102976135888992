import React, { useState } from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { TextAreaModal } from "@app/components/modals/textAreaModal";
import { ProfileId } from "@app/containers/reducer";

export interface IRespondentEmailPreviewBody {
  profileId: ProfileId;
  languageCode: string;
  ownMsg: string;
}

export interface ICreateRespondentsParams {
  profileId: ProfileId;
}

export interface ICreateManualRespondentsBody {
  name: string;
  email: string;
  telephone: string;
  instructionLanguageId: number | null;
}

interface ISendRespondentsInvitationProps {
  languageText: ITranslationObject;
  messageValue: string;
  handleSaveMessage: (value: string) => void;
  onPreviewClick: () => void;
}

export const SendRespondentsInvitation = (
  props: ISendRespondentsInvitationProps,
) => {
  const { languageText, messageValue, handleSaveMessage, onPreviewClick } =
    props;

  const [addMessageModal, setAddMessageModal] = useState<boolean>(false);

  const onAddMessageClick = (): void => {
    setAddMessageModal(true);
  };

  const closeAddMessageModal = (): void => {
    setAddMessageModal(false);
  };

  return (
    <>
      <div>
        <div className="mb-3">
          {messageValue ? (
            <span onClick={onAddMessageClick} role="button">
              <span className="fs-6 me-1">
                <i className="bi bi-chat-left-text me-2"></i>{" "}
                {getLanguageValue(languageText, "Your message")}:
              </span>
              <span className="fs-6  fw-bold">{messageValue}</span>
            </span>
          ) : (
            <span onClick={onAddMessageClick} role="button">
              <span className="fs-6 me-1">
                <i className="bi bi-chat-left-text me-2 text-dark"></i>{" "}
                {getLanguageValue(languageText, "Add a personal message")}
              </span>
              <span className="fs-6 fw-bold">
                {getLanguageValue(languageText, "(optional)")}
              </span>
            </span>
          )}
        </div>
      </div>

      {addMessageModal && (
        <TextAreaModal
          languageText={languageText}
          headerText={getLanguageValue(
            languageText,
            messageValue ? "Edit message" : "Add message",
          )}
          value={messageValue}
          handleCloseModal={closeAddMessageModal}
          handleSaveClick={handleSaveMessage}
        />
      )}

      <div className="mb-3 pb-1"></div>

      <div className="d-flex align-items-center flex-wrap">
        <button
          id="inviteRespondentInstruction5"
          className="btn btn-light"
          onClick={onPreviewClick}
        >
          <i className="bi bi-eye me-2"></i>{" "}
          {getLanguageValue(languageText, "Preview")}
        </button>
      </div>
    </>
  );
};
