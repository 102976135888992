import React from "react";

import { AlertWindow } from "../../components/alert/alert";
import { RoleName } from "../commonEnums";

type AllowedRoles = Array<RoleName>;

export const roleAccessControl = (
  Component: JSX.Element,
  allowedRoles: AllowedRoles,
  loggedInUserRoleName: RoleName | null,
): JSX.Element => {
  if (loggedInUserRoleName && allowedRoles.includes(loggedInUserRoleName)) {
    return Component;
  } else {
    return <AlertWindow />;
  }
};
