import React from "react";

import { ReactTable } from "../../components/reactTable/reactTable";
import { AddEditCourseModal } from "./addEditCourseModal";
import { DeleteModal } from "../../components/modals/deleteModal";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { useCoursesList } from "./hooks";
import { CoursePermissionModal } from "./addEditCourseModal/editCoursePermission/coursePermissionModal";
import { ReorderCoursesModal } from "./reorderCoursesModal/reorderCoursesModal";

export const CoursesList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openAddEditCoursesModal,
    openReorderCoursesModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    coursePermission,
    courseTitle,
    courseId,
    setFilter,
    closePermissionModal,
    setSorting,
    setPageLimit,
    setPageSelected,
    addCourseClick,
    closeCoursesModal,
    onReorderCoursesClick,
    closeReorderCoursesModal,
    refetchCourseList,
    handleDeleteCourse,
    closeDeleteModal,
  } = useCoursesList();

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Course List")}
        searchPlaceholder={getLanguageValue(languageText, "Search Courses")}
        buttonText={getLanguageValue(languageText, "Add Course")}
        buttonSecondOptionalText={getLanguageValue(
          languageText,
          "Reorder Courses",
        )}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={addCourseClick}
        handleSecondButtonClick={onReorderCoursesClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openAddEditCoursesModal || editedRowId ? (
        <AddEditCourseModal
          editCourseId={editedRowId}
          closeCourseModal={closeCoursesModal}
          refetchCourseList={refetchCourseList}
        />
      ) : (
        ""
      )}

      {deleteRowId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, "Course")}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteCourse}
          closeDeleteModal={closeDeleteModal}
        />
      ) : (
        ""
      )}

      {openReorderCoursesModal && (
        <ReorderCoursesModal
          languageText={languageText}
          refetchCourseList={refetchCourseList}
          closeReorderCoursesModal={closeReorderCoursesModal}
        />
      )}

      {coursePermission && (
        <CoursePermissionModal
          courseId={courseId}
          courseTitle={courseTitle}
          closeModal={closePermissionModal}
        />
      )}
    </>
  );
};
