import { validateCharsEnum } from "../components/newPasswordInputBox/validationBox";

export class ValidationHelper {
  static isUserNameValid = (param: string): boolean => {
    const regExp = new RegExp(/^[a-zA-Z0-9@._%+-]+$/);
    return regExp.test(param);
  };

  static isEmailValid = (param: string): boolean => {
    const regExp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    return regExp.test(param);
  };

  static isPasswordValid = (param: string): boolean => {
    const regExp = new RegExp(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}/,
    );
    return regExp.test(param);
  };

  static isPhoneNumberValid = (param: string): boolean => {
    const regExp = new RegExp(/^[\d\-+\s]+$/);
    return regExp.test(param);
  };

  static isPasswordHasSpecialChar = (
    status: number,
    value: string,
  ): boolean => {
    let isValid = true;
    switch (status) {
      case validateCharsEnum.eightChar:
        if (value.length < 8) {
          isValid = false;
        }
        break;
      case validateCharsEnum.alphanumeric:
        if (!/[^\w\s]/.test(value)) {
          isValid = false;
        }
        break;
      case validateCharsEnum.specialChar:
        if (!/\d/.test(value)) {
          isValid = false;
        }
        break;
      case validateCharsEnum.uppercase:
        if (!/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
          isValid = false;
        }
        break;

      default:
        break;
    }
    return isValid;
  };

  static isPlacementValueValid = (param: string): boolean => {
    // The regular expression allows:
    // - Any positive number with up to 5 decimal places, excluding numbers that start with a leading zero.
    // - Zero (0).
    // - The numbers 7 and anything in between 0 and 7, inclusive.
    // The regular expression disallows:
    // - Any negative number.
    // - Any number with more than 5 decimal places.
    // - Any number that starts with a leading zero, except for zero itself.
    // - Any number greater than 7.
    const regExp = new RegExp(/^(?!0\d)(\d+|\d+[.,]\d{0,4})$/);
    return regExp.test(param);
  };
}
