import * as React from "react";
import { useParams } from "react-router-dom";
import { getAllPresentationSlides } from "../facilitatorDelivery/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import {
  IPresentationSlide,
  renderSlideDynamicHTML,
} from "../facilitatorDelivery/hooks";
import { PresentationPosition, usePresentationControls } from "./hooks";
import {
  getLanguageValue,
  TranslateFn,
} from "@app/commonUtils/languageFunctionsHelper";
import { CurrentStepPanel } from "../participantPages/panels";

const PRESENTATION_CURSOR_SELECTOR = ".presentation-cursor, .pc";

type Props = {
  __t: TranslateFn;
  slides: ReadonlyArray<IPresentationSlide>;
  onPositionChange?: (
    next: PresentationPosition,
    didMovePastEnd: boolean,
  ) => unknown;
};

function isLastSlideAndStep(
  slides: ReadonlyArray<IPresentationSlide>,
  pos: PresentationPosition,
): boolean {
  const numStepsForSlide = slides[pos.slideIndex]?.slideSteps.length ?? 0;
  return (
    pos.slideIndex === slides.length - 1 &&
    ((numStepsForSlide === 0 && pos.slideStepIndex === null) ||
      pos.slideStepIndex === numStepsForSlide - 1)
  );
}

export const SelfDeliveryScroller: React.FC<Props> = (props) => {
  const currentSlideRef = React.useRef<HTMLDivElement>(null);
  const controls = usePresentationControls(props.slides);
  const position = controls.position;
  const timeoutRef = React.useRef<number | undefined>(undefined);
  const lang = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const __t = props.__t;

  React.useEffect(() => {
    // it takes a while for the browser to render the DOM. we can't
    // know where to scroll before the browser has successfully redrawn
    // the view.
    window.clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      const slideElem = currentSlideRef.current;
      if (!slideElem) {
        return;
      }

      const cursors = slideElem.querySelectorAll(PRESENTATION_CURSOR_SELECTOR);

      // look for the last element tagged with the cursor class, or alternatively
      // just scroll to the root of the slide.
      const elem =
        cursors.length !== 0 ? cursors[cursors.length - 1] : slideElem;

      elem.scrollIntoView({
        // this will scroll the element to the top of the viewport, if there is room.
        // otherwise it will scroll to the bottom of the page.
        //
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
  }, [props.slides, position]);

  function handleContinueClick(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    // if the current position is the last we're definitely moving past it
    // after clicking 'continue' again.
    const didMovePastEnd = isLastSlideAndStep(props.slides, position);
    const next = controls.next();
    props.onPositionChange?.(next, didMovePastEnd);
  }

  function handleBackClick(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const next = controls.previous();
    props.onPositionChange?.(next, false);
  }

  const visibleSlides = props.slides.slice(0, position.slideIndex + 1);
  const slideMargin = (
    <React.Fragment>
      <div className="pb-5" />
      <div className="pb-5" />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {visibleSlides.map((slide, index) => {
        // if we are rendering the current slide, we also retrieve the
        // step index from the position cursor. otherwise we force the
        // slide to its last step.
        //
        // 'slide.slideSteps.length' is technically always out of bounds
        // but we are guarding against that in 'unsafeRenderPresentationSlide'
        /** @see renderSlideDynamicHTML */
        const slideStep =
          index === position.slideIndex
            ? position.slideStepIndex
            : slide.slideSteps.length;
        const isLastVisibleSlide = index === visibleSlides.length - 1;
        const html = renderSlideDynamicHTML(slide, slideStep);
        const slideRef =
          index === position.slideIndex ? currentSlideRef : undefined;

        return (
          <React.Fragment key={index}>
            <div ref={slideRef}>{html}</div>
            {!isLastVisibleSlide && slideMargin}
          </React.Fragment>
        );
      })}
      <div className="pt-4 border-top">
        <div className="text-center">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-lg btn-light me-2 flex-grow-0"
              onClick={handleBackClick}
            >
              {__t("Back")}
            </button>
            <button
              className="btn btn-lg btn-primary flex-grow-1 flex-md-grow-0"
              onClick={handleContinueClick}
            >
              {isLastSlideAndStep(props.slides, position)
                ? __t("Done")
                : __t("Continue")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const PreviewPresentationTemplateSelfDelivery: React.FC<unknown> = (
  props,
) => {
  const dispatch = useDispatch();
  const params = useParams<"id">();
  const templateId = params.id ? Number(params.id) : undefined;
  const slides = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.presentationSlides,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const translate = getLanguageValue.bind(undefined, languageText);

  React.useEffect(() => {
    if (!templateId) {
      return;
    }
    getAllPresentationSlides(templateId, dispatch);
  }, [templateId]);

  return (
    <div className="container">
      <div className="py-5 col-md-10 mx-auto">
        <CurrentStepPanel
          __t={translate}
          title={translate("Your IDI profile is completed")}
        >
          <div className="p-4">
            <SelfDeliveryScroller slides={slides} __t={translate} />
          </div>
        </CurrentStepPanel>
      </div>
    </div>
  );
};
