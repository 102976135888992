import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCoursesList } from "../actions";
import { RootState } from "@app/store/configureStore";
import { ICourse } from "../types";
import { getParticipantMedia, setSpinner } from "../../actions";
import { UserGeneratedMediaType } from "../../commonEnums";

export const useCoursesList = () => {
  const dispatch = useDispatch();

  const [courses, setCourses] = useState<ICourse[]>([]);

  const defaultClient = useSelector(
    (state: RootState) => state.loginReducer.defaultClient,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const selectedCourseLanguageId: number = useSelector(
    (state: RootState) => state.academyReducer.selectedCourseLanguageId,
  );
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const fetchCourses = async (): Promise<void> => {
    dispatch(setSpinner(true));

    getCoursesList(
      defaultClient.defaultClientId,
      selectedCourseLanguageId,
      dispatch,
    )
      .then((fetchedCourses: ICourse[]) => {
        if (fetchedCourses) {
          const updatedCourseItems = fetchedCourses.map(async (course) => {
            const fetchedImage = await getParticipantMedia(
              course.id,
              UserGeneratedMediaType.AcademyCourse,
              dispatch,
            );
            return { ...course, logo: fetchedImage };
          });

          Promise.all(updatedCourseItems).then((updatedCourses) =>
            setCourses(updatedCourses),
          );
        } else setCourses([]);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (userId && defaultClient.defaultClientId) {
      fetchCourses();
    }
  }, [userId, defaultClient]);

  return { courses, languageText };
};
