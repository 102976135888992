import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import {
  ITranslationObject,
  getLanguageValue,
} from "./languageFunctionsHelper";

export enum CoursesPermissionAccess {
  Allow = 1,
  Deny = 2,
}

export enum PermissionType {
  User = 1,
  Account = 2,
}

export enum DateLimit {
  Yes = 1,
  No = 2,
}

export const getAccsssList = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: CoursesPermissionAccess.Allow,
    displayName: getLanguageValue(languageText, "Allow"),
    value: CoursesPermissionAccess.Allow.toString(),
  },
  {
    id: CoursesPermissionAccess.Deny,
    displayName: getLanguageValue(languageText, "Deny"),
    value: CoursesPermissionAccess.Deny.toString(),
  },
];

export const getPermissionTypeList = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: PermissionType.User,
    displayName: getLanguageValue(languageText, "User"),
    value: PermissionType.User.toString(),
  },
  {
    id: PermissionType.Account,
    displayName: getLanguageValue(languageText, "Account"),
    value: PermissionType.Account.toString(),
  },
];

export const getDateLimitList = (
  languageText: ITranslationObject,
): IDropdownList[] => [
  {
    id: DateLimit.No,
    displayName: getLanguageValue(languageText, "No"),
    value: DateLimit.No.toString(),
  },
  {
    id: DateLimit.Yes,
    displayName: getLanguageValue(languageText, "Yes"),
    value: DateLimit.Yes.toString(),
  },
];
