import clsx from "clsx";
import React from "react";
import DatePicker from "react-datepicker";

import { IFocusError } from "../formComponents/input";
import "react-datepicker/dist/react-datepicker.css";

interface IReactDatePicker {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  date: Date | null;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  focusInput?: IFocusError;
  disabled?: boolean;
  dateWithTime?: boolean;
  handleDateSelect: (name: string, date: Date) => void;
}

export const ReactDatePicker = (props: IReactDatePicker): JSX.Element => {
  const onDateSelect = (date: Date | null): void => {
    props.handleDateSelect(props.name, date || new Date());
  };

  return (
    <div className={clsx("mb-3", props.className && props.className)}>
      {props.label && (
        <label className={clsx("mb-1", { required: props.required })}>
          {props.label}
        </label>
      )}
      <DatePicker
        className="form-control form-control-solid w-100"
        placeholderText={props.placeholder}
        selected={props.date}
        dateFormat={props.dateWithTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
        onChange={onDateSelect}
        shouldCloseOnSelect={props.dateWithTime ? false : true}
        minDate={props.minDate}
        maxDate={props.maxDate}
        disabled={props.disabled}
        disabledKeyboardNavigation
        showTimeInput={props.dateWithTime}
      />

      {props.focusInput?.errorMessage && (
        <div className="invalid-feedback d-inline-block">
          {props.focusInput.errorMessage}
        </div>
      )}
    </div>
  );
};
