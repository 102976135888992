import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import {
  deleteUserExternalLogin,
  getUserExternalLogins,
  registerExternalLogin,
} from "./actions";
import { SocialLoginsEnum } from "../../../commonEnums";
import { updateUser } from "../../edit-user/actions";
import {
  getLoggedInUserData,
  setSocialLoginStatus,
} from "../../../auth/signUp/actions";
import { addToast, setNotification, setSpinner } from "../../../actions";
import { AnyAction } from "redux";
import { IUserProfile } from "@app/containers/commonInterfaces";
import { isLoggedInRoleParticipant } from "@app/commonUtils/roleHelper";

interface ISocialLoginsList {
  id: number;
  name: string;
  icon: string;
}

export interface ISocialLoginData {
  authProvider: string;
  providerKey: string;
  providerAccessToken: string;
  providerCode: string;
  returnUrl: string;
  singleSignIn: boolean;
}

export type IProfile = {
  access_token: string;
  code: string;
  id_token: string;
  sub: string;
};

const EMPTY_PROFILE: IProfile = {
  access_token: "",
  code: "",
  id_token: "",
  sub: "",
};

export const useSocialLogins = () => {
  const dispatch = useDispatch();
  const [socialLoginsList, setSocialLoginsList] = useState<Array<string>>([]);
  const [updateLoginsList, setUpdateLoginsList] = useState<boolean>(false);
  const [disconnectLoginName, setDisconnectLoginName] = useState<string>("");
  const [provider, setProvider] = useState<string>("");
  const [profile, setProfile] = useState<IProfile>({ ...EMPTY_PROFILE });

  const userData = useSelector(
    (state: RootState) => state.loginReducer.userData,
  );
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const socialLoginStatus: string[] = useSelector(
    (state: RootState) => state.loginReducer.socialLoginStatus,
  );

  const socialLogins = useMemo(
    () =>
      [
        {
          id: 1,
          name: SocialLoginsEnum.Google,
          icon: "bi bi-google",
        },
        {
          id: 2,
          name: SocialLoginsEnum.Microsoft,
          icon: "bi bi-microsoft",
        },
        // {
        //   id: 3,
        //   name: SocialLoginsEnum.LinkedIn,
        //   icon: LinkedInLogo,
        // },
      ] as ISocialLoginsList[],
    [],
  );

  const fetchExternalLogins = async (): Promise<void> => {
    await getUserExternalLogins(dispatch).then((response) => {
      if (response) {
        setSocialLoginsList(response);
      }
    });
  };

  useEffect(() => {
    fetchExternalLogins();
  }, [updateLoginsList]);

  const onDisconnectClick = (name: string): void =>
    setDisconnectLoginName(name);

  const handleDisconnectLogin = (): void => {
    deleteUserExternalLogin(disconnectLoginName, dispatch).then((response) => {
      if (response) setUpdateLoginsList(!updateLoginsList);
    });
    setDisconnectLoginName("");
    dispatch(setSocialLoginStatus([]));
    setProvider("");
    setProfile({ ...EMPTY_PROFILE });
  };

  const handleDisconnectCancel = (): void => setDisconnectLoginName("");

  const handleToggleTwoFactorAuthStatus = (): void => {
    dispatch(setSpinner(true));
    const updatedIsTwoFactorEnabled = !userData.isTwoFactorEnabled;
    const body: IUserProfile = {
      ...userData,
      isTwoFactorEnabled: updatedIsTwoFactorEnabled,
    };

    if (userData.isEmailConfirmed) {
      const isParticipant = isLoggedInRoleParticipant(loggedInUserRole);

      updateUser(body, isParticipant, dispatch)
        .then((response) => {
          if (response) {
            dispatch(addToast("Changes saved successfully") as AnyAction);
            getLoggedInUserData(isParticipant, dispatch);
          }
        })
        .finally(() => {
          dispatch(setSpinner(false));
        });
    } else {
      dispatch(setSpinner(false));
      dispatch(
        setNotification(
          "Please verify your email before enabling two factor authentication",
        ),
      );
    }
  };

  useEffect(() => {
    if (profile.access_token || profile.code) {
      handleRegisterLogin();
    }
  }, [profile.access_token, profile.code]);

  const handleRegisterLogin = (): void => {
    const formattedProvider =
      provider.charAt(0).toUpperCase() + provider.slice(1);
    let sub: any = "";
    let accessCode: string = "";
    if (provider === "microsoft") {
      sub = profile.id_token
        ? JSON.parse(atob(profile.id_token.split(".")[1])).oid
        : null;
    } else {
      accessCode = profile.code;
      sub = profile.sub;
      accessCode = profile.code;
    }

    const payload: ISocialLoginData = {
      authProvider: formattedProvider,
      providerKey: sub,
      providerAccessToken: profile.access_token,
      providerCode: accessCode,
      returnUrl: "/App",
      singleSignIn: true,
    };
    registerExternalLogin(dispatch, payload).then((response) => {
      if (response === true) {
        if (!socialLoginsList.includes(formattedProvider)) {
          setSocialLoginsList([...socialLoginsList, formattedProvider]);
        }
      }
    });
  };

  return {
    socialLogins,
    socialLoginsList,
    socialLoginStatus,
    disconnectLoginName,
    userData,
    profile,
    provider,
    setProfile,
    setProvider,
    dispatch,
    handleToggleTwoFactorAuthStatus,
    onDisconnectClick,
    handleDisconnectLogin,
    handleDisconnectCancel,
  };
};
