import React, { useState } from "react";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IEditActivity, IEditActivityDeliveredProfile } from "../interface";
import { GroupPreview } from "../modals/reports/preview/groupPreview/groupPreview";
import { ReportType, ReportTypes } from "../../../../types";
import { GuidePages } from "@app/containers/commonEnums";
import { ActivityId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IDeliveredParticipantsProfileProps {
  id: ActivityId;
  languageText: ITranslationObject;
  profileData: IEditActivity;
  isInstructionHelpClicked: boolean;
  completedStatusEditProfileGuideDone: boolean;
  deliveredProfileParticipants: IEditActivityDeliveredProfile[];
  deliveredProfileParticipantsCount: number;
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  messageTranslation: ITranslationObject;
  isActivityCancelled: boolean;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  refetchFullProfile: () => void;
  refetchDeliveredParticipants: () => void;
  handleUnPublishProfile: () => void;
  handleTransferProfile?: () => void;
}

export const DeliveredParticipantsProfile = (
  props: IDeliveredParticipantsProfileProps,
): JSX.Element => {
  const [reportType, setReportType] = useState<ReportType>(ReportTypes.OTHER);

  const selectReportType = (reportType: ReportType): void => {
    setReportType(reportType);
  };

  const selectedProfileIds = props.deliveredProfileParticipants.map(
    (participant) => participant.id,
  );

  return (
    <>
      <div className="p-4 rounded bg-white mt-4 ">
        <div className="fs-4">
          <span className="badge fs-5 bg-dark pt-2 me-2 bg-opacity-25">
            {props.deliveredProfileParticipantsCount}
          </span>
          {getLanguageValue(props.languageText, "Participants")}
        </div>

        <div className="d-flex gap-4 fs-5 mt-4 mb-4">
          <div
            className={clsx(
              "cursor-pointer",
              reportType === ReportTypes.SELF &&
                "text-dark text-decoration-underline",
            )}
            role="button"
            onClick={() => selectReportType(ReportTypes.SELF)}
          >
            {getLanguageValue(props.languageText, "Self")}
          </div>
          <div
            className={clsx(
              "cursor-pointer",
              reportType === ReportTypes.OTHER &&
                "text-dark text-decoration-underline",
            )}
            role="button"
            onClick={() => selectReportType(ReportTypes.OTHER)}
          >
            {getLanguageValue(props.languageText, "Others")}
          </div>

          <div
            className={clsx(
              "cursor-pointer",
              reportType === ReportTypes.BOTH &&
                "text-dark text-decoration-underline",
            )}
            role="button"
            onClick={() => selectReportType(ReportTypes.BOTH)}
          >
            {getLanguageValue(props.languageText, "Both")}
          </div>
        </div>
        <div className="p-4">
          <GroupPreview
            reportType={reportType}
            selectedProfileIds={selectedProfileIds}
            allProfilesDelivered={true}
            {...props}
          />
        </div>
      </div>
    </>
  );
};
