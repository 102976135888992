import { IFocusError } from "../../../components/formComponents/input";

export interface ILandingContent {
  id: number;
  title: string;
  details: string;
  imageSrc: string;
  function: () => void;
}

export enum NewsType {
  News = 1,
  Blogs = 2,
}

export enum TabEnum {
  TAB_1 = "News",
  TAB_2 = "Blogs",
}

export interface INewsList {
  id: number;
  title: string;
  excerpt: string;
  publishedDate: string | null;
  imageFile?: File | null;
  image: string;
  post: string;
  published: boolean;
  type: string;
  tenantId?: number | null;
}

export interface INewsType {
  id: number;
  name: string;
}

export const NewsInputs = {
  id: "id",
  title: "title",
  publishedDate: "publishedDate",
  excerpt: "excerpt",
  image: "image",
  post: "post",
  published: "published",
  type: "type",
} as const;

export interface IFocusInput {
  title: IFocusError;
  image: IFocusError;
  excerpt: IFocusError;
  post: IFocusError;
  publishedDate: IFocusError;
  type: IFocusError;
}
