import { SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "@app/store/configureStore";
import {
  getCourseQuizzes,
  getUserCourseParts,
  getUserQuizDetails,
  IUpdateUserQuizDetailsBody,
  updateUserQuizDetails,
} from "../actions";
import { ICourse, IQuiz } from "../types";
import { routePath } from "../../routePaths";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { setNotification, setSpinner } from "../../actions";

function shuffleArray<T>(array: Array<T>): Array<T> {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const useQuiz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, quizId } = useParams();
  const convertedCourseId: string = String(courseId);
  const convertedQuizId: string = String(quizId);

  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const spinner: boolean = useSelector(
    (state: RootState) => state.mainReducer.spinner,
  );
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [courseQuiz, setCourseQuiz] = useState<ICourse>();
  const [quizzes, setQuizzes] = useState<IQuiz[]>([]);
  const [activeQuiz, setActiveQuiz] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [timespent, setTimespent] = useState(0);
  const [quizNotFound, setQuizNotFound] = useState<boolean>(false);
  const isAnswered = !!selectedOption;

  const getQuizDetails = async (): Promise<void> => {
    dispatch(setSpinner(true));
    getUserQuizDetails(userId, convertedQuizId, dispatch)
      .then((res) => {
        setTimespent(res.timeSpent);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const fetchCourse = async (): Promise<void> => {
    dispatch(setSpinner(true));
    getCourseQuizzes(convertedCourseId, dispatch)
      .then((fetchedCourseQuizes) => {
        if (fetchedCourseQuizes && fetchedCourseQuizes.quizes !== null) {
          setCourseQuiz(fetchedCourseQuizes);
          // Shuffle quiz answers
          if (fetchedCourseQuizes.quizes.length > 0) {
            const newQuizzes = fetchedCourseQuizes.quizes.map((quiz: IQuiz) => {
              return {
                ...quiz,
                quizOptions: shuffleArray(quiz.quizOptions),
              };
            });
            setQuizzes(newQuizzes);
          }

          if (fetchedCourseQuizes && fetchedCourseQuizes.quizes) {
            const initialActiveSection = fetchedCourseQuizes.quizes.findIndex(
              (part) => part.id === Number(convertedQuizId),
            );
            if (initialActiveSection !== -1) {
              setActiveQuiz(initialActiveSection);
            } else {
              setQuizNotFound(true);
            }
          } else {
            dispatch(
              setNotification(
                getLanguageValue(
                  languageText,
                  "An error occurred fetching the quizparts",
                ),
              ),
            );
          }
        } else setQuizNotFound(true);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    fetchCourse();
  }, [convertedCourseId]);

  useEffect(() => {
    let timer: number;
    const startTimer = () => {
      timer = window.setInterval(() => {
        setTimespent((prevSeconds) => prevSeconds + 1); // Increment the seconds every second
      }, 1000);
    };

    // Pause the timer
    const pauseTimer = (): void => {
      window.clearInterval(timer);
    };

    // Resume the timer
    const resumeTimer = (): void => {
      startTimer();
    };

    // Event listeners for visibility change and blur
    const handleVisibilityChange = (): void => {
      if (document.visibilityState === "hidden") {
        pauseTimer();
      } else {
        resumeTimer();
      }
    };

    const handleBlur = (): void => {
      pauseTimer();
    };

    const handleFocus = (): void => {
      resumeTimer();
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    if (quizzes.length > 0 && !spinner) {
      getQuizDetails();
      startTimer();
    }
    return () => {
      window.clearInterval(timer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [activeQuiz, quizzes]); // Add activeSection as a dependency

  const backClickHandler = (): void => {
    !isAnswered && quizzes.length > 0 && updateTimespent();
    let navUrl: string = "";
    if (activeQuiz === 0) {
      getUserCourseParts(Number(convertedCourseId), dispatch).then(
        (course: ICourse) => {
          if (course && course.courseParts) {
            navUrl = routePath.participantCourse
              .replace(":courseId", convertedCourseId)
              .replace(
                ":partId",
                course.courseParts[
                  course.courseParts.length - 1
                ].id.toString() || "",
              );
            navigate(navUrl);
          }
        },
      );
    } else {
      navUrl = routePath.participantQuiz
        .replace(":courseId", convertedCourseId)
        .replace(":quizId", quizzes[activeQuiz - 1].id.toString());
      navigate(navUrl);
      setActiveQuiz(activeQuiz - 1);
      setSelectedOption("");
      setIsCorrect(false);
    }
  };

  const forwardClickHandler = async (): Promise<void> => {
    !isAnswered && updateTimespent();
    let navUrl: string = "";
    if (activeQuiz === quizzes.length - 1) {
      navUrl = routePath.participantCourseSummary.replace(
        ":courseId",
        convertedCourseId,
      );
      navigate(navUrl);
    } else {
      navUrl = routePath.participantQuiz
        .replace(":courseId", convertedCourseId)
        .replace(":quizId", quizzes[activeQuiz + 1].id.toString());
      navigate(navUrl);
    }
    setActiveQuiz(activeQuiz + 1);
    setSelectedOption("");
    setIsCorrect(false);
  };

  const updateTimespent = (): void => {
    const quiz = quizzes[activeQuiz];
    const data: IUpdateUserQuizDetailsBody = {
      id: 0,
      quizId: quiz.id,
      timeSpent: timespent,
      userId: userId,
    };
    updateUserQuizDetails(data, dispatch);
  };

  const updateQuizAnswer = (quizOptionId: number, isCorrect: boolean): void => {
    const quiz = quizzes[activeQuiz];
    const data: IUpdateUserQuizDetailsBody = {
      userId: userId,
      id: 0,
      quizOptionId: quizOptionId,
      isCorrect: isCorrect,
      quizId: quiz.id,
      timeSpent: timespent,
    };
    updateUserQuizDetails(data, dispatch);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isCorrect: boolean,
  ): void => {
    updateQuizAnswer(Number(event.target.value), isCorrect);

    setSelectedOption(event.target.value);
    setIsCorrect(isCorrect);
  };

  return {
    courseQuiz,
    quizzes,
    activeQuiz,
    languageText,
    isAnswered,
    selectedOption,
    isCorrect,
    timespent,
    quizNotFound,
    handleOptionChange,
    forwardClickHandler,
    backClickHandler,
  };
};
