import React from "react";
import { useSelector } from "react-redux";

import { ProfileSecurity } from "./profileDetails/socialLogins/profileSecurity";
import { ProfileDetails } from "./profileDetails";
import { ProfileHeader } from "./profileHeader";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";
import { ChangePassword } from "./privacySettings/changePassword";

export function UserProfile(): JSX.Element {
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  return (
    <>
      <div className="bg-white mt-3 p-3 p-md-4 rounded shadow-sm mt-4">
        <ProfileHeader />

        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="fw-bold mb-0 pb-0">
              {getLanguageValue(languageText, "Details")}
            </h4>
            <ProfileDetails />
          </div>
          <div className="col-12 col-md-6">
            <h4 className="fw-bold mb-0 pb-0 mt-3 mt-md-0 mt-xl-0">
              {getLanguageValue(languageText, "Change password")}
            </h4>
            <ChangePassword languageText={languageText} />
          </div>
        </div>
        <ProfileSecurity languageText={languageText} />
      </div>
    </>
  );
}
