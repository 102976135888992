import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLogIn } from "./hooks";
import { RootState } from "@app/store/configureStore";
import { LoginScreen } from "./loginScreen";
import { TwoFactorAuth } from "../../twoFactorAuthenticationPage";
import { ResetPassword } from "./resetPassword";
import { TokenExpired } from "../tokenExpired/tokenExpired";
import { AuthLayout } from "./layout";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";

/**
 * The backend coincidentally uses the same length for two-factor codes as email
 * verification codes.
 */
export const VERIFICATION_CODE_LENGTH = 6;

export const SignUp = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    formState,
    setFormState,
    formError,
    setFormError,
    successMessage,
    tokenExpired,
    onGoBack,
    handleSubmit,
    handleBlurEvent,
    handleCloseTokenExpiredModal,
  } = useLogIn();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  return (
    <AuthLayout page="login" language={languageText}>
      {formState.kind === "password" && (
        <LoginScreen
          languageText={languageText}
          handleSubmit={handleSubmit}
          handleEmailChange={(event) => {
            setFormState({
              kind: "password",
              username: event.target.value,
              password: formState.password,
            });
            //this is the best I can do without rewriting it all.
            //When starting typing, remove the error message - Joakim, 241129
            setFormError((prev) => ({
              ...prev,
              email: {
                touched: true,
                errorMessage: "",
              },
            }));
          }}
          handlePasswordChange={(event) => {
            setFormState({
              kind: "password",
              username: formState.username,
              password: event.target.value,
            });
            setFormError((prev) => ({
              ...prev,
              password: {
                touched: true,
                errorMessage: "",
              },
            }));
          }}
          email={formState.username}
          password={formState.password}
          formError={formError}
          handleBlurEvent={handleBlurEvent}
          setForgotPasswordPage={() => {
            setFormState({
              kind: "forgot_password",
              username: formState.username,
            });
          }}
        />
      )}
      {formState.kind === "forgot_password" && (
        <ResetPassword
          languageText={languageText}
          forgotPasswordEmail={formState.username}
          onGoBack={onGoBack}
          setForgotPasswordEmail={(value) => {
            setFormState({
              kind: "forgot_password",
              username: value,
            });
          }}
          forgotPassword={handleSubmit}
          successMessage={successMessage}
        />
      )}
      {formState.kind === "verify_two_factor" && (
        <TwoFactorAuth
          languageText={languageText}
          handleSubmit={handleSubmit}
          handleChange={(value) => {
            setFormState({
              ...formState,
              code: value,
            });
          }}
          handleBackClick={onGoBack}
          title={getLanguageValue(
            languageText,
            "A code has been sent to your device",
          )}
          value={formState.code}
          length={VERIFICATION_CODE_LENGTH}
        />
      )}
      {formState.kind === "verify_email" && (
        <TwoFactorAuth
          languageText={languageText}
          handleSubmit={handleSubmit}
          handleChange={(value) => {
            setFormState({
              ...formState,
              code: value,
            });
          }}
          handleBackClick={onGoBack}
          title={getLanguageValue(
            languageText,
            "Activation code has been sent to your device",
          )}
          value={formState.code}
          length={VERIFICATION_CODE_LENGTH}
        />
      )}
      {tokenExpired && (
        <TokenExpired handleClick={handleCloseTokenExpiredModal} />
      )}
    </AuthLayout>
  );
};
