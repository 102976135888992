import React, { useState } from "react";
import { Input } from "../../../../components/formComponents/input";
import { DeleteModal } from "../../../../components/modals/deleteModal";
import { TextEditor } from "../../../../components/textEditor/textEditor";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IQuizParts, QuizParts } from "../hooks";

interface IQuizPartsProps {
  languageText: ITranslationObject;
  quizPart: IQuizParts[];
  isQuizEdited: boolean;
  editedQuizIndex: number | null;
  onQuestionInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => void;
  onQuestionPartInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    quizIndex: number,
  ) => void;
  onQuestionTextEditorChange: (
    value: string,
    name: string,
    index: number,
  ) => void;
  handleOptionChange: (index: number, quizIndex: number) => void;
  handleAddQuizOption: (quizIndex: number) => void;
  handleQuestionPartSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    index: number,
  ) => void;
  handleDeleteQuiz: (id: number, index: number) => void;
  handleDeleteQuizOption: (index: number, quizIndex: number) => void;
}

export const AddQuiz = (props: IQuizPartsProps): JSX.Element => {
  const {
    languageText,
    quizPart,
    isQuizEdited,
    editedQuizIndex,
    onQuestionInputChange,
    onQuestionPartInputChange,
    onQuestionTextEditorChange,
    handleOptionChange,
    handleAddQuizOption,
    handleQuestionPartSubmit,
    handleDeleteQuiz,
    handleDeleteQuizOption,
  } = props;

  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [quizDeletedId, setQuizDeletedId] = useState<number>(0);
  const [quizDeletedIndex, setQuizDeletedIndex] = useState<number>(0);

  const confirmDelete = (id: number, index: number) => {
    handleDeleteQuiz(id, index);
    setDeleteModel(false);
  };

  const handleBtnDeleteClick = (id: number, index: number) => {
    if (id) {
      setQuizDeletedId(id);
      setQuizDeletedIndex(index);
      setDeleteModel(true);
    } else {
      confirmDelete(id, index); //If id is not there, but index will be there
    }
  };

  const closeDeleteModal = () => setDeleteModel(false);

  return (
    <>
      {quizPart.map((quizParts, quizIndex) => (
        <form
          key={quizIndex}
          onSubmit={(e) => handleQuestionPartSubmit(e, quizIndex)}
        >
          <div className="my-4">
            <h1 className="mb-2">
              {getLanguageValue(languageText, "Question")} {quizIndex + 1}
            </h1>
            <Input
              label={getLanguageValue(languageText, "Question")}
              placeholder={getLanguageValue(languageText, "Type Question")}
              name={QuizParts.question}
              value={quizParts.question}
              handleInputChange={(e) => onQuestionInputChange(e, quizIndex)}
              required
            />
            <TextEditor
              name={QuizParts.correctAnswerReason}
              label={getLanguageValue(languageText, "Explanation")}
              placeholder={getLanguageValue(languageText, "Explanation")}
              handleTextEditor={(content) =>
                onQuestionTextEditorChange(
                  content,
                  QuizParts.correctAnswerReason,
                  quizIndex,
                )
              }
              value={quizParts.correctAnswerReason}
              required
            />
            {quizParts.quizOptions.map((option, index) => (
              <div key={index} className="row align-items-center">
                <div
                  className={
                    quizParts.quizOptions.length > 2
                      ? "col-md-9 col-8"
                      : "col-md-10 col-8"
                  }
                >
                  <Input
                    type="text"
                    label={`${getLanguageValue(languageText, "Option")} ${index + 1}:`}
                    name={QuizParts.answerOption}
                    value={option.answerOption}
                    handleInputChange={(e) =>
                      onQuestionPartInputChange(e, index, quizIndex)
                    }
                    required
                  />
                </div>
                <div className="col-2 d-flex justify-content-end flex-wrap gap-4 align-items-center form-check form-check-success form-check-custom form-check-solid">
                  <input
                    type="radio"
                    key={index}
                    name={QuizParts.isCorrect}
                    checked={option.isCorrect}
                    className="form-check-input"
                    onChange={() => handleOptionChange(index, quizIndex)}
                  />
                  <label>{getLanguageValue(languageText, "Correct")}</label>
                </div>
                <>
                  {quizParts.quizOptions.length > 2 && (
                    <div className="col-1">
                      <button
                        type="button"
                        onClick={() => handleDeleteQuizOption(index, quizIndex)}
                        className="btn btn-sm btn-light-danger"
                      >
                        <i className="bi bi-trash3 pe-0" />
                      </button>
                    </div>
                  )}
                </>
              </div>
            ))}
            <div className="d-flex justify-content-between">
              <div>
                <button
                  className="btn btn-success me-6"
                  disabled={editedQuizIndex !== quizIndex}
                >
                  {getLanguageValue(languageText, "Save")}
                </button>
                <button
                  type="button"
                  className="btn btn-gray grey-bg-darker-9 w-125px w-md-200px text-white"
                  onClick={() => handleAddQuizOption(quizIndex)}
                >
                  {getLanguageValue(languageText, "Add choice")}
                </button>
              </div>
              {isQuizEdited && (
                <button
                  type="button"
                  className="btn btn-danger text-white"
                  onClick={() => handleBtnDeleteClick(quizParts.id, quizIndex)}
                >
                  {getLanguageValue(languageText, "Delete")}
                </button>
              )}
            </div>
            {quizParts.isError && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">
                    {getLanguageValue(
                      languageText,
                      "Please fill required fields",
                    )}
                  </span>
                </div>
              </div>
            )}
            {deleteModel && quizDeletedId && (
              <DeleteModal
                languageText={languageText}
                headerText={getLanguageValue(languageText, "Quiz")}
                bodyText={getLanguageValue(languageText, "this quiz")}
                closeDeleteModal={closeDeleteModal}
                handleDeleteClick={() =>
                  confirmDelete(quizDeletedId, quizDeletedIndex)
                }
              />
            )}
            <div className="grey-bg-darker mx-n7 h-1px mt-10" />
          </div>
        </form>
      ))}
    </>
  );
};
