import { Dispatch } from "redux";

import { api, apiEndPoints } from "../../../../api";
import { setNotification } from "../../../actions";
import {
  IParticipantOrRespondentWordsBody,
  IUpdateFormFilledDataBody,
} from "../../../../components/selfForm/selfForm";

export const updateRespondentFormFilledDataAnswer = async (
  body: IUpdateFormFilledDataBody,
  token: string,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await api.put(
      apiEndPoints.updateRespondentFormFilledDataAnswer,
      JSON.stringify(body),
      {
        // FIXME: why are we sending the body in the params too?
        params: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
