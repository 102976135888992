import React from "react";

import { IWords, useWordsList } from "./hooks";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { ReactTable } from "../../components/reactTable/reactTable";
import { AddEditWordModal } from "./addEditWordModal/index";

export const WordsList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openWordsModal,
    editedRowId,
    cultures,
    countriesList,
    setFilterCulture,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addWordsClick,
    closeWordsModal,
  } = useWordsList();

  let rowData: IWords | undefined;
  if (editedRowId) {
    rowData = data.find((item) => item.id === editedRowId);
  }

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Words List")}
        searchPlaceholder={getLanguageValue(languageText, "Search Words")}
        buttonText={getLanguageValue(languageText, "Add Word")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        filterDropdownDefaultLabel={getLanguageValue(
          languageText,
          "Select Culture",
        )}
        filterDropdownLabel={getLanguageValue(languageText, "Select Culture")}
        filterDropdownList={cultures}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilterDropdownTable={setFilterCulture}
        handleButtonClick={addWordsClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {(openWordsModal || editedRowId) && rowData ? (
        <AddEditWordModal
          rowData={rowData}
          cultures={cultures}
          countriesList={countriesList}
          closeWordModal={closeWordsModal}
        />
      ) : (
        ""
      )}

      {/*------------------ Will be added in future task -----------------*/}

      {/* {deleteRowId ? (
          <DeleteModal
            languageText={languageText}
            headerText={getLanguageValue(languageText, 'Word')}
            bodyText={selectedActionRowName}
            handleDeleteClick={handleDeleteWord}
            closeDeleteModal={closeDeleteModal}
          />
        ) : (
          ''
        )} */}
    </>
  );
};
