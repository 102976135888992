import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface ITermsFooterProp {
  languageText: ITranslationObject;
  footerContainerClassName?: string;
}

export const LoginTermsFooter = (props: ITermsFooterProp) => {
  const { languageText } = props;

  return (
    <div className="text-secondary fw-light mt-4">
      <span>{`${getLanguageValue(languageText, "By continuing, I accept")} `}</span>
      <a href="https://idi.se/terms/" target="_blank" rel="noreferrer">
        {getLanguageValue(languageText, "IDI’s Terms of Services")}
      </a>

      <span>
        {" "}
        {`${getLanguageValue(languageText, "and confirm that I have read")} `}
      </span>
      <a href="https://idi.se/privacy/" target="_blank" rel="noreferrer">
        {getLanguageValue(languageText, "IDI’s Privacy Policy")}.
      </a>
    </div>
  );
};
