import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { routePath } from "../../routePaths";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { IFocusError } from "../../../components/formComponents/input";
import { clearNotifications, setSpinner } from "../../actions";
import { RootState } from "@app/store/configureStore";
import { ValidationHelper } from "../../validationHelper";
import { useNavigate } from "react-router-dom";
import { getLoggedInUserData } from "../../auth/signUp/actions";
import {
  resetParticipantPassword,
  updateParticipantLanguage,
} from "../actions";
import { sliderDataSteps } from "../../../components/slider/sliderData";
import {
  useParticipantProfileAuth,
  ucQueryKey,
} from "../useParticipantProfileAuth";

interface IUserPassword {
  password: string;
  confirmPassword: string;
}

interface IFocusInput {
  password: IFocusError;
  confirmPassword: IFocusError;
}

export const PasswordInput = {
  password: "password",
  confirmPassword: "confirmPassword",
} as const;

export const useChangePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uniqueCode } = useParticipantProfileAuth();

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const userData = useSelector(
    (state: RootState) => state.loginReducer.userData,
  );
  const sliderData = useMemo(
    () => sliderDataSteps(languageText),
    [languageText],
  );

  const [showBar, setShowBar] = useState<boolean>(false);

  const [userPassword, setUserPassword] = useState<IUserPassword>({
    password: "",
    confirmPassword: "",
  });
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    password: {
      touched: false,
      errorMessage: "",
    },
    confirmPassword: {
      touched: false,
      errorMessage: "",
    },
  });

  useEffect(() => {
    getLoggedInUserData(true, dispatch);
  }, []);

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = "";

    switch (name) {
      case PasswordInput.password:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Password is required");
        } else if (value) {
          const isValid = ValidationHelper.isPasswordValid(value);
          if (!isValid)
            errorMessage = getLanguageValue(
              languageText,
              "Please follow the instruction",
            );
        }
        break;
      case PasswordInput.confirmPassword:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Confirm password is required",
          );
        } else if (value !== userPassword.password) {
          errorMessage = getLanguageValue(
            languageText,
            "Confirm Password does not match",
          );
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setUserPassword({
      ...userPassword,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
    if (name === "password") {
      setShowBar(true);
    }
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !userPassword.password ||
      focusInput.password.errorMessage ||
      !userPassword.confirmPassword ||
      focusInput.confirmPassword.errorMessage ||
      (userPassword.password && focusInput.password.errorMessage) ||
      (userPassword.confirmPassword &&
        focusInput.confirmPassword.errorMessage) ||
      (userPassword.password && !userPassword.confirmPassword)
    ) {
      for (const item of Object.values(PasswordInput)) {
        handleFormErrors(item, userPassword[item]);
      }
      return false;
    }
    return true;
  };

  const onChangePassword = (): void => {
    dispatch(setSpinner(true));
    dispatch(clearNotifications(""));
    const newPassword: string = userPassword.password;

    resetParticipantPassword(newPassword, dispatch)
      .then((response) => {
        if (response) {
          // Saving language to db once password is changed
          updateParticipantLanguage(
            userLanguage.userLanguageCode,
            dispatch,
          ).then((response) => {
            if (response) {
              navigate(
                `${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`,
              );
            }
          });
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  };

  const onSubmitClick = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    if (!handleValidationOnSubmit()) return;
    onChangePassword();
  };

  const onContinueClick = (): void => {
    navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`);
  };

  return {
    languageText,
    focusInput,
    userPassword,
    userData,
    sliderData,
    showBar,
    participantProfileDetails,
    onSubmitClick,
    handleInputChange,
    handleBlurEvent,
    onContinueClick,
  };
};
