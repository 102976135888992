import {
  ActivityStatus,
  ProfileStatus,
} from "./containers/profileList/profileEnums";
import { ProfileId } from "./containers/reducer";

export type Map<T> = { [key: string]: T };

export enum ReportTypes {
  SELF = "SELF",
  OTHER = "OTHER",
  BOTH = "BOTH",
}

export type ReportType =
  | ReportTypes.SELF
  | ReportTypes.OTHER
  | ReportTypes.BOTH;

export interface ApiResult<T> {
  totalCount: number;
  items: ReadonlyArray<T>;
}

export interface ValidationErrorInfo {
  message: string;
  members: string[];
}

//TODO: Joakim - Naming here is weird - find a better name.
export interface ErrorObject {
  code: number;
  message: string;
  details: string;
  validationErrors: ValidationErrorInfo[];
}

export type ApiResponse<T> =
  | { success: true; result: T }
  | { success: false; error: ErrorObject | undefined };

export interface CheckValidProfileOrRespondentLink {
  isProfile: boolean;
  isRespondent: boolean;
  languageId: number;
  languageName: string;
  isPasswordSet: boolean;
  profileId: ProfileId;
  profileRespondentId: number;
  emailAddress: string;
  participantName: string;
  /** FIXME: Trashcan name alert. Form partly filled out */
  isFormFilledData: boolean;
  isDemographicsFilled: boolean;
  /** FIXME: Trashcan name alert. Form fully filled out */
  isFormFilled: boolean;
  activityStatus: ActivityStatus;
  profileStatus: ProfileStatus;
}
