import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";
import { UserId } from "@app/containers/reducer";

interface ICustomActionsCellProps {
  id: UserId;
  languageText: ITranslationObject;
  handleEditClick: (id: UserId) => void;
  handleDisconnectClick: (id: UserId) => void;
}

export const AccountUsersActionsCell: React.FC<ICustomActionsCellProps> = (
  props,
) => {
  const { id, languageText, handleEditClick, handleDisconnectClick } = props;

  const onEditClick = (): void => {
    handleEditClick?.(id!);
  };

  const onDisconnectClick = (): void => {
    handleDisconnectClick(id);
  };

  const dropdownItems: ReadonlyArray<DropdownMenuItem> = [
    { name: getLanguageValue(languageText, "Edit"), onClick: onEditClick },
    {
      name: getLanguageValue(languageText, "Disconnect"),
      onClick: onDisconnectClick,
    },
  ];

  return <DropdownMenu items={dropdownItems} />;
};
