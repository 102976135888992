import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";

interface ILogModalProps {
  languageText: ITranslationObject;
  isEmail: boolean;
  inviteDetails: Array<string>;
  handleCloseClick: () => void;
}

export const LogModal: React.FC<ILogModalProps> = (props) => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          props.languageText,
          props.isEmail ? "Email details" : "SMS details",
        )}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
      >
        {props.inviteDetails.map((detail, index) => (
          <div key={index} className="mb-4">
            {detail}
          </div>
        ))}
      </ModalComponent>
    </>
  );
};
