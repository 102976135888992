import React from "react";

import { DeliveryStatus } from "./Utils/deliveryStatus";
import { DeliveryStatusPending } from "./Utils/deliveryStatusPending";
import { CancelPresentationModal } from "../facilitatorDelivery/Utils/cancelPresentationModal";
import { useParticipantDelivery } from "./hooks";
import { PresentationHeader } from "../facilitatorDelivery/Utils/presentationHeader";

export const ParticipantsDelivery = (): JSX.Element => {
  const {
    languageText,
    presentationData,
    presentationSlides,
    deliveryCancelled,
    deliveryFinished,
    presentationPending,
    coursePresentationDate,
    participantScreen,
    cancelPresentationModal,
    renderSlideHTML,
    handleClosePresentation,
    handleCancelPresentation,
    handleCloseModal,
  } = useParticipantDelivery();

  return (
    <>
      <div>
        {!presentationPending && !deliveryCancelled && !deliveryFinished && (
          <>
            <PresentationHeader
              languageText={languageText}
              slideNumber={participantScreen.slideNumber}
              participantName={presentationData.participantName}
              facilitatorName={presentationData.facilitatorName}
              profileSlideCount={Math.max(
                ...(presentationSlides?.map((s) => s.slideNumber) ?? [0]),
              )}
              handleCancelClick={handleCancelPresentation}
              isConnected={() => false}
            />
          </>
        )}

        <div className="portlet mb-0">
          {presentationPending && (
            <DeliveryStatusPending
              languageText={languageText}
              coursePresentationDate={coursePresentationDate}
              facilitatorName={presentationData.facilitatorName}
            />
          )}

          {!presentationPending &&
            !deliveryCancelled &&
            !deliveryFinished &&
            participantScreen &&
            renderSlideHTML()}

          {(deliveryCancelled || deliveryFinished) && (
            <DeliveryStatus
              languageText={languageText}
              isFinished={deliveryFinished}
              handleClosePresentation={handleClosePresentation}
            />
          )}
        </div>
      </div>

      {cancelPresentationModal && (
        <CancelPresentationModal
          languageText={languageText}
          isParticipantDelivery={true}
          handleCloseModal={handleCloseModal}
          handleConfirmCancelPresentation={handleClosePresentation}
        />
      )}
    </>
  );
};
