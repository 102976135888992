import React from "react";

type ChecklabelProps = {
  checked: boolean;
  checkedLabel: string;
  uncheckedLabel?: string;
};

export const Checklabel = (props: ChecklabelProps): JSX.Element => {
  return (
    <div className="d-flex align-items-center">
      {props.checked ? (
        <>
          <span className="me-2">
            <i className="text-success bi bi-check-circle-fill fs-4"></i>
          </span>
          <span>{props.checkedLabel}</span>
        </>
      ) : (
        <>
          <span className="me-2">
            <i className="text-danger bi bi-x-circle fs-4"></i>
          </span>
          <span>{props.uncheckedLabel ?? props.checkedLabel}</span>
        </>
      )}
    </div>
  );
};
